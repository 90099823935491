import styled from 'styled-components';

const BaseLoading = styled.img`
  height: 75px;
  width: 75px;
  margin: auto;
`;

export const BaseLoadingError = styled(BaseLoading)`
  background-color: red;
`;

export default BaseLoading;
