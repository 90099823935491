import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

// MENU
import CampaignContainer from './containers/menus/CampaignContainer';
import CampaignOptionsContainer from './containers/menus/CampaignOptionsContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();

  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'campaign':
        return <CampaignContainer />;
      case 'campaignOptions':
        return <CampaignOptionsContainer />;
      default:
        return <InProgressContainer text={menuName} />;
    }
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
