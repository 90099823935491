import React from 'react';
import { Container, Item, Row } from 'components/Layout';
import { Tabs } from 'components/Navigation';

import { YougovMetricsContainer, YougovSegmentsContainer } from './containers';

const YougovSettingsContainer = () => {
  const [menu, setMenu] = React.useState('metrics');
  return (
    <Container>
      <Row>
        <Item>
          <Tabs
            tabs={[
              { label: 'Metrics', onClick: () => setMenu('metrics') },
              { label: 'Segments', onClick: () => setMenu('settings') }
            ]}
          />
        </Item>
      </Row>
      <Row spacing={4}>
        {menu === 'metrics' && <YougovMetricsContainer />}
        {menu === 'settings' && <YougovSegmentsContainer />}
      </Row>
    </Container>
  );
};

export default YougovSettingsContainer;
