import { api } from './api';

const getAll = async () => {
  try {
    const result = await api.get(`/customInsights/list`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getAll
};
