import { api } from './api';

const createConnexion = async data => {
  try {
    const result = await api.post(`/v2/funnel/connectors/connexion`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConnexions = async () => {
  try {
    const result = await api.get(`/v2/funnel/connectors/connexion`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getConnexionsGenerator = async () => {
  try {
    const { data } = await api.get(`/v2/funnel/connectors/connexion/generator`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConnexion = async (body, id) => {
  try {
    const { data } = await api.put(
      `/v2/funnel/connectors/connexion/${id}`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteConnexion = async id => {
  try {
    const result = await api.delete(`/v2/funnel/connectors/connexion/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const generateTokens = async body => {
  try {
    const { data } = await api.post(
      `/v2/funnel/connectors/connexion/accessToken`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  createConnexion,
  updateConnexion,
  getConnexions,
  deleteConnexion,
  getConnexionsGenerator,
  generateTokens
};
