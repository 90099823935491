import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { routes } from 'utils/constants';
import { IsUpMd as IsUpMdBreakpoint } from 'utils/responsive';

import { Container, Item, Row } from 'components/Layout';

import DashboardWrapper, {
  DashboardBoxCta,
  DashboardBoxModule,
  DashboardButton,
  DashboardImage,
  DashboardText,
  DashboardTitle,
  Links
} from './style';

/**
 * Dashboard
 * @component
 */
const modules = {
  audience: {
    name: 'AUDIENCE',
    icon: './assets/img/icon-insight.png',
    new: 'Gérer la taxonomie',
    description:
      'Ajoutez, modifiez ou supprimez des critères et des catégories pour gérer la taxonomie de la section Audience.',
    route: routes.AUDIENCE
  },
  activation: {
    name: 'ACTIVATION',
    icon: './assets/img/icon-activation.png',
    new: 'Gérer les matrices',
    description: '',
    route: routes.ACTIVATION
  },
  funnel: {
    name: 'FUNNEL',
    icon: './assets/img/icon-funnel.png',
    new: 'Gérer les Funnel',
    description:
      "Ajoutez, modifiez ou supprimez des funnel, des groupes d'utilisateur ou encore des media Data Science.",
    route: routes.FUNNEL
  }
};

const AddedItems = props => {
  const { module, historyItemsNumber } = props;
  return (
    <DashboardBoxCta>
      {historyItemsNumber === 0 ? (
        <DashboardButton>
          <Links to={module.route}>
            <strong>{`${module.new}>`}</strong>
          </Links>
        </DashboardButton>
      ) : (
        <DashboardButton>
          <Links to={module.route}>
            <strong>
              {module.new} {'>'}
            </strong>
          </Links>
        </DashboardButton>
      )}
    </DashboardBoxCta>
  );
};
AddedItems.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  historyItemsNumber: PropTypes.number.isRequired
};

const DashboardModuleContent = props => {
  const { themeColors } = useTheme();
  const { module, color, borderRadius } = props;
  return (
    <DashboardBoxModule color={color} borderRadius={borderRadius}>
      <DashboardTitle small color={themeColors.keep_light}>
        <DashboardImage src={module.icon} alt="Ermes data" />
        {module.name}
      </DashboardTitle>
      <DashboardText color={themeColors.keep_light}>
        {module.description}
      </DashboardText>
      <AddedItems module={module} />
    </DashboardBoxModule>
  );
};
DashboardModuleContent.defaultProps = {
  borderRadius: '0 0 0 0'
};
DashboardModuleContent.propTypes = {
  module: PropTypes.objectOf(modules).isRequired,
  color: PropTypes.objectOf(PropTypes.any).isRequired,
  borderRadius: PropTypes.objectOf(PropTypes.any)
};

function Dashboard() {
  const { themeColors } = useTheme();
  const { isAuthenticated } = useUser();
  const IsUpMd = IsUpMdBreakpoint();
  const styleHeaderFix = isAuthenticated
    ? { marginTop: IsUpMd ? '90px' : '225px' }
    : { marginTop: '0px' };
  return (
    <Container>
      <Row>
        <Item flex style={{ flex: 'auto', ...styleHeaderFix }}>
          <DashboardWrapper>
            <DashboardModuleContent
              module={modules.audience}
              isAuthenticated={isAuthenticated}
              color={themeColors.insight}
              borderRadius="10px"
            />
            <DashboardModuleContent
              module={modules.activation}
              isAuthenticated={isAuthenticated}
              color={themeColors.activation}
              borderRadius="10px"
            />
            <DashboardModuleContent
              module={modules.funnel}
              isAuthenticated={isAuthenticated}
              color={themeColors.funnel}
              borderRadius="10px"
            />
          </DashboardWrapper>
        </Item>
      </Row>
    </Container>
  );
}

export default Dashboard;
