import React from 'react';
import PropTypes from 'prop-types';

import Fade from 'components/Fade';

const styleDiv = {
  color: 'white',
  width: '100%',
  textAlign: 'center',
  lineHeight: '75px',
  fontSize: '30px',
  justify: 'center',
  alignItems: 'center'
};

export const Header = props => {
  const { show } = props;
  return (
    <Fade show={show}>
      <div
        style={{
          height: '100px',
          minHeight: '100px',
          backgroundColor: '#282c34',
          ...styleDiv
        }}
        {...props}
      />
    </Fade>
  );
};

Header.defaultProps = {
  show: null
};
Header.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export const Footer = props => {
  const { show } = props;
  return (
    <Fade show={show}>
      <div
        style={{
          height: '60px',
          minHeight: '60px',
          backgroundColor: '#282c34',
          ...styleDiv
        }}
        {...props}
      />
    </Fade>
  );
};

Footer.defaultProps = {
  show: null
};
Footer.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export const Content = props => {
  const { show } = props;
  return (
    <Fade show={show}>
      <div
        style={{
          height: '100%',
          backgroundColor: '#8141f1',
          ...styleDiv
        }}
        {...props}
      />
    </Fade>
  );
};

Content.defaultProps = {
  show: null
};
Content.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export default Content;
