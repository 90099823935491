import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

import Sidebar, { SideItem } from 'components/Sidebar';

/**
 * BaseSidebarAudience
 * @component
 *
 */
const BaseSidebarAudience = withTheme(styled(Sidebar)`
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${({ themeColors }) => themeColors.secondary};
`);

export default BaseSidebarAudience;

export const SideItemAudience = styled(SideItem)`
  height: 68px;
  p {
    min-width: 300px;
  }
`;
