import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const ConstraintChannelsContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [constraintChannel, setConstraintChannelList] = React.useState([]);
  const [fees, setfeesList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [
    selectedConstraintChannel,
    setSelectedConstraintChannel
  ] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectConstraintChannel = id => {
    const selected = constraintChannel.find(el => el._id === id);
    setSelectedConstraintChannel(selected);
  };
  const closeModal = () => {
    setModal(null);
    selectConstraintChannel({});
  };
  const getConstraintsChannels = async () => {
    try {
      const result = await ACTIVATION.getConstraintsChannels(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getFees = async () => {
    try {
      const result = await ACTIVATION.getFees(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectConstraintChannel(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateConstraintsChannels(
      selectedConstraintChannel._id,
      datas
    ).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    Promise.all([getConstraintsChannels(), getFees()]).then(resp => {
      const contraints = resp[0];
      setLoaded(true);
      setConstraintChannelList(contraints);
      setfeesList(resp[1]);
      const mapped = contraints.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.channel = item.channel;
        newItem.start = item.start;
        newItem.duration = item.duration;
        newItem.minBudget = item.minBudget;
        newItem.format = item.format !== 'none' ? item.format : 'aucun';
        newItem.maxRepeat = item.maxRepeat;
        newItem.averageCPM = item.averageCPM;
        newItem.offTargetISC = numeral(item.offTargetISC).format('0[.]00%');
        newItem.fee = resp[1].find(el => el._id === item.fee)?.key || 'Aucun';
        newItem.memoBeta = item.memoBeta;
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Canal',
                  id: 'channel',
                  sortKey: 'channel'
                },
                {
                  label: 'Délai(jours)',
                  id: 'start'
                },
                {
                  label: 'Durée minimale(jours)',
                  id: 'duration'
                },
                {
                  label: 'Budget minimum(€)',
                  id: 'minBudget'
                },
                {
                  label: 'Format nécessaire',
                  id: 'format'
                },
                {
                  label: 'Répétition maximale',
                  id: 'maxRepeat'
                },
                {
                  label: 'CPM moyen(€)',
                  id: 'averageCPM'
                },
                {
                  label: 'Taux d’impressions sur cible hors data moyen',
                  id: 'offTargetISC'
                },
                {
                  label: 'Groupe tarifaire',
                  id: 'fee'
                },
                {
                  label: 'Beta de mémorisation',
                  id: 'memoBeta'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedConstraintChannel, fees }}
              actionName="Modifier"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConstraintChannelsContainer;
