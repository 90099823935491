import React from 'react';
import { useSnackbar } from 'notistack';

import { Item } from 'components/Layout';

const NotifContext = React.createContext();

const NotifProvider = props => {
  const { enqueueSnackbar } = useSnackbar();
  const notify = ({ message, variants, time = 4000 }) => {
    return enqueueSnackbar([<Item>{message}</Item>], {
      variant: variants,
      autoHideDuration: time
    });
  };
  const value = {
    notify
  };

  return <NotifContext.Provider {...props} value={value} />;
};

export const useNotif = () => {
  const context = React.useContext(NotifContext);
  if (!context)
    throw new Error('NotifContext must be called in Notif Provider');
  return context;
};

export default NotifProvider;
