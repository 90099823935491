// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Color from 'color';

import { useTheme } from 'contexts/ThemeContext';

import { Tooltip } from 'components/DataDisplay';
import Fade from 'components/Fade';

export const Button = props => {
  const {
    color,
    rounded,
    size,
    show,
    style,
    variant,
    widthSize,
    upperCase,
    disabled,
    disabledMessage,
    fontColor,
    ...rest
  } = props;

  const { panelColor, themeColors } = useTheme();
  let colorRules = themeColors[color] || color || themeColors[panelColor];
  let txtColor = fontColor || themeColors.keep_light;

  let styleByVariant = { color: colorRules };
  if (variant === 'outlined') {
    styleByVariant = {
      color: colorRules,
      border: `1px solid ${colorRules}`
    };
  }
  if (variant === 'contained') {
    if (disabled) {
      colorRules = themeColors.themeV2.detail;
      txtColor = themeColors.themeV2.black;
    }
    styleByVariant = {
      color: txtColor,
      backgroundColor: colorRules,
      '&:hover': {
        color: Color(themeColors.keep_light)
          .darken(0.1)
          .string(),
        backgroundColor: Color(colorRules)
          .darken(0.3)
          .string()
      }
    };
  }
  const styles = {
    borderRadius: rounded && '22px',
    ...styleByVariant,
    width:
      (widthSize === 'medium' && 200) ||
      (widthSize === 'large' && 300) ||
      widthSize,
    textTransform: upperCase ? 'uppercase' : 'none',
    ...style
  };

  if (disabled && disabledMessage) {
    return (
      <Fade show={show}>
        <Tooltip arrow title={disabledMessage}>
          <LoadingButton
            style={styles}
            disabled={disabled}
            size={size}
            {...rest}
          />
        </Tooltip>
      </Fade>
    );
  }

  return (
    <Fade show={show}>
      <LoadingButton style={styles} disabled={disabled} size={size} {...rest} />
    </Fade>
  );
};

export default Button;

Button.defaultProps = {
  color: null,
  rounded: false,
  size: 'medium',
  show: null,
  style: null,
  variant: 'text',
  widthSize: 'small',
  disabled: false
};
Button.propTypes = {
  color: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  style: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  widthSize: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool
};
