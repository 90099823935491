import React from 'react';
import { Container } from '@mui/material';
import styled from 'styled-components';

const BaseSection = styled.div`
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: ${({ borderradius }) => borderradius || '2px'};
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'stretch'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  height: ${({ height }) => height || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => width || 'auto'};
  min-height: ${({ minHeight }) => minHeight || 'auto'};
  color: ${({ color }) => color || 'currentcolor'};
  box-sizing: border-box;
  position: ${({ position }) => position || 'inherit'};

  .is-flex {
    display: flex;
  }

  .flex-direction-column {
    flex-direction: column;
  }

  .flex-1 {
    flex: 1;
  }
`;

export const BaseSectionContainer = styled(Container)`
  && {
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

const Section = props => {
  return <BaseSection {...props} />;
};

export default Section;
