import { api } from './api';

const getFunnelUpdateStatusById = async funnelId => {
  try {
    const result = await api.get(`v2/funnel/updatestatus/${funnelId}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getFunnelUpdateStatus = async () => {
  try {
    const result = await api.get(`v2/funnel/updatestatus`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getLastUpdateStatus = async funnelIds => {
  try {
    const { data } = await api.post(`v2/funnel/updatestatus/lastStatus`, {
      funnelIds
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getByFunnelId = async (id, last7days = 'false') => {
  try {
    const { data } = await api.get(`v2/funnel/updatestatus/funnel/${id}`, {
      params: { last7days }
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getFunnelUpdateJob = async datas => {
  try {
    const result = await api.post(
      `v2/funnel/updatestatus/funnelUpdateJob`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getFunnelUpdateStatusById,
  getFunnelUpdateStatus,
  getFunnelUpdateJob,
  getLastUpdateStatus,
  getByFunnelId
};
