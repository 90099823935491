import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { ACTIVATION, PARTNERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const PartnersContainer = () => {
  const { adminRights } = useUser();
  const { themeColors } = useTheme();

  const [openModal, setOpenModal] = useState('');
  const [config, setConfig] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [partners, setPartners] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [activableMedias, setActivableMedias] = useState([]);

  const addPartners = async () => {
    if (adminRights?.audiences?.partners?.create) {
      setConfig({ partners: {}, activableMedias });
      setOpenModal('modalCreate');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const closeModal = () => {
    setOpenModal('');
    setSelectedRow({});
  };

  const openModalDelete = deleteRow => {
    const selectPartner = rows.find(row => row._id === deleteRow);
    setSelectedRow(selectPartner);
    setOpenModal('modalDelete');
  };

  // Actions CRUD modale
  const onUpdate = async datas => {
    if (adminRights?.audiences?.partners?.update) {
      await PARTNERS.updatePartner(selectedRow._id, datas);
      setLoaded(false);
      setSelectedRow({});
      closeModal();
    } else {
      setOpenModal('unauthorized');
    }
  };

  const updatePartner = updateRow => {
    const selectPartner = rows.find(row => row._id === updateRow);
    setConfig({ partner: selectPartner, activableMedias });
    setSelectedRow(selectPartner);
    setOpenModal('modalUpdate');
  };

  const deletePartner = async () => {
    if (adminRights?.audiences?.partners?.delete) {
      await PARTNERS.deletePartner(selectedRow._id);
      setSelectedRow({});
      setOpenModal('');
      setLoaded(false);
    } else {
      setOpenModal('unauthorized');
    }
  };

  const onCreate = async datas => {
    await PARTNERS.create(datas);
    setLoaded(false);
    closeModal();
  };

  // Load Data
  useEffect(() => {
    const getToolTipText = txt => {
      return (
        <Row spacing={0}>
          <Item>
            <Text color={themeColors.dark}>{txt}</Text>
          </Item>
        </Row>
      );
    };

    const formatActivation = presets => {
      const nbPresets = presets.length;

      if (nbPresets === 0) {
        return <Text>Aucun</Text>;
      }
      const str = `${nbPresets} ${pluralize('paramétrage', nbPresets)}`;

      const tooltipContent = () => {
        return (
          <Container>
            <Row spacing={0}>
              <Item>{presets.map(({ media }) => getToolTipText(media))}</Item>
            </Row>
          </Container>
        );
      };

      return (
        <Container>
          <Row spacing={0}>
            <Item>
              <Tooltip title={tooltipContent()}>
                <Text color="audience">{str}</Text>
              </Tooltip>
            </Item>
          </Row>
        </Container>
      );
    };

    const fetchDatas = async () => {
      try {
        const allPartners = await PARTNERS.getAll();
        const allActivableMedias = await ACTIVATION.getActivableMedias();
        setPartners(
          allPartners.map(partner => ({
            ...partner,
            cpms: formatActivation(partner.cpms),
            priorities: formatActivation(partner.priorities)
          }))
        );
        setActivableMedias(allActivableMedias);
        setRows(allPartners);
        setLoaded(true);

        return;
      } catch (err) {
        console.error(err);
      }
    };

    if (!loaded) {
      fetchDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const filterRows = newRows => {
    setPartners(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={partners}
            placeholder="Nom, Email, ..."
            titleHead="Rechercher un partenaire"
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addPartners} text="Ajouter un partenaire" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              config={config}
              onValidate={onCreate}
              actionName="Créer le partenaire"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} src="/assets/img/loader_insight.svg" />
          {loaded && (
            <TableData
              rows={partners}
              headers={[
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Email', id: 'email' },
                { label: 'CPM', id: 'cpms' },
                { label: 'Priorité', id: 'priorities' }
              ]}
              hidenFields={['_id']}
              onUpdate={updatePartner}
              onDelete={openModalDelete}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              config={config}
              onValidate={onUpdate}
              actionName="Modifier le partenaire"
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              open={openModal === 'modalDelete'}
              type="le partenaire"
              name={selectedRow.name}
              onClose={closeModal}
              onDelete={deletePartner}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default PartnersContainer;
