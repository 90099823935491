// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Switch as SwitchComp } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

export const Switch = props => {
  const {
    checked,
    color,
    onChange,
    size,
    show,
    title,
    required,
    tooltip,
    options = [],
    defaultColor,
    ...rest
  } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    root: {
      width: 47,
      height: 26,
      padding: 0
    },
    switchBase: {
      padding: 1,
      color: themeColors.lightSmooth,
      '&$checked': {
        transform: 'translateX(21px)',
        color: '#fff',
        '& + $track': {
          backgroundColor: colorRules,
          opacity: 1,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        color: colorRules,
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${themeColors.bgSwitchOff}`,
      backgroundColor: themeColors.bgSwitchOff,
      opacity: 1
    },
    checked: {},
    focusVisible: {}
  });
  const classes = useStyles();

  const onNext = event => {
    onChange(event.target.checked);
  };

  return (
    <Fade show={show}>
      {title && (
        <Row spacing={2} justify="flex-start" alignItems="center">
          <Item
            justify="flex-start"
            flex
            style={{
              maxWidth: tooltip ? 'calc(100% - 24px)' : null
            }}
          >
            <Text noWrap fontSize="14px">
              {title === '&nbsp;' ? <>&nbsp;</> : title}
              {required && '*'}
            </Text>
          </Item>
          {tooltip && (
            <Item flex style={{ alignItem: 'center' }}>
              <HelpTooltip title={tooltip} />
            </Item>
          )}
        </Row>
      )}
      {options[0] && (
        <Text fontWeight={!checked ? 700 : 400}>{options[0]}</Text>
      )}
      <SwitchComp
        checked={checked}
        classes={classes}
        onChange={onNext}
        size={size}
        color={defaultColor}
        {...rest}
      />
      {options[1] && <Text fontWeight={checked ? 700 : 400}>{options[1]}</Text>}
    </Fade>
  );
};

Switch.defaultProps = {
  checked: null,
  color: null,
  size: 'medium',
  show: null,
  title: null,
  tootltip: null,
  required: false
};

Switch.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  title: PropTypes.string,
  tootltip: PropTypes.string,
  required: PropTypes.bool
};

export default Switch;
