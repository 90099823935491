import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import {
  ACTIVATEDAUDIENCES,
  CUSTOMINSIGHT,
  F_AUDIENCEMAPPING,
  F_DATAFEEDBACKCONFIG,
  F_LINEITEMS,
  FUNNEL,
  PROPERTIES
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { HelpTooltip, Text } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const DataFeedBackContainer = () => {
  const [allFunnels, setFunnels] = useState([]);
  const [allActivatedAudiences, setActivatedAudiences] = useState([]);
  const [customInsights, setCustomInsights] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selected, setSelected] = useState({});
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelected(null);
  };

  const selectItem = id => {
    const item = allRows.find(el => el._id === id);
    setSelected(item);

    return selected;
  };

  const formatConfig = (steps, funnel) => {
    return (
      <Container>
        <Row spacing={0}>
          <Item alignItems="center" justify="center">
            <Text>{steps.length} </Text>
            <HelpTooltip
              width="12px"
              title={
                <Row spacing={1} alignItems="center">
                  {steps.map(step => {
                    const funnelLevel =
                      funnel?.schemaId?.steps?.[step.level - 1]?.name;
                    return (
                      <Item justify="flex-start">
                        <Text color="dark">
                          {funnelLevel} : {step.type}
                        </Text>
                      </Item>
                    );
                  })}
                </Row>
              }
            />
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const [
      properties,
      { funnels },
      aggs,
      lineItems,
      dataFeedbacks,
      audiencesMappings,
      activatedAudiences,
      newCustomInsights
    ] = await Promise.all([
      PROPERTIES.get(),
      FUNNEL.getFunnels(['name']),
      FUNNEL.getAggregationConfig(),
      F_LINEITEMS.get(),
      F_DATAFEEDBACKCONFIG.get(),
      F_AUDIENCEMAPPING.get(),
      ACTIVATEDAUDIENCES.getActivatedAudiences(),
      CUSTOMINSIGHT.getAll()
    ]);


    setLoaded(true);
    const mapped = dataFeedbacks.map(item => {
      const funnel = funnels.find(el => el._id === item.funnelId);
      const newAudiencesMappings = audiencesMappings.find(
        el => el.funnelId === item.funnelId
      );
      const selectedActivatedAudiences = newAudiencesMappings?.activatedAudiences?.flatMap(
        el => {
          const foundAudience = activatedAudiences.find(f => f._id === el);
          return foundAudience ? [foundAudience] : [];
        }
      );

      const newItem = {
        ...item,
        funnelName: funnel?.name,
        funnel,
        lineItems: lineItems.reduce((acc, curr) => {
          if (curr.funnelId === item.funnelId) {
            if (!acc[curr.insightActivatedAudienceId]) {
              acc[curr.insightActivatedAudienceId] = [curr];
            } else {
              acc[curr.insightActivatedAudienceId].push(curr);
            }
          }
          return acc;
        }, []),
        flatLineItems: lineItems.filter(el => {
          return el.funnelId === item.funnelId;
        }, []),
        audiencesMappings: newAudiencesMappings,
        selectedActivatedAudiences
      };

      return newItem;
    });
    setCustomInsights(newCustomInsights);
    setActivatedAudiences(activatedAudiences);
    setFunnels(
      funnels
        .filter(el => !aggs.find(aggr => aggr.funnelId === el._id))
        .map(el => {
          const propertie =
            properties.find(prop => prop.funnelIds.includes(el._id))?.name ||
            'Autres';
          return { ...el, name: `${propertie} > ${el.name}` };
        })
    );
    setRows(mapped);
    setAllRows(mapped);
  };
  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const openCreate = async () => {
    if (adminRights?.funnel?.dataFeedback?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async data => {
    const mappedLineItems = data.audiencesMappings.activatedAudiences.reduce(
      (acc, curr) => {
        if (data.lineItems[curr]) {
          acc.push(...data.lineItems[curr]);
        }
        return acc;
      },
      []
    );
    for await (const el of mappedLineItems) {
      F_LINEITEMS.create({ ...el, funnelId: data.funnelId, withData: true });
    }
    await F_AUDIENCEMAPPING.create({
      funnelId: data.funnelId,
      activatedAudiences: data?.audiencesMappings?.activatedAudiences
    });
    await F_DATAFEEDBACKCONFIG.create({
      funnelId: data.funnelId,
      steps: data.steps,
      noInvoice: data.noInvoice
    });
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.funnel?.dataFeedback?.update) {
      selectItem(id);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async data => {
    const mappedLineItems = data.audiencesMappings.activatedAudiences.reduce(
      (acc, curr) => {
        if (data.lineItems[curr]) {
          acc.push(...data.lineItems[curr]);
        }
        return acc;
      },
      []
    );
    const mappedSelectedLineItems = selected.audiencesMappings.activatedAudiences.reduce(
      (acc, curr) => {
        if (selected.lineItems[curr]) {
          acc.push(...selected.lineItems[curr]);
        }
        return acc;
      },
      []
    );
    const toCreate = [];
    const toDelete = [];
    for await (const el of mappedLineItems) {
      const isExist = mappedSelectedLineItems.find(e => {
        return (
          e.externalId === el.externalId &&
          e.media === el.media &&
          e.insightActivatedAudienceId === el.insightActivatedAudienceId
        );
      });
      if (!isExist) {
        toCreate.push({
          ...el,
          funnelId: data.funnelId,
          withData: true
        });
      }
    }

    for await (const el of mappedSelectedLineItems) {
      const isExist = mappedLineItems.find(
        e =>
          e.externalId === el.externalId &&
          e.media === el.media &&
          e.insightActivatedAudienceId === el.insightActivatedAudienceId
      );
      if (!isExist) {
        toDelete.push(el._id);
      }
    }

    if (toCreate.length > 0) {
      await F_LINEITEMS.createMany(toCreate);
    }
    if (toDelete.length > 0) {
      await F_LINEITEMS.deleteByIds(toDelete);
    }
    await F_AUDIENCEMAPPING.update(
      data.audiencesMappings._id,
      data.audiencesMappings
    );
    await F_DATAFEEDBACKCONFIG.update(selected._id, {
      funnelId: data.funnelId,
      steps: data.steps,
      noInvoice: data.noInvoice
    });
    closeModal();
    setLoaded(false);
  };

  const openDelete = id => {
    if (adminRights?.funnel?.dataFeedback?.delete) {
      selectItem(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const onDelete = async () => {
    await F_LINEITEMS.deleteByFunnelId(selected.funnelId);
    await F_AUDIENCEMAPPING.deleteOne(selected.audiencesMappings._id);
    await F_DATAFEEDBACKCONFIG.deleteOne(selected._id);
    setLoaded(false);
    closeModal();
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche un funnel"
            placeholder="Funnel id, propriété, nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter une configuration" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              config={{ funnels: allFunnels, allActivatedAudiences }}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'ID du funnel', id: 'funnelId', sortKey: 'name' },
                { label: 'Nom du funnel', id: 'funnelName', sortKey: 'name' },
                {
                  label: 'LI avec data',
                  id: 'flatLineItems',
                  format: value => value?.length
                },
                {
                  label: 'Audiences activées',
                  id: 'selectedActivatedAudiences',
                  format: value => value?.length
                },
                {
                  label: 'Configuration des niveaux',
                  id: 'steps',
                  format: (value, row) => formatConfig(value, row.funnel)
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdate}
              onDelete={openDelete}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                funnels: allFunnels,
                selected,
                allActivatedAudiences,
                customInsights
              }}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              type="ce paramètre"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default DataFeedBackContainer;
