import React from 'react';

import { CustomerProvider } from './contexts/customContext';
import CustomerContainer from './CustomerContainer';

const CustomerApp = () => {
  return (
    <CustomerProvider>
      <CustomerContainer />
    </CustomerProvider>
  );
}

export default CustomerApp;
