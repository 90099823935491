import React, { useState } from 'react';
import { MenuItem, Popover } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

import { Divider, Text } from 'components/DataDisplay';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const Menu = ({
  options,
  children,
  // width = '278px',
  contentBgColor,
  variant = '',
  color = '',
  custom = false
}) => {
  const { themeColors } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = event => {
    if (anchorEl) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-menu' : undefined;

  const handleClick = action => () => {
    if (typeof action === 'function') {
      action();
    }
    handleClose();
  };
  const formatMenuItem = menu => {
    if (Array.isArray(menu)) {
      return (
        <Item>
          <Row
            alignItems="flex-start"
            spacing={0}
            style={{ maxHeight: '320px', overflow: 'auto' }}
          >
            {menu.map(el => formatMenuItem(el))}
          </Row>
          <Divider style={{ width: '90%' }} />
        </Item>
      );
    }

    if (custom) {
      return (
        <Item justify="flex-start" alignItems="center">
          <Button
            disabled={menu.disabled}
            onClick={handleClick(menu.onClick)}
            style={{
              height: '25px',
              width: '100%',
              justifyContent: 'flex-start',
              backgroundColor: menu.disabled
                ? themeColors.themeV2.subtitle
                : 'white',
              borderRadius: '0px'
            }}
          >
            <Text fontWeight={400} fontSize="12px">
              {menu.label}
            </Text>
          </Button>
        </Item>
      );
    }

    return (
      <Item justify="flex-start" alignItems="center">
        <MenuItem onClick={handleClick(menu.onClick)}>{menu.label}</MenuItem>
      </Item>
    );
  };

  return (
    <Container>
      <Button
        onClick={handleOpen}
        widthSize="fit-content"
        variant={variant}
        color={color}
      >
        {children}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container
          style={{
            backgroundColor: contentBgColor,
            width: '207px'
          }}
        >
          <Row alignItems="flex-start" spacing={0}>
            {options.map(option => formatMenuItem(option))}
          </Row>
        </Container>
      </Popover>
    </Container>
  );
};

export default Menu;
