import { api } from './api';

const getUploadTask = async () => {
  try {
    const { data } = await api.get(`/v2/activation/segmentUploadTask/`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getUploadTaskUser = async userId => {
  try {
    const { data } = await api.get(
      `/v2/activation/segmentUploadTask/user/${userId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getUploadTask,
  getUploadTaskUser
};
