import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { FUNNEL, PROPERTIES } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const PropertiesContainer = () => {
  const [config, setConfig] = useState({});
  const [propertiesList, setProperties] = useState([]);
  const [funnelsList, setFunnels] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedPropertie, setSelectedPropertie] = useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelectedPropertie({});
  };

  const selectPropertie = id => {
    const propertie = propertiesList.find(el => el._id === id);
    setSelectedPropertie(propertie);

    return propertie;
  };

  const getProperties = async () => {
    try {
      const result = await PROPERTIES.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatFunnels = datas => {
    const funnels = datas.filter(el => !!el);
    const nbFunnel = funnels.length;

    if (nbFunnel === 0) {
      return <Text>Aucun</Text>;
    }
    const str = `${nbFunnel} ${pluralize('funnel', nbFunnel)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{funnels.map(funnel => getToolTipText(funnel.name))}</Item>
          </Row>
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="funnel">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const [properties, { funnels }] = await Promise.all([
      getProperties(),
      FUNNEL.getFunnels(['name'])
    ]);

    setLoaded(true);
    const mapped = properties.map(item => {
      const newItem = {};
      newItem._id = item._id;
      newItem.name = item.name;
      newItem.funnels = formatFunnels(
        item.funnelIds.map(id => funnels.find(el => el._id === id))
      );
      return newItem;
    });
    setFunnels(funnels);
    setProperties(properties);
    setRows(mapped);
    setAllRows(mapped);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const openCreate = async () => {
    if (adminRights?.funnel?.properties?.create) {
      setConfig({ funnels: funnelsList });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async data => {
    await PROPERTIES.create({
      name: data.name,
      funnelIds: data.selectedFunnels.map(e => e._id)
    });
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.funnel?.properties?.update) {
      const propertie = selectPropertie(id);
      setConfig({
        funnels: funnelsList,
        propertie: {
          id,
          name: propertie.name,
          selectedFunnels: propertie.funnelIds.map(e =>
            funnelsList.find(f => f._id === e)
          )
        }
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async data => {
    await PROPERTIES.update(selectedPropertie._id, {
      name: data.name,
      funnelIds: data.selectedFunnels.map(el => el._id)
    });
    closeModal();
    setLoaded(false);
  };

  const openDelete = id => {
    if (adminRights?.funnel?.properties?.delete) {
      selectPropertie(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onDelete = async () => {
    await PROPERTIES.deleteProperties(selectedPropertie._id);
    setLoaded(false);
    closeModal();
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche une propriété"
            placeholder="Funnel id, propriété, nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter une propriété" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onCreate={onCreate}
              config={config}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Funnels', id: 'funnels' }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdate}
              onDelete={openDelete}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onCreate={onUpdate}
              config={config}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedPropertie.name}
              type="ce paramètre"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default PropertiesContainer;
