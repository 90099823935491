import { api } from './api';

export default {
  getConfig: async () => {
    try {
      const result = await api.get(`/backoffice-funnel/configFunnel`);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  getFunnels: async fields => {
    try {
      const { data } = await api.get(`/backoffice-funnel/funnel`, {
        params: { fields }
      });
      return data;
    } catch (err) {
      console.error(err);
      return { funnels: [] };
    }
  },

  getTagMapping: async id => {
    try {
      const result = await api.get(
        `/backoffice-funnel/funnel/tagmapping/${id}`
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  setTagMapping: async datas => {
    try {
      const result = await api.put(
        `/backoffice-funnel/funnel/tagmapping`,
        datas
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  deleteTagMapping: async id => {
    try {
      const result = await api.delete(
        `/backoffice-funnel/funnel/tagmapping/${id}`
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  getFunnelById: async id => {
    try {
      const result = await api.get(`/backoffice-funnel/funnel/${id}`);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  update: async (id, datas) => {
    try {
      const result = await api.put(`/backoffice-funnel/funnel/${id}`, datas);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  create: async datas => {
    try {
      const result = await api.post(`/backoffice-funnel/funnel`, datas);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  deleteFunnel: async id => {
    try {
      const result = await api.delete(`/backoffice-funnel/funnel/${id}`);
      return result;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  getSeasonality: async id => {
    try {
      const result = await api.get(
        `/backoffice-funnel/funnel/seasonality/${id}`
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  setSeasonality: async datas => {
    try {
      const result = await api.put(
        `/backoffice-funnel/funnel/seasonality`,
        datas
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  getComputings: async () => {
    try {
      const { data } = await api.get(
        `/backoffice-funnel/funnel/funnelcomputings`
      );
      return data.computings;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  setFunnelComputing: async (funnelId, computeAll) => {
    try {
      await api.post(
        `/v2/funnel/backoffice/funnel/funnelComputing/${funnelId}`,
        {
          computeAll
        }
      );
      return true;
    } catch (err) {
      console.error('setFunnelComputing', err);
      return false;
    }
  },

  getCampaignDetailsTs: async datas => {
    try {
      const result = await api.post(`/v2/funnel/campaigns/exportts`, datas, {
        timeout: 0
      });
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  getCampaignDetailsTsMedia: async datas => {
    try {
      const result = await api.post(
        `/v2/funnel/campaigns/exporttsmedia`,
        datas,
        { timeout: 0 }
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  getBudget: async () => {
    try {
      const result = await api.get(`/v2/funnel/funnelbudget`);
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  funnelAnomaliesBoundaries: async () => {
    try {
      const result = await api.get(`/v2/funnel/funnelbudget`);
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  getAggregationConfig: async () => {
    try {
      const result = await api.get(`/v2/funnel/aggregationConfig`);
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },

  createAggregationConfig: async datas => {
    try {
      const result = await api.post(`/v2/funnel/aggregationConfig`, datas);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  deleteAggregationConfig: async id => {
    try {
      const result = await api.delete(`/v2/funnel/aggregationConfig/${id}`);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  updateAggregationConfig: async (id, datas) => {
    try {
      const result = await api.put(`/v2/funnel/aggregationConfig/${id}`, datas);
      return result.data;
    } catch (err) {
      console.error(err);
      return {};
    }
  },

  getEmailAddress: async () => {
    try {
      const { data } = await api.get(`/v2/funnel/emailAddress`);
      return data;
    } catch (err) {
      console.error('getEmailAddress', err);
      return [];
    }
  },

  createEmailAddress: async datas => {
    try {
      const { data } = await api.post(`/v2/funnel/emailAddress`, datas);
      return data;
    } catch (err) {
      console.error('createEmailAddress', err);
      return {};
    }
  },

  deleteEmailAddress: async id => {
    try {
      const { data } = await api.delete(`/v2/funnel/emailAddress/${id}`);
      return data;
    } catch (err) {
      console.error('deleteEmailAddress', err);
      return {};
    }
  },

  getCampaigns: async funnelId => {
    try {
      const { data } = await api.post(`/v2/funnel/campaigns/list`, {
        funnelId
      });
      return data;
    } catch (err) {
      return [];
    }
  },

  getMediasAndKpisList: async funnelId => {
    try {
      const { data } = await api.get(
        `/v2/funnel/campaigns/mediasandkpis/${funnelId}`
      );
      return data;
    } catch (err) {
      return { medias: [], kpis: [] };
    }
  },

  deleteFunnelData: async ({
    medias = [],
    kpis = [],
    funnelId,
    start,
    end
  }) => {
    try {
      const { data } = await api.delete(`/v2/funnel/campaigns/${funnelId}`, {
        params: { start, end },
        data: { medias, kpis }
      });
      return data;
    } catch (err) {
      return {};
    }
  },

  sendMailValidation: async funnelId => {
    try {
      const { data } = await api.post(`/v2/funnel/notifyfunnel`, {
        funnelId
      });
      return data;
    } catch (err) {
      return {};
    }
  },
  getDataInvoicing: async params => {
    try {
      const { data } = await api.get(`/v2/funnel/dataInvoicing`, {
        params,
        timeout: 0
      });
      return data;
    } catch (err) {
      return {};
    }
  },
  setupDataFeedback: async campaignConfigId => {
    try {
      const { data } = await api.post(`/v2/funnel/dataFeedbackConfig/setup`, {
        campaignConfigId
      });
      return data;
    } catch (err) {
      console.error(err);
      return [];
    }
  },
  getMediaDailyRates: async () => {
    try {
      const result = await api.get(
        `/backoffice-funnel/funnel/mediasdailyrates`
      );
      return result.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  }
};
