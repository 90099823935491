import React from 'react';

import { Text } from 'components/DataDisplay';
import { LLM } from 'utils/rest';

import { QuestionContainer } from './QuestionContainer';
import IntervalTimer from 'react-interval-timer';

import { DiscussionContainer } from './DiscussionContainer';
import { Row, Item } from 'components/Layout';

export const Learning = () => {
  const [request, setRequest] = React.useState('');
  const [discussion, setDiscussion] = React.useState(null);
  const [discussionId, setDiscussionId] = React.useState(null);
  const [polling, setPolling] = React.useState(false);
  const [finished, setFinished] = React.useState(false);

  const getDiscussion = async () => {
    const data = await LLM.getLearningById(discussionId);
    if (data.status === 'waitingForAnswer') {
      setDiscussion(data);
      setPolling(false);
    }
  };

  const createDiscussion = async message => {
    const payload = {
      config: {
        criteria_segmentation: {
          request: message,
          category: '',
          parameters: {
            temperature: 0.7
          }
        }
      }
    };
    const data = await LLM.createLearning(payload);
    if (data) {
      setPolling(true);
      setDiscussionId(data.id);
      setFinished(false);
    }
  };

  const updateDiscussion = async avis => {
    const payload = {
      config: {
        ...discussion.config,
        avis
      }
    };

    await LLM.updateLearning(discussionId, payload);
    if (!avis) {
      setPolling(true);
      setDiscussion(null);
    } else {
      setDiscussion(null);
      setRequest('');
      setFinished(true);
    }
  };

  return (
    <>
      <IntervalTimer
        timeout={2000}
        callback={getDiscussion}
        enabled={polling}
        repeat
      />
      <Row>
        <Item xs={12} alignItems="flex-start">
          <QuestionContainer
            setRequest={setRequest}
            createDiscussion={createDiscussion}
            polling={polling}
            request={request}
          />
          {discussionId && !finished && (
            <DiscussionContainer
              discussion={discussion}
              updateDiscussion={updateDiscussion}
              polling={polling}
            />
          )}
          {finished && (
            <Row>
              <Item xs={6} alignItems="flex-start">
                <Text align="left">Votre réponse a bien été enregistrée !</Text>
              </Item>
            </Row>
          )}
        </Item>
      </Row>
    </>
  );
};

export default Learning;
