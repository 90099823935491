import React from 'react';
import { PropTypes } from 'prop-types';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';

import { useTheme } from 'contexts/ThemeContext';

import { fontSizes } from 'utils/constants';
import moment from 'utils/moment';

const DivWrapper = ({
  onDatesChange,
  endDate,
  startDate,
  borderColor,
  bgColor,
  fontSize,
  className,
  width,
  ...rest
}) => {
  return <div className={className} {...rest} />;
};
DivWrapper.defaultProps = {
  onDatesChange: () => {},
  endDate: null,
  startDate: null,
  borderColor: null,
  bgColor: null,
  fontSize: null,
  width: null,
  className: null
};
DivWrapper.propTypes = {
  onDatesChange: PropTypes.func,
  endDate: PropTypes.instanceOf(moment),
  startDate: PropTypes.instanceOf(moment),
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string
};

/**
 * BaseDateWrapper
 * @component
 *
 */
const OriginBaseDateWrapper = styled(DivWrapper)`
  display: inline-block;
  min-width: ${({ width }) => width * 2 || '436px'};
  .DateInput,
  .DateRangePickerInput {
    background-color: transparent;
    border: none;
  }
  .DateInput {
    width: ${({ width }) => width || '165px'};
  }
  .DateInput_input {
    font-size: ${({ fontSize }) => fontSize || fontSizes.small};
    background-color: ${({ bgColor, themeColors }) =>
      bgColor || themeColors.light};
    color: ${({ color, themeColors }) => color || themeColors.dark};
    border: 1px solid
      ${({ borderColor, themeColors }) => borderColor || themeColors.black};
    border-radius: 4px;
    padding: 10px 8px;
    cursor: pointer;
    line-height: 0;
    &:disabled {
      color: grey;
      border-color: grey;
    }
  }

  .DateRangePickerInput_arrow_svg {
    fill: ${({ borderColor, themeColors }) => borderColor || themeColors.black};
    visibility: hidden;
    &:disabled {
      color: grey;
      border-color: grey;
    }
    margin: 0 10px;
    width: 14px;
    height: 14px;
  }

  .CalendarDay__selected_span {
    background: ${({ themeColors }) => themeColors.funnel};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected {
    background: ${({ themeColors }) => themeColors.funnel_dark};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }

  .CalendarDay__selected_span:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }
  @media (min-width: 1800px) {
    .DateInput {
      width: 160px;
    }
    .DateInput_input {
      font-size: ${fontSizes.medium};
      padding: 7px 16px;
    }
    .DateRangePickerInput_arrow_svg {
      width: 24px;
      height: 24px;
      margin: 0 10px;
    }
  }
`;

export const BaseDateWrapper = props => {
  const { themeColors } = useTheme();
  return <OriginBaseDateWrapper {...props} themeColors={themeColors} />;
};

const DateRangePickerWrapper = ({ className, ...rest }) => {
  return <DateRangePicker {...rest} />;
};
DateRangePickerWrapper.defaultProps = {
  className: null
};
DateRangePickerWrapper.propTypes = {
  className: PropTypes.string
};

const BaseDateRange = styled(DateRangePickerWrapper)`
  && {
    &:disabled {
      color: grey;
    }
  }
`;

export default BaseDateRange;
