import React, { useEffect, useState } from 'react';

import { PLATFORMCONFIGS } from 'utils/rest';

const { getPlatformConfigs } = PLATFORMCONFIGS;

const PlatformConfigContext = React.createContext();

/**
 * PlatformConfigProvider
 * @component
 */

const PlatformConfigProvider = props => {
  const [platformConfig, setPlatformConfig] = useState();
  const fetchPlatformConfig = async () => {
    const result = await getPlatformConfigs();
    setPlatformConfig(result[0]);
  };

  useEffect(() => {
    fetchPlatformConfig();
  }, []);

  const value = {
    ...platformConfig
  };
  return <PlatformConfigContext.Provider {...props} value={value} />;
};

export default PlatformConfigProvider;

export const usePlatformConfig = () => {
  const context = React.useContext(PlatformConfigContext);
  if (!context) throw new Error('PlatformConfigContext must be in PlatformConfigProvider');
  return context;
};
