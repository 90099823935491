import React, { useCallback, useEffect, useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { DASHBOARD, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Table, Text } from 'components/DataDisplay';
import { IconButton, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { MessageContents, ModalForm } from './components';

const DashboardContentContainer = () => {
  const { themeColors } = useTheme();
  const [contents, setContentsList] = useState([]);
  const [users, setUsersList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const { adminRights } = useUser();

  const selectContents = id => {
    const selected = contents.find(el => el._id === id);
    setSelectedContent(selected);
  };
  const selectContentsByLabel = label => {
    const selected = contents.find(el => el.label === label);
    setSelectedContent(selected);
  };
  const closeModal = () => {
    setModal(null);
  };
  const getUsers = async () => {
    try {
      const result = await USERS.getAll();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getContents = async () => {
    try {
      const result = await DASHBOARD.getContents();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const resetOtherDefault = id => {
    const oldDefault = contents.filter(el => el.isDefault && el._id !== id);
    const list = oldDefault.map(el => {
      const newEl = el;
      newEl.isDefault = false;
      return DASHBOARD.updateContent(el._id, newEl);
    });

    return Promise.all(list);
  };

  const onCreate = async datas => {
    const list = [];
    if (datas.isDefault) {
      list.push(resetOtherDefault());
    }
    list.push(DASHBOARD.createContent(datas));
    closeModal();
    await Promise.all(list).then(() => {
      setLoaded(false);
    });
  };

  const onCreateModal = () => {
    if (adminRights?.general?.dashboard?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const onUpdateModal = () => {
    if (adminRights?.general?.dashboard?.update) {
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onDelete = async () => {
    closeModal();
    await DASHBOARD.deleteContent(selectedContent._id);
    selectContents(null);
    setLoaded(false);
  };

  const onDeleteModal = () => {
    if (adminRights?.general?.dashboard?.delete) {
      onDelete();
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async datas => {
    const list = [];
    if (datas.isDefault) {
      list.push(resetOtherDefault(selectedContent._id));
    }
    list.push(DASHBOARD.updateContent(selectedContent._id, datas));
    closeModal();
    await Promise.all(list).then(() => {
      setLoaded(false);
    });
  };

  const getDatas = useCallback(() => {
    Promise.all([getContents(), getUsers()]).then(resp => {
      const contentsResp = resp[0].map(el => {
        const item = el;
        item.label = el.title + (el.isDefault ? ' (par défaut)' : '');
        return item;
      });

      if (!selectedContent) {
        const defaultContent =
          contentsResp.find(el => el.isDefault) || contentsResp[0];
        setSelectedContent(defaultContent);
      }
      setLoaded(true);
      setContentsList(contentsResp);
      setUsersList(resp[1]);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={5}>
          {contents.length === 0 && (
            <Text variant="h6">Aucun message paramétré</Text>
          )}
          {contents.length !== 0 && (
            <Select
              options={contents}
              value={selectedContent && selectedContent.label}
              onChange={data => {
                selectContentsByLabel(data);
              }}
            />
          )}
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={onCreateModal} text="Ajouter un  message" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              config={{ users }}
              onValidate={onCreate}
              actionName="Créer le funnel"
            />
          )}
        </Item>
      </Row>
      <Row spacing={0}>
        <Loading loading={!loaded} />
        {selectedContent && (
          <Item>
            <Container>
              <Row spacing={2} justify="flex-start">
                <Item justify="flex-start">
                  <Text variant="h5">
                    Positionnement :&nbsp;
                    <Text component="span">
                      {selectedContent.isOnTop ? ' Au dessus' : 'En dessous'}
                    </Text>
                  </Text>
                </Item>
              </Row>
              <Row
                spacing={2}
                justify="flex-start"
                style={{ margin: '0px 0px 12px' }}
              >
                <Item xs justify="flex-start">
                  <Text variant="h5">Message : </Text>
                </Item>
                <Item flex>
                  <IconButton onClick={onUpdateModal}>
                    <CreateIcon style={{ color: themeColors.light }} />
                  </IconButton>
                  <IconButton onClick={onDeleteModal}>
                    <DeleteIcon style={{ color: themeColors.light }} />
                  </IconButton>
                </Item>
              </Row>
              <Row spacing={0}>
                <MessageContents contents={selectedContent.contents} />
              </Row>
              {!selectedContent.isDefault && (
                <>
                  <Row spacing={2} justify="flex-start">
                    <Item xs={8} justify="flex-start">
                      <Text variant="h5">Utilisateurs : </Text>
                    </Item>
                  </Row>
                  <Row spacing={2} justify="flex-start">
                    <Item xs={12} justify="flex-start">
                      <Table
                        rows={users.filter(
                          el => selectedContent.userIds.indexOf(el._id) !== -1
                        )}
                        headers={[
                          {
                            label: 'Nom',
                            id: 'family_name',
                            sortKey: 'family_name'
                          },
                          {
                            label: 'Prénom',
                            id: 'given_name',
                            sortKey: 'given_name'
                          },
                          {
                            label: 'Propriétaire',
                            id: 'email',
                            style: { color: 'funnel' },
                            sortKey: 'email'
                          }
                        ]}
                        noHeader={['_id', 'createdAt', 'id', 'updatedAt']}
                      />
                    </Item>
                  </Row>
                </>
              )}
            </Container>
          </Item>
        )}
        {openModal === 'ModalUpdate' &&
          adminRights.general.dashboard.update && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedContent, users }}
              actionName="Modifier"
            />
          )}
        {openModal === 'unauthorized' && (
          <UnauthorizedModal
            open={openModal === 'unauthorized'}
            onClose={closeModal}
          />
        )}
      </Row>
    </Container>
  );
};

export default DashboardContentContainer;
