// READY TO STORYBOOK
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as SelectMui
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { referencial } from 'utils/ref';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

export const Select = props => {
  const {
    color,
    defaultValue,
    error,
    errorText,
    helperText,
    label,
    onChange,
    options,
    show,
    small,
    value,
    bgColor,
    textColor,
    title,
    required,
    tooltip,
    fontSize,
    width,
    ...rest
  } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const [localOptions, setLocalOptions] = useState([]);

  const refreshOptions = async () => {
    let newOption = options;

    if (typeof newOption === 'string') {
      newOption =
        typeof referencial[options] === 'function'
          ? await referencial[options]()
          : [];
    }
    setLocalOptions(newOption);
  };

  useEffect(() => {
    refreshOptions();
    // eslint-disable-next-line
  }, [options]);

  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },
      '& .MuiInputBase-root': {
        color: textColor || themeColors.light,
        backgroundColor: bgColor || 'transparent',
        fontSize
      },
      '& .MuiSelect-icon': {
        color: textColor || themeColors.light
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .Mui-disabled': {
        backgroundColor: 'transparant'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });
  const classes = useStyles();

  const handleChange = event => {
    onChange(event.target.value);
  };
  return (
    <Fade show={show}>
      <FormControl
        variant="outlined"
        error={error}
        className={classes.root}
        style={{
          padding: small && '6px 0px',
          width
        }}
      >
        {title && (
          <Row spacing={2} justify="flex-start" alignItems="center">
            <Item
              justify="flex-start"
              flex
              style={{
                maxWidth: tooltip ? 'calc(100% - 24px)' : null
              }}
            >
              <Text noWrap color={error ? 'error' : null} fontSize="14px">
                {title === '&nbsp;' ? <>&nbsp;</> : title}
                {required && '*'}
              </Text>
            </Item>
            {tooltip && (
              <Item flex style={{ alignItem: 'center' }}>
                <HelpTooltip title={tooltip} />
              </Item>
            )}
          </Row>
        )}
        {label && <InputLabel>{label}</InputLabel>}
        <SelectMui
          style={{ height: small && '40px' }}
          value={value || defaultValue}
          defaultValue={defaultValue}
          onChange={handleChange}
          {...rest}
        >
          {defaultValue && (
            <MenuItem disabled value={defaultValue}>
              {defaultValue}
            </MenuItem>
          )}
          {localOptions.map((item, index) => {
            // Old List, need to be delete after refact
            if (typeof item === 'string') {
              const key = `${index}-${item}`;
              return (
                <MenuItem key={key} value={item}>
                  {item}
                </MenuItem>
              );
            }
            // New List, array of Objects
            const key = `${index}-${item.label && item.label}`;
            return item.subHeader ? (
              <ListSubheader key={key} disableSticky>
                {item.subHeader}
              </ListSubheader>
            ) : (
              <MenuItem key={key} value={item.value || item.label}>
                {item.label && item.label}
              </MenuItem>
            );
          })}
        </SelectMui>
        {((error && errorText) || helperText) && (
          <FormHelperText id="helper-text">
            {errorText || helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Fade>
  );
};

export default Select;

Select.defaultProps = {
  color: null,
  defaultValue: null,
  error: false,
  errorText: null,
  helperText: null,
  label: null,
  onChange: () => null,
  options: [],
  width: '100%',
  show: null,
  small: true,
  value: null,
  bgColor: null,
  fontSize: null,
  title: null,
  tootltip: null,
  required: false
};
Select.propTypes = {
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
  ]),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  small: PropTypes.bool,
  value: PropTypes.string,
  bgColor: PropTypes.string,
  fontSize: PropTypes.string,
  title: PropTypes.string,
  tootltip: PropTypes.string,
  required: PropTypes.bool
};
