import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from 'contexts/UserContext';

import { BUDGETALERT, FUNNEL, PROPERTIES, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { HelpTooltip, Text } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const FollowBudgetContainer = () => {
  const [notUsedFunnel, setNotUsedFunnels] = useState([]);
  const [usedFunnel, setUsedFunnels] = useState([]);
  const [allUsers, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selected, setSelected] = useState({});
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelected(null);
  };

  const selectItem = id => {
    const item = allRows.find(el => el._id === id);
    setSelected(item);
    return selected;
  };

  const formatConfig = emails => {
    if (emails.length === 0) {
      return <Text>Aucun</Text>;
    }
    return (
      <Container>
        <Row spacing={0}>
          <Item alignItems="center">
            <Text>{emails.length} </Text>
            <HelpTooltip
              width="12px"
              title={
                <Row spacing={1} alignItems="center">
                  {emails.map(email => {
                    return (
                      <Item justify="flex-start">
                        <Text color="dark">{email}</Text>
                      </Item>
                    );
                  })}
                </Row>
              }
            />
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const [{ funnels }, properties, budgetAlerting, users] = await Promise.all([
      FUNNEL.getFunnels(['name']),
      PROPERTIES.get(),
      BUDGETALERT.getAll(),
      USERS.getAll()
    ]);

    const userEmail = users.map(e => e.email);
    setLoaded(true);
    const tmpUsedFunnel = [];
    const mappedFunnelLIst = funnels.map(el => {
      const propertie =
        properties.find(prop => prop.funnelIds.includes(el._id))?.name ||
        'Autres';
      return { ...el, name: `${propertie} > ${el.name}` };
    });
    const mapped = budgetAlerting.map(item => {
      const funnel = mappedFunnelLIst.find(el => el._id === item.funnelId);

      if (funnel) {
        tmpUsedFunnel.push(funnel);
      }

      const newItem = {
        ...item,
        funnelName: funnel?.name
      };
      return newItem;
    });

    const tmpNotUsedFunnel = mappedFunnelLIst.filter(el => {
      if (tmpUsedFunnel?.find(e => el._id === e._id)) {
        return false;
      }
      return true;
    });

    setUsers(userEmail);
    setNotUsedFunnels(tmpNotUsedFunnel);
    setUsedFunnels(tmpUsedFunnel);
    setRows(mapped);
    setAllRows(mapped);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const openCreate = async () => {
    if (adminRights?.funnel?.alert?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async data => {
    await BUDGETALERT.create(data);
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.funnel?.alert?.update) {
      selectItem(id);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async data => {
    await BUDGETALERT.update(selected._id, data);
    closeModal();
    setLoaded(false);
  };

  const openDelete = id => {
    if (adminRights?.funnel?.alert?.delete) {
      selectItem(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const onDelete = async () => {
    await BUDGETALERT.deleteOne(selected._id);
    setLoaded(false);
    closeModal();
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche un funnel"
            placeholder="Funnel id, propriété, nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter une configuration" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              config={{ funnels: notUsedFunnel, allUsers }}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'ID du funnel', id: 'funnelId', sortKey: 'name' },
                { label: 'Nom du funnel', id: 'funnelName', sortKey: 'name' },
                {
                  label: 'URL Google Sheet',
                  id: 'sheetUrl',
                  format: value => (
                    <Link to={{ pathname: value }} target="blank">
                      <Text noWrap>{value}</Text>
                    </Link>
                  ),
                  cellStyle: { maxWidth: '220px' }
                },
                {
                  label: 'Seuil de tolérance',
                  id: 'toleranceLimit'
                },
                {
                  label: 'Alertes mail',
                  id: 'alerting',
                  format: value => (value ? 'Oui' : 'Non')
                },
                {
                  label: 'Adresse alerting',
                  id: 'emails',
                  format: value => formatConfig(value)
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdate}
              onDelete={openDelete}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                funnels: usedFunnel,
                selected,
                allUsers
              }}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              type="ce paramètre"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default FollowBudgetContainer;
