import React from 'react';
import PropTypes from 'prop-types';
import FadeMUI from '@mui/material/Fade';

const Fade = props => {
  const { show, children } = props;
  return show === true || show === false ? (
    <FadeMUI in={show}>{children}</FadeMUI>
  ) : (
    <>{show !== 'none' && children}</>
  );
};

Fade.defaultProps = {
  children: null,
  show: 'default'
};
Fade.propTypes = {
  children: PropTypes.node,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export default Fade;
