import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DayPickerRangeController } from 'react-dates';

import moment from 'utils/moment';

import { BaseDateWrapper } from './style';

const DayPickerRangeControllerWrapper = props => {
  const {
    autoFocusEndDate,
    startDate,
    endDate,
    renderCalendarInfo,
    minimumNights,
    onDatesChange,
    datePickerKey
  } = props;

  const [focusedInput, setFocusInput] = useState(
    autoFocusEndDate ? 'startDate' : 'endDate'
  );

  const onFocusChange = focusedInputChange => {
    setFocusInput(!focusedInputChange ? 'startDate' : focusedInputChange);
  };

  return (
    <BaseDateWrapper>
      <DayPickerRangeController
        key={datePickerKey}
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        calendarInfoPosition="before"
        renderCalendarInfo={renderCalendarInfo}
        orientation="horizontal"
        numberOfMonths={2}
        minimumNights={minimumNights}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={
          endDate && startDate
            ? () => (focusedInput === 'endDate' ? endDate : startDate)
            : null
        }
      />
    </BaseDateWrapper>
  );
};

DayPickerRangeControllerWrapper.propTypes = {
  autoFocusEndDate: PropTypes.bool,
  minimumNights: PropTypes.number,
  renderCalendarInfo: PropTypes.node,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onDatesChange: PropTypes.func,
  datePickerKey: PropTypes.string
};
DayPickerRangeControllerWrapper.defaultProps = {
  autoFocusEndDate: false,
  startDate: null,
  endDate: null,
  minimumNights: 0,
  renderCalendarInfo: null,
  onDatesChange: () => {},
  datePickerKey: 'defaultKey'
};

export default DayPickerRangeControllerWrapper;
