import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  errorName: false,
  errorModel: false
};
export const ModalForm = props => {
  const {
    config,
    onValidate,
    onClose,
    actionName,
    connexionsGenerator
  } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config?.name || '',
    model: config?.model || '',
    username: config?.username,
    password: config?.password,
    clientId: config?.clientId || '',
    clientSecret: config?.clientSecret || '',
    clientEmail: config?.clientEmail || '',
    clientCertUrl: config?.clientCertUrl || '',
    accessToken: config?.accessToken || '',
    refreshToken: config?.refreshToken || '',
    developerToken: config?.developerToken || '',
    consumerKey: config?.consumerKey || '',
    consumerSecretKey: config?.consumerSecretKey || '',
    accessTokenSecret: config?.accessTokenSecret || '',
    orgId: config?.orgId || '',
    projectId: config?.projectId || '',
    privateKeyId: config?.privateKeyId || '',
    privateKey: config?.privateKey || '',
    networkCode: config?.networkCode || ''
  };
  const [
    {
      name,
      model,
      username,
      password,
      clientId,
      clientSecret,
      clientEmail,
      clientCertUrl,
      accessToken,
      refreshToken,
      developerToken,
      consumerKey,
      consumerSecretKey,
      accessTokenSecret,
      orgId,
      projectId,
      privateKeyId,
      privateKey,
      networkCode
    },
    setState
  ] = useState(initialState);
  const [{ errorName, errorModel }, setError] = useState(ErrorState);
  const [fields, setFields] = useState(
    connexionsGenerator.find(el => el.name === config?.model)?.fields || []
  );

  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };
  const toggleErrorModel = state => {
    setError(prevState => ({ ...prevState, errorModel: state }));
  };
  const handleChange = field => value => {
    const text = value.trim();
    if (field === 'model') {
      toggleErrorModel(!text);

      const list =
        connexionsGenerator.find(el => el.name === text)?.fields || [];
      setFields(list);
      setState(prevState => ({ ...prevState, model: text, name: text }));

      return;
    }
    setState(prevState => ({ ...prevState, [field]: text }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const doAction = () => {
    let error = false;

    const datas = {
      name,
      model,
      username,
      password,
      clientId,
      clientSecret,
      clientEmail,
      clientCertUrl,
      accessToken,
      refreshToken,
      developerToken,
      consumerKey,
      consumerSecretKey,
      accessTokenSecret,
      orgId,
      projectId,
      privateKeyId,
      privateKey,
      networkCode
    };
    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (!model) {
      toggleErrorModel(true);
      error = true;
    }
    if (!error) {
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} la connexion
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text color={errorModel ? 'error' : 'inherit'}>
                          Model du connectors *
                        </Text>
                        <Select
                          label="Choisisez un connector"
                          options={connexionsGenerator.map(el => el.name)}
                          onChange={handleChange('model')}
                          value={model}
                        />
                      </Item>
                    </Row>
                  </Item>
                  {model && (
                    <Item xs={6} justify="flex-start">
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du connectors *
                          </Text>
                          <TextField
                            placeholder={model}
                            error={errorName}
                            onChange={handleChange('name')}
                            value={name}
                          />
                        </Item>
                      </Row>
                    </Item>
                  )}
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {(fields.indexOf('clientId') !== -1 ||
          fields.indexOf('clientSecret') !== -1 ||
          fields.indexOf('clientEmail') !== -1 ||
          fields.indexOf('clientCertUrl') !== -1 ||
          fields.indexOf('accessToken') !== -1 ||
          fields.indexOf('refreshToken') !== -1 ||
          fields.indexOf('developerToken') !== -1 ||
          fields.indexOf('consumerKey') !== -1 ||
          fields.indexOf('consumerSecretKey') !== -1 ||
          fields.indexOf('accessTokenSecret') !== -1 ||
          fields.indexOf('orgId') !== -1 ||
          fields.indexOf('projectId') !== -1 ||
          fields.indexOf('privateKeyId') !== -1 ||
          fields.indexOf('privateKey') !== -1 ||
          fields.indexOf('networkCode') !== -1) && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="Connexion" xs={3}>
                <Container>
                  <Row spacing={0} justify="flex-start">
                    {fields.indexOf('clientId') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">clientId</Text>
                              <TextField
                                small
                                label="Renseigner un clientId"
                                onChange={handleChange('clientId')}
                                value={clientId}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('clientSecret') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">clientSecret</Text>
                              <TextField
                                small
                                label="Renseigner un clientSecret"
                                onChange={handleChange('clientSecret')}
                                value={clientSecret}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('clientEmail') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">clientEmail</Text>
                              <TextField
                                small
                                label="Renseigner un clientEmail"
                                onChange={handleChange('clientEmail')}
                                value={clientEmail}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('clientCertUrl') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">clientCertUrl</Text>
                              <TextField
                                small
                                label="Renseigner un clientCertUrl"
                                onChange={handleChange('clientCertUrl')}
                                value={clientCertUrl}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('accessToken') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">accessToken</Text>
                              <TextField
                                small
                                label="Renseigner un accessToken"
                                onChange={handleChange('accessToken')}
                                value={accessToken}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('refreshToken') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">refreshToken</Text>
                              <TextField
                                small
                                label="Renseigner un refreshToken"
                                onChange={handleChange('refreshToken')}
                                value={refreshToken}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('developerToken') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">developerToken</Text>
                              <TextField
                                small
                                label="Renseigner un developerToken"
                                onChange={handleChange('developerToken')}
                                value={developerToken}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('consumerKey') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">consumerKey</Text>
                              <TextField
                                small
                                label="Renseigner un consumerKey"
                                onChange={handleChange('consumerKey')}
                                value={consumerKey}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('consumerSecretKey') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">consumerSecretKey</Text>
                              <TextField
                                small
                                label="Renseigner un consumerSecretKey"
                                onChange={handleChange('consumerSecretKey')}
                                value={consumerSecretKey}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('accessTokenSecret') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">accessTokenSecret</Text>
                              <TextField
                                small
                                label="Renseigner un accessTokenSecret"
                                onChange={handleChange('accessTokenSecret')}
                                value={accessTokenSecret}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('orgId') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">orgId</Text>
                              <TextField
                                small
                                label="Renseigner un orgId"
                                onChange={handleChange('orgId')}
                                value={orgId}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('projectId') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">projectId</Text>
                              <TextField
                                small
                                label="Renseigner un projectId"
                                onChange={handleChange('projectId')}
                                value={projectId}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('privateKeyId') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">privateKeyId</Text>
                              <TextField
                                small
                                label="Renseigner un privateKeyId"
                                onChange={handleChange('privateKeyId')}
                                value={privateKeyId}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('privateKey') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">privateKey</Text>
                              <TextField
                                small
                                label="Renseigner un privateKey"
                                onChange={handleChange('privateKey')}
                                value={privateKey}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                    {fields.indexOf('networkCode') !== -1 && (
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text color="inherit">networkCode</Text>
                              <TextField
                                small
                                label="Renseigner un networkCode"
                                onChange={handleChange('networkCode')}
                                value={networkCode}
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                    )}
                  </Row>
                </Container>
              </FormSection>
            </Item>
          </Row>
        )}
        {(fields.indexOf('username') !== -1 ||
          fields.indexOf('password') !== -1) && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="Identifiant" xs={3}>
                <Container>
                  <Row spacing={0} justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Container>
                        <Row spacing={0} justify="flex-start">
                          <Item xs={11} justify="flex-start">
                            <Text color="inherit">Utilisateur</Text>
                            <TextField
                              small
                              label="Renseigner un identifiant"
                              onChange={handleChange('username')}
                              value={username}
                            />
                          </Item>
                        </Row>
                      </Container>
                    </Item>
                    <Item xs={6} justify="flex-start">
                      <Container>
                        <Row spacing={0} justify="flex-start">
                          <Item xs={11} justify="flex-start">
                            <Text color="inherit">password</Text>
                            <TextField
                              small
                              label="Renseigner un mot de passe"
                              onChange={handleChange('password')}
                              value={password}
                            />
                          </Item>
                        </Row>
                      </Container>
                    </Item>
                  </Row>
                </Container>
              </FormSection>
            </Item>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null,
  actionName: ''
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  connexionsGenerator: PropTypes.arrayOf(PropTypes.any).isRequired,
  actionName: PropTypes.string
};

export default ModalForm;
