import React from 'react';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalMail = props => {
  const { onClose, onSendMail, selectedFunnel } = props;
  const { themeColors } = useTheme();

  return (
    <Modal maxWidth="sm" {...props}>
      <Container>
        <Row>
          <Item>
            <Text bold variant="h6" align="center" color={themeColors.funnel}>
              Contacter les membres
            </Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Text>
              Cette action va vous permettre d’envoyer un email général à tous
              les membres de votre organisation afin de les prévenir de l’accès
              à leur funnel.
            </Text>
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={6}>
            <Button
              color={themeColors.funnel}
              size="medium"
              widthSize="small"
              onClick={onClose}
            >
              <ChevronLeftIcon />
              Annuler
            </Button>
          </Item>
          <Item xs={12} sm={6}>
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="small"
              onClick={() => {
                onSendMail(selectedFunnel);
              }}
            >
              <ChevronRightIcon />
              OK
            </Button>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalMail.defaultProps = {};
ModalMail.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSendMail: PropTypes.func.isRequired,
  selectedFunnel: PropTypes.shape().isRequired
};

export default ModalMail;
