import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATION, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const OfflineCPMContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [offlineCPM, setOfflineCPMList] = React.useState([]);
  const [config, setConfig] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedOfflineCPM, setSelectedOfflineCPM] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectOfflineCPM = id => {
    const selected = offlineCPM.find(el => el._id === id);
    setSelectedOfflineCPM(selected);
  };
  const closeModal = () => {
    setModal(null);
    selectOfflineCPM({});
  };
  const getOfflineCPM = async () => {
    try {
      const result = await ACTIVATION.getOfflineCPM(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getUsers = async () => {
    try {
      const result = await USERS.getAll();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getChannels = async () => {
    try {
      const result = await ACTIVATION.getChannels();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getMediaProxies = async () => {
    try {
      const result = await ACTIVATION.getOfflineMediaProxies();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const onCreate = datas => {
    ACTIVATION.createOfflineCPM({ preset: currentPresetId, ...datas }).then(
      () => {
        setLoaded(false);
        closeModal();
      }
    );
  };

  const openCreateModal = () => {
    if (adminRights?.activation?.update) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectOfflineCPM(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateOfflineCPM(selectedOfflineCPM._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    Promise.all([
      getOfflineCPM(),
      getUsers(),
      getChannels(),
      getMediaProxies()
    ]).then(resp => {
      const contraints = resp[0];
      const conf = {
        users: resp[1],
        channels: resp[2],
        offlineMediaProxies: resp[3]
      };
      setLoaded(true);
      setOfflineCPMList(resp[0]);
      setConfig(conf);
      const mapped = contraints.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.offlineMediaProxy = item.offlineMediaProxy.name;
        newItem.channel = item.channel?.label;
        newItem.user =
          conf.users.find(el => el._id === item.user)?.email || 'Aucun';
        newItem.cpm = item.cpm;
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3} />
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              config={config}
              onValidate={onCreate}
              actionName="Ajouter"
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Cible média traditionnel',
                  id: 'offlineMediaProxy',
                  sortKey: 'offlineMediaProxy'
                },
                {
                  label: 'Canal',
                  id: 'channel',
                  sortKey: 'channel'
                },
                {
                  label: 'Utilisateur (optionnel)',
                  id: 'user',
                  sortKey: 'user'
                },
                {
                  label: 'CPM (€)',
                  id: 'cpm'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedOfflineCPM, ...config }}
              actionName="Modifier le média"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default OfflineCPMContainer;
