import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { IMPORTMODEL, MEDIA } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const ConnectorContainer = () => {
  const [connectorList, setConnectors] = useState([]);
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [mediaList, setMadiaList] = useState([]);
  const [openModal, setModal] = useState(null);
  const [selectedConnector, setSelectedConnector] = useState({});

  const closeModal = () => {
    setModal(null);
    setSelectedConnector({});
  };

  const onDelete = async () => {
    await IMPORTMODEL.deleteById(selectedConnector._id);
    setLoaded(false);
    closeModal();
  };

  const selectConnector = id => {
    const connector = connectorList.find(el => el._id === id);
    setSelectedConnector(connector);
  };

  const getDatas = async () => {
    const resImportModel = await IMPORTMODEL.get();
    const medias = await MEDIA.getMedias();
    setMadiaList(medias);
    setRows(resImportModel);
    setAllRows(resImportModel);
    setConnectors(resImportModel);
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateConnecteur = async data => {
    await IMPORTMODEL.create(data);
    setModal(null);
    setLoaded(false);
  };

  const handleUpdateConnector = async data => {
    await IMPORTMODEL.update(selectedConnector._id, data);
    setModal(null);
    setLoaded(false);
  };

  const addConnexion = () => {
    if (adminRights?.general?.connector?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const updateFunnel = id => {
    if (adminRights?.general?.connector?.update) {
      selectConnector(id);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const deleteConnector = id => {
    if (adminRights?.general?.connector?.delete) {
      selectConnector(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher un connecteur"
            placeholder="facebook, amazon..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addConnexion} text="Ajouter un connecteur" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={handleCreateConnecteur}
              mediaList={mediaList}
              actionName="Créer"
            />
          )}
        </Item>
      </Row>
      <Row>
        <Item>
          <TableData
            rows={rows}
            headers={[
              { label: 'label', id: 'label', sortKey: 'label' },
              { label: 'model', id: 'model' },
              { label: 'connectorName', id: 'connectorName' },
              { label: 'exportAgregationKey', id: 'exportAgregationKey' }
            ]}
            onUpdate={updateFunnel}
            onDelete={deleteConnector}
          />
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={handleUpdateConnector}
              config={selectedConnector}
              mediaList={mediaList}
              actionName="Modifier"
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedConnector.name}
              type="cette connexion"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConnectorContainer;
