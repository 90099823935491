import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { AutocompleteTextField, Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

export const ModalForm = props => {
  const { onValidate, config } = props;
  const {
    selectedActivatedAudience = {},
    selectedExtractAudiences = {},
    allGroups
  } = config;
  const startGroup = allGroups.filter(e =>
    e.activatedAudiences.includes(selectedActivatedAudience._id)
  );
  const initialState = {
    name: selectedActivatedAudience.name,
    extractId: selectedActivatedAudience.extractId,
    selectedGroup: startGroup,
    removedGroup: []
  };
  const [
    { name, extractId, selectedGroup, removedGroup },
    setState
  ] = React.useState(initialState);

  const handleChange = itemKey => value => {
    const text = value.trim();
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const handleChangeGroup = value => {
    const valueIds = value.map(e => e._id);
    const removed = startGroup.filter(el => !valueIds.includes(el._id));
    setState(prevState => {
      const states = { ...prevState };
      states.selectedGroup = value;
      states.removedGroup = removed;
      return states;
    });
  };

  const handleChangeExport = value => {
    const newObj = selectedExtractAudiences.find(el => el.name === value);
    setState(prevState => {
      const states = { ...prevState };
      states.extractId = newObj?._id;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    const datas = {
      name,
      extractId,
      selectedGroup,
      removedGroup
    };
    if (!name || name.length === 0 || !extractId || extractId.length === 0) {
      return;
    }
    onValidate(datas);
    resetStates();
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              Valider
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };
  const removeDuplicates = array => {
    const uniqueId = {};
    return array.filter(item => {
      if (!uniqueId[item.name]) {
        uniqueId[item.name] = true;
        return true;
      }
      return false;
    });
  };
  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Export</Text>
                          <AutocompleteTextField
                            options={removeDuplicates(selectedExtractAudiences)}
                            required
                            placeholder="Choisissez un export"
                            formatLabel={option => option.name}
                            small
                            value={
                              removeDuplicates(selectedExtractAudiences)?.find(
                                el => el._id === extractId
                              )?.name
                            }
                            onChange={item => handleChangeExport(item?.name)}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Label</Text>
                          <TextField
                            small
                            value={name}
                            required
                            placeholder="Choisissez un label"
                            onChange={handleChange('name')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Organisation" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={allGroups.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name']}
                      values={selectedGroup.map(el => ({
                        name: el.name,
                        _id: el._id
                      }))}
                      onChange={handleChangeGroup}
                      actionTxt="Ajouter une organisation"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null,
  update: false
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool
};

export default ModalForm;
