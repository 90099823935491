import React from 'react';
import { PropTypes } from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import styled from 'styled-components';

import { useTheme } from 'contexts/ThemeContext';

import { fontSizes } from 'utils/constants';

/**
 * SingleBaseDateWrapper
 * @component
 *
 */
const OriginSingleBaseDateWrapper = styled.div`
  display: inline-block;
  .DateInput,
  .SingleDatePickerInput,
  .DayPicker {
    background: ${({ themeColors }) => themeColors.secondary} !important;
  }
  .DayPicker__horizontal,
  .CalendarMonth,
  .CalendarMonth,
  .CalendarMonthGrid {
    background: transparent;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }

  .DayPickerNavigation_button__default {
    background: ${({ themeColors }) => themeColors.secondary};
    border: ${({ themeColors }) => `1px solid ${themeColors.light}`};
  }
  .DayPicker_weekHeader,
  .CalendarMonth_caption {
    color: ${({ themeColors }) => themeColors.light};
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__default {
    background: transparent;
    color: ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected_span {
    background: ${({ themeColors }) => themeColors.funnel};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected {
    background: ${({ themeColors }) => themeColors.funnel_dark};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }

  .CalendarDay__selected_span:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }

  .CalendarDay__blocked_out_of_range {
    color: ${({ themeColors }) => themeColors.disableTxt};
    text-decoration: line-through;
  }

  .DateInput {
    width: 100%;
    height: 36.5px;
    background-color: ${({ bgColor, themeColors }) =>
      bgColor || themeColors.primary};
  }

  .DateInput_input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: ${({ fontSize }) => fontSize || fontSizes.medium};
    background-color: ${({ bgColor, themeColors }) =>
      bgColor || themeColors.primary};
    color: ${({ color, themeColors }) => color || themeColors.light};
    border: ${({ error }) => (error ? '1px solid #FF0000' : 'none')};
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
    line-height: 0;
    &:disabled {
      color: grey;
    }
  }

  .SingleDatePicker_picker {
    border: 1px solid #e4e7e7;
  }
`;

export const SingleBaseDateWrapper = props => {
  const { themeColors } = useTheme();
  return <OriginSingleBaseDateWrapper {...props} themeColors={themeColors} />;
};

const SingleDatePickerWrapper = ({ className, ...rest }) => {
  return <SingleDatePicker {...rest} />;
};
SingleDatePickerWrapper.defaultProps = {
  className: null
};
SingleDatePickerWrapper.propTypes = {
  className: PropTypes.string
};

const SingleBaseDatePicker = styled(SingleDatePickerWrapper)`
  && {
    &:disabled {
      color: grey;
    }
  }
`;

export default SingleBaseDatePicker;
