import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

/**
 * BaseLayout
 * @component
 *
 */
const BaseLayout = styled.div`
  background-color: ${({ themeColors }) => themeColors.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export default withTheme(BaseLayout);

/** * @component */
const BaseLayoutModule = styled.div`
  flex: auto;
`;

/**
 * LayoutModule
 *
 */
export const Wrapper = props => {
  return <BaseLayoutModule {...props} />;
};

/**
 * GridModule
 * @desc contains the two columns (left and right)
 * @component
 */
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 6fr 24fr;
  padding: 0 16px 0 2px;
  grid-column-gap: 16px;
`;

/**
 * BaseColumnModule
 * @desc the style of one ColumnModule
 * @components
 *
 */
const BaseColumnModule = styled.div`
  .verticalySpaced {
    display: grid;
    grid-row-gap: 16px;
  }
  .one-block {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

/**
 * ColumnModule
 * @desc adapt children wrapper for the ColumnModule
 * @components
 */
export const Column = props => {
  const { verticalySpaced, children } = props;
  return (
    <BaseColumnModule>
      {verticalySpaced ? (
        <div className="verticalySpaced">{children}</div>
      ) : (
        <div className="one-block">{children}</div>
      )}
    </BaseColumnModule>
  );
};
Column.defaultProps = {
  verticalySpaced: false
};
Column.propTypes = {
  children: PropTypes.node.isRequired,
  verticalySpaced: PropTypes.bool
};

/**
 * Switch
 * @component
 *
 */
export const Switch = withTheme(styled.div`
  background-color: ${({ themeColors }) => themeColors.secondary};
  border-radius: 4px;
  padding: 15px 0px;
  overflow-y: auto;
  width: 100%;
  heigh: 100%;
  min-height: 622px;
  .title_blue_line {
    margin-top: 0;
  }
  .table {
    background-color: ${({ themeColors }) => themeColors.primary};
    color: ${({ themeColors }) => themeColors.light};
    width: 100%;
    // border: 1px solid ${({ themeColors }) => themeColors.light};
    padding: 8px;
    // border-radius: 4px;
    margin-bottom: 16px;
  }
  tbody {
    background-color: ${({ themeColors }) => themeColors.secondary};
  }
  td {
    // border: 2px solid ${({ themeColors }) => themeColors.primary};
    padding: 4px;
    margin: 4px;
    border-radius: 4px;
  }
  input[type='text'] {
    background-color: ${({ themeColors }) => themeColors.primary};
    color: ${({ themeColors }) => themeColors.light};
    padding: 8px;
  }
  .DateInput input[type='text'] {
    cursor: pointer;
  }
  .input-row {
    border-color: ${({ themeColors }) => themeColors.backoffice};
    margin: 0 4px 0 4px;
    width: 100px;
  }
  .action_td_container a {
    color: ${({ themeColors }) => themeColors.light};
  }
  .kpi_title {
    font-weight: 600;
  }
  .kpi_table_description {
    color: ${({ themeColors }) => themeColors.light};
    text-align: center;
    @media(max-width: 1024px){
       font-size: 14px;
    }
  }
`);
