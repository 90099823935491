// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const Text = props => {
  const {
    bold,
    color,
    font,
    italic,
    show,
    style,
    uppercase,
    link,
    fontWeight,
    fontSize,
    variant,
    ...rest
  } = props;
  const { themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors.light;

  const textStyle = {
    cursor: link ? 'pointer' : undefined,
    fontWeight: bold ? 'bold' : fontWeight,
    fontStyle: italic ? 'italic' : undefined,
    textTransform: uppercase ? 'uppercase' : 'none',
    fontFamily: "'Poppins', sans-serif",
    color: colorRules,
    fontSize: fontSize || (variant === 'body2' ? '14px' : fontSize),
    ...style
  };

  return (
    <Fade show={show}>
      <Typography {...rest} style={textStyle} variant={variant} />
    </Fade>
  );
};

Text.defaultProps = {
  bold: false,
  link: false,
  uppercase: false,
  color: null,
  fontWeight: 'normal',
  font: 'primary',
  italic: false,
  show: null,
  style: null,
  variant: 'body2',
  fontSize: '14px'
};
Text.propTypes = {
  link: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  font: PropTypes.oneOf(['primary', 'secondary']),
  italic: PropTypes.bool,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  style: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit'
  ])
};

export default Text;
