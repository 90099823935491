import React from 'react';
import PropTypes from 'prop-types';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Collapse,
  Table as TableMUI,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination as TablePaginationMui,
  TableRow,
  TableSortLabel,
  Checkbox
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { IconButton } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { Paper } from 'components/Surfaces';

import { Text } from './Text';
import { HelpTooltip } from './Tooltip';

const TablePaginationActions = props => {
  const { themeColors } = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isFirstPage = page === 0;
  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1;
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isFirstPage}
        aria-label="first page"
      >
        <FirstPageIcon
          style={{
            fill: isFirstPage ? themeColors.disabled : themeColors.light
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isFirstPage}
        aria-label="previous page"
      >
        <KeyboardArrowLeft
          style={{
            fill: isFirstPage ? themeColors.disabled : themeColors.light
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isLastPage}
        aria-label="next page"
      >
        <KeyboardArrowRight
          style={{
            fill: isLastPage ? themeColors.disabled : themeColors.light
          }}
        />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isLastPage}
        aria-label="last page"
        color={themeColors.light}
      >
        <LastPageIcon
          style={{
            fill: isLastPage ? themeColors.disabled : themeColors.light
          }}
        />
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const CustomRow = props => {
  const { themeColors } = useTheme();
  const { row, columns, enableSubRows, rowColor, sizeOfcolumnAction, actions, showCheckboxes, isItemSelected, handleClick, rowClicked, setRowClicked } = props;
  const bgColor = rowColor || row.rowColor;
  const [open, setOpen] = React.useState(false);

  const handleRowClick = (event, id) => {
    if (!rowClicked && handleClick) {
      handleClick(event, id);
      setRowClicked(true);
    }
  };

  const handleCheck = (event, id) => {
    setRowClicked(!rowClicked);
    handleClick(event, id);
  }
  const hasSubRows = row.subRows && row.subRows.length !== 0;
  return (
    <React.Fragment>
      <TableRow style={{ backgroundColor: bgColor }} onClick={(event) => handleRowClick(event, row.id)}>
        {showCheckboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
              onClick={(event) => {
                handleCheck(event, row.id);
              }}
            />
          </TableCell>
        )}
        {enableSubRows && (
          <TableCell
            style={{ width: '34px', minWidth: '34px', maxWidth: '34px' }}
          >
            {hasSubRows ? (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRight />}
              </IconButton>
            ) : (
              ' '
            )}
          </TableCell>
        )}
        {columns.map((column, index) => {
          if (column.id === 'actions') {
            return (
              <TableCell
                key={column.id}
                align="center"
                style={{ ...column.cellStyle, ...sizeOfcolumnAction }}
              >
                {
                  actions.map(action => {
                    return (
                      <IconButton
                        key={action.key}
                        onClick={() => {
                          action.onClick(row);
                        }}
                        style={{ margin: '0px 5px' }}
                      >
                        {action.icon}
                      </IconButton>
                    )
                  })
                }
              </TableCell>
            )
          }
          const value = column.format
            ? column.format(row[column.id], row)
            : row[column.id];

          return (
            <TableCell
              key={column.id}
              align="center"
              style={{ ...column.cellStyle, ...sizeOfcolumnAction }}
            >
              {typeof value === 'string' ? (
                <Text {...column.style}>{value}</Text>
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {hasSubRows && (
        <TableRow style={{ backgroundColor: bgColor }}>
          <TableCell
            style={{
              padding: 0,
              backgroundColor: bgColor
            }}
            colSpan={columns.length + (enableSubRows ? 1 : 0)}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              style={{ width: '100%' }}
            >
              <TableMUI size="small">
                <TableBody
                  style={{
                    backgroundColor: bgColor || themeColors.secondary
                  }}
                >
                  {row.subRows.map(subRow => (
                    <CustomRow
                      showCheckboxes
                      style
                      row={subRow}
                      columns={columns}
                      enableSubRows={enableSubRows}
                      rowColor={bgColor}
                    />
                  ))}
                </TableBody>
              </TableMUI>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export const Table = props => {
  const { themeColors } = useTheme();
  const {
    rows,
    hidenFields,
    noHeader,
    headers,
    getPageNumber = () => { },
    pageNumber = 0,
    setNumberPerRow,
    skipSlice,
    overrideCount,
    enableSubRows = false,
    noPaginate,
    noBorderRadius,
    noBorderColor,
    noBoxShadow,
    colorHeader,
    headerJustify,
    sizeOfcolumnAction,
    actions,
    showCheckboxes,
    onCheck

  } = props;
  const [page, setPage] = React.useState(pageNumber);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = React.useState(null);
  const [order, setOrder] = React.useState('asc');
  const [selected, setSelected] = React.useState([]);
  const [allRowsClicked, setAllRowsClicked] = React.useState({});

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row.id);
      setSelected(newSelecteds);
      setAllRowsClicked(rows.reduce((acc, row) => ({ ...acc, [row.id]: true }), {}));
      onCheck(newSelecteds)
      return;
    }
    if (onCheck) {
      onCheck([])
    }
    setSelected([]);
    setAllRowsClicked({});
  };


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    if (onCheck) {
      onCheck(newSelected)
    }
    setSelected(newSelected);
  };


  const isSelected = (id) => selected.indexOf(id) !== -1;



  const handleChangePage = (event, newPage) => {
    getPageNumber(newPage);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setNumberPerRow(+event.target.value);
    setPage(0);
  };
  const onSort = event => {
    if (orderBy === event) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(event);
    }
  };

  const getHeaders = datas => {
    if (headers) return headers;
    const list = [];
    for (const key in datas[0]) {
      if (
        Object.prototype.hasOwnProperty.call(datas[0], key) &&
        hidenFields.indexOf(key) === -1
      ) {
        list.push({
          id: key,
          label: noHeader.indexOf(key) === -1 ? key : ''
        });
      }
    }
    return list;
  };
  const columns = getHeaders(rows, hidenFields);


  const cellsSize = parseInt(
    100 / columns.length + (enableSubRows ? 1 : 0),
    10
  );

  const useStyles = makeStyles({
    root: {
      width: '100%',
      backgroundColor: themeColors.secondary,
      maxWidth: '100%',
      '& .MuiTableCell-head': {
        color: themeColors.light,
        fontWeight: 'bold'
      },
      '& .MuiTableCell-body': {
        borderRadius: '0px',
        color: themeColors.light,
        width: `${cellsSize}%`
      },
      '& .MuiTableCell-root': {
        padding: '6px'
      },
      '& .MuiTablePagination-root': {
        color: themeColors.light
      },
      '& .MuiSelect-icon': {
        color: themeColors.light
      },
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: themeColors.dark
      },
      '& .MuiTableCell-footer': {
        backgroundColor: themeColors.dark,
        '& .MuiSvgIcon-root': {
          color: themeColors.light
        }
      }
    },
    container: {
      width: '100%',
      overflowX: 'auto',
      maxWidth: `calc(100vw - 435px)`
    },
    tablePagination: {
      backgroundColor: themeColors.secondary
    }
  });
  const classes = useStyles();
  const TablePagination = () => {
    return (
      <TablePaginationMui
        className={classes.tablePagination}
        count={overrideCount || rows.length}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="lignes par pages"
        page={page}
        onPageChange={handleChangePage}
        backIconButtonProps={{ style: { color: themeColors.light } }}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    );
  };

  const descendingComparator = (a, b, key) => {
    const textA =
      typeof a[key] === 'number'
        ? a[key]
        : (a[key] && a[key].toUpperCase()) || '';
    const textB =
      typeof b[key] === 'number'
        ? b[key]
        : (b[key] && b[key].toUpperCase()) || '';
    if (textB < textA) {
      return -1;
    }
    if (textB > textA) {
      return 1;
    }
    return 0;
  };

  const getComparator = (sens, key) => {
    return sens === 'desc'
      ? (a, b) => descendingComparator(a, b, key)
      : (a, b) => -descendingComparator(a, b, key);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const result = comparator(a[0], b[0]);
      return result;
    });
    return stabilizedThis.map(el => el[0]);
  };

  const getHeaderItem = column => {
    if (column.sortKey) {
      return (
        <Item flex>
          <TableSortLabel
            active={orderBy === column.sortKey}
            direction={orderBy === column.sortKey ? order : 'asc'}
            onClick={() => onSort(column.sortKey)}
          >
            <Text bold>{column.label} </Text>
            {column.help && <HelpTooltip title={column.help} />}
          </TableSortLabel>
        </Item>
      );
    }

    return (
      <Item flex>
        <Text bold>{column.label} </Text>
        {column.help && <HelpTooltip title={column.help} />}
      </Item>
    );
  };

  let items = orderBy ? stableSort(rows, getComparator(order, orderBy)) : rows;
  if (!skipSlice) {
    items = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }

  return (
    <Paper
      className={classes.root}
      style={{
        maxWidth: '100%',
        backgroundColor: themeColors.secondary,
        ...(noBorderRadius ? { borderRadius: '0px' } : {}),
        ...(noBorderColor ? { borderColor: 'transparent' } : {}),
        ...(noBoxShadow ? { boxShadow: 'none' } : {})
      }}
    >
      <Container>
        <Row spacing={0}>
          <Item>
            <TableContainer className={classes.container}>
              <TableMUI size="small" stickyHeader stickyFooter>
                <TableHead>
                  <TableRow>
                    {enableSubRows && (
                      <TableCell style={{ width: '34px' }}>&nbsp;</TableCell>
                    )}
                    {
                      showCheckboxes && (
                        <TableCell style={{ width: '34px' }}>
                          <Checkbox
                            indeterminate={selected.length > 0 && selected.length < rows.length}
                            checked={rows.length > 0 && selected.length === rows.length}
                            onChange={handleSelectAllClick}
                          />
                        </TableCell>
                      )

                    }
                    {columns.map(column => (
                      <TableCell
                        align="center"
                        style={{
                          ...(colorHeader
                            ? { backgroundColor: colorHeader }
                            : {})
                        }}
                      >
                        <Container>
                          <Row justify={headerJustify}>
                            {getHeaderItem(column)}
                          </Row>
                        </Container>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map(row => {
                    return (
                      <CustomRow
                        key={row.id}
                        row={row}
                        columns={columns}
                        enableSubRows={enableSubRows}
                        sizeOfcolumnAction={sizeOfcolumnAction}
                        actions={actions}
                        showCheckboxes={showCheckboxes}
                        isItemSelected={isSelected(row.id)}
                        handleClick={handleClick}
                        rowClicked={allRowsClicked[row.id]}
                        setRowClicked={(value) => setAllRowsClicked({ ...allRowsClicked, [row.id]: value })}
                      />
                    );
                  })}
                </TableBody>
              </TableMUI>
            </TableContainer>
            {!noPaginate && (
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={overrideCount || rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            )}
          </Item>
        </Row>
      </Container>
    </Paper>
  );
};

Table.defaultProps = {
  hidenFields: [],
  noHeader: [],
  headers: null,
  setNumberPerRow: () => { },
  // Provisoir
  skipSlice: false,
  overrideCount: null,
  noPaginate: false,
  noBorderRadius: false,
  noBoxShadow: false,
  noBorderColor: false,
  colorHeader: null,
  headerJustify: 'center',
  sizeOfcolumnAction: {}
};

Table.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  hidenFields: PropTypes.arrayOf(PropTypes.string),
  noHeader: PropTypes.arrayOf(PropTypes.string),
  headers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  setNumberPerRow: PropTypes.func,
  skipSlice: PropTypes.bool,
  overrideCount: PropTypes.number,
  noPaginate: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  noBoxShadow: PropTypes.bool,
  noBorderColor: PropTypes.bool,
  colorHeader: PropTypes.string,
  headerJustify: PropTypes.string,
  sizeOfcolumnAction: PropTypes.shape()
};
export default Table;
