import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import { BOUNDARIES, FUNNEL, PROPERTIES } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import ModalForm from './components/ModalForm';

const Anomalies = () => {
  const { adminRights } = useUser();
  const [loaded, setLoaded] = useState(false);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [selectBoundarie, setSelectBoundarie] = useState({});
  const [funnels, setFunnels] = useState([]);
  const [allFunnels, setAllFunnels] = useState([]);
  const [allBoundaries, setAllBoundaries] = useState([]);
  const [funnelAvailable, setFunnelAvailable] = useState([]);
  const closeModal = () => {
    setOpenModal('');
    setSelectBoundarie({});
  };

  const onCreate = data => {
    const funnel = allFunnels.find(el => el._id === data._id);
    const type = funnel.schemaId ? funnel.schemaId.key : funnel.measureName;
    const { name } = funnel;
    BOUNDARIES.createBoundaries({ ...data, label: name, funnelType: type })
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const onUpdate = data => {
    BOUNDARIES.updateBoundaries(selectBoundarie._id, {
      ...data,
      funnelType: selectBoundarie.funnelType,
      label: selectBoundarie.label
    })
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };
  const openCreateModal = () => {
    if (adminRights?.funnel?.anomalies?.create) {
      setOpenModal('ModalCreate');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.funnel?.anomalies?.update) {
      const boundarie = allBoundaries.find(el => el._id === id);
      setSelectBoundarie(boundarie);
      setOpenModal('ModalUpdate');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const openModalDelete = id => {
    if (adminRights?.funnel?.anomalies?.delete) {
      const boundarie = allBoundaries.find(el => el._id === id);
      setSelectBoundarie(boundarie);
      setOpenModal('ModalDelete');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const onDelete = () => {
    BOUNDARIES.deleteBoundaries(selectBoundarie._id)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const findPropertie = (id, properties) => {
    for (const propertie of properties) {
      for (const funnelId of propertie.funnelIds) {
        if (funnelId === id) {
          return propertie.name;
        }
      }
    }
    return 'Autre';
  };

  useEffect(() => {
    const getDatas = async () => {
      const funnelsList = await FUNNEL.getFunnels(['name', 'schemaId', 'measure']);
      const properties = await PROPERTIES.get();
      const boundaries = await BOUNDARIES.getAllBoundaries();
      const mapped = funnelsList.funnels
        .map(item => {
          const boundarie = boundaries.find(el => el._id === item._id);
          if (!boundarie) {
            return null;
          }
          return {
            _id: item._id,
            id: item._id,
            name: item.name,
            type: item.schemaId ? item.schemaId.key : item.measure.name,
            min: boundarie.cpm.min,
            max: boundarie.cpm.max,
            maj: moment(item.lastUpdate).format('DD/MM/YYYY')
          };
        })
        .filter(el => el !== null);
      const funnelLibre = funnelsList.funnels
        .map(item => {
          const boundarie = boundaries.find(el => el._id === item._id);
          const propertie = findPropertie(item._id, properties);
          if (boundarie) {
            return null;
          }
          return {
            label: `${propertie} - ${item.name} (${item._id})`,
            value: item._id
          };
        })
        .filter(el => el !== null);
      const funnelDispo = funnelsList.funnels
        .map(item => {
          const propertie = findPropertie(item._id, properties);
          return {
            label: `${propertie} - ${item.name} (${item._id})`,
            value: item._id
          };
        })
        .filter(el => el !== null);

      setAllFunnels(funnelsList.funnels);
      setAllBoundaries(boundaries);
      setFunnelAvailable(funnelLibre);
      setFunnels(funnelDispo);
      setRows(mapped);
      setAllRows(mapped);
      setLoaded(true);
    };
    if (!loaded) {
      getDatas();
    }
  }, [loaded]);
  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={newRows => setRows(newRows)}
            datas={allRows}
            titleHead="Rechercher un funnel"
            placeholder="Funnel id, propriété, nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Définir de nouvelles limites"
          />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              config={{ funnels: funnelAvailable }}
              actionName="Créer"
            />
          )}
          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ funnels }}
              boundarie={selectBoundarie}
              actionName="Mettre à jour"
            />
          )}
          {openModal === 'ModalDelete' && (
            <ModalDelete
              name={selectBoundarie.label}
              type="ce boundarie"
              open={openModal === 'ModalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'ID',
                  id: 'id'
                },
                {
                  label: 'Nom',
                  id: 'name'
                },
                {
                  label: 'Min',
                  id: 'min'
                },
                {
                  label: 'Max',
                  id: 'max'
                },
                {
                  label: 'Mise à jour',
                  id: 'maj'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openModalDelete}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default Anomalies;
