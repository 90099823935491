import React from 'react';
import { SnackbarProvider } from 'notistack';

import styles from './style';

function Notification(props) {
  const classes = styles();
  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      hideIconVariant={false}
      preventDuplicate
      {...props}
    />
  );
}

export default Notification;
