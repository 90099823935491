import { api } from './api';

const getTagFunnels = async () => {
  try {
    const result = await api.get(`/backoffice-insight/tagfunnel`);
    return result.data;
  } catch (err) {
    console.error(err);
    return { funnels: [] };
  }
};

const updateTagFunnel = async (id, data) => {
  try {
    const result = await api.put(`/backoffice-insight/tagfunnel/${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getTagFunnels,
  updateTagFunnel
};
