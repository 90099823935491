import React from 'react';
import PropTypes from 'prop-types';

import moment from 'utils/moment';

import BaseDateRange, { BaseDateWrapper } from './style';

import 'react-dates/lib/css/_datepicker.css';

import 'react-dates/initialize';

function isOutsideRange(date) {
  return moment(date).isAfter();
}

function DatePicker({ propsDateRangePicker, ...props }) {
  const [focusedInput, setFocusedInput] = React.useState(null);
  const {
    startDate,
    endDate,
    onDatesChange,
    disabled,
    toTop,
    onClose,
    minimumNights
  } = props;
  return (
    <BaseDateWrapper {...props}>
      <BaseDateRange
        startDateId="startDate"
        endDateId="endDate"
        startDatePlaceholderText="Date de début"
        endDatePlaceholderText="Date de fin"
        startDate={startDate.isValid() ? startDate : null}
        endDate={endDate.isValid() ? endDate : null}
        onDatesChange={({ startDate: newStartDate, endDate: newEndDate }) => {
          onDatesChange({ startDate: newStartDate, endDate: newEndDate });
        }}
        isOutsideRange={isOutsideRange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        hideKeyboardShortcutsPanel
        disabled={disabled}
        openDirection={toTop ? 'up' : 'down'}
        onClose={({ startDate: newStartDate, endDate: newEndDate }) => {
          onClose({ startDate: newStartDate, endDate: newEndDate });
        }}
        minimumNights={minimumNights}
        {...propsDateRangePicker}
      />
    </BaseDateWrapper>
  );
}
DatePicker.defaultProps = {
  startDate: null,
  endDate: null,
  start: null,
  end: null,
  minimumNights: null,
  disabled: false,
  toTop: false,
  propsDateRangePicker: {},
  onClose: () => {}
};
DatePicker.propTypes = {
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  onDatesChange: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(moment().format()),
  end: PropTypes.instanceOf(moment().format()),
  disabled: PropTypes.bool,
  toTop: PropTypes.bool,
  minimumNights: PropTypes.number,
  propsDateRangePicker: PropTypes.shape({
    isOutsideRange: PropTypes.func
  }),
  onClose: PropTypes.func
};

export default DatePicker;
