import React, { useEffect, useState } from 'react';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  MultipleSelect,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Column, Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const isValidUrl = urlString => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return !!urlPattern.test(urlString);
};

export const ModalForm = props => {
  const { onValidate, config, initData } = props;
  const initialState = {
    exportParams: [],
    encrypted: true,
    media: null,
    cpm: 1,
    formats: [],
    exportType: null,
    exportValue: null,
    mergeFiles: false
  };

  const [
    {
      media,
      cpm,
      formats,
      encrypted,
      exportType,
      exportValue,
      exportParams,
      mergeFiles
    },
    setState
  ] = useState(initialState);

  const [isUrlError, setIsUrlError] = useState(false);

  const sortedMedia = () => {
    const ermesMediaKeys = [...config?.ermesMediaKeys];
    if (media) {
      ermesMediaKeys.unshift({ mediaKeyLabel: media });
    }
    return ermesMediaKeys?.map(ermesMediaKey => {
      const label = ermesMediaKey.mediaKeyLabel;
      const mediaKey = label.split(' ').join('');
      return {
        mediaKey, // ermesMediaKey.mediaKeyId,
        label
      };
    });
  };

  const sortedFormats = config?.formats.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const sortedTypes = () => config?.exportMediaTypes;

  const exportTextValue = () => {
    switch (exportType) {
      case 'Connecteur':
        return 'Model';
      case 'Webhook':
        return 'URL';
      default:
        return null;
    }
  };

  const sortedConnectors = () => {
    return config?.exportMediaModels;
  };

  useEffect(() => {
    if (initData) {
      setState(initData);
    }
  }, [initData]);

  const doActionValidate = () => {
    return (
      media?.length &&
      formats?.length &&
      ((exportType !== 'Aucun' && exportValue?.length) ||
        exportType === 'Aucun')
    );
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    const datas = {
      media,
      cpm,
      formats,
      encrypted,
      exportType,
      exportValue,
      exportParams,
      mergeFiles
    };
    onValidate(datas).then(() => {
      resetStates();
    });
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs>
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              disabled={!doActionValidate()}
              onClick={doAction}
            >
              Valider
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const getExportModel = () => {
    return (
      <Item style={{ paddingLeft: '40px' }}>
        {exportType === 'Webhook' && (
          <TextField
            small
            title="URL"
            required
            placeholder="https://"
            onChange={value => {
              if (value?.length) {
                if (isValidUrl(value)) {
                  handleChange('exportValue')(value);
                  setIsUrlError(false);
                } else {
                  setIsUrlError(true);
                }
              } else {
                setIsUrlError(false);
              }
            }}
            value={exportValue}
            error={isUrlError}
            errorText={isUrlError ? 'URL invalide' : null}
          />
        )}
        {exportType === 'Connecteur' && (
          <Select
            title={exportTextValue()}
            options={sortedConnectors()}
            value={
              exportValue
                ? sortedConnectors().find(el => el.key === exportValue)?.label
                : undefined
            }
            required
            onChange={value => {
              const match = sortedConnectors().find(el => el.label === value);
              return handleChange('exportValue')(match.key);
            }}
          />
        )}
      </Item>
    );
  };

  const getParamsSection = () => {
    return (
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection title="Paramétre du requête" xs={3}>
            <Container>
              <Row spacing={0} justify="flex-start">
                <Item justify="flex-start">
                  <ListField
                    labels={['Key', 'Valeur']}
                    options={['key', 'value']}
                    inputs={['TextField', 'TextField']}
                    isRequireds={[true, true]}
                    values={exportParams}
                    selectNbMax={2}
                    valueUnique
                    variant="customFields"
                    onChange={handleChange('exportParams')}
                    actionTxt="Ajouter un paramétre"
                    deleteAction
                  />
                </Item>
              </Row>
            </Container>
          </FormSection>
        </Item>
      </Row>
    );
  };
  const removeDuplicates = array => {
    const uniqueMedia = {};
    return array.filter(item => {
      if (!uniqueMedia[item.mediaKey]) {
        uniqueMedia[item.mediaKey] = true;
        return true;
      }
      return false;
    });
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1} style={{ justifyContent: 'start' }}>
          <Item xs={6}>
            <Row spacing={1}>
              <Item>
                <AutocompleteTextField
                  options={removeDuplicates(sortedMedia())}
                  required
                  formatLabel={option => option.label}
                  title="Média"
                  placeholder="Rechercher un média"
                  small
                  value={
                    media
                      ? sortedMedia().find(
                          el => el.mediaKey === media.split(' ').join('')
                        )
                      : undefined
                  }
                  onChange={item => handleChange('media')(item?.label)}
                />
              </Item>
            </Row>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="CPM">
              <Row spacing={1} style={{ justifyContent: 'start' }}>
                <Item xs={4} style={{ justifyContent: 'start' }}>
                  <Text>CPM Data par défaut</Text>
                  <TextField
                    small
                    placeholder="1 par défaut"
                    onChange={value => {
                      const floatValue = parseFloat(value);
                      if (!Number.isNaN(floatValue)) {
                        handleChange('cpm')(floatValue);
                      }
                    }}
                    value={cpm || 1}
                  />
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item>
            <FormSection title="Données" justify="start">
              <Column xs={4} justifyContent="start">
                <Row spacing={1} justifyContent="start">
                  <Item justifyContent="start">
                    <MultipleSelect
                      title="Format"
                      required
                      selectAll
                      options={sortedFormats}
                      selected={formats.map(el => el.key)}
                      onChange={value => {
                        handleChange('formats')(value);
                      }}
                    />
                    <Switch
                      title="fusionner les formats"
                      checked={mergeFiles}
                      onChange={e => handleChange('mergeFiles')(e)}
                    />
                  </Item>
                </Row>
              </Column>
              {formats.find(el => el.key === 'phoneNumber') && (
                <Column xs={8}>
                  <Item xs={6}>
                    <Row spacing={2}>
                      <Item flex>
                        <Text fontSize="14px">Données exportées en clair</Text>
                      </Item>
                    </Row>
                    <Switch
                      checked={encrypted}
                      onChange={event => handleChange('encrypted')(event)}
                      options={['Oui', 'Non']}
                      defaultColor="warning"
                    />
                  </Item>
                </Column>
              )}
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Export">
              <Column xs={4}>
                <Row spacing={1} style={{ justifyContent: 'start' }}>
                  <Item style={{ justifyContent: 'start' }}>
                    <Select
                      title="Type"
                      options={sortedTypes()}
                      required
                      value={exportType}
                      onChange={value => {
                        handleChange('exportType')(value);
                        handleChange('exportValue')(null);
                      }}
                    />
                  </Item>
                </Row>
              </Column>
              <Column xs={8}>{getExportModel()}</Column>
            </FormSection>
          </Item>
        </Row>
        {exportType === 'Webhook' && getParamsSection()}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {};
ModalForm.propTypes = {};

export default ModalForm;
