import React from 'react';
import ReactDOM from 'react-dom';

import { AppProvider } from 'contexts/AppContext';
import NotifProvider from 'contexts/NotifContext';
import PlatformConfigProvider from 'contexts/PlatformConfigContext';
import ThemeProvider from 'contexts/ThemeContext';
import UserProvider from 'contexts/UserContext';

import Notification from 'components/Notification';

import App from './App';

import './index.css';

ReactDOM.render(
  <PlatformConfigProvider>
    <UserProvider>
      <ThemeProvider>
        <AppProvider>
          <Notification>
            <NotifProvider>
              <App />
            </NotifProvider>
          </Notification>
        </AppProvider>
      </ThemeProvider>
    </UserProvider>
  </PlatformConfigProvider>,
  document.getElementById('root')
);
