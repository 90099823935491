// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as IconButtonComp } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const IconButton = props => {
  const { color, edge, size, show, style, ...rest } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    button: {
      color: colorRules,
      ...style
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <IconButtonComp
        className={classes.button}
        size={size}
        edge={edge}
        {...rest}
      />
    </Fade>
  );
};

export default IconButton;

IconButton.defaultProps = {
  color: null,
  edge: false,
  size: 'medium',
  show: null,
  style: null
};
IconButton.propTypes = {
  color: PropTypes.string,
  edge: PropTypes.oneOf(['start', 'end', false]),
  size: PropTypes.oneOf(['small', 'medium']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  style: PropTypes.objectOf(PropTypes.any)
};
