import { api } from './api';

const createContent = async datas => {
  try {
    const result = await api.post(`/backoffice-dashboard/content`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getContents = async () => {
  try {
    const result = await api.get(`/backoffice-dashboard/content/list`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateContent = async (id, datas) => {
  try {
    const result = await api.put(`/backoffice-dashboard/content/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteContent = async (id, datas) => {
  try {
    const result = await api.delete(
      `/backoffice-dashboard/content/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { createContent, getContents, updateContent, deleteContent };
