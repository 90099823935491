import React from 'react';
import { version } from 'src/../../package.json';

import { usePlatformConfig } from 'contexts/PlatformConfigContext';

import moment from 'utils/moment';
import { IsUpSm as IsUpSmBreakpoint } from 'utils/responsive';

import { Item, Row } from 'components/Layout';

import { Container } from './style';

/**
 * FooterContainer
 * @param
 */

function FooterContainer() {
  const {
    cguUrl,
    legalMentionsUrl,
    contactEmail,
    policyUrl
  } = usePlatformConfig();
  const IsUpSm = IsUpSmBreakpoint();
  const currentYear = moment().format('YYYY');
  const Separator = () => {
    /* move style inline to styled-component */
    return (
      <Item flex style={{ padding: '0 5px' }}>
        |
      </Item>
    );
  };
  return (
    <Container>
      <Row>
        <Item show={IsUpSm || 'none'} xs={0} sm={1} />
        <Item xs>
          <Item flex>© {currentYear} Ermes</Item>
          <Separator />
          <Item flex>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                contactEmail
                  ? `mailto:${contactEmail}`
                  : 'mailto:contact@ermes.ai'
              }
            >
              {contactEmail || 'contact@ermes.ai'}
            </a>
          </Item>
          <Separator />
          <Item flex>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={cguUrl || 'https://www.ermes.ai/cgu/'}
            >
              CGU
            </a>
          </Item>
          <Separator />
          <Item flex>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                legalMentionsUrl || 'https://www.ermes.ai/mentions-legales/'
              }
            >
              Mentions Légales
            </a>
          </Item>
          <Separator />
          <Item flex>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                policyUrl ||
                'https://www.ermes.ai/politique-de-confidentialite/'
              }
            >
              Politique de confidentialité
            </a>
          </Item>
        </Item>
        <Item xs={12} sm={1} justify="center">{`V${version}`}</Item>
      </Row>
    </Container>
  );
}

export default FooterContainer;
