import React from 'react';

const NavigationContext = React.createContext();

/**
 * NavigationProvider
 * @context
 * @component
 */
const NavigationProvider = props => {
  const [menu, setMenu] = React.useState({});
  const [showMenu, setShowMenu] = React.useState(true);
  const value = {
    menu,
    setMenu,
    showMenu,
    setShowMenu
  };
  return <NavigationContext.Provider {...props} value={value} />;
};

export default NavigationProvider;

/**
 * useNavigation
 * @function
 */
export const useNavigation = () => {
  const context = React.useContext(NavigationContext);
  if (!context)
    throw new Error(
      'NavigationContext must be used in NavigationProvider(NavigationModule)'
    );
  return context;
};
