import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import numeral from 'numeral';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';

import { Divider, Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Button, IconButton, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const MediaOrderItem = props => {
  const {
    children,
    mediaOrder,
    toggleExpanded,
    mediaLogoDisplay,
    activableMedia,
    getStatusChip,
    setMediaOrders,
    handleSaveMediaOrder,
    mediaSetupTask,
    launchSetup
  } = props;

  const { themeColors } = useTheme();
  return (
    <Container key={mediaOrder.id}>
      <Row spacing={1}>
        <Item
          justify="flex-start"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Row spacing={0} justify="flex-start">
            <Item flex>
              <IconButton onClick={() => toggleExpanded(mediaOrder.id)}>
                {mediaOrder.isExpanded ? (
                  <KeyboardArrowDownIcon style={{ fill: themeColors.light }} />
                ) : (
                  <KeyboardArrowRight style={{ fill: themeColors.light }} />
                )}
              </IconButton>
              <Image
                src={mediaLogoDisplay}
                alt={mediaOrder.activableMedia}
                height="38px"
              />
              {getStatusChip(activableMedia, mediaSetupTask, true)}
              {mediaOrder?.displaySetup && (
                <Button
                  disabled={!mediaOrder?.setup}
                  onClick={() => {
                    launchSetup(mediaOrder);
                  }}
                >
                  <Icon
                    icon="setup"
                    attrs={{
                      width: '40px',
                      height: '31px',
                      enabled: mediaOrder?.setup
                    }}
                  />
                </Button>
              )}
              {!mediaOrder.isEditing ? (
                <>
                  {mediaOrder.externalId && (
                    <Text fontSize="12px" fontWeight={400}>
                      (id: {mediaOrder.externalId})
                    </Text>
                  )}
                  <IconButton
                    disabled={!mediaOrder?.setup}
                    onClick={() => {
                      setMediaOrders(prevMediaOrders =>
                        prevMediaOrders.map(order => {
                          if (order.id === mediaOrder.id) {
                            return { ...order, isEditing: true };
                          }
                          return order;
                        })
                      );
                    }}
                  >
                    <CreateIcon style={{ color: themeColors.light }} />
                  </IconButton>{' '}
                </>
              ) : (
                <Item
                  flex
                  style={{
                    width: '305px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    value={mediaOrder.externalId}
                    onChange={e => {
                      setMediaOrders(prevMediaOrders =>
                        prevMediaOrders.map(order => {
                          if (order.id === mediaOrder.id) {
                            return { ...order, externalId: e };
                          }
                          return order;
                        })
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setMediaOrders(prevMediaOrders =>
                        prevMediaOrders.map(order => {
                          if (order.id === mediaOrder.id) {
                            return { ...order, isEditing: false };
                          }
                          return order;
                        })
                      );
                      handleSaveMediaOrder(
                        mediaOrder.id,
                        mediaOrder.externalId
                      );
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Item>
              )}
            </Item>

            <Item xs justify="flex-start" style={{ paddingRight: '5px' }}>
              <Divider
                color="light"
                noFade
                style={{ width: '100%', height: '1.5px' }}
              />
            </Item>
            <Item flex>
              <Text fontSize="14px" fontWeight={700}>
                {numeral(mediaOrder.budgetMedia).format('0,0$')}
              </Text>
            </Item>
          </Row>
        </Item>
      </Row>
      {children}
    </Container>
  );
};

MediaOrderItem.defaultProps = {
  mediaLogoDisplay: '',
  launchSetup: () => {}
};
MediaOrderItem.propTypes = {
  children: PropTypes.node.isRequired,
  mediaOrder: PropTypes.shape().isRequired,
  activableMedia: PropTypes.shape().isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  getStatusChip: PropTypes.func.isRequired,
  setMediaOrders: PropTypes.func.isRequired,
  handleSaveMediaOrder: PropTypes.func.isRequired,
  mediaSetupTask: PropTypes.shape().isRequired,
  mediaLogoDisplay: PropTypes.string,
  launchSetup: PropTypes.func
};

export default MediaOrderItem;
