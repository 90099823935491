import { api } from './api';

const createLearning = async data => {
  try {
    const result = await api.post(`/llm/learning`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getLearning = async () => {
  try {
    const result = await api.get(`/llm/learning`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getLearningById = async id => {
  try {
    const result = await api.get(`/llm/learning/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateLearning = async (id, body) => {
  try {
    const { data } = await api.put(`/llm/learning/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteLearning = async id => {
  try {
    const result = await api.delete(`/llm/learning/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getGraphics = async id => {
  try {
    const result = await api.get(`/llm/graphics`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  createLearning,
  getLearning,
  getLearningById,
  updateLearning,
  deleteLearning,
  getGraphics
};
