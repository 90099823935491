import { api } from './api';

const get = async () => {
  try {
    const result = await api.get(`/v2/funnel/audienceMapping`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async data => {
  try {
    const result = await api.post(`/v2/funnel/audienceMapping`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, data) => {
  try {
    const result = await api.put(`/v2/funnel/audienceMapping/${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteOne = async id => {
  try {
    const result = await api.delete(`/v2/funnel/audienceMapping/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  get,
  create,
  update,
  deleteOne
};
