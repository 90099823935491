import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalToDefault = props => {
  const { onClose, onConfirm } = props;

  return (
    <Modal titleHead="Réinitialisation" maxWidth="xs" {...props}>
      <Container>
        <Row>
          <Item>
            <Text>
              Si vous validez la réinitialisation, votre organisation reprendra les valeurs par défaut.
            </Text>
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={6}>
            <Button
              variant="outlined"
              size="medium"
              widthSize="medium"
              rounded
              onClick={onClose}
            >
              ANNULER
            </Button>
          </Item>
          <Item xs={12} sm={6}>
            <Button
              variant="outlined"
              size="medium"
              widthSize="medium"
              rounded
              onClick={onConfirm}
            >
              VALIDER
            </Button>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalToDefault.defaultProps = {};
ModalToDefault.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ModalToDefault;
