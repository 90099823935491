import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import moment from 'utils/moment';

import { Text } from 'components/DataDisplay';
import DateRangePicker from 'components/DateRangePicker';
import { Container, Item, Row } from 'components/Layout';

/**
 * PeriodFunnel
 * @component
 *
 */
function PeriodFunnel(props) {
  const { setPeriod, start, end, error, onClose, label } = props;
  const { themeColors } = useTheme();
  return (
    <Container>
      <Row spacing={1}>
        <Item justify="flex-start">
          <Text color={error ? 'error' : 'inherit'}>{label} *</Text>
        </Item>
        <Item xs justify="flex-start">
          <DateRangePicker
            bgColor="transparent"
            color={themeColors.light}
            borderColor={themeColors.funnel}
            onDatesChange={setPeriod}
            startDate={moment(start)}
            endDate={moment(end)}
            onClose={onClose}
            required
            minimumNights={0}
            propsDateRangePicker={{
              startDatePlaceholderText: 'Choisisez une date',
              endDatePlaceholderText: 'Choisisez une date'
            }}
          />
        </Item>
      </Row>
    </Container>
  );
}
PeriodFunnel.defaultProps = {
  start: undefined,
  end: undefined,
  error: false,
  label: 'Période analysée'
};
PeriodFunnel.propTypes = {
  setPeriod: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
  label: PropTypes.string
};

export default PeriodFunnel;
