import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

import ConstraintChannelsContainer from './containers/menus/ConstraintChannelsContainer';
// MENU
import ConstraintContainer from './containers/menus/ConstraintContainer';
import ConversionRatesContainer from './containers/menus/ConversionRatesContainer';
import ConversionRatesMediasContainer from './containers/menus/ConversionRatesMediasContainer';
import DefaultBudgetContainer from './containers/menus/DefaultBudgetContainer';
import EmailsCountContainer from './containers/menus/EmailsCountContainer';
import FeesContainer from './containers/menus/FeesContainer';
import ForcedChannelContainer from './containers/menus/ForcedChannelContainer';
import MeasuresContainer from './containers/menus/MeasuresContainer';
import MediasContainer from './containers/menus/MediasContainer';
import OfflineCPMContainer from './containers/menus/OfflineCPMContainer';
import SeasonalityContainer from './containers/menus/SeasonalityContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();

  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'constraint':
        return <ConstraintContainer />;
      case 'medias':
        return <MediasContainer />;
      case 'constraintChannel':
        return <ConstraintChannelsContainer />;
      case 'conversionRates':
        return <ConversionRatesContainer />;
      case 'conversionRatesMedias':
        return <ConversionRatesMediasContainer />;
      case 'fees':
        return <FeesContainer />;
      case 'measures':
        return <MeasuresContainer />;
      case 'offlineCPM':
        return <OfflineCPMContainer />;
      case 'forcedChannel':
        return <ForcedChannelContainer />;
      case 'seasonality':
        return <SeasonalityContainer />;
      case 'defaultBudget':
        return <DefaultBudgetContainer />;
      case 'emailsCount':
        return <EmailsCountContainer />;

      default:
        return <InProgressContainer text={menuName} />;
    }
    // eslint-disable-next-line
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
