import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  conversionTypesError: false,
  channelError: false,
  lowError: false,
  mainError: false,
  highError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta } = props;
  const initialState = {
    conversionTypes:
      config.conversionTypes && config.conversionTypes === 'web'
        ? config.conversionTypes
        : 'direct magasin' || null,
    channel: config.channel || null,
    low: config.low * 100 || null,
    main: config.main * 100 || null,
    high: config.high * 100 || null
  };
  const [
    { conversionTypes, channel, low, main, high },
    setState
  ] = React.useState(initialState);
  const [
    { conversionTypesError, channelError, lowError, mainError, highError },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!conversionTypes) {
      toggleError('conversionTypes', true);
      error = true;
    }
    if (!channel) {
      toggleError('channel', true);
      error = true;
    }
    if (!low) {
      toggleError('low', true);
      error = true;
    }
    if (!main) {
      toggleError('main', true);
      error = true;
    }
    if (!high) {
      toggleError('high', true);
      error = true;
    }

    if (!error) {
      const datas = {
        conversionTypes: conversionTypes !== 'web' ? 'shop' : 'web',
        channel,
        low: low / 100,
        main: main / 100,
        high: high / 100
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionBeta || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Type de conversion</Text>
                          <Select
                            onChange={handleChange('text', 'conversionTypes')}
                            options={['web', 'direct magasin']}
                            value={conversionTypes}
                            error={conversionTypesError}
                            small
                            disabled
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Canal</Text>
                          <TextField
                            small
                            value={channel}
                            disabled
                            onChange={handleChange('text', 'channel')}
                            error={channelError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de conversion" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de conversion minimal *</Text>
                          <TextField
                            small
                            value={low}
                            onChange={handleChange('rate', 'low')}
                            error={lowError}
                            format="numberDec"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de conversion moyen *</Text>
                          <TextField
                            small
                            value={main}
                            onChange={handleChange('rate', 'main')}
                            error={mainError}
                            format="numberDec"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de conversion maximal *</Text>
                          <TextField
                            small
                            value={high}
                            onChange={handleChange('rate', 'high')}
                            error={highError}
                            format="numberDec"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
