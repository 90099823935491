import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Checkbox } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { Accordion } from 'components/Surfaces';

const CustomOption = ({
  datas: { items = [], key, label, media, itemsType = '' },
  onChange,
  addAll,
  removeAll,
  selected = [],
  underline = false,
  onExpandChange,
  expandeds,
  parentKey = ''
}) => {
  const deepCountSelected = datas => {
    if (!datas?.items?.length) {
      const isSelected = selected.findIndex(
        el => el.key === datas.key && el.media === datas.media
      );
      return isSelected !== -1 ? 1 : 0;
    }

    return datas.items.reduce((acc, item) => {
      return acc + deepCountSelected(item);
    }, 0);
  };

  const deepItems = data => {
    const {
      key: dataKey,
      media: dataMedia,
      label: dataLabel,
      items: dataItems
    } = data;
    if (!dataItems?.length) {
      return [{ key: dataKey, media: dataMedia, label: dataLabel }];
    }

    return dataItems.reduce((acc, item) => {
      return [...acc, ...deepItems(item)];
    }, []);
  };

  const onAllChange = list => {
    const notSelected = list.filter(
      el =>
        !selected.find(
          ({ key: selectedKey, media: selectedMedia, label: selectedLabel }) =>
            selectedKey === el.key &&
            selectedMedia === el.media &&
            selectedLabel === el.label
        )
    );
    if (notSelected.length) {
      addAll(notSelected);
    } else {
      removeAll(list);
    }
  };

  const isSelected =
    selected.findIndex(el => el.key === key && el.media === media) !== -1;
  const nbSelected = deepCountSelected({ items, key, label, media, itemsType });

  const itemsList = items.reduce((acc, item) => {
    return [...acc, ...deepItems(item)];
  }, []);

  return items.length !== 0 ? (
    <Row justify="flex-start" spacing={0}>
      <Item>
        <Accordion
          onExpandChange={onExpandChange}
          expanded={expandeds[key + parentKey]}
          rows={[
            {
              key: key + parentKey,
              instructions: {
                show: `Voir les ${itemsType}`,
                hide: `Masquer les ${itemsType}`
              },
              label: (
                <Row
                  alignItems="center"
                  justify="flex-start"
                  spacing={0}
                  style={{ padding: '0px 3px' }}
                >
                  <Item flex>
                    <Checkbox
                      color="light"
                      indeterminate={
                        nbSelected !== itemsList.length && nbSelected !== 0
                      }
                      checked={nbSelected === itemsList.length}
                      onChange={() => onAllChange(itemsList)}
                    />
                  </Item>
                  <Item xs justify="flex-start">
                    <Text noWrap>{label || key}</Text>
                  </Item>
                </Row>
              ),
              items: items.map(el => {
                return (
                  <CustomOption
                    onExpandChange={onExpandChange}
                    expandeds={expandeds}
                    parentKey={key}
                    datas={el}
                    addAll={addAll}
                    removeAll={removeAll}
                    onChange={onChange}
                    selected={selected}
                  />
                );
              })
            }
          ]}
          underline={underline}
        />
      </Item>
    </Row>
  ) : (
    <Row
      alignItems="center"
      justify="flex-start"
      spacing={0}
      key={`lineItem_${key}`}
      style={{ padding: '0px 3px' }}
    >
      <Item flex>
        <Checkbox
          color="light"
          checked={isSelected}
          onChange={() => onChange({ key, media, label })}
        />
      </Item>
      <Item xs justify="flex-start">
        <Text noWrap>{label || key}</Text>
      </Item>
    </Row>
  );
};

export const InspectCampaigns = props => {
  const { onChange, options, selected = [], name } = props;
  const { themeColors } = useTheme();

  const onCheck = ({ key, media, label }) => {
    const tmp = [...selected];
    const existIndex = selected.findIndex(
      el => el.key === key && el.media === media
    );
    if (existIndex !== -1) {
      return onChange(
        tmp.filter(el => !(el.key === key && el.media === media))
      );
    }
    tmp.push({ key, media, label });
    return onChange(tmp);
  };

  const addAll = list => {
    onChange([...selected, ...list]);
  };

  const removeAll = list => {
    const tmp = selected.filter(
      el =>
        !list.find(
          ({ key, media, label }) =>
            key === el.key && media === el.media && label === el.label
        )
    );
    onChange(tmp);
  };

  const [expandeds, setExpanded] = React.useState({});

  const onExpandChange = id => {
    const newExpanded = { ...expandeds };
    newExpanded[id] = !newExpanded[id];
    setExpanded(newExpanded);
  };

  return (
    <Container
      spacing={0}
      style={{
        backgroundColor: themeColors.primary,
        borderRadius: '10px',
        padding: '20px',
        marginBottom: '16px'
      }}
    >
      <Row
        spacing={0}
        style={{
          maxHeight: '400px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <Item justify="flex-start">
          <Text fontSize="16px" fontWeight={600}>
            {name}
          </Text>
        </Item>
        {options.map(option => {
          return (
            <CustomOption
              onExpandChange={onExpandChange}
              expandeds={expandeds}
              datas={option}
              onChange={onCheck}
              addAll={addAll}
              removeAll={removeAll}
              selected={selected}
              underline
            />
          );
        })}
      </Row>
    </Container>
  );
};

InspectCampaigns.defaultProps = {
  onChange: () => null,
  options: [],
  selected: [],
  name: undefined
};
InspectCampaigns.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
  selected: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string
};

export default InspectCampaigns;
