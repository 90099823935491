import React from 'react';
import { withRouter } from 'react-router-dom';

import { Text } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import { useTheme } from 'contexts/ThemeContext';
import { Graph3D, Learning } from './components';

const App = () => {
  const { themeColors } = useTheme();
  return (
    <Container
      style={{
        minHeight: 'calc(100vh - 120px - 50px)',
        backgroundColor: themeColors.themeV2.white
      }}
      spacing={0}
      alignItems="stretch"
    >
      <Row spacing={2} alignItems="flex-start">
        <Item xs={6}>
          <Text fontSize="24px" fontWeight={500}>
            Apprentissage LLM - Base de données enrichie
          </Text>
        </Item>
      </Row>
      <Row spacing={2} alignItems="flex-start">
        <Item xs={6}>
          <Learning />
        </Item>
        <Item xs={6} style={{ minWidth: '512px', width: '100%' }}>
          <Graph3D />
        </Item>
      </Row>
    </Container>
  );
};

export default withRouter(App);
