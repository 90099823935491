// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton } from '@mui/material';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Color from 'color';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Container, Item, Row } from 'components/Layout';

export const Modal = props => {
  const {
    children,
    disableBackdropClick,
    disableEscapeKeyDown,
    dividers,
    hideCloseButton,
    maxWidth,
    onClose,
    open,
    scroll,
    show,
    titleHead,
    actions
  } = props;
  const { panelColor, themeColors, isThemeLight } = useTheme();
  const useStyles = makeStyles({
    modal: {
      '& .MuiPaper-root': {
        backgroundColor: themeColors.dark,
        borderRadius: 10,
        border: `${themeColors[panelColor] && '1px'} solid ${Color(
          themeColors[panelColor]
        )
          .fade(isThemeLight ? 0.5 : 0.9)
          .rgb()
          .string()}`
      },
      '& .MuiBackdrop-root': {
        backdropFilter: 'blur(10px)'
      }
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <Dialog
        className={classes.modal}
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={maxWidth}
        scroll={scroll}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        {...props}
      >
        {titleHead && (
          <MuiDialogTitle onClose={onClose}>
            <Container>
              <Row>
                <Item xs={11}>
                  <Text variant="h6" align="center" bold>
                    {titleHead}
                  </Text>
                </Item>
                <Item
                  flex
                  style={{
                    top: 8,
                    right: 8,
                    position: 'absolute'
                  }}
                >
                  {!hideCloseButton ? (
                    <IconButton aria-label="close" onClick={onClose}>
                      <CloseIcon style={{ color: themeColors.light }} />
                    </IconButton>
                  ) : null}
                </Item>
              </Row>
            </Container>
          </MuiDialogTitle>
        )}
        <MuiDialogContent dividers={dividers}>
          <Container>
            <Row spacing={0}>
              <Item>{children}</Item>
            </Row>
          </Container>
        </MuiDialogContent>
        {actions && <MuiDialogActions>{actions}</MuiDialogActions>}
      </Dialog>
    </Fade>
  );
};

Modal.defaultProps = {
  children: null,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  dividers: false,
  hideCloseButton: false,
  maxWidth: 'md',
  onClose: () => null,
  open: false,
  scroll: 'paper',
  show: null,
  titleHead: null,
  actions: null
};
Modal.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.node,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  dividers: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  scroll: PropTypes.oneOf(['paper', 'body']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  titleHead: PropTypes.string
};

export default Modal;
