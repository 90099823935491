import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false,
  errorName_en: false,
  errorCpm: false,
  errorOrder: false,
  errorDescription: false,
  errorDescription_en: false,
  errorCategory: false,
  errorKey: false
};
export const ModalForm = props => {
  const { onValidate, onClose, actionName, config } = props;

  const { criterias, criteria = {}, categoryList } = config;
  const catList = categoryList
    .filter(el => el.parentKey !== null)
    .map(item => ({
      name: item.name,
      key: item.key,
      src: item.parentCategory
        ? `${item.parentCategory.name} > ${item.name}`
        : ''
    }));
  const { themeColors } = useTheme();
  const initialState = {
    name: criteria.name || '',
    name_en: criteria.name_en || '',
    description: criteria.description || '',
    description_en: criteria.description_en || '',
    order: criteria.order || '',
    cpm: criteria.cpm || '',
    cpmData: criteria.dataCpm ?? 0,
    key: criteria.key || '',
    category:
      (criteria.categoryKey &&
        catList.filter(el => el.key === criteria.categoryKey)) ||
      [],
    visibility:
      (criteria.active !== undefined &&
        (criteria.active ? 'Actif' : 'Inactif')) ||
      'Actif'
  };

  const [
    {
      name,
      order,
      key,
      description,
      name_en,
      description_en,
      visibility,
      category,
      cpmData
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      errorName,
      errorName_en,
      errorOrder,
      errorDescription,
      errorDescription_en,
      errorVisibility,
      errorCategory,
      errorKey,
      errorCpmData
    },
    setError
  ] = React.useState(ErrorState);
  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };
  const toggleErrorName_en = state => {
    setError(prevState => ({ ...prevState, errorName_en: state }));
  };
  const toggleErrorDescription = state => {
    setError(prevState => ({ ...prevState, errorDescription: state }));
  };
  const toggleErrorDescription_en = state => {
    setError(prevState => ({ ...prevState, errorDescription_en: state }));
  };
  const toggleErrorCpm = state => {
    setError(prevState => ({ ...prevState, errorCpm: state }));
  };
  const toggleErrorCpmData = state => {
    setError(prevState => ({ ...prevState, errorCpmData: state }));
  };
  const toggleErrorOrder = state => {
    setError(prevState => ({ ...prevState, errorOrder: state }));
  };
  const toggleErrorKey = state => {
    setError(prevState => ({ ...prevState, errorKey: state }));
  };
  const toggleErrorCategory = state => {
    setError(prevState => ({ ...prevState, errorCategory: state }));
  };
  const toggleErrorVisibility = value => {
    setError(prevState => ({
      ...prevState,
      errorVisibility: value
    }));
  };
  const toggleCategory = value => {
    setState(prevState => ({
      ...prevState,
      category: value
    }));
  };
  const toggleVisibility = value => {
    setState(prevState => ({ ...prevState, visibility: value }));
  };
  const handleChange = field => value => {
    const text = value.trim();
    if (field === 'name') {
      toggleErrorName(!text);
      setState(prevState => ({ ...prevState, name: text }));
    }
    if (field === 'name_en') {
      toggleErrorName_en(!text);
      setState(prevState => ({ ...prevState, name_en: text }));
    }
    if (field === 'description') {
      toggleErrorDescription(!text);
      setState(prevState => ({ ...prevState, description: text }));
    }
    if (field === 'description_en') {
      toggleErrorDescription_en(!text);
      setState(prevState => ({ ...prevState, description_en: text }));
    }
    if (field === 'order') {
      toggleErrorOrder(!text);
      setState(prevState => ({ ...prevState, order: text }));
    }
    if (field === 'cpm') {
      toggleErrorCpm(!text);
      setState(prevState => ({ ...prevState, cpm: text }));
    }
    if (field === 'cpmData') {
      toggleErrorCpmData(!text);
      toggleErrorCpmData(!/^-?\d+$/.test(cpmData));
      setState(prevState => ({ ...prevState, cpmData: text }));
    }
    if (field === 'key') {
      const exist = criterias.find(
        el => el.key === text && el._id !== criteria._id
      );
      toggleErrorKey(!text || exist);
      setState(prevState => ({ ...prevState, key: text }));
    }
  };

  const resetStates = () => {
    setState(initialState);
  };

  const create = () => {
    let error = false;

    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (!name_en) {
      toggleErrorName_en(true);
      error = true;
    }
    if (!description) {
      toggleErrorDescription(true);
      error = true;
    }
    if (!description_en) {
      toggleErrorDescription_en(true);
      error = true;
    }
    // if (!cpm) {
    //   toggleErrorCpm(true);
    //   error = true;
    // }
    if (category.length !== 1) {
      toggleErrorCategory(true);
      error = true;
    }
    if (!visibility) {
      toggleErrorVisibility(true);
      error = true;
    }
    if (!/^-?\d+$/.test(cpmData)) {
      toggleErrorCpmData(true);
      error = true;
    }
    if (
      !key ||
      criterias.find(el => el.key === key && el._id !== criteria._id)
    ) {
      toggleErrorKey(true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        name_en,
        description,
        description_en,
        order: order || 0,
        key,
        dataCpm: cpmData,
        // cpm,
        active: visibility === 'Actif',
        categoryKey: category[0].key
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.insight}
              size="medium"
              widthSize="medium"
              onClick={create}
            >
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du critère *
                          </Text>
                          <TextField
                            small
                            placeholder="Hauts revenus"
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName_en ? 'error' : 'inherit'}>
                            Nom du critère (anglais)*
                          </Text>
                          <TextField
                            small
                            placeholder="High income"
                            onChange={handleChange('name_en')}
                            value={name_en}
                            error={errorName_en}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorDescription ? 'error' : 'inherit'}>
                            Description *
                          </Text>
                          <TextField
                            small
                            placeholder="Les personnes qui..."
                            onChange={handleChange('description')}
                            value={description}
                            error={errorDescription}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text
                            color={errorDescription_en ? 'error' : 'inherit'}
                          >
                            Description (anglais)*
                          </Text>
                          <TextField
                            small
                            placeholder="People who..."
                            onChange={handleChange('description_en')}
                            value={description_en}
                            error={errorDescription_en}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>

                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorOrder ? 'error' : 'inherit'}>
                            Ordre
                          </Text>
                          <TextField
                            small
                            placeholder="Par défaut, dernier critère affiché"
                            onChange={handleChange('order')}
                            value={order}
                            error={errorOrder}
                            format="numberInt"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorKey ? 'error' : 'inherit'}>
                            Key *
                          </Text>
                          <TextField
                            small
                            placeholder="C_42"
                            onChange={handleChange('key')}
                            value={key}
                            error={errorKey}
                            errorText="Key non saisie ou appartenant à un autre critère"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorVisibility ? 'error' : 'inherit'}>
                            Visibilité *
                          </Text>
                          <Select
                            onChange={toggleVisibility}
                            options={['Actif', 'Inactif']}
                            error={errorVisibility}
                            defaultValue="Choisissez la visibilitée"
                            value={visibility}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorKey ? 'error' : 'inherit'}>
                            CPM data *
                          </Text>
                          <TextField
                            small
                            placeholder="CPM data"
                            onChange={handleChange('cpmData')}
                            value={cpmData}
                            error={errorCpmData}
                            errorText="CPM data non saisie ou appartenant à un autre critère"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Catégorie">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text color={errorCategory ? 'error' : 'inherit'}>
                      Catégorie *
                    </Text>
                    <ListField
                      options={catList}
                      values={category}
                      searchOptionsKeys={['name', 'key']}
                      onChange={toggleCategory}
                      actionTxt="Choisir une categorie"
                      deleteAction
                      selectNbMax={1}
                      placeholder="Niveaux de revenu"
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  actionName: null
};
ModalForm.propTypes = {
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actionName: PropTypes.string
};

export default ModalForm;
