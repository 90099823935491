import React from 'react';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Container, Item, Row } from 'components/Layout';
import { Paper } from 'components/Surfaces';

const UnauthorizedContainer = () => {
  const { themeColors } = useTheme();
  return (
    <Container>
      <Row spacing={0}>
        <Item xs={10}>
          <Paper>
            <Row
              spacing={0}
              style={{ backgroundColor: themeColors.backoffice }}
            >
              <Item xs={6}>
                <Row>
                  <Item>
                    <Text variant="h1" bold color="keep_dark">
                      403
                    </Text>
                  </Item>
                  <Item>
                    <Text variant="h4" color="keep_dark">
                      Droits insuffisants
                    </Text>
                  </Item>
                </Row>
              </Item>
              <Item xs={6}>
                <Image src="./assets/img/unauthorized.png" />
              </Item>
            </Row>
            <Row spacing={0} style={{ padding: '20px' }}>
              <Item>
                <Text color="keep_dark">
                  Les droits rattachés à votre compte ne vous permettent pas
                  d’effectuer cette action. Rapprochez vous d’un autre
                  administrateur ayant les droits d’update sur les droits des
                  administrateurs.
                </Text>
              </Item>
            </Row>
          </Paper>
        </Item>
      </Row>
    </Container>
  );
};

UnauthorizedContainer.defaultProps = {};
UnauthorizedContainer.propTypes = {};

export default UnauthorizedContainer;
