import { api } from './api';

const getExportableMediaById = async id => {
  try {
    const result = await api.get(`/insightExportableMedias/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAllExportableMedias = async () => {
  try {
    const result = await api.get(`/insightExportableMedias`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createExportableMedia = async datas => {
  try {
    const result = await api.post(`/insightExportableMedias/`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateExportableMedia = async (id, datas) => {
  try {
    const result = await api.put(`/insightExportableMedias/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getAllExportableMedias,
  getExportableMediaById,
  createExportableMedia,
  updateExportableMedia
};
