import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import {
  EXPORTABLEFORMATS,
  EXPORTABLEMEDIA,
  FUNNEL,
  IMPORTMODEL
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const ExportMediaContainer = () => {
  const exportMediaTypes = ['Aucun', 'Connecteur', 'Webhook'];
  const defaultExportMediaConfig = { exportParams: [] };

  const { adminRights: adminRightsContext } = useUser();
  const [filteredRows, setFilteredRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [ermesMediaKeys, setErmesMediaKeys] = useState([]);
  const [availableErmesMediaKeys, setAvailableErmesMediaKeys] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modalSelector, setModalSelector] = useState(null);
  const [exportMediaData, setExportMediaData] = useState(
    defaultExportMediaConfig
  );
  const [mediaKeyId, setMediaKeyId] = useState('');
  const [models, setModels] = useState([]);
  const [formats, setFormats] = useState([]);
  const closeModal = () => {
    setModalSelector(null);
  };

  useEffect(() => {
    const getDatas = async () => {
      const exportableMediaList = await EXPORTABLEMEDIA.getAllExportableMedias();
      const mediaDailyRates = await FUNNEL.getMediaDailyRates();

      setErmesMediaKeys(
        mediaDailyRates
          .map(mediaDailyRate => {
            return {
              mediaKeyLabel: mediaDailyRate.ermesMediaKey
            };
          })
          .sort((a, b) =>
            a.mediaKeyLabel
              .toLowerCase()
              .localeCompare(b.mediaKeyLabel.toLowerCase())
          )
      );
      const medias = exportableMediaList.map(item => {
        return {
          ...item,
          isUrlConfigured: item.url?.length ? 'oui' : 'non',
          isClearData: item.uncrypted ? 'oui' : 'non'
        };
      });
      setAllRows(medias);
      setFilteredRows(medias);
      const importModelList = await IMPORTMODEL.get();
      const availableModel = importModelList.reduce((acc, item) => {
        if (item.model && item.label) {
          acc.push({ key: item.model, label: item.label });
        }
        return acc.sort((a, b) =>
          a.key.toLowerCase().localeCompare(b.key.toLowerCase())
        );
      }, []);
      setModels(availableModel);

      const formatList = await EXPORTABLEFORMATS.getAllExportableFormats();
      setFormats(
        formatList.map(item => {
          return {
            key: item.format,
            label: item.name
          };
        })
      );
      setLoaded(true);
    };

    if (!loaded) {
      getDatas();
    }
  }, [loaded]);

  useEffect(() => {
    const unavailableErmesMediaKeys = allRows.map(media => media.mediaKey);
    const availableErmesMediaKeyList = ermesMediaKeys.filter(
      ermesMediaKey =>
        !unavailableErmesMediaKeys.includes(ermesMediaKey.mediaKeyLabel)
    );
    setAvailableErmesMediaKeys(availableErmesMediaKeyList);
    // eslint-disable-next-line
  }, [allRows]);

  const handleCreateExportMedia = async exportMediaConfig => {
    const {
      media,
      cpm,
      format,
      encrypted,
      exportType,
      exportValue,
      exportParams
    } = exportMediaConfig;

    const data = {
      mediaKey: media,
      defaultCpm: cpm,
      format,
      uncrypted: !encrypted,
      url: exportType === 'Webhook' ? exportValue : null,
      model: exportType === 'Connecteur' ? exportValue : null,
      requestParams: exportType === 'Webhook' ? exportParams : []
    };

    await EXPORTABLEMEDIA.createExportableMedia(data);
    setLoaded(false);
    closeModal();
  };

  const handleUpdateExportMedia = async exportMediaUpdateConfig => {
    const {
      media,
      cpm,
      formats: format,
      encrypted,
      exportType,
      exportValue,
      exportParams,
      mergeFiles
    } = exportMediaUpdateConfig;

    const data = {
      mediaKey: media,
      defaultCpm: cpm,
      formats: format.map(r => r.key),
      uncrypted: !encrypted,
      url: exportType === 'Webhook' ? exportValue : null,
      model: exportType === 'Connecteur' ? exportValue : null,
      requestParams: exportType === 'Webhook' ? exportParams : [],
      mergeFiles
    };
    await EXPORTABLEMEDIA.updateExportableMedia(mediaKeyId, data);
    setLoaded(false);
    closeModal();
    setExportMediaData(defaultExportMediaConfig);
    setMediaKeyId('');
  };

  const addExportMediaConfig = () => {
    if (adminRightsContext?.audiences?.exportMedia?.create) {
      setModalSelector('modalCreate');
    } else {
      setModalSelector('unauthorized');
    }
  };

  const updateExportMedia = exportMediaId => {
    if (adminRightsContext?.audiences?.exportMedia?.update) {
      const exportMedia = allRows.find(row => row.id === exportMediaId);
      setMediaKeyId(exportMediaId);
      const newFormats = exportMedia.formats.map(r =>
        formats.find(f => f.key === r)
      );
      setExportMediaData({
        media: exportMedia.mediaKey,
        cpm: exportMedia.defaultCpm,
        formats: newFormats,
        encrypted: !exportMedia.uncrypted,
        exportType: exportMedia.url
          ? 'Webhook'
          : exportMedia.model
          ? 'Connecteur'
          : 'Aucun',
        exportValue: exportMedia.url || exportMedia.model || null,
        exportParams: exportMedia.requestParams,
        mergeFiles: exportMedia.mergeFiles
      });
      setModalSelector('modalUpdate');
    } else {
      setModalSelector('unauthorized');
    }
  };

  const filterRows = newRows => {
    setFilteredRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher un média"
            placeholder="Media, Model, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={addExportMediaConfig}
            text="Ajouter une configuration"
          />
          {modalSelector === 'modalCreate' && (
            <ModalForm
              open={modalSelector === 'modalCreate'}
              onClose={closeModal}
              onValidate={handleCreateExportMedia}
              config={{
                ermesMediaKeys: availableErmesMediaKeys,
                formats,
                exportMediaTypes,
                exportMediaModels: models
              }}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={filteredRows}
              headers={[
                { label: 'Media', id: 'mediaKey', sortKey: 'mediaKey' },
                { label: 'Model', id: 'model', sortKey: 'model' },
                {
                  label: 'CPM par défaut',
                  id: 'defaultCpm',
                  sortKey: 'defaultCpm'
                },
                { label: 'Formats', id: 'formats', sortKey: 'formats' },
                {
                  label: 'Données en clair',
                  id: 'isClearData',
                  sortKey: 'isClearData'
                },
                {
                  label: 'URL paramétrée',
                  id: 'isUrlConfigured',
                  sortKey: 'isUrlConfigured'
                }
              ]}
              onUpdate={updateExportMedia}
            />
          )}
          {modalSelector === 'modalUpdate' && (
            <ModalForm
              open={modalSelector === 'modalUpdate'}
              onClose={closeModal}
              onValidate={handleUpdateExportMedia}
              config={{
                ermesMediaKeys: availableErmesMediaKeys,
                formats,
                exportMediaTypes,
                exportMediaModels: models
              }}
              initData={{ ...exportMediaData }}
            />
          )}
          {modalSelector === 'unauthorized' && (
            <UnauthorizedModal
              open={modalSelector === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ExportMediaContainer;
