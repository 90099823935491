import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useNotif } from 'contexts/NotifContext';

import { DOMAINCONFIG, USERS } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';
import Loading from 'components/Loading';

const NewsletterContainer = () => {
  const [allUsers, setAllUser] = useState([]);

  const { notify } = useNotif();
  const [users, setUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const getDatas = async () => {
    const userList = await USERS.getAll();

    setAllUser(userList);
    setLoaded(true);
  };

  const handleChange = value => {
    setUsers(value);
  };

  const send = async () => {
    const result = await DOMAINCONFIG.sendNewsletter(users.map(el => el.email));

    if (!result) {
      return notify({
        message: "Echec de l'envoi de la newsLetter",
        variants: 'error'
      });
    }
    return notify({
      message:
        "Demande d'envoie de newsletter prise en compte, cela peux prendre quelques instants",
      variants: 'success'
    });
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
  }, [loaded]);

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row spacing={5}>
        <Item style={{ padding: '20px 0px' }}>
          <Text fontWeight={500} fontSize="21px">
            Envoyer la newsletter
          </Text>
        </Item>
        <Item>
          <Text fontWeight={600} fontSize="12px">
            Laissez le champs utilisateurs ci dessous vide, pour envoyer l’email
            à tous les utilisateurs du domaine.
          </Text>
        </Item>
      </Row>
      <Row spacing={5}>
        <Item xs={10}>
          <FormSection title="Utilisateurs">
            <Item style={{ maxWidth: '600px' }}>
              <ListField
                options={allUsers.map(e => ({
                  email: e.email
                }))}
                searchOptionsKeys={['email']}
                values={users}
                onChange={handleChange}
                actionTxt="Ajouter un utilisateur"
                deleteAction
              />
            </Item>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={5}>
        <Item>
          <Button
            onClick={send}
            variant="contained"
            disabled={allUsers.length === 0}
            style={{ padding: '10px 16px' }}
          >
            <ChevronRightIcon />
            &nbsp; Envoyer l’email
          </Button>
        </Item>
      </Row>
    </Container>
  );
};

export default NewsletterContainer;
