import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default () => null;

export const IsUpXs = () => {
  return useMediaQuery(useTheme().breakpoints.up('xs'));
};

export const IsUpSm = () => {
  return useMediaQuery(useTheme().breakpoints.up('sm'));
};

export const IsUpMd = () => {
  return useMediaQuery(useTheme().breakpoints.up('md'));
};

export const IsUpLg = () => {
  return useMediaQuery(useTheme().breakpoints.up('lg'));
};

export const IsUpXl = () => {
  return useMediaQuery(useTheme().breakpoints.up('xl'));
};

export const IsDownXs = () => {
  return useMediaQuery(useTheme().breakpoints.down('xs'));
};

export const IsDownSm = () => {
  return useMediaQuery(useTheme().breakpoints.down('sm'));
};

export const IsDownMd = () => {
  return useMediaQuery(useTheme().breakpoints.down('md'));
};

export const IsDownLg = () => {
  return useMediaQuery(useTheme().breakpoints.down('lg'));
};

export const IsDownXl = () => {
  return useMediaQuery(useTheme().breakpoints.down('xl'));
};
