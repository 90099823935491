import { api, bufferApi } from './api';

const upload = async data => {
  try {
    const result = await api.post(`/v2/funnel/csvfile/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const download = async path => {
  try {
    const resp = await bufferApi.post(`/v2/funnel/csvfile`, { path });
    return resp;
  } catch (err) {
    console.error(err);
    return null;
  }
};
const deleteUpload = async data => {
  try {
    const result = await api.delete(
      `/v2/funnel/csvfile`,
      { data },
      { headers: { 'Content-Type': 'application/json; charset=utf-8' } }
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  upload,
  deleteUpload,
  download
};
