import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Fade from 'components/Fade';

export const Item = props => {
  const {
    children,
    flex,
    show,
    spacing,
    style,
    justify,
    alignItems,
    ...rest
  } = props;
  return (
    <Fade show={show}>
      {flex ? (
        <div
          style={{
            display: 'flex',
            padding: spacing * 3,
            justifyContent: justify,
            alignItems,
            ...style
          }}
          {...rest}
        >
          {children}
        </div>
      ) : (
        <Grid
          container
          item
          xs={12}
          justifyContent={justify}
          alignItems={alignItems}
          spacing={spacing}
          style={style}
          {...rest}
        >
          {children}
        </Grid>
      )}
    </Fade>
  );
};

Item.defaultProps = {
  children: null,
  flex: false,
  show: null,
  spacing: 0,
  style: null,
  justify: 'center',
  alignItems: 'center'
};
Item.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.bool,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  spacing: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
  justify: PropTypes.string,
  alignItems: PropTypes.string
};

export default Item;
