// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Fade from 'components/Fade';

export const Row = props => {
  const { children, show, justify, ...rest } = props;
  return (
    <Fade show={show}>
      <Grid
        container
        item
        direction="row"
        xs={12}
        spacing={3}
        style={{ margin: '0px' }}
        justifyContent={justify}
        alignItems="stretch"
        {...rest}
      >
        {children}
      </Grid>
    </Fade>
  );
};

Row.defaultProps = {
  show: null,
  justify: 'center'
};
Row.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  justify: PropTypes.string
};

export default Row;
