import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalDelete = props => {
  const { themeColors } = useTheme();
  const { onClose, onDelete } = props;

  return (
    <Modal maxWidth="xs" {...props}>
      <Container>
        <Row style={{ paddingLeft: '0px', paddingTop: '24px' }}>
          <Item>
            <Text
              bold
              color={themeColors.delete}
              fontWeight={600}
              fontSize="20px"
              variant="body1"
            >
              Supprimer la campagne
            </Text>
          </Item>
        </Row>
        <Row style={{ paddingLeft: '0px', paddingTop: '24px' }}>
          <Item>
            <Text>Cette action est irréversible. Êtes-vous sûr ?</Text>
          </Item>
        </Row>
        <Row style={{ paddingLeft: '0px', paddingTop: '24px' }}>
          <Item xs={12} sm={6}>
            <Button
              color={themeColors.grey_light_button}
              variant="contained"
              size="medium"
              onClick={onClose}
            >
              <Icon icon="left" style={{ marginRight: '20px' }} />
              <Text
                style={{ marginLeft: '10px' }}
                bold
                fontWeight={600}
                color={themeColors.Black}
                fontSize="15px"
              >
                Annuler
              </Text>
            </Button>
          </Item>
          <Item xs={12} sm={6}>
            <Button
              color={themeColors.delete}
              variant="contained"
              size="medium"
              onClick={onDelete}
            >
              <Text bold color={themeColors.keep_light}>
                Supprimer
              </Text>
            </Button>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalDelete.defaultProps = {};
ModalDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ModalDelete;
