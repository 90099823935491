import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorBeta: false,
  errorContactsPerMailbox: false,
  errorMaximumDataCost: false
};
export const ModalForm = props => {
  const { onValidate, config, actionBeta } = props;
  const initialState = {
    beta: config.beta * 100 || null,
    maximumDataCost: config.maximumDataCost * 100 || null,
    contactsPerMailbox: config.contactsPerMailbox || null,
    defaultConversionRates: config.defaultConversionRates || null,
    ranges: config.ranges || []
  };
  const [
    {
      beta,
      maximumDataCost,
      contactsPerMailbox,
      defaultConversionRates,
      ranges
    },
    setState
  ] = React.useState(initialState);
  const [
    { errorBeta, errorMaximumDataCost, errorContactsPerMailbox },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [key]: state }));
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, key) => value => {
    const text = typeof value === 'object' ? value : value.trim();
    switch (field) {
      case 'beta':
        toggleError('errorBeta', !text);
        setState(prevState => ({ ...prevState, beta: text }));
        break;
      case 'maximumDataCost':
        toggleError('errorMaximumDataCost', !text);
        setState(prevState => ({ ...prevState, maximumDataCost: text }));
        break;
      case 'contactsPerMailbox':
        toggleError('errorContactsPerMailbox', !text);
        setState(prevState => ({ ...prevState, contactsPerMailbox: text }));
        break;
      case 'conversionRate':
        setState(prevState => {
          const states = { ...prevState };
          states.defaultConversionRates[key] = text / 100;
          return states;
        });
        break;
      case 'ListField':
        setState(prevState => ({
          ...prevState,
          ranges: value
        }));
        break;
      default:
    }
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!beta) {
      toggleError('errorBeta', true);
      error = true;
    }
    if (!contactsPerMailbox) {
      toggleError('errorContactsPerMailbox', true);
      error = true;
    }
    if (!maximumDataCost) {
      toggleError('errorMaximumDataCost', true);
      error = true;
    }

    if (!error) {
      const datas = {
        beta: beta / 100,
        maximumDataCost: maximumDataCost / 100,
        contactsPerMailbox,
        defaultConversionRates,
        ranges: ranges.map(el => {
          const item = {};
          item._id = el._id;
          item.min = parseFloat(el.min);
          item.max = parseFloat(el.max);
          item.value = parseFloat(el.value);
          if (item.min === 0) {
            item.min = -1;
          }
          return item;
        })
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionBeta || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const conversionRates = Object.keys(defaultConversionRates).map(key => {
    return { key, value: defaultConversionRates[key] * 100 };
  });

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Beta"
                            required
                            value={beta}
                            onChange={handleChange('beta')}
                            format="numberDec"
                            unit="%"
                            error={errorBeta}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Nombre de contacts par boite aux lettres"
                            required
                            value={contactsPerMailbox}
                            onChange={handleChange('contactsPerMailbox')}
                            error={errorContactsPerMailbox}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Frais data maximums"
                            required
                            small
                            value={maximumDataCost}
                            onChange={handleChange('maximumDataCost')}
                            format="numberDec"
                            unit="%"
                            error={errorMaximumDataCost}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de conversion par defaut *" xs={5}>
              <Container>
                {conversionRates &&
                  conversionRates.map(conversionRate => (
                    <Row spacing={0}>
                      <Item xs={6} justify="flex-start">
                        <Container>
                          <Row spacing={0} justify="flex-start">
                            <Item xs={11} justify="flex-start">
                              <Text>{conversionRate.key}</Text>
                              <TextField
                                small
                                value={conversionRate.value}
                                unit="%"
                                onChange={handleChange(
                                  'conversionRate',
                                  conversionRate.key
                                )}
                                format="numberDec"
                              />
                            </Item>
                          </Row>
                        </Container>
                      </Item>
                      <Item xs={6} />
                    </Row>
                  ))}
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection
              title="Coefficient multiplicateur pour ISC contextuel"
              xs={7}
            >
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item>
                    <ListField
                      options={['min', 'max', 'value']}
                      labels={['min', 'max', 'value']}
                      fieldsFormat={['numberDec', 'numberDec', 'numberDec']}
                      values={ranges.map(({ _id, min, max, value }) => ({
                        _id,
                        min,
                        max,
                        value
                      }))}
                      formatedValues={ranges.map(({ min, max, value }) => ({
                        range: `[ ${min} - ${max}]`,
                        value
                      }))}
                      sortValues={list => list.sort((a, b) => a.min - b.min)}
                      onChange={handleChange('ListField', 'ranges')}
                      actionTxt="Ajouter"
                      deleteAction
                      variant="customFields"
                      inputs={['TextField', 'TextField', 'TextField']}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
