import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackward from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import { useTheme } from 'contexts/ThemeContext';

import { Text, Alert } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button, AutocompleteTextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

import {
  useCustomer,
  CustomerActionsTypes
} from '../../../contexts/customContext';
import { isParent } from '../../../utils';
import { CUSTOMERS } from 'utils/rest';

const AddChildCustomer = props => {
  const [{ customers, selectedCustomer }, dispatch] = useCustomer();
  const { themeColors } = useTheme();
  const { onClose } = props;
  const [selectedChild, setSelectedChild] = useState(null);
  const [newCustomers, setNewCustomers] = useState([]);

  const filterCustomers = () => {
    const filtered = customers.filter(
      r =>
        !r?.parentId &&
        !isParent(r, customers) &&
        r._id !== selectedCustomer._id
    );
    setNewCustomers(filtered);
  };

  const updateSelectedChild = () => {
    if (!newCustomers?.find(r => r?._id === selectedChild?._id)) {
      setSelectedChild(newCustomers[0]);
    }
  };

  useEffect(filterCustomers, [customers]);
  useEffect(updateSelectedChild, [newCustomers]);

  const handleAddChild = async () => {
    if (!selectedChild) return;

    const result = await CUSTOMERS.updateCostumers(selectedChild._id, {
      parentId: selectedCustomer._id
    });

    if (result) {
      const newCustomers = customers.map(customer => {
        if (customer._id === selectedChild._id) {
          return { ...customer, parentId: selectedCustomer._id };
        }
        return customer;
      });

      dispatch({
        type: CustomerActionsTypes.UPDATE_CUSTOMER,
        props: {
          customers: newCustomers,
        }
      });
      onClose();
    }
  };

  return (
    <Modal maxWidth="sm" {...props}>
      <Container spacing={5}>
        <Row>
          <Item>
            <Text fontWeight={700} fontSize="20px">
              Ajouter un sous compte
            </Text>
          </Item>
        </Row>
        <Row>
          <Item xs={8}>
            {newCustomers.length ? (
              <AutocompleteTextField
                options={newCustomers}
                onChange={el => setSelectedChild(el)}
                value={selectedChild || newCustomers[0]}
                formatLabel={o => o?.info?.name}
              />
            ) : (
              <Alert
                severity="warning"
                text="Aucun compte enfant n'est disponible"
              />
            )}
          </Item>
        </Row>
        <Row
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            style={{
              borderRadius: '8px'
            }}
            color={themeColors.themeV2.detail}
            variant="contained"
            startIcon={<ArrowBackward sx={{ color: 'black' }} />}
            onClick={() => onClose()}
          >
            <Text
              color={themeColors.themeV2.black}
              fontWeight={600}
              fontSize="14px"
            >
              Annuler
            </Text>
          </Button>
          <Button
            style={{
              borderRadius: '8px'
            }}
            variant="contained"
            endIcon={<ArrowForward />}
            onClick={() => handleAddChild()}
          >
            <Text color="inherit" fontWeight={600} fontSize="14px">
              Créer
            </Text>
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};

AddChildCustomer.defaultProps = {
  onClose: () => null
};
AddChildCustomer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default AddChildCustomer;
