import React from 'react';

import { Text } from 'components/DataDisplay';
import { Item, Row } from 'components/Layout';
import { TextField, Button } from 'components/Inputs';

import { useTheme } from 'contexts/ThemeContext';

export const QuestionContainer = ({
  setRequest,
  createDiscussion,
  polling,
  request
}) => {
  const { themeColors } = useTheme();
  return (
    <Row alignItems="flex-start" justify="flex-start" spacing={1}>
      <Item justify="flex-start">
        <Text align="left" fontWeight={600}>
          Quelle cible souhaitez vous créer ?
        </Text>
      </Item>
      <Item xs={6}>
        <TextField
          multiline
          bgColor={themeColors.themeV2.llmInputBgColor}
          rows={3}
          placeholder="Indiquez ici ce que vous souhaitez demander"
          onChange={setRequest}
          value={request}
          disabled={polling}
        />
      </Item>
      <Item justify="flex-start">
        <Button
          variant="contained"
          small
          disabled={polling}
          onClick={() => {
            createDiscussion(request);
          }}
          loading={polling}
        >
          Envoyer
        </Button>
      </Item>
    </Row>
  );
};
