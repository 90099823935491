import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowBackward from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import { CUSTOMERS } from 'utils/rest';

import { useTheme } from 'contexts/ThemeContext';

import { Alert, Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { AutocompleteTextField } from 'components/Inputs';

import {
  CustomerActionsTypes,
  useCustomer
} from '../../../contexts/customContext';

const CreateCustomerModal = props => {
  const { themeColors } = useTheme();
  const [{ groups, customers }, dispatch] = useCustomer();

  const { onClose } = props;

  const [group, setGroup] = useState(groups[0]);

  const createCustomer = async () => {
    const result = await CUSTOMERS.create({
      groupId: group._id,
      info: { name: group.name }
    });
    if (result) {
      dispatch({
        type: CustomerActionsTypes.UPDATE_CUSTOMER,
        props: {
          customers: [{ ...result, group }, ...customers],
          selectedCustomer: { ...result, group, groupId: group._id }
        }
      });
      onClose();
    }
  };

  return (
    <Modal maxWidth="sm" {...props}>
      <Container>
        <Row spacing={2}>
          {groups?.length ? (
            <>
              <Item>
                <Text fontWeight={700} fontSize="20px">
                  Créer un nouveau compte
                </Text>
              </Item>
              <Item>
                <Text fontWeight={400} fontSize="14px" align="center">
                  Sélectionnez ci-dessous l’organisation à laquelle vous
                  souhaitez associer un nouveau compte client Stripe.
                </Text>
              </Item>
              <Item xs={8} justify="flex-start">
                <AutocompleteTextField 
                  options={groups}
                  onChange={setGroup}
                  value={group}
                  formatLabel={o => o.name}
                />
              </Item>
            </>
          ) : (
            <Item>
              <Alert
                severity="warning"
                text="Il n'y a plus/pas de compte à créer dans votre domaine"
              />
            </Item>
          )}

          <Item justify="flex-start">
            <Row
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                style={{
                  borderRadius: '8px'
                }}
                color={themeColors.themeV2.detail}
                variant="contained"
                startIcon={
                  <ArrowBackward sx={{ color: themeColors.themeV2.black }} />
                }
                onClick={onClose}
              >
                <Text
                  color={themeColors.themeV2.black}
                  fontWeight={600}
                  fontSize="14px"
                >
                  Annuler
                </Text>
              </Button>
              <Button
                disabled={!groups?.length}
                style={{ borderRadius: '8px' }}
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => createCustomer()}
              >
                <Text color="inherit" fontWeight={600} fontSize="14px">
                  Créer
                </Text>
              </Button>
            </Row>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

CreateCustomerModal.defaultProps = {
  onClose: () => null,
  quit: () => null,
  createCustomer: () => null
};
CreateCustomerModal.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  quit: PropTypes.func,
  createCustomer: PropTypes.func
};

export default CreateCustomerModal;
