import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATION, MEDIA } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from '../../../components/media/ModalForm';
import { ModalDelete } from '../../../components/ModalDelete';

function MediaDailyRateContainer() {
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [medias, setMediaList] = useState([]);
  const [channels, setChannels] = useState([]);
  const [mediaLogos, setMediaLogos] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState({});
  const { adminRights } = useUser();

  useEffect(() => {
    const fetchMedias = async () => {
      const [allMedias, logos, channelsList] = await Promise.all([
        MEDIA.getMedias(),
        MEDIA.getMediaLogos(),
        ACTIVATION.getChannels()
      ]);

      setMediaLogos(logos);
      setLoaded(true);
      setMediaList(allMedias);
      const mapped = allMedias.map(item => {
        const logo = logos.find(
          el => el.key.toLowerCase() === item.ermesMediaKey.toLowerCase()
        );
        const newItem = {};
        newItem._id = item._id;
        newItem.mediaName = item.name;
        newItem.responseRate = `${item.responseRate}`;
        newItem.visitDistribution = `${item.responseRate} %`;
        newItem.channel = item.channel;
        newItem.media = item.mediaGroup || '';
        newItem.ermesMediaKey = item.ermesMediaKey || 'N/A';
        newItem.visitRate = item.visitRates
          .map(rate => `${rate * 100}%`)
          .join(', ');
        newItem.logo = logo?.base64Image ? (
          <img src={logo.base64Image} height="37px" alt={newItem.mediaName} />
        ) : null;
        newItem.icon = logo?.base64Icon ? (
          <img src={logo.base64Icon} height="37px" alt={newItem.mediaName} />
        ) : null;
        return newItem;
      });
      setRows(mapped);
      setAllRows(mapped);
      setChannels(channelsList.map(el => el.key));
    };
    if (!loaded) {
      fetchMedias();
    }
  }, [loaded]);

  // DO NOT DELETE
  // const helper = {
  //   visitRate: (
  //     <Container>
  //       <Row>
  //         <Item>
  //           <Text paragraph color="inherit">
  //             La répartition des visites correspond à la distribution sur 3jours
  //             des visites générées par le médias.
  //           </Text>
  //           <Text color="inherit">
  //             Par exemple, pour 10 visites générées par le média, si la valeur
  //             est egal à 80%, 15%, 5%, alors ces 10 visites seront étalées comme
  //             suit:
  //             <ul>
  //               <li>8 visites le même jour que les impression</li>
  //               <li>1.5 visites le jour d&apos;après</li>
  //               <li>0.35 deux jours après</li>
  //             </ul>
  //           </Text>
  //         </Item>
  //       </Row>
  //     </Container>
  //   ),
  //   visitDistribution: (
  //     <Container>
  //       <Row>
  //         <Item>
  //           <Text paragraph color="inherit">
  //             Le taux de visite théorique est le taux de conversion
  //             impression=&gt;visites théorique du média
  //           </Text>
  //           <Text color="inherit">
  //             Par exemple, si le taux est de 0.7%, pour 1000 impressions
  //             générées par le média, on considère que 7 visites (0.7% * 1000)
  //             seront générées par le média.
  //           </Text>
  //         </Item>
  //       </Row>
  //     </Container>
  //   )
  // };
  const selectMedia = id => {
    const media = medias.find(el => el._id === id);
    setSelectedMedia(media);
  };
  const closeModal = () => {
    setModal(null);
    selectMedia({});
  };

  const openCreateModal = () => {
    if (adminRights?.funnel?.medias?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.funnel?.medias?.update) {
      selectMedia(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openDeleteModal = id => {
    if (adminRights?.funnel?.medias?.delete) {
      selectMedia(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async ({ logo, ...datas }) => {
    const result = await MEDIA.create(datas);
    if (result && logo) {
      await MEDIA.createMediaLogo(logo);
    }
    setLoaded(false);
    closeModal();
  };

  const onUpdate = async ({ logo, ...datas }) => {
    await MEDIA.update(selectedMedia._id, datas);
    if (logo) {
      const { id: logoId, ...payload } = logo;
      console.log(payload)
      if (!logoId) {
        await MEDIA.createMediaLogo(payload);
      } else {
        await MEDIA.updateMediaLogo(logoId, payload);
      }
    }
    setLoaded(false);
    closeModal();
  };

  const onDelete = async () => {
    await MEDIA.deleteMedia(selectedMedia._id);
    setLoaded(false);
    closeModal();
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche une régie"
            placeholder="Nom de la régie, Canal, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter une régie" />
          {openModal === 'ModalCreate' && adminRights.funnel.medias.create && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              actionName="Créer le média"
              mediaLogos={mediaLogos}
              channels={channels}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Logo',
                  id: 'logo'
                },
                {
                  label: 'Icône',
                  id: 'icon'
                },
                {
                  label: 'Nom de la régie',
                  id: 'mediaName',
                  sortKey: 'mediaName'
                },
                {
                  label: 'Ermes régie key',
                  id: 'ermesMediaKey',
                  sortKey: 'ermesMediaKey'
                },
                {
                  label: 'Média',
                  id: 'media',
                  sortKey: 'media'
                },
                {
                  label: 'Canal',
                  id: 'channel',
                  sortKey: 'channel'
                }
                // DO NOT DELETE
                // {
                //   label: 'Taux de visite (théorique)',
                //   id: 'visitDistribution',
                //   help: helper.visitDistribution,
                //   sortKey: 'responseRate'
                // },
                // {
                //   label: 'Répartition des visites (j, j+1, j+2)',
                //   id: 'visitRate',
                //   help: helper.visitRate
                // }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openDeleteModal}
            />
          )}
          {openModal === 'ModalUpdate' && adminRights.funnel.medias.update && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedMedia}
              actionName="Modifier le média"
              mediaLogos={mediaLogos}
              channels={channels}
            />
          )}
          {openModal === 'modalDelete' && adminRights.funnel.medias.delete && (
            <ModalDelete
              name={selectedMedia.mediaName}
              type="cette régie"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
}

export default MediaDailyRateContainer;
