import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';

import { shortMonthToFull } from 'utils/functions';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  channelError: false,
  cpmError: false,
  offlineMediaProxyError: false,
  userError: false
};

export const ModalForm = props => {
  const { onValidate, selectedSeasonality } = props;
  const initialState = {
    month: shortMonthToFull(selectedSeasonality.month),
    value: selectedSeasonality.value
  };
  const [{ month, value }, setState] = React.useState(initialState);
  const [{ valueError }, setError] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = itemKey => valueChange => {
    const text = valueChange.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!value) {
      toggleError('value', true);
      error = true;
    }

    if (!error) {
      const datas = {
        value
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              <SaveIcon />
              &nbsp;Sauvegarder
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title={`Saisonnalité ${month}`} xs={4}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>coefficient *</Text>
                          <TextField
                            onChange={handleChange('value')}
                            value={value}
                            error={valueError}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  selectedSeasonality: {}
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  selectedSeasonality: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
