import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  Select,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import TagFunnel from '../TagFunnel';

const ErrorState = {
  nameError: false,
  funnelTypeError: false,
  userError: false,
  groupOwnerError: false,
  tagMappingError: false,
  schemaFunnelError: false
};

const enumType = ['demo', 'customer'];

export const ModalForm = props => {
  const { config, onValidate, funnel, actionName } = props;

  const {
    groupes = [],
    properties = [],
    users = [],
    tagFunnels = [],
    yougovMetrics = [],
    yougovSegments = [],
    schemaFunnel = [],
    funnelBudget = [],
    presetActivation = []
  } = config;
  const { themeColors } = useTheme();

  const [funnelBudgetList] = useState(
    funnelBudget.filter(el => !el.funnelId || el.funnelId === funnel._id)
  );

  const formatTagMapping = funnelTagMapping => {
    if (funnelTagMapping.length === 0) {
      return {};
    }
    const tagId = funnelTagMapping[0].tagFunnelId;
    const tag = tagFunnels.find(el => el._id === tagId);
    const correspondences = funnelTagMapping.map(el => {
      const { label } = tag.funnelLevels.find(
        item => item.level === el.tagFunnelLevel
      ) || { label: el.customLabel };
      return {
        _id: el._id,
        tagLevel: { label, level: el.tagFunnelLevel },
        funnelLevel: el.funnelStep
      };
    });
    return { tag, correspondences };
  };

  const getInitialState = () => {
    const initGroups =
      (funnel._id &&
        groupes.filter(
          el =>
            el.funnelIds.filter(item => item._id === funnel._id).length !== 0
        )) ||
      [];

    const initProperties =
      (funnel._id &&
        properties.filter(el => el.funnelIds.indexOf(funnel._id) !== -1)) ||
      [];

    const initFunnelBudget =
      (funnel?.funnelBudgets &&
        funnel.funnelBudgets.map(el =>
          funnelBudgetList.find(item => item._id === el)
        )) ||
      [];

    const initPresetId =
      funnel.presetId &&
      presetActivation.find(el => el._id === funnel.presetId)?.name;

    return {
      name: funnel.name || null,
      type: funnel?.type,
      notify: funnel.notify || 'none',
      user:
        (funnel.userId &&
          users.find(item => item._id === funnel.userId)?.email) ||
        null,
      groupOwner: funnel?.groupOwner,
      group: initGroups,
      propertie: initProperties,
      funnelBudgets: initFunnelBudget,
      presetId: initPresetId || null,
        tagMapping:
        (funnel.funnelTagMapping &&
          formatTagMapping(funnel.funnelTagMapping)) ||
        {},
      schemaId: (funnel.schemaId && funnel.schemaId.name) || null,
      schemaStep: (funnel.schemaId && funnel.schemaId.steps) || [],
      brandId: (funnel.yougovSettings && funnel.yougovSettings.brandId) || null,
      brandIds: (funnel.yougovSettings && funnel.yougovSettings.brandIds) || [],
      sectorId:
        (funnel.yougovSettings && funnel.yougovSettings.sectorId) || null,
      metrics: (funnel.yougovSettings && funnel.yougovSettings.metrics) || [],
      segments: (funnel.yougovSettings && funnel.yougovSettings.segments) || []
    };
  };

  const [
    {
      name,
      notify,
      user,
      groupOwner,
      type,
      group,
      propertie,
      funnelBudgets,
      presetId,
      tagMapping,
      brandId,
      brandIds,
      sectorId,
      metrics,
      segments,
      schemaId,
      schemaStep
    },
    setState
  ] = useState(getInitialState());
  const [
    {
      nameError,
      userError,
      typeError,
      groupOwnerError,
      tagMappingError,
      schemaFunnelError
    },
    setError
  ] = useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const toggleSchemaFunnel = value => {
    toggleError('schemaFunnel', !value);
    setState(prevState => ({
      ...prevState,
      schemaId: value,
      schemaStep: schemaFunnel.find(item => item.name === value).steps
    }));
  };

  const setTagMapping = value => {
    if (
      value?.tag &&
      !value?.correspondences.find(
        f => f.tagLevel === null || f.funnelLevel === null
      )
    ) {
      toggleError('tagMapping', false);
    }
    setState(prevState => ({ ...prevState, tagMapping: value }));
  };

  const handleChangeMetrics = value => {
    setState(prevState => ({
      ...prevState,
      metrics: value.map(e => yougovMetrics.find(f => f._id === e._id))
    }));
  };
  const handleChangeSegments = value => {
    setState(prevState => ({
      ...prevState,
      segments: value.map(e => yougovSegments.find(f => f._id === e._id))
    }));
  };

  const resetStates = () => {
    setState(getInitialState());
    resetErrors();
  };

  const create = () => {
    let error = false;

    if (!name) {
      toggleError('name', true);
      error = true;
    }
    if (!type) {
      toggleError('type', true);
      error = true;
    }
    if (!user) {
      toggleError('user', true);
      error = true;
    }

    if (!groupOwner) {
      toggleError('groupOwner', true);
      error = true;
    }

    if (!schemaId) {
      toggleError('schemaFunnel', true);
      error = true;
    }

    if (
      tagMapping.tag &&
      tagMapping.correspondences.find(
        f => f.tagLevel === null || f.funnelLevel === null
      )
    ) {
      toggleError('tagMapping', true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        type,
        groupOwner,
        notify,
        group,
        propertie,
        tagMapping,
        measure: {},
        schemaId: schemaFunnel.find(item => item.name === schemaId)._id,
        userId: users.find(item => item.email === user)._id,
        yougovSettings: {
          brandId,
          brandIds,
          sectorId,
          metrics: metrics.map(el => ({
            name: el.name,
            key: el.key,
            description: el.description,
            _id: el._id
          })),
          segments
        },
        funnelBudgets: funnelBudgets.map(el => el._id),
        presetId: presetId
          ? presetActivation.find(el => el.name === presetId)._id
          : undefined
      };

      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={create}
            >
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Nom du funnel"
                            required
                            small
                            placeholder="Choisisez un nom pour votre Funnel"
                            onChange={handleChange('name')}
                            value={name}
                            error={nameError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-end">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Type"
                            required
                            onChange={handleChange('type')}
                            options={enumType}
                            error={typeError}
                            defaultValue="Choisissez un type"
                            value={type}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <AutocompleteTextField
                            title="Organisation propriétaire"
                            required
                            options={groupes}
                            formatLabel={option => {
                              const str = `${option.name} (${option._id})`;
                              return str;
                            }}
                            small
                            value={groupes.find(el => el._id === groupOwner)}
                            onChange={item => {
                              handleChange('groupOwner')(item?._id);
                            }}
                            searchOptionsKeys={['name', '_id']}
                            placeholder="Choisissez une organisation"
                            error={groupOwnerError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-end">
                        <Item xs={11} justify="flex-start">
                          <AutocompleteTextField
                            title="Utilisateur propriétaire"
                            required
                            options={users}
                            formatLabel={usr => {
                              const str = usr.email;
                              return str;
                            }}
                            small
                            value={users.find(el => el.email === user)}
                            onChange={item => {
                              handleChange('user')(item?.email);
                            }}
                            searchOptionsKeys={['email', '_id']}
                            placeholder="Choisissez un utilisateur"
                            error={userError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Schéma du funnel"
                            required
                            onChange={toggleSchemaFunnel}
                            options={schemaFunnel.map(el => el.name)}
                            error={schemaFunnelError}
                            defaultValue="Choisissez un schema"
                            value={schemaId}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                {schemaStep.length !== 0 && (
                  <Row spacing={0}>
                    <Item xs={6} justify="flex-start">
                      <Container>
                        <Row spacing={0} justify="flex-start">
                          <Item xs={11} justify="flex-start">
                            <ListField
                              values={schemaStep.map(item => ({
                                _id: item.key,
                                title: item.title?.fr,
                                name: item.name?.fr
                              }))}
                              selectNbMax={schemaStep.length}
                            />
                          </Item>
                        </Row>
                      </Container>
                    </Item>
                    <Item xs={6} />
                  </Row>
                )}
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Tag funnel">
              <TagFunnel
                options={tagFunnels}
                onChange={setTagMapping}
                error={tagMappingError}
                value={tagMapping}
              />
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Organisation" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={groupes.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={group.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      onChange={handleChange('group')}
                      actionTxt="Ajouter une organisation"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Propriété">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={properties.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={propertie.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      onChange={handleChange('propertie')}
                      actionTxt="Ajouter une propriété"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Yougov">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color="inherit">BrandId principal</Text>
                          <TextField
                            small
                            label="BrandId"
                            onChange={handleChange('brandId')}
                            value={brandId}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6}>
                    <Container>
                      <Row spacing={0} justify="flex-end">
                        <Item xs={11} justify="flex-start">
                          <Text color="inherit">SectorId</Text>
                          <TextField
                            small
                            label="SectorId"
                            onChange={handleChange('sectorId')}
                            value={sectorId}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start" xs={6}>
                    <Container spacing={0}>
                      <Row justify="flex-start" spacing={0}>
                        <Item justify="flex-start">
                          <Text>BrandId supplémentaire(s) </Text>
                          <ListField
                            labels={[' ']}
                            values={brandIds.map(id => {
                              return { id };
                            })}
                            onChange={values =>
                              handleChange('brandIds')(values.map(el => el.id))
                            }
                            actionTxt="Ajouter une marque"
                            deleteAction
                            variant="customFields"
                            inputs={['TextField']}
                            options={['id']}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={1} justify="flex-start">
                        <Item justify="flex-start">
                          <Text>Metrics</Text>
                          <ListField
                            options={yougovMetrics.map(e => ({
                              name: e.name,
                              key: e.key,
                              _id: e._id
                            }))}
                            searchOptionsKeys={['name', 'key']}
                            values={metrics?.map(e => ({
                              name: e.name,
                              key: e.key,
                              _id: e._id
                            }))}
                            onChange={handleChangeMetrics}
                            actionTxt="Ajouter un métrics"
                            deleteAction
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={1} justify="flex-start">
                        <Item justify="flex-start">
                          <Text>Segments</Text>
                          <ListField
                             options={yougovSegments.map(e => ({
                              name: e.name,
                              _id: e._id
                            }))}
                            searchOptionsKeys={['name', '_id']}
                            values={segments?.map(e => ({
                              name: e.name,
                              _id: e._id
                            }))}
                            onChange={handleChangeSegments}
                            actionTxt="Ajouter un segment"
                            deleteAction
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  funnel: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  funnel: PropTypes.objectOf(PropTypes.any),
  actionName: PropTypes.string
};

export default ModalForm;
