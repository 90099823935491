import React from 'react';
import { Row, Item } from 'components/Layout';
import { Text } from 'components/DataDisplay';
import { Button } from 'components/Inputs';
import Loading from 'components/Loading';

export const DiscussionContainer = ({
  discussion,
  polling,
  updateDiscussion
}) => {
  const formatCombination = combinaison => {
    const text = combinaison?.split(' ');
    return text?.map(el => {
      return (
        <Text
          fontWeight={['ET', 'OU'].includes(el) ? 700 : 400}
          component="span"
        >
          {el}&nbsp;
        </Text>
      );
    });
  };

  const formatDefinitions = description => {
    const text = description?.split('|');
    return text?.map((item, index) => (
      <Item justify="flex-start">
        <Text align="left">
          {index + 1} - {item}
        </Text>
      </Item>
    ));
  };

  return (
    <Loading loading={polling}>
      <Row spacing={1}>
        <Item justify="flex-start">
          <Text bold align="left">
            1/ Liste des mots clés retournés par ChatGPT et correspondants à
            votre requête :
          </Text>
        </Item>
        {discussion?.config?.['criteria_segmentation']?.enrichment
          .split('|')
          .map((item, index) => (
            <Item justify="flex-start">
              <Text align="left">
                {index + 1} - {item}
              </Text>
            </Item>
          ))}
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <Text fontWeight={700} align="left">
            2/ Liste et combinaison de critères Ermes correspondants à votre
            requête :
          </Text>
        </Item>
        <Item justify="flex-start">
          <Text fontWeight={700} align="left">
            Critères proposés et définitions associés
          </Text>
        </Item>
        <Item justify="flex-start">
          {formatDefinitions(
            discussion?.config?.['criteria_segmentation']?.description
          )}
        </Item>
        <Item justify="flex-start">
          <Text fontWeight={700} align="left">
            Combinaison proposée
          </Text>
        </Item>
        <Item justify="flex-start">
          {formatCombination(
            discussion?.config?.['criteria_segmentation']?.combinaison
          )}
        </Item>
      </Row>
      <Row>
        <Item xs={3}>
          <Button
            small
            variant="contained"
            onClick={() => {
              updateDiscussion(true);
            }}
            disabled={polling}
            loading={polling}
          >
            Oui
          </Button>
        </Item>
        <Item xs={3}>
          <Button
            small
            variant="outlined"
            onClick={() => {
              updateDiscussion(false);
            }}
            disabled={polling}
            loading={polling}
          >
            Non
          </Button>
        </Item>
      </Row>
    </Loading>
  );
};
