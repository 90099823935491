import React, { useState } from 'react';
import { Tab, Tabs as MuiTabs } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

export const Tabs = ({ tabs = [], color, startValue = 0 }) => {
  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];
  const [value, setValue] = useState(startValue);
  const handleChange = (_, newValue) => {
    if (typeof tabs[newValue]?.onClick === 'function') {
      tabs[newValue].onClick();
    }
    tabs[newValue].onClick();
    setValue(newValue);
  };

  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },

      '& .MuiTab-root': {
        color: themeColors.light,
        textTransform: 'none',
        fontSize: '18px',
        '&.Mui-selected': {
          color: themeColors.light,
          fontWeight: 700
        }
      },
      '& .MuiTabs-indicator': {
        backgroundColor: colorRules
      }
    }
  });
  const classes = useStyles();
  return (
    <MuiTabs className={classes.root} value={value} onChange={handleChange}>
      {tabs.map(tab => (
        <Tab label={tab.label} />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
