import { api } from './api';

const getAll = async () => {
  try {
    const result = await api.get(`/v2/funnel/globalUpdateConfig/`);
    return result;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const deleteOne = async objectId => {
  try {
    await api.delete(`/v2/funnel/globalUpdateConfig/${objectId}`);
  } catch (e) {
    console.error(e);
  }
};

const addOne = async datas => {
  try {
    await api.post(`/v2/funnel/globalUpdateConfig`, datas);
  } catch (e) {
    console.error(e);
  }
};

const updateOne = async (id, datas) => {
  try {
    await api.put(`/v2/funnel/globalUpdateConfig/${id}`, datas);
  } catch (e) {
    console.error(e);
  }
};

export default { getAll, deleteOne, addOne, updateOne };
