import { api } from './api';

const getLanguages = async () => {
  try {
    const { data } = await api.get('/languages');
    return data;
  } catch (err) {
    console.error('languages', err);
    return [];
  }
};

export default { getLanguages };
