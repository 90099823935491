import React, { useCallback, useEffect, useState } from 'react';

import { useApp } from 'contexts/AppContext';
import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import {
  ACTIVATION,
  FUNNEL,
  GROUP,
  PROPERTIES,
  SCHEMA,
  YOUGOVMETRICS,
  YOUGOVE_SEGMENTS
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from '../../../components/funnel/ModalForm';
import { ModalMail } from '../../../components/funnel/ModalMail';
import { ModalDelete } from '../../../components/ModalDelete';

const FunnelContainer = () => {
  const { searchFieldValue, setSearchFieldValue } = useApp();
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedFunnel, setSelectedFunnel] = useState({});
  const [config, setConfig] = useState({});
  const { adminRights } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelectedFunnel({});
  };

  const seachKey = (item, toTest) => {
    const val = toTest || searchFieldValue.toLowerCase();
    for (const key in item) {
      if (
        typeof item[key] === 'string' &&
        item[key].toLowerCase().indexOf(val) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  const selectFunnel = id => {
    const funnel = rows.find(el => el._id === id);
    setSelectedFunnel(funnel);
    return funnel;
  };

  const findPropertie = (id, properties) => {
    for (const propertie of properties) {
      for (const funnelId of propertie.funnelIds) {
        if (funnelId === id) {
          return propertie.name;
        }
      }
    }
    return 'Autre';
  };

  const formatFunnelBudget = (listUser, funnelBudget, funnels) => {
    const listReturn = funnelBudget.map(el => {
      return {
        ...el,
        userId: listUser.find(u => u._id === el.userId)?.email,
        funnelId:
          funnels.find(f => f.funnelBudgets.indexOf(el._id) !== -1)?._id || null
      };
    });
    return listReturn;
  };

  const getDatas = useCallback(async searchFieldValueReceived => {
    const [
      properties,
      resp,
      funnelConfig,
      yougovMetrics,
      yougovSegments,
      schemaFunnel,
      funnelBudget,
      presetActivation
    ] = await Promise.all([
      PROPERTIES.get(),
      FUNNEL.getFunnels(),
      FUNNEL.getConfig(),
      YOUGOVMETRICS.getYougovMetrics(),
      YOUGOVE_SEGMENTS.getAll(),
      SCHEMA.get(),
      FUNNEL.getBudget(),
      ACTIVATION.getActivationPresets()
    ]);
    funnelConfig.yougovMetrics = yougovMetrics;
    funnelConfig.yougovSegments = yougovSegments;
    funnelConfig.schemaFunnel = schemaFunnel;
    funnelConfig.presetActivation = presetActivation;
    funnelConfig.funnelBudget = formatFunnelBudget(
      funnelConfig.users,
      funnelBudget,
      resp.funnels
    );

    const mapped = resp.funnels.map(item => {
      const newItem = item;
      newItem.measure = item.schemaId ? item.schemaId.key : item.measureName;
      newItem.propertie = findPropertie(item._id, properties);
      newItem.start = moment(item.start).format('DD/MM/YYYY');
      newItem.end = moment(item.end).format('DD/MM/YYYY');
      newItem.lastUpdate = moment(item.lastUpdate).format('DD/MM/YYYY');
      return newItem;
    });

    setConfig({ ...funnelConfig, properties });
    setRows(
      !searchFieldValueReceived
        ? mapped
        : mapped.filter(item => {
            return seachKey(item, searchFieldValueReceived);
          })
    );
    setAllRows(mapped);
    setLoaded(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loaded) {
      getDatas(searchFieldValue);
    }
    // eslint-disable-next-line
  }, [getDatas, loaded]);

  useEffect(() => {
    return () => setSearchFieldValue('');
    // eslint-disable-next-line
  }, []);

  const openCreateModal = () => {
    if (adminRights?.funnel?.funnel?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const onCreate = async ({ tagMapping, propertie, group, ...datas }) => {
    const { _id: funnelId } = await FUNNEL.create(datas);
    if (tagMapping.tag && tagMapping.correspondences.length !== 0) {
      await FUNNEL.setTagMapping({
        tagMapping: tagMapping.correspondences.map(e => ({
          customLabel: e.tagLevel.label,
          funnelId,
          funnelStep: e.funnelLevel,
          tagFunnelId: tagMapping.tag._id,
          tagFunnelLevel: e.tagLevel.level
        }))
      });
    }
    for await (const item of propertie) {
      await PROPERTIES.addFunnel({ propertieId: item._id, funnelId });
    }
    for await (const item of group) {
      await GROUP.addFunnel({ groupId: item._id, funnelId });
    }
    setLoaded(false);
    closeModal();
  };

  const openUpdateModal = async id => {
    if (adminRights?.funnel?.funnel?.update) {
      const season = await FUNNEL.getSeasonality(id);
      const funnel = await FUNNEL.getFunnelById(id);
      const funnelTagMapping = await FUNNEL.getTagMapping(id);
      const seasonality = (season && season[0] && season[0].seasonality) || {};

      setSelectedFunnel({
        ...funnel,
        seasonality,
        funnelTagMapping
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async ({
    tagMapping,
    seasonality,
    group,
    propertie,
    ...datas
  }) => {
    try {
      const funnelId = selectedFunnel._id;
      const { groupes, properties } = config;

      /* manage groups */
      for await (const item of groupes) {
        const funnelInGroup =
          item.funnelIds.filter(funnel => funnel._id === funnelId).length !== 0;
        if (group.find(el => el.name === item.name)) {
          if (!funnelInGroup) {
            await GROUP.addFunnel({ groupId: item._id, funnelId });
          }
        } else if (funnelInGroup) {
          await GROUP.deleteFunnel({ groupId: item._id, funnelId });
        }
      }

      /* manage properties */
      for await (const item of properties) {
        const inProperties = item.funnelIds.indexOf(funnelId) !== -1;
        if (propertie.find(el => el._id === item._id)) {
          if (!inProperties) {
            await PROPERTIES.addFunnel({
              propertieId: item._id,
              funnelId
            });
          }
        } else if (inProperties) {
          await PROPERTIES.deleteFunnel({ propertieId: item._id, funnelId });
        }
      }

      /* manage funnelTagMapping to remove */
      await Promise.all(
        selectedFunnel.funnelTagMapping
          .filter(
            el => !tagMapping.correspondences.find(tag => tag._id === el._id)
          )
          .map(async el => FUNNEL.deleteTagMapping(el._id))
      );

      if (seasonality) {
        await FUNNEL.setSeasonality({ funnelId, seasonality });
      }

      await FUNNEL.update(funnelId, datas);
      if (tagMapping.tag && tagMapping.correspondences.length !== 0) {
        await FUNNEL.setTagMapping({
          tagMapping: tagMapping.correspondences.map(e => ({
            _id: e._id || null,
            customLabel: e.tagLevel.label,
            funnelId,
            funnelStep: e.funnelLevel,
            tagFunnelId: tagMapping.tag._id,
            tagFunnelLevel: e.tagLevel.level
          }))
        });
      }
    } catch (e) {
      console.error(e);
    }

    setLoaded(false);
    closeModal();
  };

  const openDeleteModal = id => {
    if (adminRights?.funnel?.funnel?.delete) {
      selectFunnel(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const onDelete = async () => {
    await FUNNEL.deleteFunnel(selectedFunnel._id);
    setLoaded(false);
    closeModal();
  };

  const sendMail = funnel => {
    FUNNEL.sendMailValidation(funnel._id);
    closeModal();
  };

  const openModalSendMail = id => {
    selectFunnel(id);
    setModal('ModalMail');
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={newRows => setRows(newRows)}
            datas={allRows}
            titleHead="Recherche un funnel"
            setSearchFieldValue={setSearchFieldValue}
            placeholder="Funnel id, propriété, nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          {openModal === 'ModalMail' && (
            <ModalMail
              open={openModal === 'ModalMail'}
              onClose={closeModal}
              selectedFunnel={selectedFunnel}
              onSendMail={sendMail}
            />
          )}
          <ButtonCreate onClick={openCreateModal} text="Ajouter un funnel" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              config={config}
              onValidate={onCreate}
              actionName="Créer le funnel"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Id Funnel', id: '_id', sortKey: '_id' },
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Propriété', id: 'propertie', sortKey: 'propertie' },
                {
                  label: 'Propriétaire',
                  id: 'email',
                  style: { color: 'funnel' },
                  sortKey: 'email'
                },
                { label: 'Type', id: 'measure', sortKey: 'measure' },
                {
                  label: 'Dernière MAJ',
                  id: 'lastUpdate',
                  sortKey: 'lastUpdate'
                }
              ]}
              onUpdate={openUpdateModal}
              onDelete={openDeleteModal}
              onSendMail={openModalSendMail}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              funnel={selectedFunnel}
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              actionName="Modifier le funnel"
              config={config}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedFunnel.name}
              type="ce funnel"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default FunnelContainer;
