import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from 'styled-components';

import { useUser } from 'contexts/UserContext';

import { Text } from 'components/DataDisplay';
import { IconButton, Button, TextField } from 'components/Inputs';
import { Column, Container, Item } from 'components/Layout';
import { Paper } from 'components/Surfaces';

import { config, setAdminLocalStorage, setLocalStorage } from './index';

const Login = styled.div`
  width: 100vw;
  height: calc(
    100vh - ${({ footerSize, headerSize }) => `${headerSize} - ${footerSize}`}
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  margin: 30px auto;
  width: 300px;
  cursor: pointer;
`;

const useStyles = makeStyles(() => ({
  paper: {
    paddingTop: 50,
    maxHeight: 500,
    height: 500,
    maxWidth: 500,
    width: 500,
    boxShadow: '10px 10px 100px -51px rgba(0,0,0,0.46)'
  },
  head: {
    marginBottom: 20
  },
  text: {
    width: 500,
    borderRadius: 20,
    margin: 15
  }
}));
/**
 * Login
 * @component
 */
const LoginContainer = ({
  redirectTo,
  logoSrc,
  footerSize,
  headerSize,
  isAdmin,
  ...props
}) => {
  const { signIn, fetchUser, isAuthenticated } = useUser();
  const history = useHistory();
  const styles = useStyles();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isErrorMsg, setIsErrorMsg] = React.useState('');
  const [isErrorEmail, setIsErrorEmail] = React.useState(false);
  const [isErrorPwd, setIsErrorPwd] = React.useState(false);
  const [clearPassword, setClearPassword] = React.useState({
    newPassword: false,
    confirmPassword: false
  });

  React.useEffect(() => {
    if (window.localStorage.getItem(config.refreshToken) !== null && !isAdmin) {
      if (isAuthenticated) {
        history.push(redirectTo);
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const auth = () => {
    signIn({ email, password }).then(({ success, response, error }) => {
      if (success) {
        if (isAdmin) {
          setAdminLocalStorage(response);
        } else {
          setLocalStorage(response);
        }
        fetchUser().then(() => history.push(redirectTo));
      } else {
        setIsErrorEmail(true);
        setIsErrorPwd(true);
        setIsErrorMsg(
          error.message === 'Request failed with status code 400'
            ? 'Vos identifiants sont incorrects.'
            : 'Erreur réseau, contactez votre CSM Ermes.'
        );
      }
    });
  };

  return (
    <Login headerSize={headerSize} footerSize={footerSize} {...props}>
      <Logo src={logoSrc} alt="LOGO" />
      <Paper elevation={1} className={styles.paper} bgColor="keep_light">
        <Container direction="column" justify="flex-start">
          <Column xs={8}>
            <Item justify="center">
              <Text fontSize="34px" className={styles.head}>
                Connexion
              </Text>
              <TextField
                id="email"
                type="text"
                className={styles.text}
                label="Email"
                margin="normal"
                variant="outlined"
                small={false}
                onChange={value => setEmail(value)}
                onClick={() => setIsErrorEmail(false)}
                error={isErrorEmail}
                required
              />
              <TextField
                id="psw"
                type={clearPassword.newPassword ? 'text' : 'password'}
                className={styles.text}
                label="Mot de passe"
                small={false}
                margin="normal"
                variant="outlined"
                onChange={value => setPassword(value)}
                onClick={() => setIsErrorPwd(false)}
                onEnter={auth}
                error={isErrorPwd}
                required
                unit={
                  <IconButton
                    onClick={() =>
                      setClearPassword({
                        ...clearPassword,
                        newPassword: !clearPassword.newPassword
                      })
                    }
                  >
                    {clearPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                }
              />
              {isErrorMsg && (
                <Text style={{ color: '#9b0216' }}>{isErrorMsg}</Text>
              )}
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{
                  width: 500,
                  margin: 15,
                  color: 'white',
                  background:
                    'linear-gradient(90deg,#6b5392 0,#6b5392 18%,#1165b2 60%,#00a4d4 100%)',
                  borderRadius: 20
                }}
                rounded
                onClick={auth}
              >
                Se connecter
              </Button>
            </Item>
          </Column>
        </Container>
      </Paper>
    </Login>
  );
};
LoginContainer.defaultProps = {
  isAdmin: false,
  logoSrc: '/assets/img/logo_ermes_homepage_dark.svg',
  footerSize: '0px',
  headerSize: '0px'
};
LoginContainer.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  logoSrc: PropTypes.string,
  footerSize: PropTypes.string,
  headerSize: PropTypes.string
};

export default LoginContainer;
