import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const ConversionRatesMediasContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [conversionRateMedia, setConversionRateMediaList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [
    selectedConversionRateMedia,
    setSelectedConversionRateMedia
  ] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectConversionRateMedia = id => {
    const media = conversionRateMedia.find(el => el._id === id);
    setSelectedConversionRateMedia(media);
  };
  const closeModal = () => {
    setModal(null);
    selectConversionRateMedia({});
  };
  const getConversionRatesMedias = async () => {
    try {
      const result = await ACTIVATION.getConversionRatesMedias(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectConversionRateMedia(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateConversionRatesMedias(
      selectedConversionRateMedia._id,
      datas
    ).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    getConversionRatesMedias().then(resp => {
      setLoaded(true);
      setConversionRateMediaList(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.conversionTypes =
          item.conversionTypes !== 'shop'
            ? item.conversionTypes
            : 'direct magasin';
        newItem.channel = item.channel;
        newItem.low = numeral(item.low).format('0[.]000%');
        newItem.main = numeral(item.main).format('0[.]000%');
        newItem.high = numeral(item.high).format('0[.]000%');
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Type de conversion',
                  id: 'conversionTypes',
                  sortKey: 'conversionTypes'
                },
                {
                  label: 'Canal',
                  id: 'channel',
                  sortKey: 'channel'
                },
                {
                  label: 'Taux de conversion minimal',
                  id: 'low'
                },
                {
                  label: 'Taux de conversion moyen',
                  id: 'main'
                },
                {
                  label: 'Taux de conversion maximal',
                  id: 'high'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedConversionRateMedia}
              actionName="Modifier"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConversionRatesMediasContainer;
