import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import numeral from 'numeral';

import { useTheme } from 'contexts/ThemeContext';

import { Divider, Text } from 'components/DataDisplay';
import { IconButton, TextField } from 'components/Inputs';
import { Item, Row } from 'components/Layout';

export const LineItem = props => {
  const {
    isCampaignSetupPending,
    children,
    lineItem,
    getStatusChip,
    setLineItems,
    activableMedia,
    mediaSetupTask,
    handleSaveLineItem
  } = props;
  const { themeColors } = useTheme();

  return (
    <>
      <Row spacing={1} key={lineItem.id}>
        <Item
          justify="flex-start"
          style={{
            marginLeft: '55px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Row spacing={0} justify="flex-start">
            <Item flex>
              <Text fontSize="14px" fontWeight={700}>
                {lineItem.name}
              </Text>
            </Item>
            <Item flex> {getStatusChip(activableMedia, mediaSetupTask)}</Item>
            <Item flex>
              {!lineItem.isEditing ? (
                <>
                  {lineItem.externalId && (
                    <Text fontSize="12px" fontWeight={400}>
                      (id: {lineItem.externalId})
                    </Text>
                  )}
                  <IconButton
                    disabled={isCampaignSetupPending}
                    onClick={() => {
                      setLineItems(prevLineItems =>
                        prevLineItems.map(li => {
                          if (li.id === lineItem.id) {
                            return {
                              ...li,
                              isEditing: true
                            };
                          }
                          return li;
                        })
                      );
                    }}
                  >
                    <CreateIcon
                      style={{
                        color: themeColors.light
                      }}
                    />
                  </IconButton>{' '}
                </>
              ) : (
                <Item
                  flex
                  style={{
                    width: '305px',
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    value={lineItem.externalId}
                    onChange={e => {
                      setLineItems(prevLineItems =>
                        prevLineItems.map(li => {
                          if (li.id === lineItem.id) {
                            return {
                              ...li,
                              externalId: e
                            };
                          }
                          return li;
                        })
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setLineItems(prevLineItems =>
                        prevLineItems.map(li => {
                          if (li.id === lineItem.id) {
                            return {
                              ...li,
                              isEditing: false
                            };
                          }
                          return li;
                        })
                      );
                      handleSaveLineItem(lineItem.id, lineItem.externalId);
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Item>
              )}
            </Item>
            <Item xs justify="flex-start" style={{ paddingRight: '5px' }}>
              <Divider
                color="light"
                noFade
                style={{
                  width: '100%',
                  height: '1px'
                }}
              />
            </Item>
            <Item flex>
              <Text fontSize="14px" fontWeight={400}>
                {numeral(lineItem.budgetMedia).format('0,0$')}
              </Text>
            </Item>
          </Row>
        </Item>
      </Row>
      {children}
    </>
  );
};
LineItem.defaultProps = {
  isCampaignSetupPending: false
};

LineItem.propTypes = {
  isCampaignSetupPending: PropTypes.bool,
  children: PropTypes.shape().isRequired,
  lineItem: PropTypes.shape().isRequired,
  getStatusChip: PropTypes.func.isRequired,
  setLineItems: PropTypes.func.isRequired,
  activableMedia: PropTypes.shape().isRequired,
  mediaSetupTask: PropTypes.shape().isRequired,
  handleSaveLineItem: PropTypes.func.isRequired
};

export default LineItem;
