import React from 'react';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Container, Item, Row } from 'components/Layout';

function InProgressContainer() {
  return (
    <Container>
      <Row spacing={6}>
        <Item>
          <Text variant="h4" bold>
            Welcome to Ermes back office
          </Text>
        </Item>
        <Item>
          <Image src="./assets/img/work-in-progress.png" />
        </Item>
      </Row>
    </Container>
  );
}

InProgressContainer.defaultProps = {};
InProgressContainer.propTypes = {};

export default InProgressContainer;
