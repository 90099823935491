import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { removeDuplicates } from 'utils/functions';
import { ACTIVATION } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  nameError: false,
  shopError: false,
  groupKeyError: false
};
export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onCreate, onClose } = props;
  const {
    shops: shopList,
    users: userList,
    selected,
    shopGroups = [],
    levels
  } = config;
  const initialState = {
    name: selected?.name || '',
    groupKey: selected?.key,
    canLaunch: selected?.canLaunch !== undefined ? selected?.canLaunch : true,
    canPropose:
      selected?.canPropose !== undefined ? selected?.canPropose : true,
    shops:
      selected?.shops?.reduce((acc, shopId) => {
        const shop = shopList.find(el => el._id === shopId);
        if (shop) {
          acc.push({ name: shop.name, _id: shop._id });
        }
        return acc;
      }, []) || [],
    parentGroup: shopGroups.find(el => selected?.parent === el?.key)?.key || '',
    childGroups: selected?.key
      ? shopGroups
          ?.filter(el => el.parent === selected?.key)
          .map(e => ({
            name: e.name,
            parent: e.parent || '',
            _id: e._id
          }))
      : [],
    users:
      selected?.users?.reduce((acc, userId) => {
        const user = userList.find(el => el._id === userId);
        if (user) {
          acc.push({ email: user.email, _id: user._id });
        }
        return acc;
      }, []) || []
  };

  const [
    {
      name,
      shops,
      users,
      canLaunch,
      canPropose,
      groupKey,
      parentGroup,
      childGroups
    },
    setState
  ] = React.useState(initialState);
  const [{ nameError, groupKeyError }, setError] = React.useState(ErrorState);

  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [`${key}Error`]: state }));
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    if (itemKey === 'groupKey') {
      toggleError(
        'groupKey',
        shopGroups.some(group => group.key === value)
      );
    }
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const onValidate = async () => {
    let error = false;

    if (!name) {
      toggleError('name', true);
      error = true;
    }

    if (!groupKey) {
      toggleError('groupKey', true);
      error = true;
    }

    if (shops?.length === 0) {
      toggleError('shops', true);
    }

    if (!error) {
      const datas = {
        name,
        key: groupKey,
        shops: shops.map(e => e._id),
        users: users.map(e => e._id),
        canLaunch,
        canPropose,
        parent: parentGroup
      };
      onCreate(datas);
      const combinedArray = [
        ...childGroups,
        ...(Array.isArray(selected?.children) ? selected.children : [])
      ];

      const uniqueArray = removeDuplicates(combinedArray);

      if (uniqueArray.length > 0) {
        for await (const group of uniqueArray) {
          const foundChildShopGroup = childGroups.find(
            el => el._id === group._id
          );
          await ACTIVATION.updateActivationShopGoup(group._id, {
            parent: foundChildShopGroup ? selected?.key : ''
          });
        }
      }
      resetStates();
    }
  };

  const getActions = () => {
    const disabled = !name || !groupKey;
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={disabled ? themeColors.disabled : themeColors.funnel}
              size="medium"
              disabled={disabled}
              widthSize="medium"
              onClick={onValidate}
            >
              {selected ? 'Actualiser' : 'Créer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };
  const computeLastlevelValue = () => {
    const lastLevelObj = [...levels].pop();
    return parseInt(lastLevelObj?.level, 10);
  };
  const filteredHigherShopGroups = shopGroups.filter(ele => {
    if (selected) {
      return (
        !ele.shopsName?.length &&
        // ele.level <= selected.level &&
        ele.levelFromShop >= selected.levelFromShop &&
        !childGroups?.find(child => child._id === ele.id) &&
        selected.id !== ele.id
      );
    }
    return (
      !ele.shopsName?.length &&
      ele.level !== computeLastlevelValue() &&
      !childGroups?.find(child => child._id === ele.id)
    );
  });
  const filteredLowerShopGroups = shopGroups.filter(ele => {
    if (selected) {
      return (
        ele.id !== selected?.id &&
        !ele?.parent?.length &&
        !ele.isRoot &&
        (ele.levelFromShop < selected.levelFromShop ||
          ele.levelFromShop === 1) &&
        parentGroup !== ele.key
      );
    }
    return !ele?.parent?.length && !ele.isRoot && parentGroup !== ele.key;
  });

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Key"
                            small
                            required
                            placeholder="Identifiant unique du groupe"
                            onChange={handleChange('groupKey')}
                            value={groupKey}
                            error={groupKeyError}
                            disabled={selected?.key}
                          />
                          {groupKeyError && groupKey && (
                            <Text xs fontSize="11px" color={themeColors.red}>
                              Cette key existe déjà, veuillez en renseigner une
                              autre unique.
                            </Text>
                          )}
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={9} justify="flex-start">
                          <TextField
                            title="Nom du groupe"
                            small
                            required
                            placeholder="Choisissez un nom pour votre groupe"
                            onChange={handleChange('name')}
                            value={name}
                            error={nameError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text style={{ paddingRight: '5px' }}>
                      Peut proposer des campagnes
                    </Text>
                    <Text fontWeight={!canPropose ? 600 : 300}>OFF</Text>
                    <Switch
                      checked={canPropose}
                      onChange={handleChange('canPropose')}
                    />
                    <Text fontWeight={canPropose ? 600 : 300}>ON</Text>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text style={{ paddingRight: '5px' }}>
                      Peut lancer des campagnes
                    </Text>
                    <Text fontWeight={!canLaunch ? 600 : 300}>OFF</Text>
                    <Switch
                      checked={canLaunch}
                      onChange={handleChange('canLaunch')}
                    />
                    <Text fontWeight={canLaunch ? 600 : 300}>ON</Text>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        {levels.length > 0 && (
          <>
            <Row spacing={1}>
              <Item justify="flex-start">
                <FormSection title="Groupement Parent" xs={3}>
                  {filteredHigherShopGroups.length > 0 ? (
                    <Select
                      onChange={handleChange('parentGroup')}
                      options={[
                        { label: '', value: '' },
                        ...filteredHigherShopGroups.map(e => ({
                          label: e.name,
                          value: e.key
                        }))
                      ]}
                      label="Groupement parent"
                      value={parentGroup}
                      small
                    />
                  ) : (
                    <Item justify="flex-start">
                      <Text>
                        Aucun groupement de niveau supérieur n&apos;est
                        disponible
                      </Text>
                    </Item>
                  )}
                </FormSection>
              </Item>
            </Row>
            <Row spacing={1}>
              <Item justify="flex-start">
                <FormSection title="Groupements enfants" xs={3}>
                  {shops.length > 0 ? (
                    <Item justify="flex-start">
                      <Text>
                        Vous ne pouvez pas ajouter de groupement enfant
                        lorsqu&apos;un magasin est sélectionné
                      </Text>
                    </Item>
                  ) : (
                    <ListField
                      options={filteredLowerShopGroups.map(e => ({
                        name: e.name,
                        parent: e.parent || '',
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name']}
                      values={childGroups}
                      onChange={handleChange('childGroups')}
                      actionTxt="Ajouter un groupe"
                      deleteAction
                    />
                  )}
                </FormSection>
              </Item>
            </Row>
          </>
        )}
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Magasins" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {childGroups.length > 0 ? (
                      <Item justify="flex-start">
                        <Text>
                          Vous ne pouvez pas ajouter de magasin lorsqu&apos;un
                          groupement enfant est sélectionné
                        </Text>
                      </Item>
                    ) : (
                      <ListField
                        options={shopList.map(e => ({
                          name: e.name,
                          _id: e._id
                        }))}
                        searchOptionsKeys={['name']}
                        values={shops}
                        onChange={handleChange('shops')}
                        actionTxt="Ajouter un magasin"
                        deleteAction
                      />
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Utilisateurs" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={userList.map(e => ({
                        email: e.email,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['email']}
                      values={users}
                      onChange={handleChange('users')}
                      actionTxt="Ajouter un utilisateur"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
