/* eslint-disable import/no-cycle */
// READY TO STORYBOOK
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
// eslint-disable-next-line no-restricted-imports
import { FormControl, MenuItem, Select as SelectMui } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

import { Checkbox } from './Checkbox';

export const MultipleSelectSimple = props => {
  const {
    color,
    defaultSelected,
    error,
    title,
    required,
    onChange,
    options,
    show,
    selected,
    small,
    bgColor,
    placeHolder,
    search
  } = props;
  const { panelColor, themeColors } = useTheme();
  const [selectedItems, setSelectedItems] = useState(
    selected || defaultSelected
  );
  const colorRules = themeColors[color] || color || themeColors[panelColor];
  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },
      '& .MuiInputBase-root': {
        color: themeColors.light,
        backgroundColor: bgColor || 'transparent'
      },
      '& .MuiSelect-icon': {
        color: themeColors.light
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });

  useEffect(() => {
    onChange(options.filter(e => selectedItems.find(s => s === e.label)));
    // eslint-disable-next-line
  }, [selectedItems]);

  const StyledMenuItem = withStyles(() => ({
    root: {
      '&:hover': {
        backgroundColor: themeColors.primary
      }
    }
  }))(MenuItem);

  const classes = useStyles();

  const handleChange = event => {
    let newSelection = event.target.value;
    if (event.target.value.includes('selectAll')) {
      if (selectedItems.length >= options.length) {
        newSelection = [];
      } else {
        newSelection = options.map(e => e.key);
      }
    }
    setSelectedItems(newSelection);
  };

  return (
    <Fade show={show}>
      <FormControl
        variant="outlined"
        error={error}
        className={classes.root}
        style={{
          padding: small && '6px 0px',
          width: '100%'
        }}
      >
        {title && (
          <Row spacing={2} justify="flex-start" alignItems="center">
            <Item justify="flex-start" flex>
              <Text noWrap color={error ? 'error' : null} fontSize="14px">
                {title === '&nbsp;' ? <>&nbsp;</> : title}
                {required && '*'}
              </Text>
            </Item>
          </Row>
        )}
        <SelectMui
          multiple
          displayEmpty
          value={selectedItems}
          onChange={handleChange}
          renderValue={selec => {
            if (selec.length === 0) {
              return (
                <Text
                  fontWeight={400}
                  fontSize="16px"
                  color={themeColors.themeV2.subtitle}
                >
                  {placeHolder}
                </Text>
              );
            }

            return selec.join(', ');
          }}
          style={{
            height: small && '40px'
          }}
          MenuProps={{}}
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={() => {
            if (search) {
              return (
                <SearchIcon
                  style={{ color: themeColors.light, marginRight: '10px' }}
                />
              );
            }
            return <ArrowDropDownIcon style={{ color: themeColors.light }} />;
          }}
        >
          <StyledMenuItem disabled value="">
            <Text
              fontWeight={400}
              fontSize="16px"
              color={themeColors.themeV2.subtitle}
            >
              {placeHolder}
            </Text>
          </StyledMenuItem>
          {options.map(option => (
            <StyledMenuItem
              key={option.key}
              value={option.label}
              style={{ height: small && '40px' }}
            >
              <Checkbox checked={selectedItems.indexOf(option.label) !== -1} />
              {option.label}
            </StyledMenuItem>
          ))}
        </SelectMui>
      </FormControl>
    </Fade>
  );
};

export default MultipleSelectSimple;

MultipleSelectSimple.defaultProps = {
  color: null,
  defaultSelected: [],
  error: false,
  title: null,
  required: false,
  onChange: () => null,
  options: [],
  show: null,
  small: false,
  bgColor: null,
  selected: null,
  placeHolder: null,
  search: false
};
MultipleSelectSimple.propTypes = {
  color: PropTypes.string,
  defaultSelected: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.bool,
  title: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  small: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
  bgColor: PropTypes.string,
  placeHolder: PropTypes.string,
  search: PropTypes.bool
};
