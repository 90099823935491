import { api } from './api';

const getExportableFormatById = async id => {
  try {
    const result = await api.get(`/exportableFormats/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getAllExportableFormats = async () => {
  try {
    const result = await api.get(`/exportableFormats`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createExportableFormat = async datas => {
  try {
    const result = await api.post(`/exportableFormats/`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateExportableFormat = async (id, datas) => {
  try {
    const result = await api.put(`/exportableFormats/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getAllExportableFormats,
  getExportableFormatById,
  createExportableFormat,
  updateExportableFormat
};