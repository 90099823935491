import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTheme } from 'contexts/ThemeContext';

import { getTabObjective } from 'utils/functions/index';
import Icon from 'utils/icons';

import { Text, Tooltip } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  Button,
  Calendar as CampaignCalendar,
  Select
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import { ModalDelete } from '../ModalCampaign/ModalDelete';

const getPaid = campaign => {
  if (campaign.isCampaignGroup) {
    if (campaign.paid === '') {
      return 'none';
    }
    return campaign.paid;
  }
  if (campaign.paid) {
    return 'true';
  }
  return 'false';
};

const ModalUpdate = props => {
  const { themeColors } = useTheme();
  const { onClose, campaign, onDelete, config, onValidate } = props;
  const [allUsers, setAllUsers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState('');
  const [usersSelected, setUsersSelected] = useState([]);
  const [status, setStatus] = useState(campaign.status);
  const [paid, setPaid] = useState(getPaid(campaign));
  const [tags, setTags] = useState([]);
  const [objective, setObjective] = useState(campaign.objective);
  const [startDate, setStartDate] = useState(
    campaign.start !== null
      ? moment(campaign.start)
          .utc()
          .startOf('day')
          .format()
      : null
  );
  const [endDate, setEndDate] = useState(
    campaign.end !== null
      ? moment(campaign.end)
          .utc()
          .endOf('day')
          .format()
      : null
  );

  const formatStartDate = date => {
    return date
      ? moment(date)
          .utc()
          .startOf('day')
          .format()
      : null;
  };

  const formatEndDate = date => {
    return date
      ? moment(date)
          .utc()
          .endOf('day')
          .format()
      : null;
  };

  const setPeriod = async ({ startDate: start, endDate: end }) => {
    setStartDate(formatStartDate(start));
    setEndDate(formatEndDate(end));
  };

  const validate = () => {
    onValidate({
      startDate,
      endDate,
      objective,
      paid,
      status,
      userIds: usersSelected,
      tags: tags.length !== 0 ? tags : []
    });
  };

  useEffect(() => {
    if (campaign) {
      setAllUsers(
        config.users.map(user => ({
          _id: user._id,
          name: user.username
        }))
      );
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  return (
    <Modal
      {...props}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={1}>
                  <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Objectif*"
                            options={getTabObjective.map(el => ({
                              value: el.key,
                              label: el.value
                            }))}
                            value={objective}
                            onChange={e => {
                              setObjective(e);
                            }}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item justify="flex-start">
                          <Text>Dates</Text>
                        </Item>
                        <Item xs={11} justify="flex-start">
                          <CampaignCalendar
                            disableCampare
                            start={startDate}
                            end={endDate}
                            onChange={setPeriod}
                            displayDate
                            onClearDate={() => {
                              setPeriod({ reset: true });
                            }}
                            displayClearDate={false}
                            inline
                            disabled
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={1}>
                  <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Statut*"
                            options={[
                              { value: 'draft', label: 'Brouillon' },
                              { value: 'validated', label: 'Validée' },
                              { value: 'paused', label: 'Pause' },
                              { value: 'canceled', label: 'Annulée' },
                              { value: 'completed', label: 'Terminée' },
                              { value: 'active', label: 'Activée' }
                            ]}
                            value={status}
                            onChange={e => {
                              setStatus(e);
                            }}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Paiement*"
                            options={[
                              { value: 'false', label: 'Non Payée' },
                              { value: 'true', label: 'Payée' }
                            ]}
                            value={paid}
                            disabled={status === 'draft'}
                            onChange={e => {
                              setPaid(e);
                            }}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>

                <Row spacing={1}>
                  <Item
                    xs={6}
                    justify="flex-start"
                    style={{ alignItems: 'start' }}
                  >
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Tooltip
                            style={{ width: '100%' }}
                            backGroundColor={themeColors.themeV2.black}
                            disableHoverListener={config.disabledDelete}
                            title="Seules les campagnes au statut différent de brouillon peuvent-être supprimées."
                          >
                            <Button
                              disabled={!config.disabledDelete}
                              variant="contained"
                              color={themeColors.delete}
                              widthSize="large"
                              style={{
                                height: '40px',
                                width: '100%',
                                marginBottom: '6px'
                              }}
                              onClick={() => {
                                setOpenModalDelete(true);
                              }}
                            >
                              <Text bold color="inherit">
                                Supprimer la campagne
                              </Text>
                            </Button>
                          </Tooltip>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item
                    xs={6}
                    justify="flex-end"
                    style={{ alignItems: 'end' }}
                  />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Tags" xs={6}>
              <Container>
                <Row justify="flex-start" spacing={1}>
                  <Text justify="flex-start" fontWeight={400} fontSize="14px">
                    Sélectionnez des tags pour votre campagne, ou écrivez-en un
                    nouveau puis appuyez sur <strong>Entrée</strong>. Pour une
                    multicampagne, les tags seront copiés à l’ensemble de ses
                    campagnes enfants.
                  </Text>
                </Row>
              </Container>
              <Container>
                <Row spacing={1}>
                  <Item xs={12} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          {loaded && (
                            <ListField
                              options={config.tags.map(el => ({ tag: el }))}
                              buttonBgColor={themeColors.grey_light_button}
                              buttonTextColor={themeColors.black}
                              values={tags.map(el => ({ tag: el }))}
                              onChange={e => {
                                setTags(e.map(({ tag }) => tag));
                              }}
                              searchOptionsKeys={['tag']}
                              actionTxt="Ajouter un tag"
                              deleteAction
                              keyEnter
                            />
                          )}
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Accès à la campagne" xs={3}>
              <Container>
                <Row spacing={1}>
                  <Item xs={12} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          {loaded && (
                            <ListField
                              options={allUsers.map(item => ({
                                _id: item._id,
                                name: item.name
                              }))}
                              buttonBgColor={themeColors.grey_light_button}
                              buttonTextColor={themeColors.black}
                              searchOptionsKeys={['name', '_id']}
                              values={usersSelected}
                              onChange={e => {
                                setUsersSelected(e);
                              }}
                              actionTxt="Ajouter"
                              deleteAction
                            />
                          )}
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
            {openModalDelete && (
              <ModalDelete
                open
                onClose={() => {
                  setOpenModalDelete(false);
                }}
                onDelete={() => {
                  setOpenModalDelete(false);
                  onDelete();
                }}
              />
            )}
          </Item>
        </Row>
        <Row
          spacing={1}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '50px'
          }}
        >
          <Button
            variant="contained"
            size="medium"
            color={themeColors.black}
            onClick={() => {
              validate();
            }}
          >
            <Icon icon="right" />
            <Text fontSize="15px" color="white" style={{ marginLeft: '5px' }}>
              Valider
            </Text>
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};

ModalUpdate.defaultProps = {
  onClose: () => null,
  onValidate: () => {},
  onDelete: () => {}
};

ModalUpdate.propTypes = {
  onClose: PropTypes.func,
  config: PropTypes.shape().isRequired,
  campaign: PropTypes.shape().isRequired,
  onValidate: PropTypes.func,
  onDelete: PropTypes.func
};

export default ModalUpdate;
