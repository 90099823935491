import { api } from './api';

const getAll = async () => {
  try {
    const { data } = await api.get(`/users`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getRoles = async () => {
  try {
    const { data } = await api.get(`/users/roles`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createUser = async body => {
  try {
    const { data } = await api.post(`/users`, body);
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
const updateUser = async (id, body) => {
  try {
    const { data } = await api.put(`/users/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const deleteUser = async id => {
  try {
    const { data } = await api.delete(`/users/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAdminRights = async () => {
  try {
    const { data } = await api.get(`/adminRights`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createAdminRights = async body => {
  try {
    const { data } = await api.post(`/adminRights`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateAdminRights = async (id, body) => {
  try {
    const { data } = await api.put(`/adminRights/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteAdminRights = async id => {
  try {
    const { data } = await api.delete(`/adminRights/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getAll,
  getRoles,
  createUser,
  updateUser,
  deleteUser,
  getAdminRights,
  createAdminRights,
  updateAdminRights,
  deleteAdminRights
};
