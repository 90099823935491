import { ACTIVATION, IMPORTMODEL, MEDIA } from 'utils/rest';

const getMedias = async () => {
  const tmp = await MEDIA.getMedias();
  const rslt = tmp.map(el => ({
    value: el.ermesMediaKey,
    label: el.name
  }));
  return rslt;
};

const getActivableMedias = async () => {
  const tmp = await ACTIVATION.getActivableMedias();
  const rslt = tmp.map(el => ({
    value: el._id,
    label: el.name
  }));
  return rslt;
};

const getPageActivableMedias = async () => {
  const tmp = await ACTIVATION.getActivableMedias();
  const rslt = tmp.reduce((acc, el) => {
    if (el.page === true) {
      acc.push({
        value: el.key,
        label: el.name
      });
    }
    return acc;
  }, []);
  return rslt;
};

const getModel = async () => {
  const tmp = await IMPORTMODEL.get();
  const rslt = tmp.map(el => ({
    value: el.model,
    label: el.model
  }));
  return rslt;
};

export default {
  media: getMedias,
  activableMedia: getActivableMedias,
  pageActivableMedia: getPageActivableMedias,
  model: getModel
};
