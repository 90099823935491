import React, { useState } from 'react';

const NavigationContext = React.createContext();

/**
 * NavigationProvider
 * @context
 * @component
 */
const NavigationProvider = props => {
  const [menu, setMenu] = useState({});
  const [showMenu, setShowMenu] = useState(true);
  const [currentPresetId, setCurrentPresetId] = useState();
  const [presets, setPresets] = useState([]);
  const isDefaultPreset = () => {
    const currentPreset = presets.find(el => el.id === currentPresetId);
    return !!currentPreset.isDefault;
  };

  const value = {
    menu,
    setMenu,
    showMenu,
    setShowMenu,
    currentPresetId,
    setCurrentPresetId,
    isDefaultPreset,
    presets,
    setPresets
  };
  return <NavigationContext.Provider {...props} value={value} />;
};

export default NavigationProvider;

/**
 * useNavigation
 * @function
 */
export const useNavigation = () => {
  const context = React.useContext(NavigationContext);
  if (!context)
    throw new Error(
      'NavigationContext must be used in NavigationProvider(NavigationModule)'
    );
  return context;
};
