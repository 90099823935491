import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Divider, Text, HelpTooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';

export const FormSection = props => {
  const { children, title, error, justify, tooltip } = props;
  const { themeColors } = useTheme();

  return (
    <Container style={{ marginBottom: '20px' }}>
      <Row spacing={0} justify="flex-start">
        <Item flex style={{ marginRight: '10px' }}>
          <Text color={error && themeColors.error} variant="h6" bold>
            {title}
          </Text>
          {tooltip && <HelpTooltip title={tooltip} />}
        </Item>
        <Item xs justify="flex-start">
          <Divider
            color="light"
            noFade
            style={{ width: '100%', height: '2px' }}
          />
        </Item>
      </Row>
      <Row spacing={1}>
        <Item justify={justify}>{children}</Item>
      </Row>
    </Container>
  );
};

FormSection.defaultProps = {
  error: false,
  justify: 'center',
  tooltip: false
};
FormSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  error: PropTypes.bool,
  justify: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default FormSection;
