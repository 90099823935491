import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorLabel: false,
  errorName: false,
  errorModel: false
};
export const ModalForm = props => {
  const { config, onValidate, onClose, actionName, mediaList } = props;

  const initialState = {
    label: config?.label || '',
    model: config?.model || '',
    mediaIds: config?.mediaIds || [],
    connectorName: config?.connectorName || '',
    exportAgregationKey: config?.exportAgregationKey || '',
    canSetUp: config?.canSetUp || false
  };
  const [
    { label, model, connectorName, exportAgregationKey, mediaIds, canSetUp },
    setState
  ] = useState(initialState);
  const [{ errorLabel, errorModel, errorName }, setError] = useState(
    ErrorState
  );

  const toggleErrorlabel = state => {
    setError(prevState => ({ ...prevState, errorLabel: state }));
  };

  const toggleErrorModel = state => {
    setError(prevState => ({ ...prevState, errorModel: state }));
  };

  const handleChange = field => value => {
    let text = value;
    if (field === 'mediaIds') {
      text = value.map(el => el._id);
    } else if (field === 'canSetUp') {
      text = !canSetUp;
    } else {
      text = value.trim();
    }
    setState(prevState => ({ ...prevState, [field]: text }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const doAction = () => {
    let error = false;

    if (!label) {
      toggleErrorlabel(true);
      error = true;
    }

    if (!model) {
      toggleErrorModel(true);
      error = true;
    }

    if (!error) {
      const datas = {
        label,
        model,
        connectorName,
        exportAgregationKey,
        mediaIds,
        canSetUp
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} la connexion
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Connecteur" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={12} justify="flex-start">
                    <Row spacing={0} justify="space-between">
                      <Item xs={5} justify="flex-start">
                        <TextField
                          title="label"
                          required
                          placeholder="Google Analytics - Visites..."
                          error={errorLabel}
                          onChange={handleChange('label')}
                          value={label}
                        />
                      </Item>
                      <Item xs={5} justify="flex-start">
                        <Switch
                          title="Setup automatique"
                          onChange={handleChange('canSetUp')}
                          checked={canSetUp}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={12} justify="flex-start">
                    <Row spacing={0} justify="space-between">
                      <Item xs={5} justify="flex-start">
                        <TextField
                          title="model"
                          required
                          placeholder="googleAnalyticsKPI2..."
                          error={errorModel}
                          onChange={handleChange('model')}
                          value={model}
                        />
                      </Item>
                      <Item xs={5} justify="flex-start">
                        <TextField
                          title="connectorName"
                          placeholder="googleAnalytics..."
                          error={errorName}
                          onChange={handleChange('connectorName')}
                          value={connectorName}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Type d'export" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="exportAgregationKey"
                          placeholder="sha256..."
                          onChange={handleChange('exportAgregationKey')}
                          value={exportAgregationKey}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Médias" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={mediaList.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={mediaIds.map(id => {
                        const item = mediaList.find(el => el._id === id);
                        return {
                          _id: item._id,
                          name: item.name
                        };
                      })}
                      onChange={handleChange('mediaIds')}
                      actionTxt="Ajouter un media"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null,
  actionName: ''
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  connexionsGenerator: PropTypes.arrayOf(PropTypes.any).isRequired,
  actionName: PropTypes.string
};

export default ModalForm;
