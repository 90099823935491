import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  success: {
    backgroundColor: 'white !important',
    color: '#28A745 !important',
    width: '400px',
    minHeight: '40px !important'
  },
  error: {
    backgroundColor: 'white !important',
    color: '#BD3E48 !important',
    width: '400px',
    minHeight: '40px !important'
  },
  warning: {
    backgroundColor: 'white !important',
    color: 'black !important',
    width: '400px',
    minHeight: '40px !important'
  },
  info: {
    backgroundColor: 'white !important',
    color: 'black !important',
    width: '400px',
    minHeight: '40px !important'
  }
});

export default styles;
