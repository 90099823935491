import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

/**
 * PeriodFunnel
 * @component
 *
 */
export const SearchField = props => {
  const {
    onChange,
    titleHead,
    datas,
    placeholder,
    setSearchFieldValue,
    disabled,
    inComponent
  } = props;
  const { themeColors } = useTheme();

  const filterData = value => {
    const toTest = value.toLowerCase();
    const filtered = datas.filter(item => {
      for (const key in item) {
        if (
          typeof item[key] === 'string' &&
          key !== 'active' &&
          item[key].toLowerCase().indexOf(toTest) !== -1
        ) {
          setSearchFieldValue(toTest);
          return true;
        }
      }
      return false;
    });
    onChange(filtered);
  };

  return (
    <Container>
      <Row spacing={0}>
        {!inComponent && (
          <Item justify="flex-start">
            <Text>{titleHead}</Text>
          </Item>
        )}
        <Item justify="flex-start">
          <TextField
            type="search"
            small
            placeholder={placeholder}
            onChange={filterData}
            title={inComponent ? titleHead : null}
            disabled={disabled}
            unit={<SearchIcon style={{ color: themeColors.light }} />}
          />
        </Item>
      </Row>
    </Container>
  );
};
SearchField.defaultProps = {
  inComponent: false,
  titleHead: null,
  placeholder: null,
  disabled: false,
  setSearchFieldValue: () => {}
};
SearchField.propTypes = {
  inComponent: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  datas: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  titleHead: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  setSearchFieldValue: PropTypes.func
};

export default SearchField;
