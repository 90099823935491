import { api } from './api';

const getAllBoundaries = async () => {
  try {
    const result = await api.get(`backoffice-funnel/boundaries`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getOneBoundaries = async boundarieId => {
  try {
    const result = await api.get(`backoffice-funnel/boundaries/${boundarieId}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createBoundaries = async body => {
  try {
    const result = await api.post(`backoffice-funnel/boundaries`, body);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateBoundaries = async (boundarieId, updated) => {
  try {
    const result = await api.put(
      `backoffice-funnel/boundaries/${boundarieId}`,
      updated
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteBoundaries = async boundarieId => {
  try {
    const result = await api.delete(
      `backoffice-funnel/boundaries/${boundarieId}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getAllBoundaries,
  getOneBoundaries,
  createBoundaries,
  updateBoundaries,
  deleteBoundaries
};
