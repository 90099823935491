import { api } from './api';

const getYougovMetrics = async () => {
  try {
    const result = await api.get(`/backoffice-funnel/yougovMetrics`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async datas => {
  try {
    const result = await api.post(`/backoffice-funnel/yougovMetrics`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, datas) => {
  try {
    const result = await api.put(
      `/backoffice-funnel/yougovMetrics/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteYougovMetrics = async id => {
  try {
    const result = await api.delete(`/backoffice-funnel/yougovMetrics/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { getYougovMetrics, create, update, deleteYougovMetrics };
