import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalDelete = props => {
  const { onClose, onDelete, name, type } = props;
  const { themeColors } = useTheme();

  return (
    <Modal titleHead="Suppression" maxWidth="xs" {...props}>
      <Container>
        <Row>
          <Item>
            <Text>
              Êtes-vous sûr(e) de vouloir supprimer {type} ?
              <Text bold color="funnel">
                {name || ''}
              </Text>
            </Text>
          </Item>
        </Row>
        <Row>
          <Item xs={12} sm={6}>
            <Button
              variant="outlined"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              rounded
              onClick={onClose}
            >
              ANNULER
            </Button>
          </Item>
          <Item xs={12} sm={6}>
            <Button
              variant="outlined"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              rounded
              onClick={onDelete}
            >
              SUPPRIMER
            </Button>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalDelete.defaultProps = {};
ModalDelete.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ModalDelete;
