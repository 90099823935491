import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  nameError: false,
  keyError: false,
  monthlyBudgetError: false,
  insightShopError: false,
  siteUriError: false,
  subventionError: false
};
export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onCreate, onClose } = props;
  const {
    medias,
    insightShops,
    users: userList,
    selected,
    shopGroups
  } = config;

  const shop = insightShops.find(el => el._id === selected?.insightShop);
  const tmpShopGroup = shopGroups.find(el =>
    el.shops.find(e => e === selected?._id)
  );

  const initialState = {
    key: selected?.key || '',
    name: selected?.name || '',
    monthlyBudget: selected?.monthlyBudget || '',
    create: !!selected?.create,
    insightShop: shop ? { label: shop.name, value: shop._id } : null,
    siteUri: selected?.siteUri || '',
    subvention: selected?.subvention * 100 || '',
    users:
      selected?.users?.reduce((acc, userId) => {
        const user = userList.find(el => el._id === userId);
        if (user) {
          acc.push({ email: user.email, _id: user._id });
        }
        return acc;
      }, []) || [],
    acceptedMedias:
      selected?.acceptedMedias.map(el => ({
        _id: el
      })) || [],
    shopGroup: tmpShopGroup
      ? { label: tmpShopGroup.name, value: tmpShopGroup._id }
      : null,
    filters: selected?.filters || []
  };

  const [
    {
      key,
      name,
      monthlyBudget,
      create,
      insightShop,
      siteUri,
      subvention,
      users,
      acceptedMedias,
      shopGroup,
      filters
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      nameError,
      keyError,
      monthlyBudgetError,
      insightShopError,
      siteUriError,
      subventionError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (keyToggleError, state) => {
    setError(prevState => ({
      ...prevState,
      [`${keyToggleError}Error`]: state
    }));
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const onSwitchChange = () => {
    setState(prevState => ({
      ...prevState,
      create: !create
    }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const onValidate = () => {
    let error = false;

    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (!name) {
      toggleError('name', true);
      error = true;
    }
    if (!insightShop) {
      toggleError('insightShop', true);
      error = true;
    }
    if (!siteUri) {
      toggleError('siteUri', true);
      error = true;
    }

    if (!error) {
      const datas = {
        key,
        name,
        monthlyBudget,
        create,
        insightShop: insightShop.value,
        siteUri,
        subvention: subvention / 100,
        users: users.map(e => e._id),
        acceptedMedias: acceptedMedias.map(e => e._id),
        filters,
      };
      onCreate(datas, shopGroup);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onValidate}
            >
              {selected ? 'Actualiser' : 'Créer'} le paramètre
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Point de vente" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Key"
                            small
                            required
                            placeholder="Identifiant unique du magasin"
                            onChange={handleChange('key')}
                            value={key}
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Nom du point de vente"
                            small
                            required
                            onChange={handleChange('name')}
                            value={name}
                            error={nameError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Budget mensuel"
                            small
                            placeholder="en euros"
                            unit="€"
                            onChange={handleChange('monthlyBudget')}
                            format="numberInt"
                            value={monthlyBudget}
                            error={monthlyBudgetError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <AutocompleteTextField
                            options={insightShops.map(el => ({
                              label: el.name,
                              value: el._id
                            }))}
                            required
                            formatLabel={option => option.label}
                            title="Point de vente"
                            placeholder="Sélectionner un point de vente"
                            small
                            value={insightShop}
                            onChange={handleChange('insightShop')}
                            error={insightShopError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Subvention du siège"
                            small
                            format="numberInt"
                            placeholder="en pourcent"
                            onChange={handleChange('subvention')}
                            value={subvention}
                            unit="%"
                            error={subventionError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Url du site"
                            small
                            required
                            onChange={handleChange('siteUri')}
                            value={siteUri}
                            error={siteUriError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection
              title="Module de création d’une nouvelle campagne"
              xs={3}
            >
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text fontWeight={!create ? 600 : 300}>Désactiver</Text>
                    <Switch checked={create} onChange={onSwitchChange} />
                    <Text fontWeight={create ? 600 : 300}>Activer</Text>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Groupement" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <AutocompleteTextField
                      options={shopGroups.map(el => ({
                        label: el.name,
                        value: el._id
                      }))}
                      formatLabel={option => option.label}
                      title="Groupement"
                      placeholder="Sélectionner un groupement"
                      small
                      value={shopGroup}
                      onChange={handleChange('shopGroup')}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Utilisateurs" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={userList.map(e => ({
                        email: e.email,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['email']}
                      values={users}
                      onChange={handleChange('users')}
                      actionTxt="Ajouter un utilisateur"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Médias activables" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={medias.map(el => ({
                        _id: el.ermesMediaKey
                      }))}
                      searchOptionsKeys={['_id']}
                      values={acceptedMedias}
                      onChange={handleChange('acceptedMedias')}
                      actionTxt="Ajouter un utilisateur"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Filtres" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Text>
                      Les filtres peuvent être utilisés pour trier les magasins ainsi que leurs campagnes lors d’un lancement de campagne ou dans le funnel agrégé.
                      Sélectionnez ici des filtres pour votre point de vente, ou écrivez-en de nouveau puis appuyez sur <strong>Entrée</strong>.
                    </Text>
                  </Item>
                  <Item justify="flex-start">
                    <ListField
                      options={['key', 'value']}
                      keyEnter
                      inputs={['TextField', 'TextField']}
                      labels={['key', 'value']}
                      isRequireds={[true, true]}
                      editAction
                      variant="customFields"
                      onChange={handleChange('filters')}
                      actionTxt="Ajouter un filtre"
                      values={filters}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
