import { api } from './api';

const createConfig = async data => {
  try {
    const result = await api.post(`/pml/config`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConfigs = async () => {
  try {
    const result = await api.get(`/pml/config`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConfig = async (id, body) => {
  try {
    const { data } = await api.put(`/pml/config/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteConfig = async id => {
  try {
    const result = await api.delete(`/pml/config/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const computeConfig = async (id, line) => {
  try {
    const result = await api.post(`/pml/setup/${id}/${line}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getInsightShops = async () => {
  try {
    const { data } = await api.get(`/admin/insightShops?light=true`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getLastSetupTask = async id => {
  try {
    const { data } = await api.get(`/pml/setup/task/last/${id}`);
    return data;
  } catch (err) {
    console.error('getLastSetupTask', err);
    return null;
  }
};

const stopSetupTask = async id => {
  try {
    const { data } = await api.put(`/pml/setup/task/${id}`, {
      status: 'aborted'
    });
    return data;
  } catch (err) {
    console.error('getLastSetupTask', err);
    return null;
  }
};

export default {
  createConfig,
  updateConfig,
  getConfigs,
  deleteConfig,
  computeConfig,
  getInsightShops,
  getLastSetupTask,
  stopSetupTask
};
