/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import moment from 'moment';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Container, Item, Row } from 'components/Layout';

const statusMapping = themeColors => ({
  queued: {
    message: 'Mise à jour ajoutée dans la queue',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  inProgress: {
    message: 'Funnel en cours de mise à jour',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  'In Progress': {
    message: 'Funnel en cours de mise à jour',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  inprogress: {
    message: 'Funnel en cours de mise à jour',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  done: {
    message: 'Mise à jour réussie',
    icon: <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
  },
  success: {
    message: 'Mise à jour réussie',
    icon: <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
  },
  partial: {
    message: 'Mise à jour partielle',
    icon: <Icon icon="computePartial" />
  },
  partial_retry: {
    message: 'Mise à jour partielle - retry dans 20min',
    icon: <Icon icon="computePartial" />
  },
  error: {
    message: 'Erreur lors de la mise à jour',
    icon: <ErrorOutlineIcon style={{ color: themeColors.error }} />
  },
  error_retry: {
    message: 'Erreur lors de la mise à jour - retry dans 20min',
    icon: <ErrorOutlineIcon style={{ color: themeColors.error }} />
  }
});

const sourceMapping = {
  admin: 'Manuel',
  cron: 'Automatique'
};

const ModalLogs = props => {
  const { themeColors } = useTheme();
  const {
    name,
    title = 'Historique des mises à jour du funnel',
    datas = [],
    onClose
  } = props;

  const [selected, setSelected] = React.useState(0);
  const [detailsOpen, setDetailsOpen] = React.useState([]);

  const onClick = value => {
    setSelected(value);
  };

  const details = ['error', 'error_retry', 'partial', 'partial_retry'];

  return (
    <Modal
      titleHead={`${title} : ${name}`}
      maxWidth="lg"
      open
      onClose={onClose}
    >
      <Container>
        {datas.length > 1 && (
          <Row>
            {datas.map((data, index) => (
              <Item
                flex
                style={{ padding: '10px' }}
                onClick={() => onClick(index)}
              >
                <Text
                  link
                  fontSize="18px"
                  fontWeight={selected === index ? 700 : 400}
                  style={{
                    borderBottom:
                      selected === index
                        ? `3px solid ${themeColors.funnel}`
                        : null
                  }}
                >
                  {data.label}
                </Text>
              </Item>
            ))}
          </Row>
        )}
        <Row>
          <Item xs={6} justify="flex-start">
            <Text color={themeColors.funnel}>Status</Text>
          </Item>
          <Item xs={6}>
            <Row justify="space-between" spacing={0}>
              <Item xs={9} justify="flex-start">
                <Text color={themeColors.funnel}>Date & heure</Text>
              </Item>
              <Item xs={3} justify="flex-end">
                <Text color={themeColors.funnel}>Déclencheur</Text>
              </Item>
            </Row>
          </Item>
        </Row>
        <Row>
          {datas[selected].values
            .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
            .map(e => {
              const idKey = e._id ? '_id' : 'id';
              const isDetailsOpen = detailsOpen.find(
                d => d[idKey] === e[idKey]
              );
              return (
                <Item
                  style={{
                    margin: 8,
                    borderRadius: 10,
                    backgroundColor: themeColors.primary
                  }}
                >
                  <Row spacing={0}>
                    <Item xs={6}>
                      <Row>
                        <Item xs={2} justify="flex-start">
                          {statusMapping(themeColors)[e.status].icon}
                        </Item>
                        <Item xs={10} justify="flex-start">
                          {details.indexOf(e.status) !== -1 ? (
                            <Row spacing={0} justify="flex-start">
                              <Item justify="flex-start">
                                <Text>
                                  {statusMapping(themeColors)[e.status].message}
                                </Text>
                              </Item>
                              <Item justify="flex-start">
                                <Text
                                  onClick={() => {
                                    if (isDetailsOpen) {
                                      setDetailsOpen(
                                        detailsOpen.filter(d => d._id !== e._id)
                                      );
                                    } else {
                                      setDetailsOpen([...detailsOpen, e]);
                                    }
                                  }}
                                  bold
                                  color={themeColors.funnel}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {isDetailsOpen
                                    ? 'masquer le rapport'
                                    : 'voir le rapport'}
                                </Text>
                              </Item>
                            </Row>
                          ) : (
                            <Text>
                              {statusMapping(themeColors)[e.status].message}
                            </Text>
                          )}
                        </Item>
                      </Row>
                    </Item>
                    <Item xs={6}>
                      <Row justify="space-between">
                        <Item xs={9} justify="flex-start">
                          <Text>{`le ${moment(e.updatedAt).format(
                            'DD/MM/YYYY'
                          )} à ${moment(e.updatedAt).format(
                            'hh:mm:ss'
                          )}`}</Text>
                        </Item>
                        <Item xs={3} justify="flex-end">
                          <Text>{sourceMapping[e.source]}</Text>
                        </Item>
                      </Row>
                    </Item>
                    {isDetailsOpen && (
                      <Item>
                        <Row
                          spacing={1}
                          style={{
                            backgroundColor: themeColors.light,
                            margin: 5,
                            borderRadius: 10
                          }}
                        >
                          {(e?.message || e?.tracebackMsg)
                            ?.split('|')
                            .map(message => (
                              <Item justify="flex-start">
                                <Text color={themeColors.keep_light}>
                                  - {message}
                                </Text>
                              </Item>
                            ))}
                        </Row>
                      </Item>
                    )}
                  </Row>
                </Item>
              );
            })}
        </Row>
      </Container>
    </Modal>
  );
};

ModalLogs.defaultProps = {};
ModalLogs.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalLogs;
