/* eslint-disable import/no-cycle */
// READY TO STORYBOOK
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
// eslint-disable-next-line no-restricted-imports
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMui
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

import { Button } from './Button';
import { Checkbox } from './Checkbox';

export const MultipleSelect = props => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const {
    color,
    error,
    errorText,
    helperText,
    title,
    required,
    label,
    onChange,
    options,
    show,
    selectAll,
    selectAllLabel,
    selected,
    small,
    bgColor,
    button,
    placeHolder,
    disabled,
    search
  } = props;
  const { panelColor, themeColors } = useTheme();
  const [open, setOpen] = useState(false);

  const colorRules = themeColors[color] || color || themeColors[panelColor];
  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },
      '& .MuiInputBase-root': {
        color: themeColors.light,
        backgroundColor: bgColor || 'transparent'
      },
      '& .MuiSelect-icon': {
        color: themeColors.light
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });

  const StyledMenuItem = withStyles(() => ({
    root: {
      '&:hover': {
        backgroundColor: themeColors.primary
      }
    }
  }))(MenuItem);

  const classes = useStyles();

  const handleChange = event => {
    let newSelection = event.target.value;

    if (event.target.value.includes('selectAll')) {
      if (selected.length >= options.length) {
        newSelection = [];
      } else {
        newSelection = options.map(e => e.key);
      }
    }
    if (!button) {
      onChange(options.filter(e => newSelection.find(s => s === e.key)));
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Fade show={show}>
      <FormControl
        variant="outlined"
        error={error}
        className={classes.root}
        style={{
          padding: small && '6px 0px',
          width: '100%'
        }}
      >
        {title && (
          <Row spacing={2} justify="flex-start" alignItems="center">
            <Item justify="flex-start" flex>
              <Text noWrap color={error ? 'error' : null} fontSize="14px">
                {title === '&nbsp;' ? <>&nbsp;</> : title}
                {required && '*'}
              </Text>
            </Item>
          </Row>
        )}
        {label && <InputLabel>{label}</InputLabel>}
        <SelectMui
          style={{
            height: small && '40px'
          }}
          multiple
          disabled={disabled}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            variant: 'menu',
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
              }
            }
          }}
          displayEmpty
          renderValue={() => {
            if (placeHolder !== null && selected.length === 0) {
              return (
                <Text
                  fontWeight={400}
                  fontSize="16px"
                  color={themeColors.themeV2.subtitle}
                >
                  {placeHolder}
                </Text>
              );
            }
            return selected.length >= options.length
              ? 'Tous'
              : selected
                  .map(e => options.find(opt => opt.key === e)?.label || '')
                  .join(', ');
          }}
          value={selected}
          placeholder="Tous"
          IconComponent={() => {
            if (search) {
              return <SearchIcon style={{ color: themeColors.light }} />;
            }
            return <ArrowDropDownIcon style={{ color: themeColors.light }} />;
          }}
          onChange={handleChange}
        >
          {selectAll && (
            <StyledMenuItem
              value="selectAll"
              style={{
                paddingLeft: '0px',
                paddingRight: '4px',
                paddingTop: '2px',
                paddingBottom: '2px'
              }}
            >
              <Checkbox
                style={{ padding: '4px' }}
                // color={themeColors.funnel_dark_blue}
                checked={selected.length >= options.length}
                indeterminate={
                  selected.length > 0 && selected.length < options.length
                }
                onChange={() => {}}
              />
              {selectAllLabel}
            </StyledMenuItem>
          )}
          {options.map((item, index) => {
            const key = `${index}-${item}`;
            return (
              <StyledMenuItem
                selected={selected.find(e => e === item.key)}
                key={key}
                value={item.key}
                style={{
                  paddingLeft: '0px',
                  paddingRight: '4px',
                  paddingTop: '2px',
                  paddingBottom: '2px'
                }}
              >
                <Checkbox
                  style={{ padding: '4px' }}
                  // color={themeColors.funnel_dark_blue}
                  checked={selected.find(e => e === item.key)}
                  onChange={() => {}}
                />
                {item.label}
              </StyledMenuItem>
            );
          })}
          {button && (
            <Item style={{ padding: '10px' }}>
              <Button
                variant="contained"
                onClick={() => {
                  onChange(
                    options.filter(e => selected.find(s => s === e.key))
                  );
                  handleClose();
                }}
              >
                Valider
              </Button>
            </Item>
          )}
        </SelectMui>
        {((error && errorText) || helperText) && (
          <FormHelperText id="helper-text">
            {(error && errorText) || (!error && helperText)}
          </FormHelperText>
        )}
      </FormControl>
    </Fade>
  );
};

export default MultipleSelect;

MultipleSelect.defaultProps = {
  color: null,
  error: false,
  errorText: null,
  helperText: null,
  title: null,
  required: false,
  label: null,
  onChange: () => null,
  options: [],
  show: null,
  small: false,
  bgColor: null,
  selectAllLabel: 'Tous',
  selectAll: false,
  button: false,
  selected: [],
  placeHolder: null,
  search: false
};
MultipleSelect.propTypes = {
  color: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  small: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
  bgColor: PropTypes.string,
  selectAll: PropTypes.bool,
  button: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  placeHolder: PropTypes.string,
  search: PropTypes.bool
};
