import axios from 'axios';
import moment from 'moment';

import { localStorage } from 'utils/constants';

const capitalize = word =>
  !word ? '' : word[0].toUpperCase() + word.substr(1).toLowerCase();

export const config = {
  refreshToken: localStorage.REFRESH_TOKEN,
  accessToken: localStorage.ACCESS_TOKEN,
  expireAt: localStorage.EXPIRE_AT,
  adminToken: localStorage.ADMIN_TOKEN
};

export const setAdminLocalStorage = response => {
  window.localStorage.setItem(
    config.adminToken,
    `${capitalize(response.data.token_type)} ${response.data.access_token}`
  );
};

export const setLocalStorage = response => {
  window.localStorage.setItem(
    config.accessToken,
    `${capitalize(response.data.token_type)} ${response.data.access_token}`
  );
  window.localStorage.setItem(config.refreshToken, response.data.refresh_token);
  window.localStorage.setItem(
    config.expireAt,
    moment()
      .add(response.data.expires_in, 's')
      .toDate()
      .toISOString()
  );
};

export const cleanLocalStorage = () => {
  window.localStorage.removeItem(config.accessToken);
  window.localStorage.removeItem(config.expireAt);
  window.localStorage.removeItem(config.refreshToken);
};

export const checkRefreshToken = async (force = false, minutes = 20) => {
  const refreshToken = window.localStorage.getItem(config.refreshToken);
  if (refreshToken === null) {
    return false;
  }
  const expireAt = window.localStorage.getItem(config.expireAt);
  if (moment(expireAt).diff(moment(), 'minutes') < minutes || force) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ERMES_API}/auth/refreshToken`,
        null,
        {
          headers: {
            authorization: `Bearer ${window.localStorage.getItem(
              config.refreshToken
            ) || ''}`
          }
        }
      );
      setLocalStorage(response);
      return true;
    } catch (e) {
      console.error('error on catch', e);
      return false;
    }
  } else {
    return true;
  }
};
