import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const errorState = {
  _id: false,
  cpmMin: false,
  cpmMax: false,
  taux1Min: false,
  taux1Max: false,
  taux2Min: false,
  taux2Max: false,
  taux3Min: false,
  taux3Max: false
};

const ModalForm = props => {
  const { actionName, onValidate, boundarie, config } = props;
  const { themeColors } = useTheme();
  const initialState = {
    _id: boundarie?._id || undefined,
    cpmMin: boundarie?.cpm?.min || undefined,
    cpmMax: boundarie?.cpm?.max || undefined,
    taux1Min: boundarie?.taux1?.min || undefined,
    taux1Max: boundarie?.taux1?.max || undefined,
    taux2Min: boundarie?.taux2?.min || undefined,
    taux2Max: boundarie?.taux2?.max || undefined,
    taux3Min: boundarie?.taux3?.min || undefined,
    taux3Max: boundarie?.taux3?.max || undefined
  };
  const { funnels } = config;

  const [
    {
      _id,
      cpmMin,
      cpmMax,
      taux1Min,
      taux1Max,
      taux2Min,
      taux2Max,
      taux3Min,
      taux3Max
    },
    setState
  ] = useState(initialState);
  const [error, setError] = useState(errorState);
  const [errorText, setErrorText] = useState('');

  const handleChangeText = key => value => {
    setErrorText('');
    setError({ ...error, [key]: false });
    const result = value !== '' ? parseFloat(value) : undefined;
    setState(prevState => {
      const newState = { ...prevState };
      newState[key] = result;
      return newState;
    });
  };

  const handleChangeSelect = value => {
    setState(prevState => {
      const newState = { ...prevState };
      newState._id = value;
      return newState;
    });
  };

  const doAction = () => {
    let hasError = false;
    const objError = {};
    if (cpmMin && cpmMax && cpmMin > cpmMax) {
      hasError = true;
      objError.cpmMin = true;
      objError.cpmMax = true;
    }
    if (taux1Min && taux1Max && taux1Min > taux1Max) {
      hasError = true;
      objError.taux1Min = true;
      objError.taux1Max = true;
    }
    if (taux2Min && taux2Max && taux2Min > taux2Max) {
      hasError = true;
      objError.taux2Min = true;
      objError.taux2Max = true;
    }
    if (taux3Min && taux3Max && taux3Min > taux3Max) {
      hasError = true;
      objError.taux3Min = true;
      objError.taux3Max = true;
    }

    if (!hasError) {
      onValidate({
        _id,
        cpm: { min: cpmMin || undefined, max: cpmMax || undefined },
        taux1: {
          min: taux1Min || undefined,
          max: taux1Max || undefined
        },
        taux2: {
          min: taux2Min || undefined,
          max: taux2Max || undefined
        },
        taux3: {
          min: taux3Min || undefined,
          max: taux3Max || undefined
        }
      });
    } else {
      setError(objError);
      setErrorText('La valeur "Max" doit être supérieur à la valeur "Min"');
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnel">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Funnel</Text>
                          <Select
                            onChange={handleChangeSelect}
                            options={funnels}
                            small
                            disabled={!!boundarie}
                            value={_id}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Boundaries">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>CPM min</Text>
                          <TextField
                            small
                            onChange={handleChangeText('cpmMin')}
                            placeholder="0.1"
                            value={cpmMin}
                            format="numberDec"
                            error={error.cpmMin}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>CPM max</Text>
                          <TextField
                            small
                            onChange={handleChangeText('cpmMax')}
                            placeholder="10"
                            value={cpmMax}
                            format="numberDec"
                            error={error.cpmMax}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 1 min</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux1Min')}
                            placeholder="0.1"
                            value={taux1Min}
                            format="numberDec"
                            error={error.taux1Min}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 1 max</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux1Max')}
                            placeholder="10"
                            value={taux1Max}
                            format="numberDec"
                            error={error.taux1Max}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 2 min</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux2Min')}
                            placeholder="0.1"
                            value={taux2Min}
                            format="numberDec"
                            error={error.taux2Min}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 2 max</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux2Max')}
                            placeholder="10"
                            value={taux2Max}
                            format="numberDec"
                            error={error.taux2Max}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 3 min</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux3Min')}
                            placeholder="0.1"
                            value={taux3Min}
                            format="numberDec"
                            error={error.taux3Min}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux 3 max</Text>
                          <TextField
                            small
                            onChange={handleChangeText('taux3Max')}
                            placeholder="10"
                            value={taux3Max}
                            format="numberDec"
                            error={error.taux3Max}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                {errorText && (
                  <Row spacing={0}>
                    <Item justify="flex-start">
                      <Container>
                        <Row spacing={0}>
                          <Text color={themeColors.red}>{errorText}</Text>
                        </Row>
                      </Container>
                    </Item>
                  </Row>
                )}
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  actionName: null,
  boundarie: null,
  config: {}
};

ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  boundarie: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
