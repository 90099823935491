/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  keyError: false,
  targetError: false,
  volume1Error: false,
  volume2Error: false,
  volume3Error: false,
  volume4Error: false,
  cr23Error: false,
  cr34Error: false,
  activeError: false,
  omnicanalError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta } = props;
  const initialState = {
    key: config.key || null,
    target: config.target || null,
    target_en: config.target_en || null,
    volume1: config?.volumes.volume1 || null,
    volume2: config?.volumes.volume2 || null,
    volume3: config?.volumes.volume3 || null,
    volume4: config?.volumes.volume4 || null,
    volume1_en: config?.volumes.volume1_en || null,
    volume2_en: config?.volumes.volume2_en || null,
    volume3_en: config?.volumes.volume3_en || null,
    volume4_en: config?.volumes.volume4_en || null,
    cr12: (config.conversionRates && config.conversionRates.cr1_2) || null,
    cr23:
      (config.conversionRates && config.conversionRates.cr2_3.label) || null,
    cr34:
      (config.conversionRates && config.conversionRates.cr3_4.label) || null,
    active:
      typeof config.active !== 'undefined' ? 'actif' : 'non actif' || null,
    omnicanal:
      typeof config.omnicanal !== 'undefined' ? 'omnicanal' : 'web' || null,
    conversionRatesList: config.conversionRatesList
  };
  const [
    {
      key,
      target,
      target_en,
      volume1,
      volume2,
      volume3,
      volume4,
      volume1_en,
      volume2_en,
      volume3_en,
      volume4_en,
      cr12,
      cr23,
      cr34,
      active,
      omnicanal,
      conversionRatesList
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      keyError,
      targetError,
      volume1Error,
      volume2Error,
      volume3Error,
      volume4Error,
      cr23Error,
      cr34Error,
      activeError,
      omnicanalError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (!target) {
      toggleError('target', true);
      error = true;
    }
    if (!volume1) {
      toggleError('volume1', true);
      error = true;
    }
    if (!volume2) {
      toggleError('volume2', true);
      error = true;
    }
    if (!volume3) {
      toggleError('volume3', true);
      error = true;
    }
    if (!volume4) {
      toggleError('volume4', true);
      error = true;
    }
    if (!cr12) {
      toggleError('cr12', true);
      error = true;
    }
    if (!cr23) {
      toggleError('cr23', true);
      error = true;
    }
    if (!cr34) {
      toggleError('cr34', true);
      error = true;
    }
    if (!active) {
      toggleError('active', true);
      error = true;
    }
    if (!omnicanal) {
      toggleError('omnicanal', true);
      error = true;
    }

    if (!error) {
      const datas = {
        key,
        target,
        target_en: target_en || '',
        volumes: {
          volume1,
          volume2,
          volume3,
          volume4,
          volume1_en: volume1_en || '',
          volume2_en: volume2_en || '',
          volume3_en: volume3_en || '',
          volume4_en: volume4_en || ''
        },
        conversionRates: {
          cr1_2: cr12,
          cr2_3: conversionRatesList.find(el => el.label === cr23).key,
          cr3_4: conversionRatesList.find(el => el.label === cr34).key
        },
        active: active === 'actif',
        omnicanal: omnicanal === 'omnicanal'
        // channel: channels.find(el => el.label === channel).id
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionBeta || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Cible média traditionnel</Text>
                          <TextField
                            small
                            value={key}
                            onChange={handleChange('text', 'key')}
                            error={keyError}
                            disabled
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Label *</Text>
                          <TextField
                            small
                            value={target}
                            onChange={handleChange('text', 'target')}
                            error={targetError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Label EN</Text>
                          <TextField
                            small
                            value={target_en}
                            onChange={handleChange('text', 'target_en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Type de funnel *</Text>
                          <Select
                            onChange={handleChange('text', 'omnicanal')}
                            options={['omnicanal', 'web']}
                            value={omnicanal}
                            error={omnicanalError}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Active</Text>
                          <Select
                            onChange={handleChange('text', 'active')}
                            options={['actif', 'non actif']}
                            value={active}
                            error={activeError}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Etapes">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 1</Text>
                          <TextField
                            small
                            value={volume1}
                            onChange={handleChange('text', 'volume1')}
                            error={volume1Error}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 1 EN</Text>
                          <TextField
                            small
                            value={volume1_en}
                            onChange={handleChange('text', 'volume1_en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 2</Text>
                          <TextField
                            small
                            value={volume2}
                            onChange={handleChange('text', 'volume2')}
                            error={volume2Error}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 2 EN</Text>
                          <TextField
                            small
                            value={volume2_en}
                            onChange={handleChange('text', 'volume2_en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 3</Text>
                          <TextField
                            small
                            value={volume3}
                            onChange={handleChange('text', 'volume3')}
                            error={volume3Error}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 3 EN</Text>
                          <TextField
                            small
                            value={volume3_en}
                            onChange={handleChange('text', 'volume3_en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 4</Text>
                          <TextField
                            small
                            value={volume3}
                            onChange={handleChange('text', 'volume4')}
                            error={volume4Error}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Etape 4 EN</Text>
                          <TextField
                            small
                            value={volume4_en}
                            onChange={handleChange('text', 'volume4_en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de conversion" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de conversion 3/2 *</Text>
                          <Select
                            onChange={handleChange('text', 'cr23')}
                            options={conversionRatesList.map(el => el.label)}
                            value={cr23}
                            error={cr23Error}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de conversion 4/3</Text>
                          <Select
                            onChange={handleChange('text', 'cr34')}
                            options={conversionRatesList.map(el => el.label)}
                            value={cr34}
                            error={cr34Error}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
