import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { ACTIVATION, SCHEMA, SCHEMASTEP } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const SchemaContainer = () => {
  const [rows, setRows] = useState([]);
  const [stepsList, setStepsList] = useState([]);
  const [measuresList, setMeasureList] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [schemaList, setSchemaList] = useState([]);
  const [selectedSchema, setSelectedSchema] = useState({});

  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const selectSchema = id => {
    const schemaStep = schemaList.find(el => el._id === id);
    setSelectedSchema(schemaStep);
    return schemaStep;
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatSteps = steps => {
    const nbSteps = steps.length;
    if (nbSteps === 0) {
      return <Text>Aucune</Text>;
    }
    const str = `${nbSteps} ${pluralize('étape', nbSteps)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row>
            <Item>{steps.map(step => getToolTipText(step.key))}</Item>
          </Row>
        </Container>
      );
    };

    return (
      <Container>
        <Row>
          <Item>
            <Tooltip title={tooltipContent(steps)}>
              <Text>{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const closeModal = () => {
    setModal(null);
    selectSchema({});
  };

  const onCreate = data => {
    SCHEMA.create(data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const onDelete = () => {
    SCHEMA.deleteSchema(selectedSchema._id)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const openCreateModal = async () => {
    if (adminRights?.funnel?.funnelStep?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const openUpdateModal = id => {
    if (adminRights?.funnel?.funnelStep?.update) {
      selectSchema(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openModalDelete = id => {
    if (adminRights?.funnel?.funnelStep?.delete) {
      selectSchema(id);
      setModal('ModalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onUpdate = data => {
    SCHEMA.update(selectedSchema._id, data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const fetchDatas = async () => {
    try {
      const schemas = await SCHEMA.get();
      const steps = await SCHEMASTEP.get();
      const measures = await ACTIVATION.getMeasures();
      return { schemas, steps, measures };
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  useEffect(() => {
    if (!loaded) {
      fetchDatas().then(({ schemas, steps, measures }) => {
        setLoaded(true);
        setSchemaList(schemas);
        const mapped = schemas.map(item => {
          const newItem = {};
          newItem._id = item._id;
          newItem.key = item.key;
          newItem.name = item.name;
          newItem.type =
            measures.find(el => el.key === item.activationMeasureKey)?.target ||
            '';
          newItem.steps = formatSteps(
            item.steps.map(elem => item.steps.find(el => el._id === elem._id))
          );
          return newItem;
        });
        setStepsList(steps);
        setMeasureList(measures);
        setRows(mapped);
        setAllRows(mapped);
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher un modèle"
            placeholder="Key, Nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter" />
          {openModal === 'ModalCreate' && adminRights.funnel.models.create && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              config={{ selectedSchema: {}, stepsList, measuresList }}
              actionName="Créer"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Key (Unique)',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Nom',
                  id: 'name'
                },
                {
                  label: 'Type de funnel',
                  id: 'type'
                },
                {
                  label: 'Étapes',
                  id: 'steps'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openModalDelete}
            />
          )}
          {openModal === 'ModalUpdate' && adminRights.funnel.models.update && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ selectedSchema, stepsList, measuresList }}
              actionName="Modifier"
            />
          )}
          {openModal === 'ModalDelete' && adminRights.funnel.models.delete && (
            <ModalDelete
              name={selectedSchema.name}
              type="ce paramètre"
              open={openModal === 'ModalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default SchemaContainer;
