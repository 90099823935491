import { api } from './api';

const get = async () => {
  try {
    const { data } = await api.get(`/v2/insight/dataProviders`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default { get };
