import dayjs from 'dayjs';
import { CUSTOMERS } from 'utils/rest';

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');

dayjs.extend(isSameOrBefore);

export const updateWithMutateProp = (obj, path, value) => {
  const pathArray = path.split('.');
  const key = pathArray.pop();
  pathArray.reduce((nestedObj, pathPart) => {
    // eslint-disable-next-line no-param-reassign
    if (!nestedObj[pathPart] === undefined) nestedObj[pathPart] = {};
    return nestedObj[pathPart];
  }, obj)[key] = value;
};
export const isParent = (r, arr, options = {}) => {
  const key = options.key ? options.key : 'parentId';
  return arr ? new Set(arr.map(obj => obj?.[key])).has(r?._id) : false;
};
export const parentNone = { label: 'Aucun', key: null };
export const createParentData = (data, { filter } = {}) => {
  return data?.reduce(
    (parents, item) => {
      const parentId = !item.parentId;
      const isNotFiltered = !filter || item._id !== filter._id;
      if (parentId && isNotFiltered) {
        parents.push({ label: item.info.name, key: item._id });
      }
      return parents;
    },
    [parentNone]
  );
};

export const sortByDate = tb => {
  return (
    tb?.sort((a, b) =>
      dayjs(a.updatedAt).isSameOrBefore(dayjs(b.updatedAt)) ? 1 : -1
    ) || []
  );
};


export const getAndParseSubAccounts = async id => {
  const allSubAccounts = await CUSTOMERS.getSubAccounts(id);
  if (!allSubAccounts) {
    return null;
  }
  return allSubAccounts.map(e => {
    return {
      id: e._id,
      name: e.info.name,
      balance: e?.balance,
      financialContribution: e.financialContribution,
      margin: e?.margin?.rate
    };
  });
};


