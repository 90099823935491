import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { ACTIVATION, GROUP } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { AutocompleteTextField, Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';
import Loading from 'components/Loading';

const CreateNewPresetModal = ({ onClose, onCreate, presets, ...props }) => {
  const { themeColors } = useTheme();
  const [groups, setGroups] = useState();
  const [name, setName] = useState('');
  const [presetId, setPresetId] = useState(
    presets.find(el => el.isDefault)._id
  );
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [errorName, setErrorName] = useState(false);

  useEffect(() => {
    GROUP.getGroups().then(res => {
      if (res) {
        setGroups(res);
      }
    });
  }, []);

  const toggleErrorName = state => {
    setErrorName(state);
  };

  const handleNameChange = value => {
    const text = value.trim();
    toggleErrorName(!text);
    setName(text);
  };

  const createPreset = async () => {
    if (!name) {
      toggleErrorName(true);
    } else {
      const newPreset = await ACTIVATION.createPreset({ name, presetId });
      onCreate(newPreset);
      onClose();
      if (newPreset._id) {
        for (const selectedGroup of selectedGroups) {
          GROUP.updateV2(selectedGroup._id, {
            activationPresetIds: selectedGroup.activationPresetIds
              ? [...selectedGroup.activationPresetIds, newPreset._id]
              : [newPreset._id]
          });
        }
      }
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.activation}
              size="medium"
              widthSize="medium"
              onClick={() => createPreset()}
            >
              Créer
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  if (!groups) {
    return (
      <Modal {...props}>
        <Loading src="/assets/img/loader_activation.svg" />
      </Modal>
    );
  }

  return (
    <Modal actions={getActions()} onClose={onClose} {...props}>
      <Container>
        <Row spacing={1}>
          <Item>
            <FormSection title="Général">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du paramétrage *
                          </Text>
                          <TextField
                            small
                            label="Choisisez un nom pour le paramétrage"
                            onChange={handleNameChange}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Parametrage à copier *
                          </Text>
                          <AutocompleteTextField
                            autoSelect
                            autoComplete
                            defaultValue={presets.find(el => el.isDefault)}
                            onChange={e => {
                              if (e) setPresetId(e._id);
                            }}
                            small
                            options={[...presets]}
                            formatLabel={p => p.name}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Groupe de partage">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={groups.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedGroups.map(item => ({
                        _id: item._id,
                        name: item.name
                      }))}
                      deleteFunc={(data, allData) =>
                        allData.filter(item => item._id !== data._id)
                      }
                      onChange={values => {
                        setSelectedGroups(
                          values.map(e => groups.find(g => g._id === e._id))
                        );
                      }}
                      actionTxt="Ajouter une organisation"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

CreateNewPresetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  presets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

export default CreateNewPresetModal;
