import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalDelete = props => {
  const { onClose, onDelete } = props;
  const { themeColors } = useTheme();

  return (
    <Modal titleHead="Suppression" maxWidth="sm" {...props}>
      <Container>
        <Row spacing={2}>
          <Item justify="flex-start">
            <Text bold>Êtes-vous sûr(e) de vouloir supprimer ce profil ?</Text>
          </Item>
        </Row>
        <Row spacing={2}>
          <Item justify="flex-start">
            <Text>cette action est irreversible</Text>
          </Item>
        </Row>
        <Row justify="flex-end" spacing={2}>
          <Item xs={12} sm={3}>
            <Button size="medium" widthSize="medium" onClick={onClose}>
              ANNULER
            </Button>
          </Item>
          <Item xs={12} sm={4}>
            <Button
              variant="contained"
              color={themeColors.error}
              size="medium"
              widthSize="medium"
              onClick={onDelete}
            >
              <DeleteIcon />
              SUPPRIMER
            </Button>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalDelete.defaultProps = {};
ModalDelete.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ModalDelete;
