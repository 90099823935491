import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false
};
export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onCreate, onClose } = props;
  const { funnels = [], propertie } = config;

  const initialState = {
    name: propertie?.name || '',
    selectedFunnels: propertie?.selectedFunnels || []
  };

  const [{ name, selectedFunnels }, setState] = React.useState(initialState);
  const [{ errorName }, setError] = React.useState(ErrorState);

  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };
  const handleChange = field => value => {
    const text = value.trim();
    if (field === 'name') {
      toggleErrorName(!text);
      setState(prevState => ({ ...prevState, name: text }));
    }
  };

  const handleChangeFunnels = value => {
    setState(prevState => ({
      ...prevState,
      selectedFunnels: value.map(e => funnels.find(f => f._id === e._id))
    }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const onValidate = () => {
    let error = false;
    const datas = {
      name,
      selectedFunnels
    };
    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (!error) {
      onCreate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onValidate}
            >
              {propertie ? 'Actualiser' : 'Créer'} le paramètre
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du paramètre *
                          </Text>
                          <TextField
                            small
                            label="Choisisez un nom pour votre paramètre"
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnels" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={funnels.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedFunnels.map(e => ({
                        name: e?.name,
                        _id: e?._id
                      }))}
                      onChange={handleChangeFunnels}
                      actionTxt="Ajouter un funnel"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
