import { api } from './api';

const getPlatformConfigs = async () => {
  try {
    const result = await api.get(`/platformConfig`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default { getPlatformConfigs };
