import axios from 'axios';

import { localStorage } from 'utils/constants';

import { api } from './api';

const login = async (username, password) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_ERMES_API}/auth/admin/login`,
      null,
      {
        auth: { username, password }
      }
    );
    return { success: true, response: result };
  } catch (err) {
    console.error(err);
    return { success: false, error: err };
  }
};

const logout = async () => {
  try {
    const result = await api.post(
      `${process.env.REACT_APP_ERMES_API}/auth/logout`,
      null,
      {
        headers: {
          authorization: `${window.localStorage.getItem(
            localStorage.ACCESS_TOKEN
          ) || ''}`
        }
      }
    );
    return { success: true, response: result };
  } catch (err) {
    console.error(err);
    return { success: false, error: err };
  }
};

const getUser = async () => {
  try {
    const result = await api.get(
      `${process.env.REACT_APP_ERMES_API}/users/me`,
      {
        headers: {
          authorization: `${window.localStorage.getItem(
            localStorage.ACCESS_TOKEN
          ) || ''}`
        }
      }
    );
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default { login, logout, getUser };
