import React, { useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { CATEGORY, CRITERIA } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

function CategoryContainer() {
  const [categories, setCategories] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [config, setConfig] = useState({});
  const [openModal, setModal] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const selectCategory = id => {
    const category = categories.find(el => el._id === id);
    setSelectedCategory(category);
    return category;
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatData = (data, name, keyToShow) => {
    const nbData = data.length;
    if (nbData === 0) {
      return <Text>Aucuns</Text>;
    }
    if (nbData === 1) {
      return <Text color="insight"> {data[0] && data[0][keyToShow]} </Text>;
    }

    const others = nbData - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      name,
      others
    )}`;

    const tooltipContent = key => {
      return <Container>{data.map(el => getToolTipText(el[key]))}</Container>;
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="insight"> {data[0][keyToShow]}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent(keyToShow)}>
              <Text color="insight">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatParentKey = item => {
    if (item.parentKey === null || !item.parentCategory) {
      return <Text>Aucune</Text>;
    }
    return <Text color="insight"> {item.parentCategory.name} </Text>;
  };

  if (!loaded) {
    CATEGORY.getFullCategoryList().then(resp => {
      setLoaded(true);
      setCategories(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.name = item.name;
        newItem.key = item.key;
        newItem.active = item.active;
        newItem.parentName = item.parentCategory && item.parentCategory.name;
        newItem.parentKey = formatParentKey(item);
        newItem.subCategory = formatData(
          item.subCategory.category,
          'catégorie',
          'name'
        );
        newItem.subCriteria = formatData(
          item.subCriteria.criteria,
          'critère',
          'name'
        );
        return newItem;
      });
      setRows(mapped);
      setAllRows(mapped);
    });
  }
  const closeModal = () => {
    setModal(null);
    setSelectedCategory({});
  };

  const handleCreateCategory = datas => {
    const createConfig = {
      name: datas.name,
      name_en: datas.name_en,
      key: datas.key,
      active: datas.active,
      description: datas.description,
      order: datas.order,
      parentKey: (datas.parentCategory && datas.parentCategory.key) || null,
      userCustomCategories: null,
      isMapDisplay: datas.isMapDisplay
    };
    CATEGORY.create(createConfig)
      .then(() => {
        // update all subCat + All criterias
        const subList = datas.subCategories.map(cat =>
          CATEGORY.update(cat._id, { parentKey: datas.key })
        );
        const criteriaList = datas.criterias.map(crit =>
          CRITERIA.update(crit._id, { categoryKey: datas.key })
        );
        Promise.all([...subList, ...criteriaList])
          .catch()
          .then(() => {
            setLoaded(false);
            closeModal();
          });
      })
      .catch(() => closeModal());
  };

  const handleUpdateCategory = datas => {
    const updateConfig = {
      name: datas.name,
      name_en: datas.name_en,
      key: datas.key,
      active: datas.active,
      description: datas.description,
      order: datas.order,
      parentKey: (datas.parentCategory && datas.parentCategory.key) || null,
      userCustomCategories: null,
      isMapDisplay: datas.isMapDisplay
    };
    const { _id } = selectedCategory;
    CATEGORY.update(_id, updateConfig)
      .then(() => {
        // update all subCat + All criterias
        const subList = datas.subCategories.map(cat =>
          CATEGORY.update(cat._id, { parentKey: datas.key })
        );
        const criteriaList = datas.criterias.map(crit =>
          CRITERIA.update(crit._id, { categoryKey: datas.key })
        );

        const removeSub =
          selectedCategory.subCategory &&
          selectedCategory.subCategory.category &&
          selectedCategory.subCategory.category
            .filter(el => !datas.subCategories.find(cat => cat._id === el._id))
            .map(el => CATEGORY.update(el._id, { parentKey: null }));

        const removeCriteria =
          selectedCategory.subCriteria &&
          selectedCategory.subCriteria.criteria &&
          selectedCategory.subCriteria.criteria
            .filter(el => !datas.criterias.find(crit => crit._id === el._id))
            .map(el => CRITERIA.update(el._id, { categoryKey: null }));

        // detached cat & crit
        Promise.all([
          ...subList,
          ...criteriaList,
          ...removeSub,
          ...removeCriteria
        ])
          .catch()
          .then(() => {
            setLoaded(false);
            closeModal();
          });
      })
      .catch(() => closeModal());
  };

  const addCategory = async () => {
    if (adminRights?.audiences?.categories?.create) {
      const data = await CRITERIA.getCriterias();
      setConfig({
        categories,
        criterias: data
      });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const updateCategory = async id => {
    if (adminRights?.audiences?.categories?.update) {
      const category = selectCategory(id);
      const criterias = await CRITERIA.getCriterias();
      setConfig({
        categories,
        category,
        criterias
      });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher une catégorie"
            placeholder="socia-démo, achat"
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addCategory} text="Ajouter un catégorie" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              actionName="Créer la catégorie"
              onClose={closeModal}
              onConfirm={handleCreateCategory}
              config={config}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} src="/assets/img/loader_insight.svg" />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Key', id: 'key', sortKey: 'key' },
                {
                  label: 'Nom',
                  id: 'name',
                  style: { bold: true },
                  sortKey: 'name'
                },
                {
                  label: 'Catégorie parente',
                  id: 'parentKey',
                  sortKey: 'parentName'
                },
                { label: 'Sous catégories', id: 'subCategory' },
                { label: 'Critères rattachés', id: 'subCriteria' }
              ]}
              onUpdate={updateCategory}
              rowCustomStyle={item => {
                return {
                  backgroundColor: item.active
                    ? 'transparent'
                    : themeColors.greyLight
                };
              }}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              actionName="Modifier la catégorie"
              onClose={closeModal}
              onConfirm={handleUpdateCategory}
              config={config}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
}

export default CategoryContainer;
