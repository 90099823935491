import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATION, CONNEXION, LANGUAGES } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { Tabs } from 'components/Navigation';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const ActivableMediaContainer = () => {
  const [parent, setParent] = useState(true);
  const [
    { parentList, childrenList, parentWithoutChildList },
    setActivableMedias
  ] = useState({
    parentList: [],
    childrenList: []
  });
  const [connexionsList, setConnexionsList] = useState([]);
  const [channelsList, setChannelsList] = useState([]);
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [langue, setLangue] = useState([]);
  const [selectedActivableMedia, setSelectedActivableMedia] = useState({});

  const closeModal = () => {
    setModal(null);
    setSelectedActivableMedia({});
  };

  const selectActivableMedia = id => {
    const activableMedia = allRows.find(el => el._id === id);
    setSelectedActivableMedia(activableMedia);
  };

  const getModalDatas = async () => {
    const languages = await LANGUAGES.getLanguages();
    const resConnexionGenerator = await CONNEXION.getConnexions();
    const resChannels = await ACTIVATION.getChannels();
    setLangue(languages);
    setConnexionsList(resConnexionGenerator);
    setChannelsList(resChannels);
  };

  const getDatas = async () => {
    if (langue.length === 0) {
      await getModalDatas();
    }

    const parents = (await ACTIVATION.getActivableMedias(false)).map(el => ({
      ...el,
      id: el._id
    }));

    const getSubMediaWarning = item => {
      const parentMedia = parents.find(el => el.id === item.parentId);
      if (!parentMedia) {
        return false;
      }
      return `si vous cliquez sur “Ajouter le média”, le média enfant “${item.name}“ sera détaché du média parent “${parentMedia.name}“`;
    };

    const children = (await ACTIVATION.getActivableMedias(true)).map(el => ({
      ...el,
      id: el._id,
      warning: getSubMediaWarning(el, parents)
    }));

    const parentWithoutChild = parents.filter(el => {
      const hasChildren = children.filter(child => child.parentId === el.id);
      return hasChildren.length === 0;
    });

    setActivableMedias({
      parentList: parents,
      parentWithoutChildList: parentWithoutChild,
      childrenList: children
    });

    const actives = parent ? parents : children;
    setRows(actives);
    setAllRows(actives);

    setLoaded(true);
  };

  useEffect(() => {
    if (loaded) {
      const actives = parent ? parentList : childrenList;
      setRows(actives);
      setAllRows(actives);
    }
    // eslint-disable-next-line
  }, [parent]);

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleUpdateActivableMedia = async ({ children, ...data }) => {
    const { id } = selectedActivableMedia;
    const toUnChild = childrenList.filter(
      activableMedia =>
        activableMedia.parentId === id && !children.includes(activableMedia.id)
    );

    await Promise.all(
      children.map(child =>
        ACTIVATION.updateActivableMedia(child, {
          parentId: id
        })
      )
    );
    await Promise.all(
      toUnChild.map(child =>
        ACTIVATION.updateActivableMedia(child.id, {
          parentId: ''
        })
      )
    );

    await ACTIVATION.updateActivableMedia(selectedActivableMedia._id, data);
    setModal(null);
    setLoaded(false);
  };

  const updateActivableMedia = id => {
    if (adminRights?.general?.activableMedias?.update) {
      selectActivableMedia(id);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row>
        <Item flex>
          <Tabs
            tabs={[
              { label: 'Medias Parents', onClick: () => setParent(true) },
              { label: 'Medias Enfants', onClick: () => setParent(false) }
            ]}
          />
        </Item>
      </Row>
      <Loading loading={!loaded}>
        <Row>
          <Item xs={3}>
            <SearchField
              onChange={filterRows}
              datas={allRows}
              titleHead="Rechercher un média"
              placeholder="Média, model, types ..."
            />
          </Item>
        </Row>
        <Row>
          <Item>
            <TableData
              rows={rows}
              headers={[
                { label: 'Media', id: 'name', sortKey: 'media' },
                { label: 'key', id: 'key' },
                { label: 'Connecteur', id: 'model' },
                { label: 'Budget minimum', id: 'minimumBudget' },
                {
                  label: 'Budget minimum par jour',
                  id: 'dailyMinimumBudget'
                },
                { label: 'Audience minimum', id: 'minimumAudience' }
              ]}
              onUpdate={updateActivableMedia}
            />
            {openModal === 'modalUpdate' && (
              <ModalForm
                open={openModal === 'modalUpdate'}
                onClose={closeModal}
                onValidate={handleUpdateActivableMedia}
                config={selectedActivableMedia}
                connexionsGenerator={connexionsList}
                channels={channelsList}
                parentList={(parent
                  ? parentList.filter(
                      el => el.id !== selectedActivableMedia._id
                    )
                  : parentList
                ).map(el => ({
                  name: el.name,
                  key: el.key,
                  id: el.id
                }))}
                childrenList={(!parent
                  ? childrenList.filter(
                      el => el.id !== selectedActivableMedia._id
                    )
                  : [
                      ...childrenList,
                      ...parentWithoutChildList.filter(
                        el => el.id !== selectedActivableMedia._id
                      )
                    ]
                ).map(el => ({
                  name: el.name,
                  key: el.key,
                  id: el.id,
                  parentId: el.parentId,
                  warning:
                    selectedActivableMedia.id === el.parentId
                      ? false
                      : el.warning
                }))}
                actionName="Modifier"
                langues={langue}
              />
            )}
            {openModal === 'unauthorized' && (
              <UnauthorizedModal
                open={openModal === 'unauthorized'}
                onClose={closeModal}
              />
            )}
          </Item>
        </Row>
      </Loading>
    </Container>
  );
};

export default ActivableMediaContainer;
