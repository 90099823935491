import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

import numeral from 'utils/numeral';
import pluralize from 'utils/pluralize';
import { ACTIVATION, DOMAINCONFIG } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalSetupOption } from './components/ModalSetup/index';

const CampaignOptionsContainer = () => {
  const { themeColors } = useTheme();

  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState('');
  const [rows, setRows] = useState([]);
  const [dataConfig, setDataConfig] = useState([]);
  const [allCampaignOptions, setAllCampaignOptions] = useState([]);
  const [campaignOptionsSelected, setCampaignOptionsSelected] = useState([]);

  const tarifications = [
    { value: 'fix', label: 'Tarification fixe' },
    {
      value: 'percentTotal',
      label: 'Pourcentage du budget total'
    },
    {
      value: 'percentMedia',
      label: 'Pourcentage du budget media'
    }
  ];

  const closeModal = () => {
    setModal(null);
  };

  const handleCreateAndUpdate = async ({
    id,
    tarification,
    tarif,
    ...data
  }) => {
    if (id) {
      await ACTIVATION.updateCampaignOptions(id, {
        ...data,
        price: {
          type: tarification,
          amount: tarif
        }
      });
    } else {
      await ACTIVATION.createCampaignOptions({
        ...data,
        price: {
          type: tarification,
          amount: tarif
        }
      });
    }

    setModal('');
    setLoaded(false);
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatData = (data, name, keyToShow) => {
    const nbData = data.length;
    if (nbData === 0) {
      return (
        <Chip
          label="Tous"
          style={{
            fontWeight: 600,
            fontSizes: '12px'
          }}
        />
      );
    }
    if (nbData === 1) {
      return (
        <Chip
          label={data[0] && data[0][keyToShow]}
          style={{
            backgroundColor:
              name === 'domaine'
                ? themeColors.badgeDomains
                : themeColors.badgeFunnel,
            fontWeight: 600,
            fontSizes: '12px'
          }}
        />
      );
    }
    const others = nbData - 1;
    const str = ` ${others} ${pluralize('autre', others)}`;

    const tooltipContent = key => {
      return <Container>{data.map(el => getToolTipText(el[key]))}</Container>;
    };
    return (
      <Container style={{ height: '70px', paddingTop: '10px' }}>
        <Row spacing={2} style={{ alignItems: 'center' }}>
          <Item flex>
            <Chip
              label={data[0][keyToShow]}
              style={{
                backgroundColor:
                  name === 'domaine'
                    ? themeColors.badgeDomains
                    : themeColors.badgeFunnel,
                fontWeight: 400,
                fontSizes: '12px'
              }}
            />
          </Item>
          <Item flex>
            <Text>&nbsp;et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent(keyToShow)}>
              <Text>{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const domains = await DOMAINCONFIG.getAll();
    const measures = await ACTIVATION.getMeasures();
    const campaignOptions = await ACTIVATION.getCampaignOptions();

    const campaignOptionsFormatted = campaignOptions.map(
      ({ price, ...campaignOption }) => {
        return {
          ...campaignOption,
          id: campaignOption._id,
          tarif: price.amount,
          tarification: price.type,
          domains: campaignOption.domains.map(domain => ({
            key: domain,
            label: domains.find(el => el.id === domain)?.name || domain
          })),
          measureTypes: campaignOption.measureTypes.map(measureType => ({
            key: measureType,
            label: measures.find(el => el.key === measureType).target
          }))
        };
      }
    );
    setAllCampaignOptions(campaignOptionsFormatted);
    setRows(campaignOptionsFormatted);
    setDataConfig({ domains, measures });
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4} justify="flex-start" alignItems="flex-end">
        <Item xs={4}>
          <SearchField
            onChange={filterRows}
            datas={allCampaignOptions}
            titleHead="Rechercher"
            placeholder="Kairos Fire..."
            disabled={!loaded}
          />
        </Item>
        <Item xs={4} />
        <Item xs={4}>
          <ButtonCreate
            onClick={() => {
              setModal('modalCreate');
            }}
            variant="contained"
            text="Ajouter une option"
          />
        </Item>
      </Row>
      <Row justify="flex-start">
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Nom',
                  id: 'name'
                },
                {
                  label: 'Tarification',
                  id: 'tarification',
                  format: data => {
                    if (data === 'fix') {
                      return (
                        <Chip
                          label="Montant fixe"
                          style={{
                            fontWeight: 400,
                            fontSizes: '12px'
                          }}
                        />
                      );
                    }
                    if (data === 'percentTotal') {
                      return (
                        <Chip
                          label="% budget total"
                          style={{
                            fontWeight: 400,
                            fontSizes: '12px'
                          }}
                        />
                      );
                    }
                    return (
                      <Chip
                        label="% media"
                        style={{
                          fontWeight: 400,
                          fontSizes: '12px'
                        }}
                      />
                    );
                  }
                },
                {
                  label: 'Tarif',
                  id: 'tarif',
                  format: (data, row) => {
                    if (row.tarification === 'fix') {
                      return <Text>{numeral(data).format('0,0')} €</Text>;
                    }
                    return <Text>{numeral(data).format('0,0')} %</Text>;
                  }
                },
                {
                  label: 'Types de funnels',
                  id: 'measureTypes',
                  format: e => formatData(e, 'type', 'label')
                },
                {
                  label: 'Domaines',
                  id: 'domains',
                  format: e => formatData(e, 'domaine', 'label')
                },
                {
                  label: 'Statut',
                  id: 'active',
                  format: value => {
                    if (value) {
                      return (
                        <Chip
                          label="Actif"
                          style={{
                            backgroundColor: themeColors.badgeActif,
                            borderRadius: '3px',
                            fontWeight: 600,
                            fontSizes: '12px'
                          }}
                        />
                      );
                    }
                    return (
                      <Chip
                        label="Inactif"
                        style={{
                          borderRadius: '3px',
                          fontWeight: 600,
                          fontSizes: '12px'
                        }}
                      />
                    );
                  }
                }
              ]}
              hidenFields={['_id']}
              rowCustomStyle={() => {
                return { height: '70px' };
              }}
              onUpdate={e => {
                setCampaignOptionsSelected(
                  allCampaignOptions.find(({ _id }) => _id === e)
                );
                setModal('modalUpdate');
              }}
            />
          )}

          {openModal === 'unauthorized' && (
            <UnauthorizedModal open onClose={closeModal} />
          )}
        </Item>
      </Row>
      {openModal === 'modalCreate' && (
        <ModalSetupOption
          open
          onClose={() => setModal('')}
          domainConfigs={dataConfig.domains}
          measureConfigs={dataConfig.measures}
          onValidate={handleCreateAndUpdate}
          actionName="Sauvegarder"
          tarifications={tarifications}
        />
      )}
      {openModal === 'modalUpdate' && (
        <ModalSetupOption
          open
          onClose={() => setModal('')}
          onValidate={handleCreateAndUpdate}
          actionName="Sauvegarder"
          campaignOption={campaignOptionsSelected}
          campaignOptions={campaignOptionsSelected}
          domainConfigs={dataConfig.domains}
          measureConfigs={dataConfig.measures}
          tarifications={tarifications}
        />
      )}
    </Container>
  );
};

export default CampaignOptionsContainer;
