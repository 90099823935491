import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTheme } from 'contexts/ThemeContext';

import { FUNNEL } from 'utils/rest';

import { DatePicker } from 'components/DatePicker';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, MultipleSelect } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalDeleteData = props => {
  const { funnel, onValidate, onClose } = props;
  const { themeColors } = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [awaitCall, setAwaitCall] = useState(false);
  const [kpisOptions, setKpisOptions] = useState([]);
  const [mediasOptions, setMediasOptions] = useState([]);
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [period, setPeriod] = useState({
    start: null,
    end: null
  });

  const { start, end } = period;

  const getKpisFriendlyName = kpi => {
    switch (kpi) {
      case 'kpi1':
        return 'impressions';
      case 'kpi2':
        return 'visites';
      case 'kpi3':
        return 'engagements';
      case 'kpi4':
        return 'acquisitions';
      case 'volumeVisites':
        return 'visites par médias';
      case 'volumeAcquisitions':
        return 'acquisitions par médias';
      case 'volumeClics':
        return 'clics par médias';
      case 'volumeConversions':
        return 'conversions par médias';
      case 'volumeEngagements':
        return 'engagements par médias';
      case 'p25VideoCompletionVolume':
        return 'Completion vidéo 25%';
      case 'p50VideoCompletionVolume':
        return 'Completion vidéo 50%';
      case 'p75VideoCompletionVolume':
        return 'Completion vidéo 75%';
      case 'p100VideoCompletionVolume':
        return 'Completion vidéo 100%';
      case 'audienceSize':
        return 'Audience';
      case 'contribution':
        return 'Contribution';
      default:
        return kpi;
    }
  };

  const formatSelectedKpisAndMedias = () => {
    return {
      kpis: selectedKpis.map(el => el.key),
      medias: selectedMedias.map(el => el.key)
    };
  };

  const getKpisAndMedias = useCallback(async () => {
    const res = await FUNNEL.getMediasAndKpisList(funnel.id);
    const { kpis, medias } = res;

    const temporaryKpisOptions = [...kpis, 'contribution'].map(el => {
      return { key: el, id: el, label: getKpisFriendlyName(el) };
    });

    const temporaryMediasOptions = medias.map(el => {
      return { key: el, id: el, label: el };
    });

    setKpisOptions(temporaryKpisOptions);
    setSelectedKpis(temporaryKpisOptions);

    setMediasOptions(temporaryMediasOptions);
    setSelectedMedias(temporaryMediasOptions);

    setLoaded(true);
  }, [funnel]);

  useEffect(() => {
    if (!loaded) {
      getKpisAndMedias();
    }
  }, [getKpisAndMedias, loaded]);

  const handleChangeDate = inputKey => date => {
    const value = moment(date)
      .utc()
      .startOf('day')
      .toISOString();

    setPeriod(previousState => ({ ...previousState, [inputKey]: value }));
  };

  const isDateError = moment(start).isAfter(end);

  const getAction = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="space-between">
            <Button
              variant="contained"
              color={themeColors.btnBack}
              fontColor={themeColors.btnBackLabel}
              size="medium"
              widthSize="medium"
              onClick={() => onClose()}
            >
              Retour
            </Button>
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              loading={awaitCall}
              widthSize="medium"
              disabled={isDateError}
              onClick={() => {
                setAwaitCall(true);
                onValidate(funnel.id, {
                  ...formatSelectedKpisAndMedias(),
                  period
                });
              }}
            >
              Supprimer
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getAction()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Supprimer les données">
              <Container>
                <Row spacing={0}>
                  <Item>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={6} justify="flex-start">
                        <MultipleSelect
                          title="Médias"
                          required
                          selectAll
                          onChange={options => {
                            setSelectedMedias(options);
                          }}
                          options={mediasOptions}
                          selected={selectedMedias.map(el => el.key)}
                          small
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={6} justify="flex-start">
                        <MultipleSelect
                          title="KPIs"
                          required
                          selectAll
                          onChange={options => {
                            setSelectedKpis(options);
                          }}
                          options={kpisOptions}
                          selected={selectedKpis.map(el => el.key)}
                          small
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Row spacing={0} justify="flex-start">
                    <Item
                      xs={6}
                      justify="flex-start"
                      style={{ marginTop: '0.5rem' }}
                    >
                      <Row spacing={0} justify="flex-start">
                        <Item xs={6} justify="flex-start">
                          <DatePicker
                            title="Date de début"
                            onClose={({ date }) => {
                              handleChangeDate('start')(date);
                            }}
                            initialDate={
                              start ? moment(start).utc() : undefined
                            }
                          />
                        </Item>
                      </Row>
                    </Item>
                    <Item
                      xs={6}
                      justify="flex-start"
                      style={{ marginTop: '0.5rem' }}
                    >
                      <Row spacing={0} justify="flex-start">
                        <Item xs={6} justify="flex-start">
                          <DatePicker
                            title="Date de fin"
                            error={isDateError}
                            onClose={({ date }) => {
                              handleChangeDate('end')(date);
                            }}
                            initialDate={end ? moment(end).utc() : undefined}
                            isOutSideRange={date => {
                              if (
                                start &&
                                moment(date).isBefore(
                                  moment(start).add(1, 'days'),
                                  'days'
                                )
                              ) {
                                return true;
                              }
                              if (moment(date).isAfter()) {
                                return true;
                              }
                              return false;
                            }}
                            style={{ boxSizing: 'border-box' }}
                          />
                        </Item>
                      </Row>
                    </Item>
                  </Row>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalDeleteData.defaultProps = {
  funnel: {}
};
ModalDeleteData.propTypes = {
  onValidate: PropTypes.func.isRequired,
  funnel: PropTypes.objectOf(PropTypes.any)
};

export default ModalDeleteData;
