import React, { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListIcon from '@mui/icons-material/PlaylistPlay';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SearchIcon from '@mui/icons-material/Search';
import { Chip, Popper } from '@mui/material';
import IntervalTimer from 'react-interval-timer';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import Icon from 'utils/icons';
import moment from 'utils/moment';
import {
  ACTIVATION,
  CONFIGCONNECTOR,
  CONNEXION,
  FUNNEL,
  GROUP,
  IMPORTMODEL,
  MEDIA,
  PROPERTIES,
  USERS
} from 'utils/rest';

import { Text, Tooltip } from 'components/DataDisplay';
import {
  Button,
  Calendar as CampaignCalendar,
  Checkbox,
  IconButton,
  Select,
  TextField
} from 'components/Inputs';
import { CustomSelect } from 'components/Inputs/CustomSelectSearch';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import { ModalLoading } from 'components/ModalLoading';
import { Menu } from 'components/Navigation';
import { Paper } from 'components/Surfaces';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import ModalCampaign from './components/ModalCampaign';
import ModalLogs from './components/ModalLogs';
import ModalUpdate from './components/ModalUpdate';
import { getDataForCampaignUpdate, getSetupCampaignsStatus } from './utils';

let timer;

const ActionMenu = props => {
  const { themeColors } = useTheme();
  const { actions } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const close = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleReshreshClick = event => {
    if (open) {
      close();
      return;
    }
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton onClick={event => handleReshreshClick(event)}>
        <MoreVertIcon style={{ color: themeColors.light }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        style={{ maxWidth: '320px' }}
      >
        <Paper elevation={1}>
          <Row spacing={0}>
            {actions.map(el => (
              <Item>
                <Button
                  widthSize="100%"
                  color={themeColors.keep_dark}
                  onClick={() => {
                    close();
                    el.onClick();
                  }}
                  upperCase={false}
                >
                  {el.label}
                </Button>
              </Item>
            ))}
          </Row>
        </Paper>
      </Popper>
    </>
  );
};

const getCampaignGroupToDelete = ({
  selectedIds,
  allCampaignConfigs,
  allRows
}) => {
  return selectedIds.reduce((acc, currentSelectId) => {
    const currentCampaignConfig = allCampaignConfigs.find(
      el => el._id === currentSelectId
    );
    if (currentCampaignConfig.campaignGroupId) {
      const currentCampaignGroup = allRows.find(
        el => el._id === currentCampaignConfig.campaignGroupId
      );
      const allICampaignConfigIdOfCampaignGroup = currentCampaignGroup.campaignConfigs.map(
        el => el._id
      );
      const toDelete = allICampaignConfigIdOfCampaignGroup.every(el =>
        selectedIds.includes(el)
      );
      if (toDelete) {
        if (!acc.includes(currentCampaignConfig.campaignGroupId)) {
          acc.push(currentCampaignConfig.campaignGroupId);
        }
      }
    }
    return acc;
  }, []);
};

const getCampaignConfigToDelete = ({
  campaignGroupToDelete = [],
  selectedIds,
  allCampaignConfigs
}) => {
  return selectedIds.reduce((acc, currentSelectId) => {
    const currentCampaignConfig = allCampaignConfigs.find(
      el => el._id === currentSelectId
    );
    if (
      !campaignGroupToDelete.includes(currentCampaignConfig.campaignGroupId)
    ) {
      acc.push(currentCampaignConfig._id);
    }
    return acc;
  }, []);
};

const CampaignContainer = () => {
  const { adminRights } = useUser();
  const { themeColors } = useTheme();

  const [users, setUsers] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [page, setPage] = useState(0);
  const [groups, setGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState('');
  const [openModalLoading, setOpenModalLoading] = useState(false);
  const [searchStatus, setSearchStatus] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const [searchGroup, setSearchGroup] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [searchPaid, setSearchPaid] = useState('all');
  const [selected, setSelected] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [displayDate, setDisplayDate] = useState(false);
  const [allCampaignSetupTasks, setAllCampaignSetupTasks] = useState([]);
  const [allCampaignConfigs, setAllCampaignConfigs] = useState([]);
  const [setups, setSetups] = useState([]);
  const [start, setStart] = useState(
    moment('2020-01-01')
      .utc()
      .startOf('years')
  );
  const [end, setEnd] = useState(
    moment('2025-01-01')
      .utc()
      .endOf('years')
  );

  const [limit, setLimit] = useState(10);
  const [totalDoc, setTotalDoc] = useState(null);
  const [mediaLogos, setMediaLogos] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  // disabledSetup permet de savoir si une campaign selectionné à un status draft
  const [disabledSetup, setDisabledSetup] = useState(true);
  const [disabledBtnSetupCampaign, setDisabledBtnSetupCampaign] = useState(
    false
  );
  const [disabledBtnSetupCconnector, setDisabledBtnSetupCconnector] = useState(
    false
  );
  const [disabledBtnSetupBRD, setDisabledBtnSetupBRD] = useState(false);
  const [dataForModalUpdate, setDataForModalUpdate] = useState(null);
  const [selectGroupOptions, setSelectGroupOptions] = useState([]);
  const [selectTagOptions, setSelectTagOptions] = useState([]);
  const [importModel, setImportModel] = useState([]);
  const [configGenerators, setConfigGenerators] = useState([]);
  const [configConnexion, setConfigConnexion] = useState([]);

  useEffect(() => {
    const getDatas = async () => {
      const [
        importModelData,
        configGeneratorsList,
        configConnection
      ] = await Promise.all([
        IMPORTMODEL.get(),
        CONFIGCONNECTOR.getConfigGenerators(),
        CONNEXION.getConnexions()
      ]);

      const formatConnection = listConnexion => {
        const tabConnexion = [];
        for (const item of listConnexion) {
          if (item.model === 'googleAnalytics') {
            tabConnexion.push(
              {
                value: item.value,
                model: 'googleAnalytics',
                label: item.label
              },
              {
                value: item.value,
                model: 'googleAnalyticsKPI2',
                label: item.label
              },
              {
                value: item.value,
                model: 'googleAnalyticsKPI3',
                label: item.label
              },
              {
                value: item.value,
                model: 'googleAnalyticsKPI4',
                label: item.label
              }
            );
          } else {
            tabConnexion.push(item);
          }
        }
        return tabConnexion;
      };

      setImportModel(
        importModelData.map(el => ({
          label: `${el.label} (${el.model})`,
          value: el.model,
          connectorName: el.connectorName,
          json: !!el.connectorName,
          csv: true,
          campaignManagement: el.campaignManagement
        }))
      );

      let formatConnexion = configConnection.map(el => ({
        label: el.name,
        model: el.model,
        value: el._id
      }));
      formatConnexion = formatConnection(formatConnexion);

      setConfigGenerators(configGeneratorsList);
      setConfigConnexion(formatConnexion);
    };

    getDatas();
  }, []);

  const closeModal = () => {
    setModal(null);
    setSelected(null);
  };

  const launchAutoConfig = async type => {
    setDisabledBtnSetupCconnector(type === 'Connectors');
    setDisabledBtnSetupCampaign(type === 'Campaign');
    setDisabledBtnSetupBRD(type === 'BRD');

    switch (type) {
      case 'Campaign':
        await Promise.all(
          selectedIds.map(id =>
            ACTIVATION.postCampaignSetup({
              campaignConfigsId: id
            })
          )
        );
        break;

      case 'Connectors':
        await ACTIVATION.setupConnectors(selectedIds);
        break;

      case 'BRD':
        await Promise.all(selectedIds.map(id => FUNNEL.setupDataFeedback(id)));
        break;

      default:
        break;
    }

    if (type === 'Connectors') {
      setDisabledBtnSetupCconnector(false);
    } else if (type === 'Campaign') {
      setDisabledBtnSetupCampaign(false);
    } else if (type === 'BRD') {
      setDisabledBtnSetupBRD(false);
    }
  };

  const openCampaign = async row => {
    if (adminRights?.campaign?.campaign?.update) {
      const isCampaignGroup = row.isCampaignGroup;
      const method = !isCampaignGroup
        ? ACTIVATION.getCampaignConfigById
        : ACTIVATION.getCampaignGroupRich;
      const campaign = await method(row._id);
      campaign.isCampaignGroup = isCampaignGroup;
      campaign.orderStatus = row.orderStatus;
      setSelected(formatCampaignRow({ campaign }));
      setModal('modalCampaign');
    } else {
      setModal('unauthorized');
    }
  };

  const openDelete = campaign => {
    if (adminRights?.campaign?.campaign?.delete) {
      setSelected(campaign);

      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const getBuildIcon = data => {
    let icon;
    const getToolTipText = obj => {
      return (
        <Text bold color={themeColors.dark}>
          {`Setup ${moment(obj.updatedAt).fromNow()}`}
          {(obj.tracebackMsg !== '' || obj.message !== '') && (
            <Text color={themeColors.dark}>
              {obj.tracebackMsg || obj.message}
            </Text>
          )}
        </Text>
      );
    };

    switch (data.status) {
      case 'error':
        icon = <ErrorOutlineIcon style={{ color: themeColors.error }} />;
        break;
      case 'done':
        icon = (
          <CheckCircleOutlineIcon
            style={{
              color: themeColors.success
            }}
          />
        );
        break;

      case 'success':
        if (!data.tracebackMsg) {
          icon = (
            <CheckCircleOutlineIcon
              style={{
                color: themeColors.success
              }}
            />
          );
          break;
        }
      // falls through
      case 'partial':
        icon = <Icon icon="computePartial" />;
        break;
      case 'todo':
      case 'toDo':
      case 'uploadingSegments':
      case 'segmentsExported':
      case 'uploadingCreatives':
      case 'creativesUploaded':
      case 'SettingUpCampaign':
      default:
        icon = (
          <QueryBuilderIcon
            style={{
              color: themeColors.funnel
            }}
          />
        );
        break;
    }

    return <Tooltip title={getToolTipText(data)}>{icon}</Tooltip>;
  };

  const openLogsModal = async item => {
    setSelectedCampaign(item);
    setModal('logsModal');
  };

  const getDatas = async () => {
    const [
      organisations,
      userslist,
      properties,
      { funnels: funnelsFinded },
      activableMedias,
      tagsList
    ] = await Promise.all([
      GROUP.getGroups(),
      USERS.getAll(),
      PROPERTIES.get(),
      FUNNEL.getFunnels(['name']),
      ACTIVATION.getActivableMedias(false),
      ACTIVATION.getAllTags()
    ]);

    const logos = await MEDIA.getMediaLogosbyKeys(
      activableMedias.map(el => el.key.toLowerCase())
    );

    const mappedFunnels = funnelsFinded.map(funnel => {
      const propertie =
        properties.find(el => el.funnelIds.includes(funnel.id))?.name ||
        'Autres';
      return {
        ...funnel,
        name: `${propertie} - ${funnel.name}`
      };
    });

    setMediaLogos(logos);
    setUsers(userslist);
    setFunnels(mappedFunnels);

    const sortCallBack = (a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
    };
    const sortedTagList = tagsList.reduce((acc, tag) => {
      const tagLabel = tag.trim();
      if (!tagLabel.length) {
        return acc;
      }
      acc.push({ label: tag, key: tagLabel });
      return acc.sort(sortCallBack);
    }, []);
    setSelectTagOptions(sortedTagList);
    setTags(tagsList);

    const sortedOrganisations = organisations.reduce((acc, group) => {
      const label = group?.name;
      const key = group?._id;
      if (!label || !key) {
        return acc;
      }
      acc.push({ label, key });
      return acc.sort(sortCallBack);
    }, []);
    setSelectGroupOptions(sortedOrganisations);
    setGroups(organisations);
    setLoaded(true);
  };

  const formatStartDate = date => {
    return date
      ? moment(date)
          .utc()
          .startOf('day')
          .format()
      : null;
  };

  const formatEndDate = date => {
    return date
      ? moment(date)
          .utc()
          .endOf('day')
          .format()
      : null;
  };

  const setPeriod = async ({ startDate, endDate, reset = false }) => {
    if (reset) {
      setStart(
        moment('2020-01-01')
          .utc()
          .startOf('years')
      );
      setEnd(
        moment('2025-01-01')
          .utc()
          .endOf('years')
      );
    } else {
      setDisplayDate(true);
      setStart(formatStartDate(startDate));
      setEnd(formatEndDate(endDate));
    }
  };

  const onDelete = async () => {
    if (selected.isCampaignGroup) {
      await ACTIVATION.deleteCampaignGroup(selected._id);
    } else {
      await ACTIVATION.deleteCampaignConfig(selected._id);
    }
    setSelected(null);
    closeModal();
    setLoaded(false);
  };

  const onDeleteMulti = async () => {
    // Cette fonction permet de savoir si une campaign group doit etre supprimer (elle doit etre supprimer si tout ces campaigns enfant sont supprimés)
    const campaignGroupToDelete = getCampaignGroupToDelete({
      selectedIds,
      allCampaignConfigs,
      allRows
    });

    const campaignConfigToDelete = getCampaignConfigToDelete({
      campaignGroupToDelete,
      selectedIds,
      allCampaignConfigs
    });

    await Promise.all(
      campaignConfigToDelete.map(async selectedId => {
        // delete des creatives,
        await ACTIVATION.deleteCreativesByCampaignId(selectedId);
        //  delete des funnelLineItems
        await ACTIVATION.deleteLineItemsByCampaignId(selectedId);
        // delete de media Orders
        await ACTIVATION.deleteMediaOrdersByCampaignId(selectedId);
        // delete de la campagn
        await ACTIVATION.deleteCampaignConfig(selectedId);
      })
    );

    await Promise.all(
      campaignGroupToDelete.map(async selectedId => {
        await ACTIVATION.deleteCampaignGroup(selectedId);
      })
    );

    setSelectedIds([]);
    setLoaded(false); // TODO: a tester
  };

  const onUpdate = async data => {
    setModal(null);
    setOpenModalLoading(true);
    if (selected.isCampaignGroup) {
      await ACTIVATION.updateCampaignGroup(selected._id, {
        ...data,
        waitTillEnd: true
      });
      await ACTIVATION.updateManyCampaignConfigs(
        selected.campaignConfigs.map(el => ({
          _id: el._id,
          tags: data?.tags || [],
          objective: data?.objective
        }))
      );
    } else {
      await ACTIVATION.updateCampaignConfig(selected._id, data);
    }
    setOpenModalLoading(false);
    closeModal();
    setLoaded(false);
  };

  const getFunnel = id => {
    if (!id) {
      return 'aucun';
    }

    const funnel = funnels.find(el => el._id === id);

    return (
      <Row spacing={0}>
        <Item>
          <Text fontWeight={funnel?.name ? 700 : 500} fontSize="12px">
            {funnel?.name || 'N/A'}
          </Text>
        </Item>
        {!!funnel && (
          <Item>
            <Text fontSize="10px">{funnel?.id}</Text>
          </Item>
        )}
      </Row>
    );
  };

  const formatCampaignRow = ({
    campaign,
    campaignSetupTasks = [],
    rowColor
  }) => {
    const { status: campaignStatus, ...newItem } = {
      ...campaign
    };
    const owner = users.find(el => el._id === campaign.owner);

    const group = groups.find(el => el._id === newItem.groupId);
    newItem.groupName = group?.name || 'Aucune';
    newItem.owner = owner
      ? `${owner.given_name} ${owner.family_name}`
      : 'Inconnu';
    newItem.ownerStatus = owner?.roles.map(el => el.name).includes('ADMIN')
      ? 'admin'
      : 'standard';

    if (group?.accountManagers.includes(owner?._id)) {
      newItem.ownerStatus = 'accountManager';
    }
    newItem.id = campaign._id;

    newItem.state = campaignStatus;
    newItem.status = campaignStatus;

    const setup = campaignSetupTasks
      .filter(
        campaignSetupTask =>
          campaignSetupTask.campaignConfigsId === campaign._id
      )
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    newItem.setup = setup;

    newItem.funnel = getFunnel(newItem.funnelId);
    const customActionsList = [];

    customActionsList.push({
      label: 'Modifier',
      onClick: () => openCampaign(campaign)
    });

    if (['draft', 'groupDraft'].includes(campaignStatus)) {
      customActionsList.push({
        label: 'Supprimer',
        onClick: () => openDelete(campaign)
      });
    }

    newItem.rowColor = rowColor;
    newItem.customAction = <ActionMenu actions={customActionsList} />;
    if (campaign.campaignConfigs) {
      newItem.subRows = campaign.campaignConfigs.map(el =>
        formatCampaignRow({
          campaign: el,
          campaignSetupTasks,
          rowColor: themeColors.themeV2.detail
        })
      );
    }
    newItem.customAction = <ActionMenu actions={customActionsList} />;

    return newItem;
  };

  const fetchCampaigns = async () => {
    const all = await ACTIVATION.getCampaignBySearch({
      page: page + 1,
      limit,
      ...(searchValue !== '' ? { keywords: searchValue } : {}),
      ...(start ? { start } : {}),
      ...(end ? { end } : {}),
      ...(searchStatus !== 'all' ? { status: searchStatus } : {}),
      ...(searchPaid !== 'all' ? { payment: searchPaid } : {}),
      ...(searchGroup.length > 0
        ? {
            groups: searchGroup.map(el => el.key)
          }
        : {}),
      ...(searchTags.length > 0
        ? {
            tags: searchTags.map(el => el.key)
          }
        : {})
    });

    const campaigns = all.result;
    const campaignSetupTasks = await ACTIVATION.getCampaignLastSetupTasks(
      campaigns.reduce((acc, row) => {
        if (!['draft', 'groupDraft'].includes(row.status)) {
          if (row.campaignConfigIds && row.campaignConfigIds.length !== 0) {
            acc.push(...row.campaignConfigIds);
          } else {
            acc.push(row._id);
          }
        }
        return acc;
      }, [])
    );

    const mapped = campaigns.map(campaign =>
      formatCampaignRow({
        campaign,
        campaignSetupTasks
      })
    );

    setTotalDoc(all.totalDocument);
    setRows(mapped);
    setAllRows(mapped);
  };

  const getCampaignSetupTasks = async () => {
    const data = await ACTIVATION.getCampaignLastSetupTasks(
      allRows.reduce((acc, row) => {
        if (row.status !== 'draft') {
          if (row.campaignConfigIds) {
            acc.push(...row.campaignConfigIds);
          } else {
            acc.push(row.id);
          }
        }
        return acc;
      }, []),
      'campaignMediaSetupTask'
    );
    setAllCampaignSetupTasks(data);
  };

  const seachKey = (item, toTest) => {
    const val = toTest || searchValue.toLowerCase();
    for (const key in item) {
      if (
        typeof item[key] === 'string' &&
        item[key].toLowerCase().indexOf(val) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  const handleCheck = id => {
    let list = [...selectedIds];
    if (!selectedIds.includes(id)) {
      list.push(id);
    } else {
      list = selectedIds.filter(el => el !== id);
    }
    setSelectedIds(list);
  };

  const handleCheckGroup = ids => {
    let list = [...selectedIds];
    const isSelected =
      selectedIds.filter(el => ids.some(id => id === el)).length === ids.length;
    if (!isSelected) {
      ids.forEach(id => {
        if (!list.includes(id)) {
          list.push(id);
        }
      });
    } else {
      list = selectedIds.filter(el => !ids.includes(el));
    }
    setSelectedIds(list);
  };

  const handleCheckAll = () => {
    if (selectedIds.length === allCampaignConfigs.length) {
      setSelectedIds([]);
    } else {
      const list = allCampaignConfigs.map(el => el._id);
      setSelectedIds(list);
    }
  };

  const getColor = status => {
    switch (status) {
      case 'accountManager':
      case 'Partiel':
        return themeColors.badgeDomains;
      case 'admin':
        return themeColors.badgeFunnel;
      case 'Impayée':
        return themeColors.error_gestion_campagne;
      case 'En attente':
        return themeColors.themeV2.yellow;
      case 'Refusé':
        return themeColors.error_gestion_campagne;
      case 'Payée':
      case 'standard':
      default:
        return themeColors.badgeActif;
    }
  };

  const initializeOpenModalUpdate = () => {
    // TODO: A faire correctement
    const dataForCampaignUpdate = getDataForCampaignUpdate({
      selectedIds,
      allCampaignConfigs
    });
    setDataForModalUpdate(dataForCampaignUpdate);
    setModal('modalUpdate');
  };

  const updateCampaignConfigs = async data => {
    setOpenModalLoading(true);
    const formatUpdateData = selectedIds.map(selectId => {
      const campaignConfigSelect = allCampaignConfigs.find(
        campaignConfig => campaignConfig._id === selectId
      );
      return {
        _id: campaignConfigSelect._id,
        start: data.startDate ? data.startDate : undefined,
        end: data.endDate ? data.endDate : undefined,
        objective: data.objective ? data.objective : undefined,
        status: data.status || undefined,
        paid: data.paid === 'true',
        userIds: [
          ...campaignConfigSelect.userIds,
          ...data.userIds.map(el => el._id)
        ],
        ...(data.tags ? { tags: data.tags } : {})
      };
    });
    const formatUpdateGroupData = allRows.reduce((acc, curr) => {
      if (!curr.campaignConfigIds) {
        return acc;
      }
      let allCampaigns = true;
      for (const id of curr.campaignConfigIds) {
        if (!selectedIds.find(selectedId => selectedId === id)) {
          allCampaigns = false;
          break;
        }
      }
      if (allCampaigns) {
        acc.push({
          _id: curr._id,
          start: data.startDate ? data.startDate : undefined,
          end: data.endDate ? data.endDate : undefined,
          status:
            data.status === 'draft' ? 'groupDraft' : data.status || undefined,
          objective: data.objective ? data.objective : undefined,
          users: [
            ...(searchGroup?.userIds || []),
            ...data.userIds.map(el => el._id)
          ]
        });
      }
      return acc;
    }, []);
    await Promise.all([
      ...formatUpdateData.map(async ({ _id, ...campaign }) => {
        await ACTIVATION.updateCampaignConfig(_id, campaign);
      }),
      ...formatUpdateGroupData.map(async ({ _id, ...group }) => {
        await ACTIVATION.updateCampaignGroup(_id, group);
      })
    ]);
    setOpenModalLoading(false);
    setLoaded(false);
  };

  useEffect(() => {
    if (loaded) {
      if (selectedIds.length === 0) {
        setDisabledSetup(true);
        setDisabledBtnSetupCampaign(false);
        setDisabledBtnSetupCconnector(false);
        setDisabledBtnSetupBRD(false);
      } else {
        const setupCampaignsStatus = getSetupCampaignsStatus({
          selectedIds,
          allCampaignConfigs
        });
        setDisabledBtnSetupCampaign(setupCampaignsStatus);
        setDisabledBtnSetupCconnector(setupCampaignsStatus);
        setDisabledBtnSetupBRD(setupCampaignsStatus);
      }
      const disabled = selectedIds.reduce((acc, curr) => {
        const found = allCampaignConfigs.find(el => el._id === curr);
        if (found && found.state === 'draft') {
          return true;
        }
        return acc;
      }, false);
      setDisabledSetup(selectedIds.length === 0 || disabled);
    }
    // eslint-disable-next-line
  }, [selectedIds, loaded, allCampaignConfigs]);

  useEffect(() => {
    if (loaded) {
      clearTimeout(timer);
      timer = setTimeout(fetchCampaigns, 500);
    }
    // eslint-disable-next-line
  }, [
    loaded,
    page,
    limit,
    searchPaid,
    searchStatus,
    searchValue,
    start,
    end,
    searchGroup,
    searchTags
  ]);

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      const newRows = allRows.map(el => ({
        ...el,
        setup: allCampaignSetupTasks.filter(
          campaignSetupTask => campaignSetupTask.campaignConfigsId === el.id
        ),
        subRows: el.subRows
          ? el.subRows.map(subRow => ({
              ...subRow,
              setup: allCampaignSetupTasks.filter(
                campaignSetupTask =>
                  campaignSetupTask.campaignConfigsId === subRow.id
              )
            }))
          : undefined
      }));
      setAllRows(newRows);
      setRows(
        newRows.filter(el => {
          const findRow = rows.find(row => row._id === el._id);
          return findRow && seachKey(el);
        })
      );
    }
    // eslint-disable-next-line
  }, [allCampaignSetupTasks]);

  useEffect(() => {
    if (loaded) {
      const newCampaignConfigs = rows.reduce((acc, row) => {
        if (!row.isCampaignGroup) {
          acc.push(row);
        } else if (row?.subRows) {
          acc.push(...row.subRows);
        }
        return acc;
      }, []);
      setAllCampaignConfigs(newCampaignConfigs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const getOrderTooltip = orderStatus => {
    if (orderStatus === 'pending') {
      return 'Paiement en attente';
    }

    if (orderStatus === 'rejected') {
      return 'Paiement refusé, relancez le client';
    }
  };

  return (
    <Container>
      <IntervalTimer
        timeout={10000}
        callback={getCampaignSetupTasks}
        enabled={!openModal}
        repeat
      />
      <Row spacing={3} alignItems="flex-start" justify="space-between">
        <Item xs={4} justify="flex-start">
          <Button
            variant="contained"
            style={{
              width: '104px',
              height: '34px'
            }}
            disabled={disabledSetup}
            disabledMessage="Seules les campagnes au statut différent de brouillon peuvent-être set-upées."
          >
            <Icon
              icon="PMLDot"
              attrs={{
                circleColor: disabledSetup
                  ? themeColors.themeV2.subtitle
                  : themeColors.themeV2.white,
                arrowColor: disabledSetup
                  ? themeColors.themeV2.white
                  : themeColors.activation
              }}
            />
            <Menu
              variant="contained"
              color="transparent"
              style={{
                width: '70px',
                height: '34px'
              }}
              custom
              options={[
                {
                  label: 'Setup de la campagne',
                  onClick: () => {
                    launchAutoConfig('Campaign');
                  },
                  disabled: disabledBtnSetupCampaign
                },
                {
                  label: 'Setup des connecteurs',
                  onClick: () => {
                    launchAutoConfig('Connectors');
                  },
                  disabled: disabledBtnSetupCconnector
                },
                {
                  label: 'Setup de la BRD',
                  onClick: () => {
                    launchAutoConfig('BRD');
                  },
                  disabled: disabledBtnSetupBRD
                }
              ]}
            >
              <Text
                fontWeight={600}
                fontSize="12px"
                color={
                  disabledSetup
                    ? themeColors.themeV2.subtitle
                    : themeColors.themeV2.white
                }
              >
                Setup
              </Text>
            </Menu>
          </Button>
        </Item>
        <Item xs={4} justify="flex-end">
          <Button
            disabled={selectedIds.length === 0}
            variant="contained"
            onClick={() => {
              initializeOpenModalUpdate();
            }}
            style={{
              height: '34px',
              width: '104px',
              backgroundColor:
                selectedIds.length === 0
                  ? themeColors.themeV2.detail
                  : themeColors.themeV2.black
            }}
          >
            <CreateIcon
              style={{
                color:
                  selectedIds.length === 0
                    ? themeColors.themeV2.subtitle
                    : themeColors.themeV2.white
              }}
            />
            <Text
              fontWeight={600}
              fontSize="12px"
              style={{ marginLeft: '5px' }}
              color={
                selectedIds.length === 0
                  ? themeColors.themeV2.subtitle
                  : themeColors.themeV2.white
              }
            >
              Modifier
            </Text>
          </Button>
        </Item>
      </Row>
      <Row spacing={3} justify="flex-start" alignItems="flex-end">
        <Item xs={2} style={{ marginBottom: '2px' }}>
          <TextField
            onChange={e => {
              setPage(0);
              setSearchValue(e);
            }}
            value={searchValue}
            title="Recherche"
            placeholder="Campagne X, user@email.fr..."
            type="search"
            unit={
              <SearchIcon
                style={{
                  color: themeColors.light
                }}
              />
            }
          />
        </Item>
        <Item xs={2}>
          <Container>
            <Row spacing={0}>
              <Item justify="flex-start">
                <CustomSelect
                  small
                  disabled={!loaded}
                  title="Filtrer par organisation"
                  selected={searchGroup}
                  onChange={ids => {
                    setSearchGroup(ids);
                  }}
                  options={selectGroupOptions}
                  limit={25}
                  selectAll
                  noneIsAll
                  searchBar
                  type="organisation"
                  textColor={themeColors.campaign}
                />
              </Item>
            </Row>
          </Container>
        </Item>
        <Item xs={2}>
          <Container>
            <Row spacing={0}>
              <Item justify="flex-start">
                <CustomSelect
                  small
                  disabled={!loaded}
                  title="Filtrer par tag"
                  selected={searchTags}
                  onChange={ids => {
                    setSearchTags(ids);
                  }}
                  options={selectTagOptions}
                  selectAll
                  searchBar
                  noneIsAll
                  filterOthers="Aucun"
                  dynamicList
                  type="tag"
                  textColor={themeColors.campaign}
                />
              </Item>
            </Row>
          </Container>
        </Item>
        <Item xs={2}>
          <Container>
            <Row spacing={0}>
              <Item justify="flex-start">
                <Text>Filtrer par dates</Text>
              </Item>
              <Item justify="flex-start">
                <CampaignCalendar
                  disableCampare
                  start={start}
                  end={end}
                  onChange={setPeriod}
                  displayDate={displayDate}
                  onClearDate={() => {
                    setPeriod({ reset: true });
                    setDisplayDate(false);
                  }}
                  inline
                />
              </Item>
            </Row>
          </Container>
        </Item>
        <Item xs={2}>
          <Select
            onChange={e => {
              setPage(0);
              setSearchStatus(e);
            }}
            value={searchStatus}
            disabled={!loaded}
            title="Filtrer par status"
            options={[
              {
                label: 'Tous les status',
                value: 'all'
              },
              {
                label: 'Brouillon',
                value: 'draft'
              },
              {
                label: 'Validée',
                value: 'validated'
              },
              {
                label: 'Activée',
                value: 'active'
              },
              {
                label: 'En pause',
                value: 'paused'
              },
              {
                label: 'Annulée',
                value: 'canceled'
              },
              {
                label: 'Terminée',
                value: 'completed'
              },
              {
                label: 'Proposée',
                value: 'proposed'
              }
            ]}
          />
        </Item>
        <Item xs={2}>
          <Select
            onChange={e => {
              setPage(0);
              setSearchPaid(e);
            }}
            value={searchPaid}
            disabled={!loaded}
            title="Filtrer par paiement"
            options={[
              {
                label: 'Tous les status de paiement',
                value: 'all'
              },
              { label: 'Payé', value: 'paid' },
              {
                label: 'Non payé',
                value: 'none'
              },
              {
                label: 'Partiel',
                value: 'partial'
              },
              {
                label: 'En attente',
                value: 'pending'
              },
              {
                label: 'Rejeté',
                value: 'rejected'
              }
            ]}
          />
        </Item>
      </Row>
      <Row justify="flex-start">
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              pageNumber={page}
              getPageNumber={pageNumber => {
                setPage(pageNumber);
              }}
              setNumberPerRow={newLimit => {
                setLimit(newLimit);
                setPage(0);
              }}
              overrideCount={totalDoc}
              skipSlice
              enableSubRows
              headers={[
                {
                  label: (
                    <Checkbox
                      checked={
                        selectedIds.length !== 0 &&
                        selectedIds.length === allCampaignConfigs.length
                      }
                      onChange={handleCheckAll}
                    />
                  ),
                  id: 'id',
                  format: (value, row) => {
                    let isSelected = selectedIds.includes(value);
                    if (row.campaignConfigIds?.length) {
                      isSelected =
                        selectedIds.filter(el =>
                          row.campaignConfigs.some(({ _id }) => _id === el)
                        ).length === row.campaignConfigs.length;
                    }
                    return (
                      <Checkbox
                        checked={isSelected}
                        onChange={() => {
                          if (row.campaignConfigIds?.length) {
                            handleCheckGroup(
                              row.campaignConfigs.map(el => el._id)
                            );
                          } else {
                            handleCheck(value);
                          }
                        }}
                        disabled={
                          row.campaignConfigs?.length === 0 &&
                          row.campaignConfigIds.length > 0
                        }
                      />
                    );
                  }
                },
                {
                  label: 'Campagne',
                  id: 'name',
                  sortKey: 'name',
                  format: (value, row) => (
                    <Row spacing={0}>
                      <Item>
                        <Text fontSize="12px" fontWeight={700}>
                          {value}
                        </Text>
                      </Item>
                      <Item>
                        <Text fontSize="12px">{row.id}</Text>
                      </Item>
                    </Row>
                  )
                },
                {
                  label: 'Organisation',
                  id: 'groupName',
                  sortKey: 'groupName'
                },
                {
                  label: 'Propriétaire',
                  id: 'owner',
                  sortKey: 'owner',
                  format: (value, row) => {
                    return (
                      <Chip
                        label={value}
                        style={{
                          backgroundColor: getColor(row.ownerStatus),
                          fontSizes: '12px',
                          borderRadius: '5px'
                        }}
                      />
                    );
                  }
                },
                {
                  label: 'Funnel',
                  id: 'funnel'
                },
                {
                  label: 'Dates',
                  id: '',
                  format: (_, row) => {
                    return (
                      <Text fontSize="12px" fontWeight={600}>
                        {moment(row.start)
                          .utc()
                          .format('DD/MM/YYYY')}
                        &nbsp;-&nbsp;
                        {moment(row.end)
                          .utc()
                          .format('DD/MM/YYYY')}
                      </Text>
                    );
                  }
                },

                {
                  label: 'Statut',
                  id: 'state',
                  format: value => {
                    let status = value;
                    let color = themeColors.themeV2.baseGrey;
                    switch (value) {
                      case 'groupDraft':
                      case 'draft':
                        status = 'Brouillon';
                        color = themeColors.themeV2.detail;
                        break;
                      case 'validated':
                        status = 'Validée';
                        color = themeColors.badgeDomains;
                        break;
                      case 'active':
                        status = 'Activée';
                        color = themeColors.badgeActif;
                        break;
                      case 'paused':
                        status = 'En pause';
                        color = themeColors.themeV2.detail;
                        break;
                      case 'canceled':
                        status = 'Annulée';
                        color = themeColors.error_gestion_campagne;
                        break;
                      case 'completed':
                        status = 'Terminée';
                        color = themeColors.badgeFunnel;
                        break;
                      case 'proposed':
                        status = 'Proposée';
                        color = themeColors.badgeFunnel;
                        break;
                      default:
                        break;
                    }

                    return (
                      <Chip
                        label={status}
                        style={{
                          backgroundColor: color,
                          fontSizes: '12px',
                          borderRadius: '5px'
                        }}
                      />
                    );
                  }
                },
                {
                  label: 'Payée',
                  id: 'paid',
                  format: (value, { state, orderStatus }) => {
                    let status = 'N/A';
                    if (
                      orderStatus &&
                      value !== 'paid' &&
                      value !== true &&
                      value !== 'partial'
                    ) {
                      switch (orderStatus) {
                        case 'paid':
                          status = 'Payée';
                          break;
                        case 'unpaid':
                          status = 'Impayée';
                          break;
                        case 'pending':
                          status = 'En attente';
                          break;
                        case 'rejected':
                          status = 'Refusé';
                          break;
                        default:
                          break;
                      }
                      return (
                        <Item
                          flex
                          style={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Chip
                            label={status}
                            style={{
                              backgroundColor: getColor(status),
                              fontSizes: '12px',
                              borderRadius: '5px'
                            }}
                          />
                          {['pending', 'rejected'].indexOf(orderStatus) !==
                            -1 && (
                            <Tooltip title={getOrderTooltip(orderStatus)}>
                              <IconButton>
                                <InfoIcon
                                  style={{
                                    color:
                                      orderStatus === 'pending'
                                        ? themeColors.themeV2.yellow
                                        : themeColors.themeV2.red
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Item>
                      );
                    }

                    if (['groupDraft', 'draft'].includes(state)) {
                      return 'N/A';
                    }
                    switch (value) {
                      case 'paid':
                      case true:
                        status = 'Payée';
                        break;
                      case false:
                      case 'none':
                        status = 'Impayée';
                        break;
                      case 'partial':
                        status = 'Partiel';
                        break;
                      default:
                        break;
                    }
                    return (
                      <Chip
                        label={status}
                        style={{
                          backgroundColor: getColor(status),
                          fontSizes: '12px',
                          borderRadius: '5px'
                        }}
                      />
                    );
                  }
                },
                {
                  label: 'Setup',
                  id: 'setup',
                  format: (value, row) => {
                    if (!value.length) {
                      return 'N/A';
                    }
                    return (
                      <Item>
                        {getBuildIcon(value[0])}
                        <IconButton
                          onClick={async () => {
                            const newList = await ACTIVATION.getCampaignSetupTasks(
                              [row._id]
                            );
                            setSetups(newList);
                            openLogsModal(row);
                          }}
                        >
                          <ListIcon
                            style={{
                              color: themeColors.light
                            }}
                          />
                        </IconButton>
                      </Item>
                    );
                  }
                }
              ]}
              hidenFields={['_id']}
            />
          )}

          {openModal === 'logsModal' && (
            <ModalLogs
              name={selectedCampaign.name}
              datas={[
                {
                  label: 'Setups',
                  values: setups
                }
              ]}
              onClose={() => {
                setSelectedCampaign({});
                setModal('');
              }}
            />
          )}

          {openModal === 'unauthorized' && (
            <UnauthorizedModal open onClose={closeModal} />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              open
              onClose={closeModal}
              type="cette campagne"
              name={selected?.name}
              onDelete={() => {
                onDelete();
              }}
            />
          )}
          {openModal === 'modalCampaign' && (
            <ModalCampaign
              open
              onClose={closeModal}
              type="cette campagne"
              name="test"
              campaign={selected}
              onDelete={() => {
                setModal(null);
                onDelete();
              }}
              onValidate={onUpdate}
              config={{
                funnels,
                users,
                groups,
                mediaLogos,
                tags
              }}
              datas={{
                importModel,
                configGenerators,
                configConnexion
              }}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalUpdate
              open
              onClose={closeModal}
              type="cette campagne"
              name="test"
              campaign={dataForModalUpdate}
              onDelete={() => {
                onDeleteMulti();
                setModal(null);
              }}
              onValidate={data => {
                updateCampaignConfigs(data);
                closeModal();
              }}
              config={{
                users,
                disabledDelete: dataForModalUpdate.status === 'draft',
                tags
              }}
            />
          )}
          {openModalLoading && <ModalLoading open />}
        </Item>
      </Row>
    </Container>
  );
};

export default CampaignContainer;
