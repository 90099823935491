import React from 'react';
import PropTypes from 'prop-types';
import { Container as ContainerMui } from '@mui/material';

import Fade from 'components/Fade';

import { Container } from './Container';

export const ContainerLayout = props => {
  const { flex, show } = props;
  return (
    <Fade show={show}>
      {flex ? (
        <Container
          flex
          style={{
            height: '100%',
            width: '100%',
            minWidth: '600px',
            display: 'flex',
            alignItems: 'stretch',
            justify: 'center'
            // alignItems: 'stretch',
            // backgroundColor: '#a1f1f1',
            // flexDirection: 'column'
          }}
          {...props}
        />
      ) : (
        <ContainerMui {...props} />
      )}
    </Fade>
  );
};

ContainerLayout.defaultProps = {
  flex: false,
  show: null
};
ContainerLayout.propTypes = {
  flex: PropTypes.bool,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export default ContainerLayout;
