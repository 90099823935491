import { api } from './api';

const getInsight = async () => {
  try {
    const { data } = await api.get(`/backoffice-insight/audience`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getInsightByUser = async email => {
  try {
    const { data } = await api.get(`/backoffice-insight/audience/${email}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getSegmentFilesName = async segmentUploadTaskId => {
  try {
    const { data } = await api.get(
      `/v2/insight/insight/listProfileFiles/${segmentUploadTaskId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getInsight,
  getInsightByUser,
  getSegmentFilesName
};
