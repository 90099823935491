import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  namefrError: false,
  nameenError: false,
  titlefrError: false,
  titleenError: false,
  keyError: false,
  kpiError: false
};
export const ModalForm = props => {
  const { onValidate, config, actionName } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config.name || {},
    key: config.key || '',
    title: config.title || {},
    shortName: config.shortName || {},
    explanation: config.explanation || {},
    kpi: config.kpi || ''
  };
  const [
    { name, title, key, shortName, explanation, kpi },
    setState
  ] = React.useState(initialState);
  const [
    {
      namefrError,
      titlefrError,
      nameenError,
      titleenError,
      keyError,
      kpiError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state, lang = '') => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}${lang}Error`] = state;
      return errors;
    });
  };

  const handleChange = (field, lang) => value => {
    const text = value.trim();
    toggleError(field, !text, lang);
    setState(prevState => {
      const newState = { ...prevState };
      if (lang) {
        newState[field][lang] = text;
      } else {
        newState[field] = text;
      }

      return newState;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    const errorList = [
      { value: name?.fr, itemKey: 'name', lang: 'fr' },
      { value: name?.fr, itemKey: 'name', lang: 'fr' },
      { value: name?.en, itemKey: 'name', lang: 'en' },
      { value: title?.fr, itemKey: 'title', lang: 'fr' },
      { value: title?.en, itemKey: 'title', lang: 'en' },
      { value: key, itemKey: 'key' },
      { value: kpi, itemKey: 'kpi' }
    ];
    errorList.forEach(({ itemKey, value, lang }) => {
      if (!value) {
        toggleError(itemKey, !value, lang);
        error = true;
      }
    });

    if (!error) {
      const datas = {
        name,
        key,
        title,
        shortName,
        explanation,
        kpi
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Key"
                            required
                            value={key}
                            onChange={handleChange('key')}
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="KPI associé"
                            required
                            onChange={handleChange('kpi')}
                            options={['CPE', 'CPA', 'CPM', 'CPV', 'CPAHB']}
                            error={kpiError}
                            defaultValue="Choisissez un kpi"
                            value={kpi}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Libellé (FR)"
                            required
                            small
                            placeholder="Ventes en ligne"
                            value={name?.fr}
                            onChange={handleChange('name', 'fr')}
                            error={namefrError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Libellé (EN)"
                            required
                            small
                            placeholder="Ventes en ligne"
                            value={name?.en}
                            onChange={handleChange('name', 'en')}
                            error={nameenError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Libellé simplifié (FR)"
                            small
                            placeholder="Ventes"
                            value={shortName?.fr}
                            onChange={handleChange('shortName', 'fr')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Libellé simplifié (EN)"
                            small
                            placeholder="Ventes"
                            value={shortName?.en}
                            onChange={handleChange('shortName', 'en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Libellé du kpi (FR)"
                            required
                            small
                            placeholder="Taux d'acquisition"
                            onChange={handleChange('title', 'fr')}
                            value={title?.fr}
                            error={titlefrError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Libellé du kpi (EN)"
                            placeholder="Taux d'acquisition"
                            onChange={handleChange('title', 'en')}
                            required
                            value={title?.en}
                            error={titleenError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Description (FR)"
                            small
                            placeholder="Les utilisateurs qui ont acheté une prestation sur le site ermes"
                            value={explanation?.fr}
                            onChange={handleChange('explanation', 'fr')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Description (EN)"
                            small
                            placeholder="Les utilisateurs qui ont acheté une prestation sur le site ermes"
                            value={explanation?.en}
                            onChange={handleChange('explanation', 'en')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
