import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import { YOUGOVE_SEGMENTS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';
import { ModalDelete } from 'components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const YougovSegmentsContainer = () => {
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [allRows, setAllRows] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [yougovSegmentsList, setYougovSegmentsList] = React.useState([]);
  const [selectedYougovSegment, setSelectedYougovSegment] = React.useState({});

  const selectYougovSegment = id => {
    const yougovSegment = yougovSegmentsList.find(el => el._id === id);
    setSelectedYougovSegment(yougovSegment);
    return yougovSegment;
  };

  const closeModal = () => {
    setModal(null);
    selectYougovSegment({});
  };

  const onCreate = async data => {
    await YOUGOVE_SEGMENTS.create(data);
    setLoaded(false);
    closeModal();
  };

  const onDelete = async () => {
    await YOUGOVE_SEGMENTS.deleteSegments(selectedYougovSegment._id);
    setLoaded(false);
    closeModal();
  };

  const openCreateModal = async () => {
    if (adminRights?.funnel?.yougovMetrics?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const openUpdateModal = id => {
    if (adminRights?.funnel?.yougovMetrics?.update) {
      selectYougovSegment(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openModalDelete = id => {
    if (adminRights?.funnel?.yougovMetrics?.delete) {
      selectYougovSegment(id);
      setModal('ModalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onUpdate = async data => {
    await YOUGOVE_SEGMENTS.update(selectedYougovSegment._id, data);
    closeModal();
    setLoaded(false);
  };

  const fetchData = async () => {
    const result = await YOUGOVE_SEGMENTS.getAll();

    setYougovSegmentsList(result);

    setRows(result);
    setAllRows(result);
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  // eslint-disable-next-line
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche un segment"
            placeholder="id, Label ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Ajouter"
            disabled={!loaded}
          />
        </Item>
        <Item>
          <Loading loading={!loaded}>
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Label',
                  id: 'name'
                },
                {
                  label: 'Filtres',
                  id: 'filters',
                  format: values => {
                    return values?.map(el => el.name).join(', ') || 'Aucun';
                  }
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openModalDelete}
            />
          </Loading>
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              actionName="Créer"
            />
          )}
          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedYougovSegment}
              actionName="Modifier"
            />
          )}
          {openModal === 'ModalDelete' && (
            <ModalDelete
              name={selectedYougovSegment.name}
              type="ce segment"
              open={openModal === 'ModalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default YougovSegmentsContainer;
