import { api } from './api';

const getPricings = async (groupId = null, type = null) => {
  try {
    let query = '';
    if (groupId || type) {
      const params = new URLSearchParams();
      if (groupId) params.append('groupId', groupId);
      if (type) params.append('type', type);
      query = `?${params.toString()}`;
    }
    
    const result = await api.get(`/pricings${query}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createPricing = async datas => {
  try {
    const result = await api.post(`/pricings`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updatePricing = async (id, datas) => {
  try {
    const result = await api.put(
      `/pricings/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateManyPricings = async datas => {
  try {
    const result = await api.put(
      `/pricings/updateMany`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deletePricing = async id => {
  try {
    const result = await api.delete(`/pricings/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { getPricings, createPricing, updatePricing, updateManyPricings, deletePricing };
