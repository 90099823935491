import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Container, Item, Row } from 'components/Layout';

export const UnauthorizedModal = props => {
  return (
    <Modal titleHead="Droits insuffisants" maxWidth="sm" {...props}>
      <Container>
        <Row spacing={2}>
          <Item justify="flex-start">
            <Text>
              Les droits rattachés à votre compte ne vous permettent pas
              d’effectuer cette action. Rapprochez vous d’un autre
              administrateur ayant les droits d’update sur les droits des
              administrateurs.
            </Text>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

UnauthorizedModal.defaultProps = {};
UnauthorizedModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default UnauthorizedModal;
