import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import SidebarAudience, {
  SideItemAudience
} from '../../components/SidebarAudience';
import { useNavigation } from '../../contexts/NavigationContext';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */
const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { menu, setMenu } = useNavigation();
  const { adminRights } = useUser();

  const linkInfo = [
    {
      name: 'category',
      svg: 'category',
      subtitle: 'Gestion des catégorie de la taxonomie',
      surname: 'catégories',
      enabled: adminRights?.audiences?.categories?.read || false
    },
    {
      name: 'criteria',
      svg: 'critere',
      subtitle: 'Gestion des critères de la taxonomie',
      surname: 'critère',
      enabled: adminRights?.audiences?.criteria?.read || false
    },
    {
      name: 'tagfunnel',
      svg: 'tagfunnel',
      subtitle: 'Gestion des accès aux critères sur mesure',
      surname: 'Sur mesure',
      enabled: adminRights?.audiences?.tagFunnel?.read || false
    },
    {
      name: 'partners',
      svg: 'handShakeIcon',
      subtitle: 'Gestion des partenaires',
      surname: 'Partenaires',
      enabled: adminRights?.audiences?.partners?.read || false
    },
    {
      name: 'activatedAudiences',
      svg: 'activatedAudiences',
      subtitle: 'Gestion des audiences activées',
      surname: 'Audiences Activées',
      enabled: adminRights?.audiences?.activatedAudience?.read || false
    },
    {
      name: 'exportSegments',
      svg: 'exportSegments',
      subtitle: 'Gestion des exports de segment',
      surname: 'Export Segment',
      enabled: adminRights?.audiences?.exportSegment?.read || false
    },
    {
      name: 'exportMedias',
      svg: 'activatedAudiences',
      subtitle: 'Gérer les medias disponibles dans audiences',
      surname: 'Médias Exportables',
      enabled: adminRights?.audiences?.exportMedia?.read || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarAudience>
      {linkInfo.map(link => {
        return (
          <SideItemAudience
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarAudience>
  );
};

export default SidebarContainer;
