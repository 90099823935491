import { api } from './api';

const get = async () => {
  try {
    const result = await api.get(`/backoffice-funnel/properties`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async data => {
  try {
    const result = await api.post(`/backoffice-funnel/properties`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, data) => {
  try {
    const result = await api.put(`/backoffice-funnel/properties/${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteProperties = async id => {
  try {
    const result = await api.delete(`/backoffice-funnel/properties/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const addFunnel = async datas => {
  try {
    const result = await api.put(
      `/backoffice-funnel/properties/addfunnel`,
      datas
    );
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteFunnel = async datas => {
  try {
    const result = await api.put(
      `/backoffice-funnel/properties/deletefunnel`,
      datas
    );
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  get,
  create,
  update,
  deleteProperties,
  addFunnel,
  deleteFunnel
};
