import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Download from '@mui/icons-material/Download';

import { useTheme } from 'contexts/ThemeContext';

import { localStorage } from 'utils/constants';
import { INSIGHT } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { IconButton } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalDownload = props => {
  const { themeColors } = useTheme();

  const { config } = props;
  const { selectInsight, selectUploadTask } = config;
  const [filesName, setFilesName] = useState([]);

  const getFiles = async () => {
    const tmpFilesName = await INSIGHT.getSegmentFilesName(
      selectUploadTask._id
    );
    setFilesName(tmpFilesName);
  };

  useEffect(() => {
    getFiles(selectUploadTask._id);
    // eslint-disable-next-line
  }, [selectUploadTask]);

  const downloadFile = fileName => () => {
    try {
      const token = `${window.localStorage.getItem(localStorage.ACCESS_TOKEN) ||
        ''}`;
      const urlApi = `${process.env.REACT_APP_ERMES_API}/v2/insight/insight/downloadProfilesFiles/${selectUploadTask._id}/${fileName}?token=${token}`;
      const link = document.createElement('a');
      link.href = urlApi;
      link.download = `${selectInsight.name}_${fileName}`;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(urlApi);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error while downloading', error);
    }
  };

  const generateCheckBox = values => {
    return values.map(el => (
      <Row spacing={1} style={{ margin: '2px' }}>
        <Item
          style={{
            border: `1px solid ${themeColors.greyLight}`
          }}
        >
          <Text bold>{el.name} </Text>
          <IconButton onClick={downloadFile(el.name)}>
            <Download style={{ color: themeColors.light }} />
          </IconButton>
          <Text> {el.size}mb</Text>
        </Item>
      </Row>
    ));
  };

  return (
    <Modal {...props}>
      <Container>
        <Row spacing={1}>
          <Item>
            <Text>Segment sélectionné</Text>
          </Item>
        </Row>
        <Row>
          <Item justify="flex-start">
            <Text>Segment:&nbsp;</Text>
            <Text bold color={themeColors.audience}>
              {selectInsight?.name}
            </Text>
          </Item>
        </Row>
        <Row>
          <Item justify="flex-start">
            <Text>Nombre de profils:&nbsp;</Text>
            <Text bold color={themeColors.audience}>
              {selectInsight?.result?.nbProfiles}
            </Text>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Choix du format" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {generateCheckBox(filesName)}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalDownload.defaultProps = {
  config: {},
  actionBeta: null,
  update: false
};
ModalDownload.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool
};

export default ModalDownload;
