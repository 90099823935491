import React from 'react';

import Dashboard from 'components/Dashboard';
import { SectionDashboard } from 'components/Section';

/**
 * @component
 * DashboardContainer
 *
 */
function DashboardContainer(props) {
  return (
    <SectionDashboard {...props}>
      <Dashboard />
    </SectionDashboard>
  );
}

export default DashboardContainer;
