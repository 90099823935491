import { api } from './api';

const createConfigConnector = async datas => {
  try {
    const { data } = await api.post(`/v2/funnel/connectors/config`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConfigConnector = async () => {
  try {
    const { data } = await api.get(`/v2/funnel/connectors/config`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getConfigConnectorByFunnelId = async funnelId => {
  try {
    const { data } = await api.get(
      `/v2/funnel/connectors/config/funnel/${funnelId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConfigConnector = async (id, data) => {
  try {
    const result = await api.put(`/v2/funnel/connectors/config/${id}`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteConfigConnector = async id => {
  try {
    await api.delete(`/v2/funnel/connectors/config/${id}`);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getConfigGenerators = async () => {
  try {
    const { data } = await api.get(
      `/v2/funnel/connectors/requestConfigGenerator`
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  createConfigConnector,
  updateConfigConnector,
  getConfigConnector,
  deleteConfigConnector,
  getConfigConnectorByFunnelId,
  getConfigGenerators
};
