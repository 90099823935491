import React from 'react';
import { Text } from 'components/DataDisplay';

import { useTheme } from 'contexts/ThemeContext';

export const StatusText = props => {
  const { themeColors } = useTheme();
  const { item, status } = props;
  if (item === 'connectors') {
    switch (status) {
      case 'Error':
        return (
          <Text color={themeColors.red}>
            {' '}
            Erreur de setup de vos connecteurs{' '}
          </Text>
        );
      case 'Success':
        return (
          <Text color={themeColors.campaign}>
            Vos connecteurs ont bien été setupés
          </Text>
        );
      case 'Empty':
        return (
          <Text color={themeColors.ramses} fontSize="11px">
            Aucun connecteur n'est setupable automatiquement
          </Text>
        );
      default:
        return <Text>&nbsp;</Text>;
    }
  }
  if (item === 'brd')
    switch (status) {
      case 'Error':
        return (
          <Text color={themeColors.red}> Erreur de setup de votre BRD </Text>
        );
      case 'Success':
        return (
          <Text color={themeColors.campaign}>Votre BRD a bien été sétupée</Text>
        );
      default:
        return <Text>&nbsp;</Text>;
    }
};
