import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  errorName: false,
  errorKey: false
};
export const ModalForm = props => {
  const { onValidate, config, actionName } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config.name || null,
    key: config.key || null,
    description: config.description || null
  };
  const [{ name, key, description }, setState] = React.useState(initialState);
  const [{ errorName, errorKey }, setError] = React.useState(ErrorState);
  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };
  const toggleErrorKey = state => {
    setError(prevState => ({ ...prevState, errorKey: state }));
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = field => value => {
    const text = value.trim();
    switch (field) {
      case 'name':
        toggleErrorName(!text);
        setState(prevState => ({ ...prevState, name: text }));
        break;
      case 'key':
        toggleErrorKey(!text);
        setState(prevState => ({ ...prevState, key: text }));
        break;
      case 'description':
        setState(prevState => ({ ...prevState, description: text }));
        break;
      default:
    }
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (!key) {
      toggleErrorKey(true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        key,
        description
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Key</Text>
                          <TextField
                            small
                            value={key}
                            onChange={handleChange('key')}
                            error={errorKey}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Nom</Text>
                          <TextField
                            small
                            value={name}
                            onChange={handleChange('name')}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Description</Text>
                          <TextField
                            value={description}
                            onChange={handleChange('description')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
