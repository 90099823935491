import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';

import { AssetItem } from 'components/AssetItem';
import { Text, Tooltip } from 'components/DataDisplay';
import { IconButton, TextField } from 'components/Inputs';
import { Item, Row } from 'components/Layout';

export const AdItem = props => {
  const {
    isCampaignSetupPending,
    ad,
    getStatusChip,
    asset,
    setAds,
    activableMedia,
    mediaSetupTask,
    handleSaveAd
  } = props;
  const { themeColors } = useTheme();

  return (
    <Row spacing={1} key={ad.id}>
      <Item
        justify="flex-start"
        style={{
          marginLeft: '80px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {asset && (
          <Tooltip title={<AssetItem asset={asset} />}>
            <Icon
              icon="creaIcon"
              attrs={{
                fill: themeColors.light
              }}
            />
          </Tooltip>
        )}
        &nbsp;
        <Text fontSize="14px" fontWeight={400}>
          {ad.availableCreative?.name || 'Asset introuvable'}
        </Text>
        {getStatusChip(activableMedia, mediaSetupTask)}
        {!ad.isEditing ? (
          <>
            {ad.externalId && (
              <Text fontSize="12px" fontWeight={400}>
                (id: {ad.externalId})
              </Text>
            )}
            <IconButton
              disabled={isCampaignSetupPending}
              onClick={() => {
                setAds(prevAds =>
                  prevAds.map(crea => {
                    if (crea.id === ad.id) {
                      return {
                        ...crea,
                        isEditing: true
                      };
                    }
                    return crea;
                  })
                );
              }}
            >
              <CreateIcon
                style={{
                  color: themeColors.light
                }}
              />
            </IconButton>
          </>
        ) : (
          <Item
            flex
            style={{
              width: '305px',
              alignItems: 'center'
            }}
          >
            <TextField
              value={ad.externalId}
              onChange={e => {
                setAds(prevAds =>
                  prevAds.map(crea => {
                    if (crea.id === ad.id) {
                      return {
                        ...crea,
                        externalId: e
                      };
                    }
                    return crea;
                  })
                );
              }}
            />
            <IconButton
              onClick={() => {
                setAds(prevAds =>
                  prevAds.map(crea => {
                    if (crea.id === ad.id) {
                      return {
                        ...crea,
                        isEditing: false
                      };
                    }
                    return crea;
                  })
                );
                handleSaveAd(ad.id, ad.externalId);
              }}
            >
              <CheckIcon />
            </IconButton>
          </Item>
        )}
      </Item>
    </Row>
  );
};

AdItem.defaultProps = {
  isCampaignSetupPending: false
};

AdItem.propTypes = {
  isCampaignSetupPending: PropTypes.bool,
  ad: PropTypes.shape().isRequired,
  getStatusChip: PropTypes.func.isRequired,
  asset: PropTypes.shape().isRequired,
  setAds: PropTypes.func.isRequired,
  activableMedia: PropTypes.shape().isRequired,
  mediaSetupTask: PropTypes.shape().isRequired,
  handleSaveAd: PropTypes.func.isRequired
};

export default AdItem;
