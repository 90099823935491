/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { PhotoshopPicker } from 'react-color';
import { Editor as DraftEditor } from 'react-draft-wysiwyg';

const ColorPic = ({ expanded, onExpandEvent, onChange, currentState }) => {
  const { color } = currentState;
  const [colorState, setColorState] = useState(color);

  const stopPropagation = event => {
    event.stopPropagation();
  };
  const handleChange = value => {
    setColorState(value.hex);
  };

  const onAccept = () => {
    onChange('color', colorState);
  };

  const renderModal = () => {
    return (
      <div onClick={stopPropagation}>
        <PhotoshopPicker
          color={colorState}
          onAccept={onAccept}
          onChange={handleChange}
        />
      </div>
    );
  };
  return (
    <div
      aria-haspopup="true"
      aria-expanded={expanded}
      aria-label="rdw-color-picker"
    >
      <div onClick={onExpandEvent}>
        <ColorLensIcon />
      </div>
      {expanded ? renderModal() : undefined}
    </div>
  );
};

export const Editor = ({ editorState, onEditorStateChange }) => {
  const getFileBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
    reader.onerror = () => {};
  };

  const imageUploadCallback = file =>
    // eslint-disable-next-line no-unused-vars
    new Promise((resolve, reject) =>
      getFileBase64(file, data => resolve({ data: { link: data } }))
    );

  return (
    <DraftEditor
      editorState={editorState}
      wrapperClassName="rich-editor demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={onEditorStateChange}
      placeholder="The message goes here..."
      toolbar={{
        image: {
          uploadCallback: imageUploadCallback,
          previewImage: true,
          alignmentEnabled: true,
          defaultSize: { height: 'auto', width: '100%' }
        },
        colorPicker: { component: ColorPic }
      }}
    />
  );
};

export default Editor;
