import { api } from './api';

const uri = 'offPlatformTransaction';

const create = async data => {
  try {
    const result = await api.post(uri, data);
    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default {
    create
}
