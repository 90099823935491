import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { TAGFUNNEL, USERS } from 'utils/rest';

import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const TagFunnelContainer = () => {
  const { themeColors } = useTheme();
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [tagfunnelList, setTagfunnelsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedTagFunnel, setSelectedTagfunnel] = useState({});

  const selectTagfunnel = id => {
    const result = tagfunnelList.find(el => el._id === id);
    setSelectedTagfunnel(result);
  };
  const closeModal = () => {
    setModal(null);
    selectTagfunnel({});
  };

  const formatUsers = (users, domainUsersList) => {
    const filteredUsers = users.filter(user => {
      return domainUsersList.find(el => el.email === user);
    });

    const nbUsers = filteredUsers.length;
    if (nbUsers === 0) {
      return <Text>Aucun</Text>;
    }
    if (nbUsers === 1)
      return (
        <Text color="insight"> {filteredUsers[0] && filteredUsers[0]} </Text>
      );

    const others = nbUsers - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'utilisateur',
      others
    )}`;

    const getToolTipText = txt => {
      return (
        <Row spacing={0}>
          <Item>
            <Text color={themeColors.dark}>{txt}</Text>
          </Item>
        </Row>
      );
    };

    const tooltipContent = () => {
      return (
        <Container>{filteredUsers.map(user => getToolTipText(user))}</Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="insight"> {filteredUsers[0]}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="insight">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const fetchDatas = async () => {
    try {
      const tagfunnels = await TAGFUNNEL.getTagFunnels();
      const users = await USERS.getAll();

      setLoaded(true);
      setTagfunnelsList(tagfunnels);
      const mapped = tagfunnels.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.name = item.name;
        newItem.users = formatUsers(item.users, users);
        return newItem;
      });
      setUsersList(users);
      setRows(mapped);
      setAllRows(mapped);
      return mapped;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.audiences?.tagFunnel?.update) {
      selectTagfunnel(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async datas => {
    const updatedDatas = datas;
    if (datas.users) {
      const othersDomainUsers = selectedTagFunnel.users.filter(
        user => !usersList.find(el => el.email === user)
      );

      updatedDatas.users.push(...othersDomainUsers);
    }

    await TAGFUNNEL.updateTagFunnel(selectedTagFunnel._id, updatedDatas);

    setLoaded(false);
    closeModal();
  };

  useEffect(() => {
    if (!loaded) {
      fetchDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher un critère"
            placeholder="ID, Nom, ..."
          />
        </Item>
        <Item xs />
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'ID',
                  id: '_id',
                  sortKey: '_id'
                },
                {
                  label: 'Nom',
                  id: 'name',
                  sortKey: 'name'
                },
                {
                  label: 'Utilisateurs',
                  id: 'users'
                }
              ]}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                selectedTagFunnel,
                usersList
              }}
              actionName="Modifier le média"
              update
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal open onClose={closeModal} />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default TagFunnelContainer;
