import React from 'react';
import Konami from 'react-konami-code';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import Image from 'components/Image';
import { Container, Item, Row } from 'components/Layout';

import { getOne } from './data';

/**
 * EasterEgg
 * @param
 */

const EasterEgg = () => {
  const [open, setModal] = React.useState(false);
  const [enableKonami, setKonami] = React.useState(true);
  const [joke, setJoke] = React.useState({});

  const openModal = () => {
    const item = getOne();
    setJoke(item);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setKonami(false);
    setTimeout(() => setKonami(true), 500);
  };
  return (
    <Container>
      <Row>
        <Item>
          {enableKonami && (
            <Konami
              action={openModal}
              code={[38, 38, 40, 40, 37, 39, 37, 39]}
            />
          )}
          {open && (
            <Modal open maxWidth="sm" onClose={closeModal}>
              <Container>
                <Row spacing={1}>
                  <Item xs={3}>
                    <Image src={joke.img} width="120px" />
                  </Item>
                  <Item xs>
                    <Text variant="h5">{joke.txt}</Text>
                  </Item>
                </Row>
              </Container>
            </Modal>
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default EasterEgg;
