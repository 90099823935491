import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { useTheme } from 'contexts/ThemeContext';

import { Divider, Text } from 'components/DataDisplay';
import { IconButton } from 'components/Inputs';
import { Item, Row } from 'components/Layout';

export const ExpendedItem = props => {
  const { toggleExpanded, isExpanded, title } = props;

  const { themeColors } = useTheme();

  return (
    <Item justify="flex-start" alignItems="center">
      <Row spacing={0} justify="flex-start">
        <Item flex>
          <Text fontSize="20px" fontWeight={700}>
            {title}
          </Text>
        </Item>
        <Item flex>
          <IconButton onClick={() => toggleExpanded('all')}>
            {isExpanded ? (
              <KeyboardArrowDownIcon style={{ fill: themeColors.light }} />
            ) : (
              <KeyboardArrowRight style={{ fill: themeColors.light }} />
            )}
          </IconButton>
        </Item>
        <Item xs justify="flex-start">
          <Divider
            color="light"
            noFade
            style={{ width: '100%', height: '2px' }}
          />
        </Item>
      </Row>
    </Item>
  );
};

ExpendedItem.propTypes = {
  toggleExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default ExpendedItem;
