import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import numeral from 'numeral';

import { Divider, Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { IconButton, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const MediaOrderItemMulti = props => {
  const {
    children,
    mediaOrder,
    mediaLogoDisplay,
    activableMedia,
    getStatusChip,
    setMediaOrders,
    handleSaveMediaOrder,
    mediaSetupTask
  } = props;

  return (
    <Container key={mediaOrder.id}>
      <Row spacing={1}>
        <Item
          justify="flex-start"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Row spacing={0} justify="flex-start">
            <Item flex>
              <Image
                src={mediaLogoDisplay}
                alt={mediaOrder.activableMedia}
                height="38px"
              />
              {getStatusChip(activableMedia, mediaSetupTask, true)}
              {!mediaOrder.isEditing ? (
                <>
                  {mediaOrder.externalId && (
                    <Text fontSize="12px" fontWeight={400}>
                      (id: {mediaOrder.externalId})
                    </Text>
                  )}
                </>
              ) : (
                <Item
                  flex
                  style={{
                    width: '305px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <TextField
                    value={mediaOrder.externalId}
                    onChange={e => {
                      setMediaOrders(prevMediaOrders =>
                        prevMediaOrders.map(order => {
                          if (order.id === mediaOrder.id) {
                            return { ...order, externalId: e };
                          }
                          return order;
                        })
                      );
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setMediaOrders(prevMediaOrders =>
                        prevMediaOrders.map(order => {
                          if (order.id === mediaOrder.id) {
                            return { ...order, isEditing: false };
                          }
                          return order;
                        })
                      );
                      handleSaveMediaOrder(
                        mediaOrder.id,
                        mediaOrder.externalId
                      );
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Item>
              )}
            </Item>

            <Item xs justify="flex-start" style={{ paddingRight: '5px' }}>
              <Divider
                color="light"
                noFade
                style={{ width: '100%', height: '1.5px' }}
              />
            </Item>
            <Item flex>
              <Text fontSize="14px" fontWeight={700}>
                {numeral(mediaOrder.budgetMedia).format('0,0$')}
              </Text>
            </Item>
          </Row>
        </Item>
      </Row>
      {children}
    </Container>
  );
};

MediaOrderItemMulti.defaultProps = {
  mediaLogoDisplay: '',
  launchSetup: () => {}
};
MediaOrderItemMulti.propTypes = {
  children: PropTypes.node.isRequired,
  mediaOrder: PropTypes.shape().isRequired,
  activableMedia: PropTypes.shape().isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  getStatusChip: PropTypes.func.isRequired,
  setMediaOrders: PropTypes.func.isRequired,
  handleSaveMediaOrder: PropTypes.func.isRequired,
  mediaSetupTask: PropTypes.shape().isRequired,
  mediaLogoDisplay: PropTypes.string,
  launchSetup: PropTypes.func
};

export default MediaOrderItemMulti;
