import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';

import BaseSideBar, { BaseSideItem } from './style';

/**
 * SideBar
 * @component
 *
 */
function SideBar(props) {
  const { panel, children, fullHeight, ...rest } = props;
  return (
    <BaseSideBar panel={panel} fullHeight={fullHeight} {...rest}>
      <ul style={{ border: 'none' }}>{children}</ul>
    </BaseSideBar>
  );
}
SideBar.defaultProps = {
  children: undefined,
  fullHeight: false
};
SideBar.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  panel: PropTypes.string.isRequired
};

/**
 * SideItem
 * @component
 *
 */
export const SideItem = props => {
  const {
    active,
    icon,
    subtitle,
    title,
    hasActiveClass,
    svg,
    svgAttrs,
    ...rest
  } = props;
  const { themeColors } = useTheme();
  return (
    <BaseSideItem
      selected={active}
      hasActiveClass={hasActiveClass}
      themeColors={themeColors}
      {...rest}
    >
      <div className="image-block">
        {icon && <Image src={icon} alt="mockup" />}
        {svg && <Icon icon={svg} attrs={svgAttrs} />}
      </div>
      <Text className="text-block">
        <strong>{title}</strong>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </Text>
      <div className="badge" />
    </BaseSideItem>
  );
};
SideItem.defaultProps = {
  active: false,
  hasActiveClass: false,
  icon: '',
  subtitle: '',
  svg: '',
  svgAttrs: {}
};
SideItem.propTypes = {
  active: PropTypes.bool,
  hasActiveClass: PropTypes.bool,
  icon: PropTypes.string,
  svg: PropTypes.string,
  svgAttrs: PropTypes.objectOf(PropTypes.any),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

export { BaseSideItem } from './style'; // TODO :: remove
export default SideBar;
