import React from 'react';

import BaseImage from './style';

/**
 * @component
 *
 */
function Image(props) {
  return <BaseImage {...props} />;
}

export default Image;
