import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

import { colors } from 'utils/constants';
import Icon from 'utils/icons';

import Dropdown from 'components/Dropdown';
import { Button } from 'components/Inputs';

/**
 * NavTab
 * @component
 *
 */
export const NavTab = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  a {
    display: flex;
    align-items: center;
    color: ${colors.dark.light};
    flex-direction: column;
  }
  a.selected {
    background-color: ${colors.dark.funnel};
    color:${colors.dark.light};
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 8px solid #009fe3;
    }
  }
  }
`;

/**
 * NavTabItem
 * @component
 *
 */
export const NavTabItem = styled.li`
  min-height: 71px;
  display: flex;
  text-transform: uppercase;
  align-items: flex-end;
  .nav-tab-link {
    padding: 18px 20px;
    div {
      margin-top: 4px;
    }
  }

  background-color: ${props => props.color || 'inherit'};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

/**
 * NavImg
 * @component
 *
 */
export const NavImg = styled.div`
  margin-top: 5px;
  width: 110px;
  @media (min-width: 1800px) {
    width: 130px;
  }
`;

const LinkWrapper = ({ isActive, ...rest }) => {
  return <Link {...rest} />;
};
LinkWrapper.defaultProps = {
  isActive: false
};
LinkWrapper.propTypes = {
  isActive: PropTypes.bool
};

/**
 * BaseHeaderLink
 * @component
 *
 */
export const BaseHeaderLink = styled(LinkWrapper)`
  background-color: ${({ isActive, color, nav }) =>
    isActive ? color : colors.dark[nav]};
  color: ${colors.dark.secondary};
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : '')};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
`;

/**
 * Logo
 * @component
 *
 */
export const Logo = styled(Icon)`
  margin: 0 7px 0 0;
  vertical-align: middle;
  border-style: none;
`;

/**
 * LogoImg
 * @component
 *
 */
export const LogoImg = styled.img`
  width: 20px;
  margin: 0 7px 0 0;
  vertical-align: middle;
  border-style: none;
`;

/**
 * FunnelLink
 * @component
 *
 */
export const FunnelLink = styled(Link)`
  text-decoration: none;
  display: block;
`;

export const ButtonSwitchLight = styled(Button)`
  && {
    padding: 4px;
    width: 38px;
    min-width: 0;
    min-height: 0;
    height: 38px;
    border-radius: 12px;
    background-color: ${colors.dark.secondary};
  }
`;

/**
 * StyledDropdown
 * @component
 *
 */
const StyledDropdown = styled(Dropdown)`
  width: auto;
  display: flex;
  color: ${colors.dark.light};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  min-height: 42px;

  .dropdown-content,
  .dropdown-content a {
    top: 42px;
    width: auto;
    min-width: ${({ minWidth }) => minWidth || '200px'};
    right: 0;
    cursor: pointer;
    outline: none;
    color: ${colors.dark.light};
    background-color: ${colors.dark.primary};
  }
  .dropdown-content a:hover {
    width: auto;
    background-color: ${colors.dark.tertiary};
    font-weight: bold;
    a {
      background-color: ${colors.dark.tertiary};
    }
  }
`;

/**
 * DropdownHeader
 * @component
 *
 */
export const DropdownHeader = props => (
  <StyledDropdown
    color={colors.dark.light}
    bgColor={colors.dark.primary}
    maxWidth="300px"
    {...props}
  />
);

export default withTheme();
