import { api } from './api';

const get = async () => {
  try {
    const result = await api.get(`/v2/funnel/importmodel`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async data => {
  try {
    const result = await api.post(`/v2/funnel/importmodel`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, body) => {
  try {
    const { data } = await api.put(`/v2/funnel/importmodel/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteById = async id => {
  try {
    const result = await api.delete(`/v2/funnel/importmodel/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  get,
  create,
  update,
  deleteById
};
