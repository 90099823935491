import React from 'react';

import { useUser } from 'contexts/UserContext';

import { YOUGOVMETRICS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';
import { ModalDelete } from 'components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const YougovMetricsContainer = () => {
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [allRows, setAllRows] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [yougovMetricsList, setYougovMetricsList] = React.useState([]);
  const [selectedYougovMetric, setSelectedYougovMetric] = React.useState({});

  const selectYougoMetric = id => {
    const yougovMetric = yougovMetricsList.find(el => el._id === id);
    setSelectedYougovMetric(yougovMetric);
    return yougovMetric;
  };

  const closeModal = () => {
    setModal(null);
    selectYougoMetric({});
  };

  const onCreate = data => {
    YOUGOVMETRICS.create(data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const onDelete = () => {
    YOUGOVMETRICS.deleteYougovMetrics(selectedYougovMetric._id)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const openCreateModal = async () => {
    if (adminRights?.funnel?.yougovMetrics?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const openUpdateModal = id => {
    if (adminRights?.funnel?.yougovMetrics?.update) {
      selectYougoMetric(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openModalDelete = id => {
    if (adminRights?.funnel?.yougovMetrics?.delete) {
      selectYougoMetric(id);
      setModal('ModalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onUpdate = data => {
    YOUGOVMETRICS.update(selectedYougovMetric._id, data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const SchemaStep = async () => {
    try {
      const result = await YOUGOVMETRICS.getYougovMetrics();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  if (!loaded) {
    SchemaStep().then(resp => {
      setLoaded(true);
      setYougovMetricsList(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.key = item.key;
        newItem.name = item.name;
        newItem.description = item.description;
        return newItem;
      });
      setRows(mapped);
      setAllRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche un metric"
            placeholder="Key, Nom ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              actionName="Créer"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Key',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Nom',
                  id: 'name'
                },
                {
                  label: 'Description',
                  id: 'description'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openModalDelete}
            />
          )}
          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedYougovMetric}
              actionName="Modifier"
            />
          )}
          {openModal === 'ModalDelete' && (
            <ModalDelete
              name={selectedYougovMetric.name}
              type="ce paramètre"
              open={openModal === 'ModalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default YougovMetricsContainer;
