import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

import { fontSizes } from 'utils/constants';

import { Container as ContainerComp } from 'components/Layout';

/**
 *
 * FooterContainer
 *
 */

export const Container = withTheme(styled(ContainerComp)`
  font-family: Poppins;
  font-size: ${fontSizes.small};
  color: ${({ themeColors }) => themeColors.lightSmooth};
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  border-top: 2px solid ${({ themeColors }) => themeColors.secondary};
  a {
    color: ${({ themeColors }) => themeColors.funnel};
    font-family: Poppins;
    text-decoration: none;
  }
`);

export default { Container };
