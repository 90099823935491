import React from 'react';
import styled from 'styled-components';

import { useTheme } from 'contexts/ThemeContext';

/**
 * BaseDateWrapper
 * @component
 *
 */
const OriginBaseDateWrapper = styled.div`
  display: inline-block;
  .DayPicker {
    background: ${({ themeColors }) => themeColors.secondary};
  }
  .DayPicker__horizontal,
  .CalendarMonth,
  .CalendarMonth,
  .CalendarMonthGrid {
    background: transparent;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }

  .DayPickerNavigation_button__default {
    background: ${({ themeColors }) => themeColors.secondary};
    border: ${({ themeColors }) => `1px solid ${themeColors.light}`};
  }
  .DayPicker_weekHeader,
  .CalendarMonth_caption {
    color: ${({ themeColors }) => themeColors.light};
  }

  .DayPickerNavigation_svg__horizontal {
    fill: ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__default {
    background: transparent;
    color: ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected_span {
    background: ${({ themeColors }) => themeColors.activation};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected {
    background: ${({ themeColors }) => themeColors.activation};
    color: white;
    border: 1px solid ${({ themeColors }) => themeColors.light};
  }

  .CalendarDay__selected:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }

  .CalendarDay__selected_span:hover {
    background: #66c7f1;
    border: 1px solid ${({ themeColors }) => themeColors.light};
    color: white;
  }

  .CalendarDay__blocked_out_of_range {
    color: ${({ themeColors }) => themeColors.disableTxt};
    text-decoration: line-through;
  }

  .DayPicker_calendarInfo__horizontal {
    width: 200px;
    padding-top: 35px;
  }
`;

export const BaseDateWrapper = props => {
  const { themeColors } = useTheme();
  return (
    <OriginBaseDateWrapper
      style={{ zIndex: 10 }}
      {...props}
      themeColors={themeColors}
    />
  );
};

export default BaseDateWrapper;
