import { api } from './api';

const getAll = async () => {
  try {
    const result = await api.get(`/v2/funnel/budget/alerting`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const update = async (id, body) => {
  try {
    const { data } = await api.put(`/v2/funnel/budget/alerting/${id}`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const create = async body => {
  try {
    const { data } = await api.post(`/v2/funnel/budget/alerting`, body);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteOne = async id => {
  try {
    const { data } = await api.delete(`/v2/funnel/budget/alerting/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  getAll,
  update,
  create,
  deleteOne
};
