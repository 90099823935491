export default {
  extraLower: '8px',
  lower: '10px',
  extraSmall: '12px',
  small: '14px',
  medium: '16px',
  mediumLarge: '20px',
  large: '24px',
  big: '28px',
  extraLarge: '32px'
};
