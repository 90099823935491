import React from 'react';
import PropTypes from 'prop-types';

import { panels } from 'utils/constants';

import BaseSidebar from './style';

/**
 * SidebarAudience
 * @component
 *
 */
function SidebarActivation(props) {
  const { children } = props;
  return <BaseSidebar panel={panels.backoffice}>{children}</BaseSidebar>;
}
SidebarActivation.propTypes = {
  children: PropTypes.node.isRequired
};

export { SideItemActivation } from './style';
export default SidebarActivation;
