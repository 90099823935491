import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  nameError: false,
  keyError: false,
  stepsError: false,
  activationMeasureKeyError: false
};
export const ModalForm = props => {
  const {
    onValidate,
    config: { selectedSchema, stepsList, measuresList },
    actionName
  } = props;
  const { themeColors } = useTheme();
  const initialState = {
    key: selectedSchema.key || null,
    name: selectedSchema.name || null,
    steps: selectedSchema.steps || [],
    activationMeasureKey: selectedSchema.activationMeasureKey || null
  };
  const [{ name, key, steps, activationMeasureKey }, setState] = useState(
    initialState
  );
  const [
    { nameError, keyError, stepsError, activationMeasureKeyError },
    setError
  ] = useState(ErrorState);
  const [measures] = useState(
    measuresList.map(el => ({
      value: el.key,
      label: el.target
    }))
  );

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };

  const handleChange = field => value => {
    const text = value.trim();
    toggleError(field, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[field] = text;
      return states;
    });
  };

  const handleChangeSteps = value => {
    setState(prevState => ({
      ...prevState,
      steps: value.map(e => stepsList.find(f => f._id === e._id))
    }));
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!name) {
      toggleError('name', true);
      error = true;
    }
    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (!steps || steps.length < 4) {
      toggleError('steps', true);
      error = true;
    }

    if (!activationMeasureKey) {
      toggleError('activationMeasureKey', true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        key,
        steps: steps.map(el => el._id),
        activationMeasureKey
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Titre *</Text>
                          <TextField
                            small
                            value={name}
                            onChange={handleChange('name')}
                            error={nameError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Key (Unique)*</Text>
                          <TextField
                            small
                            value={key}
                            placeholder="LeadsSony"
                            onChange={handleChange('key')}
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Type de funnel*</Text>
                          <Select
                            options={measures}
                            onChange={handleChange('activationMeasureKey')}
                            error={activationMeasureKeyError}
                            value={activationMeasureKey}
                            small
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Étapes">
              <Container>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    {stepsError ? (
                      <Text color="red">4 Étapes minimum</Text>
                    ) : null}
                    <ListField
                      options={stepsList.map(e => ({
                        name: e.name.fr,
                        title: e.title.fr,
                        key: e.key,
                        kpi: e.kpi,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['key', 'kpi', 'title']}
                      values={steps.map(e => ({
                        name: e.name.fr,
                        title: e.title.fr,
                        key: e.key,
                        kpi: e.kpi,
                        _id: e._id
                      }))}
                      onChange={handleChangeSteps}
                      actionTxt="Ajouter une step"
                      deleteAction
                    />
                  </Item>
                  <Item xs={6} />
                </Row>
                <Row spacing={0} />
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {
    selectedSchema: {},
    stepsList: [],
    measuresList: []
  },
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
