import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import {
  objectiveCorrespondance,
  getTabObjective
} from 'utils/functions/index';
import Icon from 'utils/icons';
import pluralize from 'utils/pluralize';
import { ACTIVATION, CUSTOMERS, FUNNEL } from 'utils/rest';

import ConnectorsListField from 'components/ConnectorsListField';
import { HelpTooltip, Text, Tooltip } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  Calendar as CampaignCalendar,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import { ModalDelete } from './ModalDelete';
import { StatusText } from './components/StatusText';

const getPaid = campaign => {
  if (campaign.isCampaignGroup) {
    if (campaign.paid === '') {
      return 'none';
    }
    return campaign.paid;
  }
  if (campaign.paid) {
    return 'true';
  }
  return 'false';
};

const ModalGestion = props => {
  const {
    campaign,
    onValidate,
    onDelete,
    config,
    setupPendingTooltip,
    datas
  } = props;
  const [loaded, setLoaded] = useState(false);
  const { themeColors } = useTheme();
  const searchOptionsKeys = ['name', 'id'];
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isCampaignSetupPending, setIsCampaignSetupPending] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [name, setName] = useState(campaign.name);
  const [disableDataFeedback, setDisableDataFeedback] = useState(false);
  const [shop, setShop] = useState([]);
  const [status, setStatus] = useState(campaign.status);
  const [paid, setPaid] = useState(getPaid(campaign));
  const [funnelId, setFunnelId] = useState(campaign.funnelId || '');
  const [organisation, setOrganisation] = useState('');
  const [split, setSplit] = useState(campaign?.split || false);
  const [tags, setTags] = useState([]);
  const [customer, setCustomer] = useState();
  const [purchaseNumber, setPurchaseNumber] = useState('');
  const [objective, setObjective] = useState(campaign.objective);
  const [startDate, setStartDate] = useState(
    moment(campaign.start)
      .utc()
      .startOf('day')
      .format()
  );
  const [endDate, setEndDate] = useState(
    moment(campaign.end)
      .utc()
      .endOf('day')
      .format()
  );
  const [errorName, setErrorName] = useState(false);
  const [purchaseNumberError, setPurchaseNumberError] = useState(false);
  const [connectors, setConnectors] = useState(
    campaign.isCampaignGroup
      ? campaign.campaignConfigs[0]?.connectors || []
      : campaign.connectors || []
  );
  const [brdStatus, setBrdStatus] = useState('Default');
  const [funnelStatus, setFunnelStatus] = useState('Default');
  const { user } = useUser();

  const campaignId = campaign.id || campaign._id;

  const formatStartDate = date => {
    return date
      ? moment(date)
          .utc()
          .startOf('day')
          .format()
      : null;
  };

  const formatEndDate = date => {
    return date
      ? moment(date)
          .utc()
          .endOf('day')
          .format()
      : null;
  };

  const setPeriod = async ({ startDate: start, endDate: end }) => {
    setStartDate(formatStartDate(start));
    setEndDate(formatEndDate(end));
  };

  const onAutoCompliteChange = (data, reason) => {
    if (reason === 'select-option' || reason === 'selectOption') {
      setFunnelId(data?.id);
    }

    if (reason === 'clear') {
      setFunnelId('');
    }
  };

  const setupConnectors = async () => {
    if (funnelId) {
      const id = campaign.id || campaign._id;
      if (funnelId !== campaign.funnelId) {
        await ACTIVATION.updateCampaignConfig(id, {
          funnelId
        });
      }
      let res = await ACTIVATION.setupConnectors([id]);
      if (res === null) setFunnelStatus('Error');
      else if (res?.connectorsConfigArr.length === 0) setFunnelStatus('Empty');
      else if (res?.connectorsConfigArr.length > 0) setFunnelStatus('Success');
    }
  };

  const setupDataFeedback = async () => {
    if (funnelId) {
      setDisableDataFeedback(true);
      const id = campaign.id || campaign._id;
      if (funnelId !== campaign.funnelId) {
        await ACTIVATION.updateCampaignConfig(id, {
          funnelId
        });
      }
      let res = await FUNNEL.setupDataFeedback(id);
      if (res === '') setBrdStatus('Success');
      else setBrdStatus('Error');
      setDisableDataFeedback(false);
    }
  };

  const formatPaid = statusPaid => {
    if (campaign.isCampaignGroup) {
      return statusPaid;
    }
    if (statusPaid === 'false') {
      return false;
    }
    return true;
  };

  const handleChangeConnectors = newConfig => {
    setConnectors(newConfig);
  };

  const validate = async () => {
    let error = false;
    if (name === '') {
      error = true;
      setErrorName(true);
    }

    if (purchaseNumber) {
      if (purchaseNumber.length < 3) {
        error = true;
        setPurchaseNumberError(true);
      }
    }

    const olfFunnelId = campaign.funnelId ? campaign.funnelId : '';
    const objectSend = {
      ...(purchaseNumber !== campaign.purchaseNumber ? { purchaseNumber } : {}),
      ...(name !== campaign.name ? { name } : {}),
      ...(formatPaid(paid) !== campaign.paid ? { paid: formatPaid(paid) } : {}),
      ...(funnelId !== olfFunnelId ? { funnelId } : {}),
      ...(status !== campaign.status ? { status } : {}),
      ...(split !== campaign.split ? { split } : {}),
      ...(startDate !==
      moment(campaign.start)
        .utc()
        .startOf('day')
        .format()
        ? { start: startDate }
        : {}),
      ...(endDate !==
      moment(campaign.end)
        .utc()
        .endOf('day')
        .format()
        ? { end: endDate }
        : {}),
      userIds: usersSelected.map(el => el._id),
      tags,
      applyTags: true
    };
    if (objective) {
      objectSend.objective = objective;
    }
    if (!error) {
      onValidate(objectSend);
      if (campaign.isCampaignGroup) {
        await ACTIVATION.updateManyCampaignConfigs(
          campaign.campaignConfigs.map(el => ({
            _id: el._id,
            connectors: connectors
          }))
        );
      }
    } else {
      await ACTIVATION.updateCampaignConfig(campaign._id, { connectors });
    }
  };

  const getShops = async shopIds => {
    const allShop = await ACTIVATION.getActivationShops();
    const shops = allShop.filter(el => shopIds.includes(el._id));
    setShop(shops.map(el => el.name));
  };

  const getShopGroup = async shopGroupId => {
    const group = await ACTIVATION.getActivationShopGoupById(shopGroupId);
    setOrganisation(group?.name || '');
  };

  const getTagsCampaignChild = campaignGroup => {
    const newTags = campaignGroup.campaignConfigs.reduce(
      (acc, el) => [...new Set([...acc, ...el.tags])],
      []
    );
    return newTags;
  };

  useEffect(() => {
    if (campaign) {
      const group = config.groups.find(el => el._id === campaign.groupId);
      setOrganisation(group?.name || 'Unkwnown');
      if (campaign.isCampaignGroup && campaign.shopIds) {
        getShops(campaign.shopIds);
      }
      if (campaign.isCampaignGroup && campaign.shopGroupId) {
        getShopGroup(campaign?.shopGroupId || '');
      }
      const newTags = campaign.isCampaignGroup
        ? getTagsCampaignChild(campaign)
        : campaign?.tags;
      const usersfilter = config.users.filter(
        el => el._id !== campaign.owner && !campaign.userIds.includes(el._id)
      );
      const userList = config.users.reduce((accUser, currentUser) => {
        if (
          currentUser._id !== campaign.owner &&
          campaign.userIds.includes(currentUser._id)
        ) {
          accUser.push({
            _id: currentUser._id,
            name: currentUser.username
          });
        }
        return accUser;
      }, []);
      setTags([...newTags]);
      setUsersSelected(userList);
      setAllUsers(usersfilter.map(el => ({ _id: el._id, name: el.username })));
      setLoaded(true);
    }
  }, [campaign, config.groups, config.users]);

  const fetchData = async () => {
    const stripeCustomer = await CUSTOMERS.getStripeCustomerByGroupId(
      campaign.groupId
    );
    setCustomer(stripeCustomer);
    setPurchaseNumber(campaign.purchaseNumber || '');
    const masterCampaignSetupTask = await ACTIVATION.getCampaignLastSetupTasks([
      campaignId
    ]);
    const isPending =
      masterCampaignSetupTask.length > 0 &&
      !['done', 'error'].includes(masterCampaignSetupTask[0].status);
    setIsCampaignSetupPending(isPending);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row spacing={1}>
        <Item justify="flex-start">
          <Text fontSize="20px" style={{ marginBottom: '30px' }}>
            <span
              style={{
                fontWeight: 700,
                display: 'inline-block',
                marginRight: '8px',
                fontSize: '20px'
              }}
            >
              {name}
            </span>
            ({campaign.id || campaign._id})
          </Text>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection title="Général" xs={3}>
            <Container>
              <Row spacing={1}>
                <Item xs={6} justify="flex-start">
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text fontWeight={700} fontSize="14px">
                          Type de campagne :
                        </Text>{' '}
                        <Text style={{ marginLeft: '5px' }}>
                          {campaign.isCampaignGroup
                            ? 'Multicampagne'
                            : 'Simple'}
                        </Text>
                      </Item>
                    </Row>
                  </Container>
                </Item>
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text fontWeight={700} fontSize="14px">
                          Objectif de campagne :
                        </Text>{' '}
                        <Text style={{ marginLeft: '5px' }}>
                          {objectiveCorrespondance(campaign.objective)}
                        </Text>
                      </Item>
                    </Row>
                  </Container>
                </Item>
              </Row>
              {campaign.isCampaignGroup ? (
                <Row spacing={1}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text fontWeight={700} fontSize="14px">
                            Groupement de magasin :
                          </Text>{' '}
                          <Text style={{ marginLeft: '5px' }}>
                            {organisation}
                          </Text>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text fontWeight={700} fontSize="14px">
                            Magasins :
                          </Text>{' '}
                          <Text bold style={{ marginLeft: '5px' }}>
                            {shop.length} {pluralize('magasin', shop)}
                          </Text>
                          <HelpTooltip
                            title={shop.join(', ')}
                            style={{ padding: '10px' }}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              ) : (
                <Row spacing={1}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text fontWeight={700} fontSize="14px">
                            Organisation :
                          </Text>{' '}
                          <Text style={{ marginLeft: '5px' }}>
                            {organisation}
                          </Text>
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              )}
              {/* PARTIE INPUT */}
              <Row spacing={1}>
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          disabled={isCampaignSetupPending}
                          title="Nom"
                          required
                          onChange={e => {
                            if (e !== '') {
                              setErrorName(false);
                            }
                            setName(e);
                          }}
                          small
                          error={errorName}
                          value={name}
                        />
                      </Item>
                    </Row>
                  </Container>
                </Item>
                {user.roles.includes('ADMIN') ? (
                  <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start"></Item>
                        <Select
                          title="Objectif de campagne"
                          value={objective}
                          options={getTabObjective.map(el => ({
                            value: el.key,
                            label: el.value
                          }))}
                          onChange={e => {
                            setObjective(e);
                          }}
                        />
                      </Row>
                    </Container>
                  </Item>
                ) : (
                  <></>
                )}
              </Row>
              <Row spacing={1}>
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item justify="flex-start">
                        <Text>Dates</Text>
                      </Item>
                      <Item xs={11} justify="flex-start">
                        <CampaignCalendar
                          isPending={isCampaignSetupPending}
                          disableCampare
                          start={startDate}
                          end={endDate}
                          onChange={setPeriod}
                          displayDate
                          onClearDate={() => {
                            setPeriod({ reset: true });
                          }}
                          displayClearDate={false}
                          inline
                        />
                      </Item>
                    </Row>
                  </Container>
                </Item>
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Select
                          disabled={isCampaignSetupPending}
                          title="Statut*"
                          options={
                            campaign.isCampaignGroup
                              ? [
                                  {
                                    value: 'groupDraft',
                                    label: 'Brouillon'
                                  },
                                  { value: 'validated', label: 'Validée' },
                                  { value: 'paused', label: 'Pause' },
                                  { value: 'canceled', label: 'Annulée' },
                                  { value: 'completed', label: 'Terminée' },
                                  { value: 'active', label: 'Activée' }
                                ]
                              : [
                                  { value: 'draft', label: 'Brouillon' },
                                  { value: 'validated', label: 'Validée' },
                                  { value: 'paused', label: 'Pause' },
                                  { value: 'canceled', label: 'Annulée' },
                                  { value: 'completed', label: 'Terminée' },
                                  { value: 'active', label: 'Activée' }
                                ]
                          }
                          value={status}
                          onChange={e => {
                            setStatus(e);
                          }}
                        />
                      </Item>
                    </Row>
                  </Container>
                </Item>
              </Row>
              <Row spacing={1} justify="flex-start">
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        {campaign.orderStatus ? (
                          <Select
                            title="Paiement*"
                            options={[
                              { value: 'paid', label: 'Non payée' },
                              { value: 'unpaid', label: 'Payée' },
                              { value: 'pending', label: 'En attente' },
                              { value: 'rejected', label: 'Rejetée' }
                            ]}
                            value={campaign.orderStatus}
                            disabled
                          />
                        ) : (
                          <>
                            {campaign.isCampaignGroup ? (
                              <Select
                                title="Paiement*"
                                options={[
                                  { value: 'none', label: 'Non payée' },
                                  { value: 'partial', label: 'Partiel' },
                                  { value: 'paid', label: 'Payée' }
                                ]}
                                value={paid}
                                disabled={
                                  status === 'groupDraft' ||
                                  isCampaignSetupPending
                                }
                                onChange={e => {
                                  setPaid(e);
                                }}
                              />
                            ) : (
                              <Select
                                title="Paiement*"
                                options={[
                                  { value: 'false', label: 'Non Payée' },
                                  { value: 'true', label: 'Payée' }
                                ]}
                                value={paid}
                                disabled={
                                  status === 'draft' || isCampaignSetupPending
                                }
                                onChange={e => {
                                  setPaid(e);
                                }}
                              />
                            )}
                          </>
                        )}
                      </Item>
                    </Row>
                  </Container>
                </Item>
                {customer?.activablePurchaseOrder && (
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            error={purchaseNumberError}
                            disabled={
                              campaign.status !== 'draft' &&
                              campaign.status !== 'groupDraft'
                            }
                            onChange={e => {
                              setPurchaseNumber(e?.trim());
                            }}
                            placeholder="Numéro de commande"
                            value={purchaseNumber}
                            title="Renseignez un n° de bon de commande"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                )}
              </Row>

              <Row spacing={1}>
                <Item
                  xs={6}
                  justify="flex-start"
                  style={{ alignItems: 'start' }}
                >
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Button
                          disabled={
                            campaign.isCampaignGroup
                              ? campaign.status !== 'groupDraft'
                              : campaign.status !== 'draft'
                          }
                          variant="contained"
                          color={themeColors.delete}
                          widthSize="large"
                          style={{
                            height: '40px',
                            width: '100%',
                            marginBottom: '6px'
                          }}
                          onClick={() => {
                            setOpenModalDelete(true);
                          }}
                        >
                          <Text bold color="inherit">
                            Supprimer la campagne
                          </Text>
                        </Button>
                      </Item>
                    </Row>
                  </Container>
                </Item>
                <Item xs={6} justify="flex-end" style={{ alignItems: 'end' }}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Text style={{ marginRight: '10px' }}>
                          Split de la campagne
                        </Text>
                        <Switch
                          disabled={isCampaignSetupPending}
                          checked={split}
                          onChange={() => {
                            setSplit(!split);
                          }}
                          options={['Non', 'Oui']}
                        />
                      </Item>
                    </Row>
                  </Container>
                </Item>
              </Row>
            </Container>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection title="Tags" xs={6}>
            <Container>
              <Row justify="flex-start" spacing={1}>
                <Text justify="flex-start" fontWeight={400} fontSize="14px">
                  Sélectionnez des tags pour votre campagne, ou écrivez-en un
                  nouveau puis appuyez sur <strong>Entrée</strong>. Pour une
                  multicampagne, les tags seront copiés à l’ensemble de ses
                  campagnes enfants.
                </Text>
              </Row>
            </Container>
            <Container>
              <Row spacing={1}>
                <Item xs={12} justify="flex-start">
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        {loaded && (
                          <ListField
                            disabled={isCampaignSetupPending}
                            options={config.tags
                              .sort()
                              .map(el => ({ tag: el }))}
                            buttonBgColor={themeColors.grey_light_button}
                            buttonTextColor={themeColors.black}
                            values={tags.map(el => ({ tag: el }))}
                            onChange={e => {
                              setTags(e.map(({ tag }) => tag));
                            }}
                            searchOptionsKeys={['tag']}
                            actionTxt="Ajouter un tag"
                            deleteAction
                            keyEnter
                          />
                        )}
                      </Item>
                    </Row>
                  </Container>
                </Item>
                <Item xs={6} />
              </Row>
            </Container>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection
            title={
              <Container>
                <Row spacing={1} alignItems="center">
                  <Item xs={2} justify="flex-start">
                    <Text variant="h6" bold>
                      Funnel
                    </Text>
                  </Item>
                  <Item
                    xs={10}
                    justify="flex-start"
                    style={{ paddingLeft: '24px', width: '360px' }}
                  >
                    <AutocompleteTextField
                      options={config.funnels}
                      formatLabel={option => {
                        let str = option[searchOptionsKeys[0]];
                        if (searchOptionsKeys[1]) {
                          str += ` (${option[searchOptionsKeys[1]]})`;
                        }
                        return str;
                      }}
                      small
                      value={config.funnels.find(el => el.id === funnelId)}
                      onChange={onAutoCompliteChange}
                      placeholder="Funnel leads"
                      disabled={status === 'draft' || isCampaignSetupPending}
                    />
                  </Item>
                </Row>
              </Container>
            }
            xs={6}
          >
            <Container>
              <Row spacing={1}>
                <Text fontWeight={400} fontSize="14px">
                  Sélectionnez le funnel que vous souhaitez relier à la campagne
                  et lancer vos setup. Par défaut, le funnel créé
                  automatiquement lors de la validation de la campagne est lié.
                </Text>
              </Row>
            </Container>
            <Container style={{ marginTop: '10px' }}>
              <Row spacing={1} justify="space-evenly">
                <Item justify="flex-start" flex xs={1}>
                  <Tooltip title="Vous devez avoir un funnel lié à votre campagne pour pouvoir setuper les connecteurs.">
                    <Button
                      disabled={!funnelId || isCampaignSetupPending}
                      variant="contained"
                      color={themeColors.funnel}
                      widthSize="large"
                      onClick={setupConnectors}
                    >
                      <Text color="inherit">Setuper les connecteurs</Text>
                    </Button>
                    <StatusText status={funnelStatus} item={'connectors'} />
                  </Tooltip>
                </Item>
                <Item justify="flex-start" flex xs={6}>
                  <Tooltip title="Vous devez avoir exporté votre audience ou avoir setupé automatiquement votre campagne avant de pouvoir créer sa BRD.">
                    <Button
                      disabled={
                        !funnelId ||
                        disableDataFeedback ||
                        isCampaignSetupPending
                      }
                      variant="contained"
                      color={themeColors.funnel}
                      onClick={setupDataFeedback}
                      widthSize="large"
                    >
                      <Text color="inherit">
                        Setuper la boucle de retour data
                      </Text>
                    </Button>
                    <StatusText status={brdStatus} item={'brd'} />
                  </Tooltip>
                </Item>
              </Row>
            </Container>
          </FormSection>
        </Item>
        <Item xs={6} />
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection title="Connecteur analytics" xs={6}>
            <Container>
              <Row justify="flex-start" spacing={1}>
                <Text justify="flex-start" fontWeight={400} fontSize="14px">
                  Faites remonter des données analytics comme résultat de
                  campagne en KPI 2/3/4 ici.
                </Text>
                <Text justify="flex-start" fontWeight={400} fontSize="14px">
                  <strong>Attention :</strong> pour croiser ces données avec
                  l'audience de votre campagne, une boucle de retour
                </Text>
                <Text justify="flex-start" fontWeight={400} fontSize="14px">
                  data setupée est nécessaire.
                </Text>
              </Row>
            </Container>
            <Container>
              <Row justify="flex-start" spacing={1}>
                <ConnectorsListField
                  AnalyticsOnly
                  value={connectors}
                  onChange={handleChangeConnectors}
                  importModel={datas.importModel}
                  configGenerators={datas.configGenerators}
                  configConnexion={datas.configConnexion}
                />
              </Row>
            </Container>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item justify="flex-start">
          <FormSection title="Accès à la campagne" xs={3}>
            <Container>
              <Row spacing={1}>
                <Item xs={12} justify="flex-start">
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        {loaded && (
                          <ListField
                            disabled={isCampaignSetupPending}
                            options={allUsers.map(item => ({
                              _id: item._id,
                              name: item.name
                            }))}
                            buttonBgColor={themeColors.grey_light_button}
                            buttonTextColor={themeColors.black}
                            searchOptionsKeys={['name', '_id']}
                            values={usersSelected}
                            onChange={e => {
                              setUsersSelected(e);
                            }}
                            actionTxt="Ajouter"
                            deleteAction
                          />
                        )}
                      </Item>
                    </Row>
                  </Container>
                </Item>
                <Item xs={6} />
              </Row>
            </Container>
          </FormSection>
          {openModalDelete && (
            <ModalDelete
              open
              onClose={() => {
                setOpenModalDelete(false);
              }}
              onDelete={() => {
                setOpenModalDelete(false);
                onDelete();
              }}
            />
          )}
        </Item>
      </Row>
      <Row
        spacing={1}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '50px'
        }}
      >
        <Item justify="flex-start" flex>
          <Tooltip
            title={setupPendingTooltip}
            disabled={!isCampaignSetupPending}
          >
            <Button
              disabled={isCampaignSetupPending}
              variant="contained"
              size="medium"
              color={themeColors.black}
              onClick={() => {
                validate();
              }}
            >
              <Icon icon="right" />
              <Text fontSize="15px" color="white" style={{ marginLeft: '5px' }}>
                Valider
              </Text>
            </Button>
          </Tooltip>
        </Item>
      </Row>
    </Container>
  );
};

ModalGestion.defaultProps = {
  funnels: [],
  config: {
    funnels: [],
    users: [],
    groups: [],
    tags: []
  },
  onValidate: () => {},
  onDelete: () => {}
};

ModalGestion.propTypes = {
  config: PropTypes.shape({
    funnels: PropTypes.arrayOf(PropTypes.shape()),
    users: PropTypes.arrayOf(PropTypes.shape()),
    groups: PropTypes.arrayOf(PropTypes.shape()),
    tags: PropTypes.arrayOf(PropTypes.string)
  }),
  campaign: PropTypes.shape().isRequired,
  funnels: PropTypes.arrayOf(PropTypes.shape()),
  onValidate: PropTypes.func,
  onDelete: PropTypes.func
};

export default ModalGestion;
