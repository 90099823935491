// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Radio as RadioComp } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const Radio = props => {
  const {
    checked,
    color,
    disabled,
    onChange,
    size,
    show,
    value,
    ...rest
  } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    root: {
      '& svg': {
        color: checked ? colorRules : themeColors.light
      }
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <RadioComp
        checked={checked}
        color="default"
        className={classes.root}
        disabled={disabled}
        onChange={onChange}
        size={size}
        value={value}
        {...rest}
      />
    </Fade>
  );
};

Radio.defaultProps = {
  checked: null,
  color: null,
  disabled: false,
  size: 'medium',
  show: null,
  value: null
};

Radio.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Radio;
