export default {
  global: 'global',
  audience: 'audience',
  activation: 'activation',
  campaign: 'campaign',
  funnel: 'funnel',
  // ramses: 'ramses',
  pml: 'pml',
  llm: 'llm'
};
