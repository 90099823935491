import { adminRights } from 'utils/constants';

import { api } from './api';

const getRightsById = async userId => {
  try {
    const { data } = await api.get(`/adminRights/user/${userId}`);
    return data;
  } catch (err) {
    console.error(err);
    return [{ rights: { ...adminRights } }];
  }
};

const getPresetList = async () => {
  try {
    const { data } = await api.get(`/adminRightsPreset`);
    return data;
  } catch (e) {
    console.error('adminrights getPresetList', e);
    return [];
  }
};

export default { getRightsById, getPresetList };
