// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Divider as DividerMui } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Color from 'color';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const Divider = props => {
  const { color, show, noFade, style } = props;
  const { panelColor, themeColors, isThemeLight } = useTheme();

  const colorRules =
    themeColors[color] || themeColors[panelColor] || themeColors.light;
  const fadeLv = isThemeLight ? 0.7 : 0.9;
  const useStyles = makeStyles({
    divider: {
      backgroundColor: Color(colorRules)
        .fade(noFade ? 0 : fadeLv)
        .rgb()
        .string()
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <DividerMui className={classes.divider} style={style} {...props} />
    </Fade>
  );
};

Divider.defaultProps = {
  color: null,
  show: null,
  noFade: false,
  style: null
};
Divider.propTypes = {
  color: PropTypes.string,
  noFade: PropTypes.bool,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  style: PropTypes.objectOf(PropTypes.any)
};

export default Divider;
