import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Divider, Text } from 'components/DataDisplay';
import { Item } from 'components/Layout';

export const InvoiceItem = ({
  label,
  isBoldLabel,
  amount,
  showItem,
  paddingLeft
}) => {
  if (!showItem) return null;

  return (
    <Item justify="flex-start" style={{ paddingLeft }}>
      <Item flex>
        <Text fontWeight={isBoldLabel ? 700 : 400}>{label}</Text>
      </Item>
      <Item
        xs
        justify="flex-start"
        style={{
          paddingRight: '5px',
          paddingLeft: '5px'
        }}
      >
        <Divider
          color="light"
          noFade
          style={{
            width: '100%',
            height: '0.5px'
          }}
        />
      </Item>
      <Item flex>
        <Text fontWeight={700}>{`${numeral(amount).format('0,0 $')}`}</Text>
      </Item>
    </Item>
  );
};

InvoiceItem.defaultProps = {
  isBoldLabel: false,
  showItem: true
};

InvoiceItem.propTypes = {
  label: PropTypes.string.isRequired,
  isBoldLabel: PropTypes.bool,
  showItem: PropTypes.bool,
  amount: PropTypes.shape().isRequired
};

export default InvoiceItem;
