import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Checkbox, Editor, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import { MessageContents } from '../MessageContents';

import '../../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ErrorState = {
  titleError: false
};

export const ModalForm = props => {
  const { onValidate, config, action } = props;
  const initialState = {
    title: config.title,
    isDefault: !!config.isDefault,
    isOnTop: !!config.isOnTop,
    selectedUsers: config?.userIds
      ? config.users.reduce((acc, el) => {
          if (config.userIds.indexOf(el._id) !== -1) {
            acc.push({
              email: el.email,
              _id: `${el.family_name} ${el.given_name}`,
              id: el._id
            });
          }
          return acc;
        }, [])
      : []
  };

  const getContents = items => {
    if (!items || !items.length) {
      return [
        EditorState.createEmpty(),
        EditorState.createEmpty(),
        EditorState.createEmpty()
      ];
    }

    return items.map(content => {
      const contentBlock = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    });
  };

  const [
    { title, isDefault, isOnTop, selectedUsers },
    setState
  ] = React.useState(initialState);
  const [{ titleError }, setError] = useState(ErrorState);
  const [contents, setContents] = useState(getContents(config.contents));
  const [selected, setSelected] = useState(0);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => (event, newValue) => {
    let value = typeof event === 'string' ? event.trim() : event;
    if (field !== 'checkbox') {
      toggleError(itemKey, !value);
    } else {
      value = newValue;
    }
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = value;
      return states;
    });
  };

  const handleChangeUsers = value => {
    setState(prevState => ({ ...prevState, selectedUsers: value }));
  };

  const onEditorStateChange = index => value => {
    const newValue = [...contents];
    newValue[index] = value;
    setContents(newValue);
  };

  const resetStates = () => {
    setState(initialState);
  };

  const getHtml = state => draftToHtml(convertToRaw(state.getCurrentContent()));

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!title) {
      toggleError('title', true);
      error = true;
    }

    const userIds = selectedUsers.map(el => el.id);
    if (!error) {
      const datas = {
        title,
        contents: contents.map(el => getHtml(el)),
        isDefault,
        isOnTop,
        userIds
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {action || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} maxWidth="xl" {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={2}>
                  <Item xs={6} justify="flex-start">
                    <Text>Titre</Text>
                    <TextField
                      small
                      value={title}
                      onChange={handleChange('text', 'title')}
                      error={titleError}
                    />
                  </Item>
                  <Item xs={5} justify="flex-end">
                    <Row spacing={0}>
                      <Item xs justify="flex-start">
                        <Text>Message par défaut</Text>
                      </Item>
                      <Item xs={6}>
                        <Checkbox
                          small
                          checked={isDefault}
                          onChange={handleChange('checkbox', 'isDefault')}
                        />
                      </Item>
                    </Row>
                    <Row spacing={0}>
                      <Item xs justify="flex-start">
                        <Text>Positionnement</Text>
                      </Item>
                      <Item xs={6}>
                        <Text>on Bottom </Text>
                        <Switch
                          small
                          checked={isOnTop}
                          onChange={() =>
                            handleChange('checkbox', 'isOnTop')(
                              !isOnTop,
                              !isOnTop
                            )
                          }
                        />
                        <Text>On Top</Text>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Message">
              <Container spacing={2}>
                <MessageContents
                  contents={contents}
                  modeEdit
                  selected={selected}
                  setSelected={setSelected}
                />
                <Row
                  spacing={0}
                  style={{ minHeight: '460px' }}
                  alignItems="flex-start"
                >
                  <Item xs={11} justify="flex-start">
                    <Editor
                      editorState={contents[selected]}
                      onEditorStateChange={onEditorStateChange(selected)}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {!isDefault && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="Utilisateurs" xs={3}>
                <Container>
                  <Row spacing={0} justify="flex-start">
                    <Item justify="flex-start">
                      <ListField
                        options={config.users.map(el => ({
                          email: el.email,
                          _id: `${el.family_name} ${el.given_name}`,
                          id: el._id
                        }))}
                        searchOptionsKeys={['email', '_id']}
                        values={selectedUsers}
                        onChange={handleChangeUsers}
                        actionTxt="Ajouter un utilisateur"
                        deleteAction
                      />
                    </Item>
                  </Row>
                </Container>
              </FormSection>
            </Item>
          </Row>
        )}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  action: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  action: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
