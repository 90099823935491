// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Checkbox as CheckboxComp } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

export const Checkbox = props => {
  const {
    checked,
    color,
    disabled,
    icon,
    checkedIcon,
    indeterminateIcon,
    indeterminate,
    onChange,
    size,
    show,
    value,
    title,
    required,
    tooltip,
    ...rest
  } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const getColor = ({
    checked: checkedColor = null,
    indeterminate: indeterminateColor = null,
    colorRules: colorR
  }) => {
    if (checkedColor || indeterminateColor) {
      return colorR;
    }
    if (disabled) {
      return themeColors.funnel_light_grey;
    }
    return themeColors.light;
  };

  const useStyles = makeStyles({
    root: {
      padding: '0px',
      '& svg': {
        color: getColor({ checked, indeterminate, colorRules })
      }
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      {title && (
        <Row spacing={2} justify="flex-start" alignItems="center">
          <Item
            justify="flex-start"
            flex
            style={{
              maxWidth: tooltip ? 'calc(100% - 24px)' : null
            }}
          >
            <Text noWrap fontSize="14px">
              {title === '&nbsp;' ? <>&nbsp;</> : title}
              {required && '*'}
            </Text>
          </Item>
          {tooltip && (
            <Item flex style={{ alignItem: 'center' }}>
              <HelpTooltip title={tooltip} />
            </Item>
          )}
        </Row>
      )}
      <CheckboxComp
        checked={checked}
        color="default"
        className={classes.root}
        disabled={disabled}
        onChange={onChange}
        size={size}
        icon={icon}
        checkedIcon={checkedIcon}
        indeterminate={indeterminate}
        indeterminateIcon={indeterminateIcon}
        value={value}
        {...rest}
      />
    </Fade>
  );
};

Checkbox.defaultProps = {
  checked: null,
  color: null,
  disabled: false,
  icon: <CheckBoxOutlineBlankIcon />,
  checkedIcon: <CheckBoxIcon />,
  indeterminateIcon: <IndeterminateCheckBoxIcon />,
  size: 'medium',
  show: null,
  value: null,
  title: null,
  tootltip: null,
  required: false
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  checkedIcon: PropTypes.node,
  indeterminateIcon: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  tootltip: PropTypes.string,
  required: PropTypes.bool
};

export default Checkbox;
