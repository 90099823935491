import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { shortMonthToFull } from 'utils/functions';
import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const SeasonalityContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [seasonalityList, setseasonalityList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedSeasonality, setSelectedseasonality] = useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectseasonality = id => {
    const selected = seasonalityList.find(el => el._id === id);
    setSelectedseasonality(selected);
  };
  const closeModal = () => {
    setModal(null);
    setSelectedseasonality({});
  };

  const getseasonality = async () => {
    try {
      const result = await ACTIVATION.getSeasonality(currentPresetId);
      return result;
    } catch (err) {
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectseasonality(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateSeasonality(selectedSeasonality._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  useEffect(() => {
    if (!loaded) {
      getseasonality().then(seasonalities => {
        setLoaded(true);
        const mapped = seasonalities.map(item => {
          const newItem = {};
          newItem._id = item._id;
          newItem.month = shortMonthToFull(item.month);
          newItem.value = item.value;
          return newItem;
        });
        setseasonalityList(seasonalities);
        setRows(mapped);
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Mois',
                  id: 'month'
                },
                {
                  label: 'Coefficient',
                  id: 'value'
                }
              ]}
              hidenFields={['","id']}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              selectedSeasonality={selectedSeasonality}
              actionName="Modifier le média"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default SeasonalityContainer;
