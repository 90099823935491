import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

export const AutocompleteTextField = ({
  color,
  error,
  label,
  placeholder,
  show,
  autoComplete,
  autoHighlight,
  autoSelect,
  options,
  exclude,
  onChange,
  formatLabel,
  small,
  defaultValue,
  value,
  disableClearable,
  disabled,
  bgColor,
  title,
  keyEnter,
  functionKeyEnter,
  required,
  renderOption,
  tooltip
}) => {
  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    root: {
      '& .MuiInputBase-root': {
        color: themeColors.light,
        backgroundColor: bgColor || 'transparent'
      },
      '& .MuiInputAdornment-root p': {
        color: themeColors.light
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <Autocomplete
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        autoHighlight={autoHighlight}
        autoSelect={autoSelect}
        disableClearable={disableClearable}
        className={classes.root}
        options={options.filter(f => !exclude(f))}
        onChange={(_, valueChange, reason) => {
          return onChange(valueChange, reason);
        }}
        getOptionLabel={formatLabel}
        style={{ width: '100%' }}
        onKeyDown={e => {
          if (e.key === 'Enter' && keyEnter) {
            functionKeyEnter(e.target.value);
          }
        }}
        renderOption={renderOption}
        disabled={disabled}
        renderInput={params => (
          <>
            {title && (
              <Row spacing={0} justify="flex-start" alignItems="center">
                <Item justify="flex-start">
                  <Text noWrap color={error ? 'error' : null}>
                    {title}
                    {required && '*'}
                  </Text>
                  {tooltip && <HelpTooltip title={tooltip} />}
                </Item>
              </Row>
            )}{' '}
            <TextField
              {...params}
              error={error}
              label={label}
              placeholder={placeholder}
              variant="outlined"
              size={small ? 'small' : 'medium'}
              style={{
                width: '100%',
              }}
            />
          </>
        )}
      />
    </Fade>
  );
};

export default AutocompleteTextField;

AutocompleteTextField.defaultProps = {
  color: null,
  error: false,
  small: true,
  disableClearable: false,
  required: false,
  title: null,
  label: '',
  placeholder: '',
  show: true,
  autoComplete: false,
  autoHighlight: false,
  autoSelect: false,
  options: [],
  defaultValue: undefined,
  value: undefined,
  exclude: () => false,
  onChange: () => null,
  disabled: false,
  formatLabel: option =>
    typeof option === 'string' ? option : 'need formatLabel',
  keyEnter: false,
  functionKeyEnter: () => { },
  renderOption: false,
  tooltip: false
};
AutocompleteTextField.propTypes = {
  color: PropTypes.string,
  error: PropTypes.bool,
  small: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  autoComplete: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  autoSelect: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  exclude: PropTypes.func,
  onChange: PropTypes.func,
  formatLabel: PropTypes.func,
  defaultValue: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.objectOf(PropTypes.any),
  keyEnter: PropTypes.bool,
  functionKeyEnter: PropTypes.func,
  renderOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};
