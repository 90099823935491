import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextareaAutosize } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import Fade from 'components/Fade';

export const TextAera = props => {
  const {
    color,
    show,
    disabled,
    onChange,
    onBlur,
    value,
    format,
    error,
    defaultValue,
    bgColor
  } = props;
  const { themeColors } = useTheme();
  const [valInput, setValInput] = useState(value);

  const handleChange = e => {
    if (valInput !== e.target.value) {
      onChange(format ? e.target.rawValue : e.target.value);
      setValInput(e.target.value);
    }
  };

  const handleBlur = e => {
    onBlur(format ? e.target.rawValue : e.target.value);
    setValInput(e.target.value);
  };

  return (
    <Fade show={show}>
      {error && (
        <Text color="error" style={{ margin: '10px' }}>
          {error}
        </Text>
      )}
      <TextareaAutosize
        style={{
          width: '100%',
          margin: '10px',
          borderColor: error ? themeColors.danger : color,
          backgroundColor: bgColor || 'transparent'
        }}
        disabled={disabled}
        value={valInput}
        rowsMin={3}
        aria-label="minimum height"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={defaultValue}
      />
    </Fade>
  );
};

export default TextAera;

TextAera.defaultProps = {
  value: '',
  onChange: () => null,
  onBlur: () => null,
  disabled: false,
  show: null,
  format: null,
  defaultValue: '',
  color: null,
  error: null,
  bgColor: null
};
TextAera.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  format: PropTypes.string,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.string,
  bgColor: PropTypes.string
};
