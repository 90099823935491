import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  IconButton,
  Select,
  Switch
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import ConnectorsListField from 'components/ConnectorsListField'; 

const formatConfig = data => {
  return data.map(el => {
    if (el.type === 'JSON') {
      return {
        id: el.id,
        name: el.name,
        connexionId: el.connexionId,
        type: 'JSON',
        config: JSON.stringify(el.config, null, 4) || '{}',
        open: el.open,
        disabled: el.disabled
      };
    }
    return el;
  });
};

export const ModalForm = props => {
  const { config, funnel, onValidate, actionName } = props;
  const {
    funnels = [],
    importModel = [],
    allFunnels = [],
    configGenerators = [],
    configConnexion = []
  } = config;

  const { themeColors } = useTheme();
  const [funnelId, setFunnelId] = useState(funnel && funnel?.id);
  const [menu, setMenu] = useState(funnel?.aggregationConfig?.length ? 1 : 0);
  const [clicked, setClicked] = useState(false);

  const initialState = {
    aggregations: funnel?.aggregationConfig || [],
    emailAddress: {
      state: !!funnel.emailAddress,
      value: funnel.emailAddress?.address || ''
    },
    dailyCompute: funnel?.dailyCompute || false
  };

  const [{ aggregations, emailAddress, dailyCompute }, setState] = useState(
    initialState
  );
  const [connectorConfig, setConnectorConfig] = useState(
    funnel && funnel?.requestConfig ? formatConfig(funnel?.requestConfig) : []
  );
  const [warning, setWarning] = useState(false);
  const [errorFunnelId, setErrorFunnelId] = useState(false);

  const [duplicates, setDuplicates] = useState(
    funnel && funnel?.duplicatesConfig ? funnel.duplicatesConfig : []
  );

  const handleChangeFunnels = value => {
    setState(prevState => ({
      ...prevState,
      aggregations: value
    }));
  };

  const onEmailChange = () => {
    setState(prevState => ({
      ...prevState,
      emailAddress: { ...emailAddress, state: !emailAddress.state }
    }));
  };

  const ondailyCompute = () => {
    setState(prevState => ({
      ...prevState,
      dailyCompute: !dailyCompute
    }));
  };

  const useStyles = makeStyles({
    activeMenu: {
      maxWidth: 'fit-content',
      cursor: 'pointer',
      justifyContent: 'center',
      paddingBottom: '0px!important',
      borderBottom: `4px solid ${themeColors.funnel}`
    },
    defaultMenu: {
      justifyContent: 'center',
      maxWidth: 'fit-content',
      cursor: 'pointer',
      paddingBottom: '0px!important',
      borderBottom: 'none'
    }
  });
  const classes = useStyles();

  const selectOptions = [
    { label: 'Budget', value: 'budget' },
    { label: 'Impressions', value: 'kpi1' },
    { label: 'Visites', value: 'kpi2' },
    { label: 'Engagements', value: 'kpi3' },
    { label: 'Acquisitions', value: 'kpi4' },
    { label: 'Visites par médias', value: 'volumeVisites' },
    { label: 'Clics par médias', value: 'volumeClics' },
    { label: 'Engagements par médias', value: 'volumeEngagements' },
    { label: 'Conversions par médias', value: 'volumeConversions' },
    { label: 'Envoyé par média', value: 'volumeSent' },
    { label: 'Délivré par média', value: 'volumeDelivered' },
    { label: 'Ouvert par média', value: 'volumeOpened' }
  ];

  const getValue = value => {
    const selected = selectOptions.find(el => el.value === value);
    return selected && selected.label;
  };

  const addDuplicata = () => {
    setDuplicates(duplicates.concat({ src: null, target: null }));
  };

  const removeDuplicata = index => {
    setDuplicates(duplicates.filter((_, fIndex) => fIndex !== index));
  };

  const handleDuplicataChange = (data, index, field) => {
    const tmp = duplicates.map((e, mapIndex) => {
      if (mapIndex === index)
        return {
          ...e,
          [field]: data
        };
      return e;
    });
    setDuplicates(tmp);
  };

  const handleChangeConnectors = newConfig => {
    setConnectorConfig(newConfig);
  };

  const handleCheckJson = value => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onNext = async () => {
    let error = false;
    if (!funnelId) {
      setErrorFunnelId(true);
      error = true;
    }
    if (menu === 0) {
      connectorConfig.map(({ type, config: configConnector }) => {
        if (type !== 'JSON' && (!configConnector || !configConnector.length)) {
          error = true;
        }
        if (type === 'JSON' && !handleCheckJson(configConnector)) {
          error = true;
        }
        return type;
      });
    }
    if (!error) {
      setClicked(true);
      if (menu === 0 || aggregations.length === 0)
        onValidate({
          funnelId,
          duplicates: duplicates.filter(el => el.src && el.target),
          emailAddress: emailAddress.state,
          dailyCompute,
          requestConfig: connectorConfig.map(el => {
            return {
              ...el,
              config: el.type === 'JSON' ? JSON.parse(el.config) : el.config
            };
          })
        });
      else {
        onValidate({
          funnelId,
          duplicates: duplicates.filter(el => el.src && el.target),
          emailAddress: emailAddress.state,
          dailyCompute,
          aggregations: aggregations.map(
            funnelAggregated => funnelAggregated._id
          )
        });
      }
    }
  };

  const onValidation = () => {
    if (!emailAddress.state && funnel.emailAddress) {
      setWarning(true);
      return;
    }
    onNext();
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onValidation}
              disabled={
                (menu === 0 && connectorConfig.length === 0) ||
                (menu === 1 && aggregations.length === 0)
              }
              loading={clicked}
            >
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const searchOptionsKeys = ['label', 'value'];

  const onAutoCompliteChange = data => {
    setErrorFunnelId(!data?.value);
    setFunnelId(data?.value);
  };

  return (
    <Modal actions={getActions()} {...props}>
      {warning && (
        <Modal
          open={warning}
          maxWidth="sm"
          onClose={() => setWarning(false)}
          actions={
            <Container>
              <Row spacing={1}>
                <Item xs={6}>
                  <Button
                    variant="outlined"
                    color={themeColors.funnel}
                    onClick={() => setWarning(false)}
                  >
                    Annuler
                  </Button>
                </Item>
                <Item xs={6}>
                  <Button
                    variant="contained"
                    color={themeColors.funnel}
                    onClick={onNext}
                  >
                    Continuer et supprimer l&lsquo;email
                  </Button>
                </Item>
              </Row>
            </Container>
          }
        >
          Vous êtes sur le point de supprimer définitivement l&lsquo;adresse
          email associée à ce funnel. Tous les emails de ce compte seront
          perdus. Etes vous sur de votre choix?
        </Modal>
      )}
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnel">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Funnel *</Text>
                          <AutocompleteTextField
                            options={funnels}
                            formatLabel={option => {
                              let str = option[searchOptionsKeys[0]];
                              if (searchOptionsKeys[1]) {
                                str += ` (${option[searchOptionsKeys[1]]})`;
                              }
                              return str;
                            }}
                            small
                            value={funnels.find(el => el.value === funnelId)}
                            onChange={onAutoCompliteChange}
                            placeholder="Funnel leads"
                            disabled={funnel && funnel.id}
                            error={errorFunnelId}
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row>
          <FormSection title="Duplication des données">
            {duplicates.map((e, index) => (
              <Row spacing={0} justify="space-between">
                <Item xs={4} justify="flex-start">
                  <Text>Dupliquer les données</Text>
                  <Select
                    onChange={data =>
                      handleDuplicataChange(
                        selectOptions.find(f => f.label === data).value,
                        index,
                        'src'
                      )
                    }
                    options={selectOptions.map(el => el.label)}
                    defaultValue="Aucun"
                    value={getValue(duplicates[index].src)}
                    small
                    bgColor={themeColors.primary}
                  />
                </Item>
                <Item xs />
                <Item xs={4} justify="flex-start">
                  <Text>Dans</Text>
                  <Select
                    onChange={data =>
                      handleDuplicataChange(
                        selectOptions.find(f => f.label === data).value,
                        index,
                        'target'
                      )
                    }
                    options={selectOptions.map(
                      selectOption => selectOption.label
                    )}
                    defaultValue="Aucun"
                    value={
                      duplicates[index] && getValue(duplicates[index].target)
                    }
                    small
                    bgColor={themeColors.primary}
                  />
                </Item>
                <Item xs justify="center">
                  <IconButton onClick={() => removeDuplicata(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Item>
              </Row>
            ))}
            <Row spacing={0} justify="flex-start">
              <Item xs={4} justify="flex-start">
                <ButtonCreate
                  onClick={addDuplicata}
                  text="Ajouter une donnée à dupliquer"
                />
              </Item>
            </Row>
          </FormSection>
        </Row>
        <Row>
          <FormSection title="Adresse mail">
            <Row spacing={1}>
              <Item
                flex
                style={{ alignItems: 'center', justifyContent: 'flex-start' }}
              >
                <Text fontWeight={!emailAddress.state ? 600 : 300}>
                  Désactiver
                </Text>
                <Switch checked={emailAddress.state} onChange={onEmailChange} />
                <Text fontWeight={emailAddress.state ? 600 : 300}>Activer</Text>
              </Item>
              <Item xs>
                <Text fontWeight={700}>{emailAddress.value}</Text>
              </Item>
            </Row>
          </FormSection>
          <FormSection title="Mise à jour automatique de la contribution">
            <Row spacing={1}>
              {menu === 0 ? (
                <Item justify="flex-start">
                  <Text fontWeight={!dailyCompute ? 600 : 300}>Désactiver</Text>
                  <Switch checked={dailyCompute} onChange={ondailyCompute} />
                  <Text fontWeight={dailyCompute ? 600 : 300}>Activer</Text>
                </Item>
              ) : (
                <Item justify="flex-start">
                  <Text>
                    La contribution sera calculée selon l’agrégation des
                    contributions des funnels enfants.
                  </Text>
                </Item>
              )}
            </Row>
          </FormSection>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Données">
              <>
                <Row>
                  <Item
                    xs={6}
                    className={
                      menu === 0 ? classes.activeMenu : classes.defaultMenu
                    }
                    onClick={() => setMenu(0)}
                  >
                    <Text variant="h5" bold={menu === 0}>
                      Connecteurs
                    </Text>
                  </Item>
                  <Item
                    xs={6}
                    className={
                      menu === 1 ? classes.activeMenu : classes.defaultMenu
                    }
                    onClick={() => setMenu(1)}
                  >
                    <Text variant="h5" bold={menu === 1}>
                      Consolidation
                    </Text>
                  </Item>
                </Row>
                {menu === 0 ? (
                  <ConnectorsListField
                    importModel={importModel}
                    configGenerators={configGenerators}
                    configConnexion={configConnexion}
                    value={connectorConfig}
                    onChange={handleChangeConnectors}
                  />
                ) : (
                  <Item>
                    <Row spacing={0} justify="flex-start">
                      <Item justify="flex-start">
                        <ListField
                          options={allFunnels
                            .filter(el => el._id !== funnelId)
                            .map(e => ({
                              name: e.name,
                              _id: e._id
                            }))}
                          searchOptionsKeys={['name', '_id']}
                          values={aggregations.map(e => ({
                            name: e.name,
                            _id: e._id
                          }))}
                          onChange={handleChangeFunnels}
                          actionTxt="Ajouter un funnel"
                          deleteAction
                          bgColor={themeColors.primary}
                        />
                      </Item>
                    </Row>
                  </Item>
                )}
              </>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  funnel: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  funnel: PropTypes.objectOf(PropTypes.any),
  actionName: PropTypes.string
};

export default ModalForm;
