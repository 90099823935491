import React from 'react';
import { withRouter } from 'react-router-dom';

import InProgressContainer from 'containers/InProgressContainer';
import UnauthorizedContainer from 'containers/UnauthorizedContainer';

import { Container, Item, Row } from 'components/Layout';
import { Column, Grid, Switch, Wrapper } from 'components/LayoutApp';
import Visibilities from 'components/Visibilities';

import ActivatedAudiencesContainer from './containers/menus/ActivatedAudicences';
import CategoryContainer from './containers/menus/CategoryContainer';
import CriteriaContainer from './containers/menus/CriteriaContainer';
import ExportMediasContainer from './containers/menus/ExportMedias';
import ExportSegmentsContainer from './containers/menus/ExportSegments';
import PartnersContainer from './containers/menus/PartnersContainer';
import TagFunnelContainer from './containers/menus/TagFunnelContainer';
import SidebarContainer from './containers/SidebarContainer';
import { useNavigation } from './contexts/NavigationContext';

const App = () => {
  const { menu, showMenu } = useNavigation();
  const getContainer = () => {
    const menuName = menu.name;
    if (!menu.enabled) {
      return <UnauthorizedContainer />;
    }

    switch (menuName) {
      case 'category':
        return <CategoryContainer />;
      case 'criteria':
        return <CriteriaContainer />;
      case 'tagfunnel':
        return <TagFunnelContainer />;
      case 'partners':
        return <PartnersContainer />;
      case 'activatedAudiences':
        return <ActivatedAudiencesContainer />;
      case 'exportSegments':
        return <ExportSegmentsContainer />;
      case 'exportMedias':
        return <ExportMediasContainer />;
      default:
        return <InProgressContainer text={menuName} />;
    }
  };

  return (
    <Wrapper>
      <Grid>
        <Column>
          <SidebarContainer />
        </Column>
        <Column verticalySpaced>
          <Visibilities>
            <Switch>
              <Container>
                <Row spacing={0}>
                  <Item
                    alignItems="flex-start"
                    show={showMenu}
                    style={{ paddingRight: '8px' }}
                  >
                    {getContainer()}
                  </Item>
                </Row>
              </Container>
            </Switch>
          </Visibilities>
        </Column>
      </Grid>
    </Wrapper>
  );
};

export default withRouter(App);
