export const getOne = () => {
  const newItem = (img, txt) => {
    return { img, txt };
  };
  const list = [
    newItem(
      '/assets/img/equipe/adrien.jpeg',
      `Désolé mais j'ai rendez-vous avec Karine Le Marchand !`
    ),
    newItem(
      '/assets/img/equipe/félix.jpeg',
      `J'ai une erreur sur l'import Cheerz, tu peux regarder mon CSV?`
    ),
    newItem(
      '/assets/img/equipe/fred.jpeg',
      `Je pense qu'il ne faut pas qu'on se fasse trop chier avec cette feature!`
    ),
    newItem(
      '/assets/img/equipe/herve.jpeg',
      `Je suis né le \r\nsqrt(196)-27**(1/3)-0x7ae`
    ),
    newItem(
      '/assets/img/equipe/julien.jpeg',
      `J'ai entendu parler de chouquettes?!`
    ),
    newItem(
      '/assets/img/equipe/marco.jpeg',
      `Qu'est ce que vous êtes en train de faire sur mon produit là?!`
    ),
    newItem(
      '/assets/img/equipe/olivier.jpeg',
      'haha ... haha ... haha. Pourquoi tu racontes ta vie la!'
    ),
    newItem(
      '/assets/img/equipe/omar.jpeg',
      `Est-ce que tu peux m'emprunter ton casque?`
    ),
    newItem(
      '/assets/img/equipe/valentin.jpeg',
      `Il est où le board physique que je réclame depuis mon arrivé chez Ermes? Oui j'en veux un même si je télétravail... je ne vois pas le rapport!`
    ),
    newItem(
      '/assets/img/equipe/vinciane.jpeg',
      `Promis cette semaine je vous envoie le mail #RGPDxx.. hey! mais je n'ai pas consentie pour apparaitre ici!`
    ),
    newItem(
      '/assets/img/equipe/william.jpg',
      `J'ai l'impression qu'on est en train de se croiser les skis!`
    ),
    newItem(
      '/assets/img/equipe/helene.jpeg',
      `Charles...est-ce que tu peux effacer les données de mon funnel en prod?`
    ),
    newItem(
      '/assets/img/equipe/nathalie.jpeg',
      `On va reprendre les verres du mercredi soir mais le jeudi soir!`
    ),
    newItem(
      '/assets/img/equipe/adam.jpeg',
      `Alors moi hier j'ai... mais il m'a fait une queue de poisson ce batard!`
    ),
    newItem(
      '/assets/img/equipe/charles.jpeg',
      `Bon on va faire comme ça et mort au con!`
    )
    // newItem(
    //   '/assets/img/equipe/yann.jpeg',
    //   ``
    // )
  ];

  // get random index

  return list[Math.floor(Math.random() * list.length)];
};

export default getOne;
