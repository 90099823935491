import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

export const ModalSetupOption = props => {
  const {
    onValidate,
    onClose,
    actionName,
    domainConfigs,
    measureConfigs,
    tarifications,
    campaignOption
  } = props;

  const { themeColors } = useTheme();

  const initialState = {
    id: campaignOption?.id || null,
    name: campaignOption?.name || '',
    active: !!campaignOption?.active,
    description: campaignOption?.description || '',
    tarification: campaignOption?.tarification || [],
    tarif: campaignOption?.tarif || '',
    domains: campaignOption?.domains || [],
    measureTypes: campaignOption?.measureTypes || []
  };

  const ErrorState = {
    nameError: false,
    tarificationError: false,
    tarifError: false
  };

  const [
    {
      id,
      name,
      active,
      description,
      tarification,
      tarif,
      domains,
      measureTypes
    },
    setState
  ] = useState(initialState);

  const [{ nameError, tarificationError, tarifError }, setError] = useState(
    ErrorState
  );
  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const doAction = () => {
    let error = false;

    if (!name) {
      toggleError('name', true);
      error = true;
    }
    if (!tarification.length) {
      toggleError('tarification', true);
      error = true;
    }
    if (!tarif) {
      toggleError('tarif', true);
      error = true;
    }
    if (!error) {
      const datas = {
        name,
        active,
        description,
        tarification,
        tarif,
        measureTypes,
        id
      };

      onValidate({
        ...datas,
        domains: domains.map(({ key }) => key),
        measureTypes: measureTypes.map(({ key }) => key)
      }).then(resetStates);
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.backoffice.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              <SaveIcon style={{ marginRight: '10px' }} />
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const computeTarifPlaceholder = () => {
    if (Array.isArray(tarification)) {
      return '10';
    }
    if (tarification === 'fix') {
      return '10€';
    }
    return '10%';
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Nom</Text>
                          <TextField
                            required
                            value={name}
                            placeholder="Kairos file..."
                            error={nameError}
                            onChange={handleChange('name')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={3} />
                  <Item xs={3} justify="flex-end" style={{ alignItems: 'end' }}>
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={12} justify="flex-start">
                          <Switch
                            options={['inactif', 'actif']}
                            required
                            checked={active}
                            onChange={handleChange('active')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={12} justify="flex-start">
                    <Item justify="flex-start">
                      <Text>Description</Text>
                      <TextField
                        small
                        multiline
                        rows={5}
                        placeholder="Quel est la plus value de cette option ?"
                        value={description}
                        onChange={handleChange('description')}
                      />
                    </Item>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Tarif" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Tarification</Text>
                          <Select
                            options={tarifications}
                            value={tarification}
                            error={tarificationError}
                            onChange={handleChange('tarification')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Tarif</Text>
                          <TextField
                            required
                            value={tarif}
                            placeholder={computeTarifPlaceholder()}
                            error={tarifError}
                            onChange={handleChange('tarif')}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Domaines" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={12} justify="flex-start">
                    <Text color={themeColors.black}>
                      Par défaut, l’option est disponible pour tous les
                      domaines, sauf si des domaines spécifiques sont ajoutés
                      ci-dessous.
                    </Text>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start" style={{ marginLeft: '-24px' }}>
                    <ListField
                      options={domainConfigs.map(domainConfig => ({
                        key: domainConfig.domain,
                        label: domainConfig.name
                      }))}
                      searchOptionsKeys={['label']}
                      values={domains}
                      onChange={handleChange('domains')}
                      actionTxt="Ajouter un domaine"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Types de funnel" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={12} justify="flex-start">
                    <Text color={themeColors.black}>
                      Par défaut, l’option est disponible pour toutes les
                      campagnes, sauf si des types de funnels spécifiques sont
                      ajoutés ci-dessous.
                    </Text>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start" style={{ marginLeft: '-24px' }}>
                    <ListField
                      options={measureConfigs.map(el => ({
                        key: el.key,
                        label: el.target
                      }))}
                      searchOptionsKeys={['label']}
                      values={measureTypes}
                      onChange={handleChange('measureTypes')}
                      actionTxt="Ajouter un type de funnel"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalSetupOption.defaultProps = {
  onClose: () => null,
  actionName: '',
  domainConfigs: [],
  measureConfigs: [],
  tarifications: []
};
ModalSetupOption.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  actionName: PropTypes.string,
  domainConfigs: PropTypes.arrayOf(),
  measureConfigs: PropTypes.arrayOf(),
  tarifications: PropTypes.arrayOf()
};

export default ModalSetupOption;
