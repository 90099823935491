import React from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { SCHEMASTEP } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const SchemaStepContainer = () => {
  const [rows, setRows] = React.useState([]);
  const [allRows, setAllRows] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [schemaStepList, setschemaStepList] = React.useState([]);
  const [selectedSchemaStep, setSelectedSchemaStep] = React.useState({});

  const { themeColors } = useTheme();
  const { adminRights, user } = useUser();

  const LANGUAGE = user.language;

  const selectSchemaStep = id => {
    const schemaStep = schemaStepList.find(el => el._id === id);
    setSelectedSchemaStep(schemaStep);
    return schemaStep;
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatExplanation = explanation => {
    const nbExplanation = explanation?.length;

    if (!nbExplanation) {
      return <Text />;
    }
    const str = explanation;

    const tooltipContent = () => {
      return (
        <Container>
          <Item>{getToolTipText(explanation)}</Item>
        </Container>
      );
    };

    return (
      <Container>
        <Row>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text
                style={{
                  width: '120px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {str}
              </Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const closeModal = () => {
    setModal(null);
    selectSchemaStep({});
  };

  const onCreate = data => {
    SCHEMASTEP.create(data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const onDelete = () => {
    SCHEMASTEP.deleteSchemaStep(selectedSchemaStep._id)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const openCreateModal = async () => {
    if (adminRights?.funnel?.funnelStep?.create) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const openUpdateModal = id => {
    if (adminRights?.funnel?.funnelStep?.update) {
      selectSchemaStep(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openModalDelete = id => {
    if (adminRights?.funnel?.funnelStep?.delete) {
      selectSchemaStep(id);
      setModal('ModalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onUpdate = data => {
    SCHEMASTEP.update(selectedSchemaStep._id, data)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const SchemaStep = async () => {
    try {
      const result = await SCHEMASTEP.get();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  if (!loaded) {
    SchemaStep().then(resp => {
      setLoaded(true);
      setschemaStepList(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.title = item?.title?.[LANGUAGE];
        newItem.key = item.key;
        newItem.kpi = item.kpi;
        newItem.name = item?.name?.[LANGUAGE];
        newItem.shortName = item?.shortName?.[LANGUAGE];
        newItem.explanation = formatExplanation(item?.explanation?.[LANGUAGE]);
        return newItem;
      });
      setRows(mapped);
      setAllRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche une étape de funnel"
            placeholder="Label, Libellé, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreateModal} text="Ajouter" />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              actionName="Créer"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'label (Unique)',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: `Libellé de l'étape`,
                  id: 'name'
                },
                {
                  label: 'Libellé simplifié',
                  id: 'shortName'
                },
                {
                  label: 'Libellé du kpi',
                  id: 'title',
                  sortKey: 'title'
                },
                {
                  label: 'KPI associé',
                  id: 'kpi',
                  sortKey: 'kpi'
                },
                {
                  label: 'Description',
                  id: 'explanation'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdateModal}
              onDelete={openModalDelete}
            />
          )}
          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedSchemaStep}
              actionName="Modifier"
            />
          )}
          {openModal === 'ModalDelete' && (
            <ModalDelete
              name={selectedSchemaStep.title?.[LANGUAGE]}
              type="ce paramètre"
              open={openModal === 'ModalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default SchemaStepContainer;
