import React from 'react';
import PropTypes from 'prop-types';
import MuiAvatar from '@mui/material/Avatar';

import Fade from 'components/Fade';

export const Avatar = ({ name, show }) => {
  const stringToColor = string => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      // eslint-disable-next-line no-bitwise
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      // eslint-disable-next-line no-bitwise
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = str => {
    return {
      style: {
        backgroundColor: stringToColor(str)
      },
      children: `${str.split(' ')[0][0]}${str.split(' ')[1][0]}`.toUpperCase()
    };
  };

  return (
    <Fade show={show}>
      <MuiAvatar {...stringAvatar(name)} />
    </Fade>
  );
};

Avatar.defaultProps = {
  show: null
};
Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export default Avatar;
