import React from 'react';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { useTheme } from 'contexts/ThemeContext';

import { Container, Item, Row } from 'components/Layout';
import { Paper } from 'components/Surfaces';

export const MessageContents = ({
  contents,
  selected = null,
  setSelected = () => {},
  modeEdit = false
}) => {
  const getHtml = state => {
    return draftToHtml(convertToRaw(state.getCurrentContent()));
  };
  const { themeColors } = useTheme();

  return (
    <Row
      spacing={1}
      style={{
        minHeight: modeEdit ? '100px' : null,
        backgroundColor: themeColors.primary,
        paddingBottom: '20px'
      }}
    >
      {contents.map((item, index) => (
        <Item
          xs={4}
          alignItems="stretch"
          onClick={() => {
            setSelected(index);
          }}
        >
          <Paper
            elevation={0}
            bgColor={themeColors.keep_light}
            style={{
              borderRadius: '10px',
              flex: 1,
              border:
                index === selected ? `2px solid ${themeColors.global}` : 'none'
            }}
          >
            <Container>
              <Row spacing={4}>
                <Item>
                  <div
                    style={{ width: '100%', textAlign: 'center' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: modeEdit ? getHtml(item) : item
                    }}
                  />
                </Item>
              </Row>
            </Container>
          </Paper>
        </Item>
      ))}
    </Row>
  );
};

export default MessageContents;
