import React, { useEffect, useState } from 'react';
import IntervalTimer from 'react-interval-timer';

import { useApp } from 'contexts/AppContext';
import { useNotif } from 'contexts/NotifContext';
import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import {
  CONFIGCONNECTOR,
  CONNEXION,
  CSVCONFIG,
  DUPLICATES,
  FILEUPLOAD,
  FUNNEL,
  FUNNEL_UPDATE_STATUS,
  IMPORTMODEL,
  PROPERTIES
} from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import ModalLogs from 'components/ModalLogs';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';
import { ModalDeleteData } from '../../../components/ModalDeleteData';

import { ModalForm } from './components/ModalForm';

const findPropertie = (id, properties) => {
  for (const propertie of properties) {
    for (const funnelId of propertie.funnelIds) {
      if (funnelId === id) {
        return propertie.name;
      }
    }
  }
  return 'Autre';
};

const formatConnection = listConnexion => {
  const tabConnexion = [];
  for (const item of listConnexion) {
    if (item.model === 'googleAnalytics') {
      tabConnexion.push(
        {
          value: item.value,
          model: 'googleAnalytics',
          label: item.label
        },
        {
          value: item.value,
          model: 'googleAnalyticsKPI2',
          label: item.label
        },
        {
          value: item.value,
          model: 'googleAnalyticsKPI3',
          label: item.label
        },
        {
          value: item.value,
          model: 'googleAnalyticsKPI4',
          label: item.label
        }
      );
    } else {
      tabConnexion.push(item);
    }
  }
  return tabConnexion;
};

const ConnectorConfig = () => {
  const { adminRights } = useUser();
  const { notify } = useNotif();
  const { searchFieldValue, setSearchFieldValue } = useApp();
  const [loaded, setLoaded] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [openModal, setOpenModal] = useState('');
  const [selectedFunnel, setSelectedFunnel] = useState({});
  const [computings, setComputings] = React.useState([]);
  const [updateStatus, setUpdateStatus] = useState([]);
  const [selectedUpdateStatus, setSelectedUpdateStatus] = useState([]);
  const [allFunnels, setAllFunnels] = React.useState([]);

  const [configImportModel, setConfigImportModel] = useState([]);
  const [configGenerators, setConfigGenerators] = useState([]);
  const [configFunnelDispo, setConfigFunnelDispo] = useState([]);
  const [configConnexion, setConfigConnexion] = useState([]);

  const openCreateModal = () => {
    if (adminRights?.funnel?.connectors?.create) {
      setOpenModal('ModalCreate');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const openLogsModal = async item => {
    setSelectedFunnel(item);
    const logs = await FUNNEL_UPDATE_STATUS.getByFunnelId(item.id, 'true');
    setSelectedUpdateStatus(logs);
    setOpenModal('modalLogs');
  };

  const openUpdateModal = id => {
    if (adminRights?.funnel?.connectors?.update) {
      const funnelSelect = allRows.find(el => el.id === id);
      setSelectedFunnel(funnelSelect);
      setOpenModal('modalUpdate');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const openDeleteModal = (id, item) => {
    if (adminRights?.funnel?.connectors?.create) {
      setSelectedFunnel(item);
      setOpenModal('modalDelete');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const openDeleteDataModal = (id, item) => {
    if (adminRights?.funnel?.funnel?.delete) {
      setSelectedFunnel(item);
      setOpenModal('modalDeleteData');
    } else {
      setOpenModal('unauthorized');
    }
  };

  const closeModal = () => {
    setSelectedFunnel({});
    setOpenModal('');
  };

  const onDelete = async () => {
    // Partie JSON
    const requestConfigJson = selectedFunnel.requestConfig.filter(
      el => el.type === 'JSON'
    );
    await Promise.all(
      requestConfigJson.map(async el => {
        await CONFIGCONNECTOR.deleteConfigConnector(el.id);
      })
    );
    // Partie CSV
    const requestConfigCsv = selectedFunnel.requestConfig.filter(
      el => el.type === 'CSV'
    );
    await Promise.all(
      requestConfigCsv.map(async ({ config }) => {
        await Promise.all(
          config.map(async el => {
            await FILEUPLOAD.deleteUpload({ path: `${el.path}` });
            await CSVCONFIG.deleteConfig(el._id);
          })
        );
      })
    );
    // Partie Custom
    const requestConfigCustom = selectedFunnel.requestConfig.filter(
      el => el.type !== 'JSON' && el.type !== 'CSV'
    );
    if (requestConfigCustom.length) {
      await Promise.all(
        requestConfigCustom[0].config.map(async el => {
          await FILEUPLOAD.deleteUpload({ path: `${el.path}` });
          await CSVCONFIG.deleteConfig(el._id);
        })
      );
    }
    if (selectedFunnel.duplicatesConfig.length) {
      DUPLICATES.deleteOne(selectedFunnel.duplicatesConfig[0].objectId);
    }

    if (selectedFunnel.aggregationConfig.length) {
      FUNNEL.deleteAggregationConfig(selectedFunnel.aggregationConfig[0].id);
    }

    if (selectedFunnel.emailAddress) {
      FUNNEL.deleteEmailAddress(selectedFunnel.emailAddress._id);
    }

    setLoaded(false);
    closeModal();
  };

  const deleteFunnelData = async (funnelId, options) => {
    const { medias, kpis, period } = options;
    const res = await FUNNEL.deleteFunnelData({
      medias,
      kpis,
      funnelId,
      start: period.start,
      end: period.end
    });
    closeModal();
    if (!res.response) {
      return notify({
        message: 'Echec de la suppression des données du funnel',
        variants: 'error'
      });
    }
    return notify({
      message: 'Suppression des données du funnel terminée',
      variants: 'success'
    });
  };

  const seachKey = (item, toTest) => {
    const val = toTest || searchFieldValue.toLowerCase();
    for (const key in item) {
      if (
        typeof item[key] === 'string' &&
        item[key].toLowerCase().indexOf(val) !== -1
      ) {
        return true;
      }
    }
    return false;
  };

  const getConnexionIDFromModel = modelName => {
    const model = configImportModel.find(el => el.value === modelName);
    const connexionConfig = configConnexion.find(
      el => el.model === model.connectorName
    );
    return connexionConfig?.value || '';
  };

  const onCreate = async data => {
    const {
      requestConfig,
      aggregations,
      duplicates,
      emailAddress,
      dailyCompute,
      funnelId
    } = data;

    if (requestConfig) {
      // Partie JSON
      const jsonConfig = requestConfig.filter(el => el.type === 'JSON');
      const dataJson = jsonConfig.map(
        ({ name, connexionId, config, disabled }) => ({
          funnelId,
          name,
          requestConfig: config || {},
          connexionId: connexionId || getConnexionIDFromModel(name),
          disabled
        })
      );

      await Promise.all(
        dataJson.map(async el => {
          await CONFIGCONNECTOR.createConfigConnector(el);
        })
      );
      // Partie CSV
      const dataSendCSV = data.requestConfig.filter(el => el.type === 'CSV');
      await Promise.all(
        dataSendCSV.map(async ({ name, config, mediaName, disabled }) => {
          await Promise.all(
            config.map(async el => {
              const formDataCsv = new FormData();
              formDataCsv.append('file', el);
              formDataCsv.append('funnelId', data.funnelId);
              formDataCsv.append('model', name);
              if (name === 'custom') {
                const fileName = `${mediaName}_custom`;
                formDataCsv.append('fileName', fileName);
              }
              const csvPath = await FILEUPLOAD.upload(formDataCsv);
              await CSVCONFIG.create({
                funnelId: data.funnelId,
                model: name,
                path: csvPath.path,
                disabled
              });
            })
          );
        })
      );
    }

    // Partie Duplicates
    if (duplicates.length) {
      await DUPLICATES.addOne({
        funnelId: data.funnelId,
        kpiToReplace: duplicates.map(e => {
          return { target: e.target, src: e.src };
        })
      });
    }

    if (aggregations) {
      await FUNNEL.createAggregationConfig({
        funnelId: data.funnelId,
        funnels: aggregations
      });
    }

    if (emailAddress) {
      await FUNNEL.createEmailAddress({
        funnelId: data.funnelId
      });
    }

    await FUNNEL.update(data.funnelId, { dailyCompute });

    setLoaded(false);
    closeModal();
  };

  const onUpdate = async data => {
    const {
      requestConfig,
      aggregations,
      duplicates,
      emailAddress,
      dailyCompute
    } = data;

    if (requestConfig) {
      // Partie Json
      const oldRequestConfigJson = selectedFunnel.requestConfig.filter(
        el => el.type === 'JSON'
      );
      const requestConfigJson = data.requestConfig.filter(
        el => el.type === 'JSON'
      );
      await Promise.all(
        requestConfigJson.map(async element => {
          const present = oldRequestConfigJson.find(el => el.id === element.id);

          if (present) {
            await CONFIGCONNECTOR.updateConfigConnector(present.id, {
              name: element.name,
              funnelId: data.funnelId,
              connexionId:
                element.connexionId || getConnexionIDFromModel(element.name),
              requestConfig: element.config || {},
              disabled: element.disabled
            });
          } else {
            await CONFIGCONNECTOR.createConfigConnector({
              funnelId: data.funnelId,
              name: element.name,
              requestConfig: element.config || {},
              connexionId:
                element.connexionId || getConnexionIDFromModel(element.name),
              disabled: element.disabled
            });
          }
        })
      );
      const newName = requestConfigJson.map(el => el.id);
      const configJsonToDelete = oldRequestConfigJson.filter(
        el => newName.indexOf(el.id) === -1
      );
      await Promise.all(
        configJsonToDelete.map(async el => {
          await CONFIGCONNECTOR.deleteConfigConnector(el.id);
        })
      );

      // Partie CSV
      const oldRequestConfigCSV = selectedFunnel.requestConfig.filter(
        el => el.type === 'CSV'
      );
      const requestConfigCSV = data.requestConfig.filter(
        el => el.type === 'CSV'
      );

      // Suppression des anciens models CSV
      const csvToDelete = oldRequestConfigCSV.filter(
        el =>
          !requestConfigCSV.some(
            item => item.name === el.name && item.mediaName === el.mediaName
          )
      );

      await Promise.all(
        csvToDelete.map(async ({ config }) => {
          await Promise.all(
            config.map(async file => {
              await CSVCONFIG.deleteConfig(file._id);
              await FILEUPLOAD.deleteUpload({ path: file.path });
            })
          );
        })
      );

      // Mise à jour et ajout des nouveaux
      await Promise.all(
        requestConfigCSV.map(async ({ name, config, mediaName, disabled }) => {
          const present = oldRequestConfigCSV.find(
            el => el.name === name && el.mediaName === mediaName
          );
          if (present) {
            // old CSVs to delete
            const toDelete = present.config.filter(
              el => !config.some(item => item?._id === el._id)
            );
            await Promise.all(
              toDelete.map(async file => {
                await CSVCONFIG.deleteConfig(file._id);
                await FILEUPLOAD.deleteUpload({ path: file.path });
              })
            );

            // Les csv qui ont été ajouté sont uploader
            const toUpload = config.filter(el => !el._id);
            await Promise.all(
              toUpload.map(async el => {
                let csvPath = {};
                const formDataCsv = new FormData();
                formDataCsv.append('file', el);
                formDataCsv.append('funnelId', data.funnelId);
                formDataCsv.append('model', name);
                if (name === 'custom') {
                  const fileName = `${mediaName}_custom`;
                  formDataCsv.append('fileName', fileName);
                }
                csvPath = await FILEUPLOAD.upload(formDataCsv);
                await CSVCONFIG.create({
                  funnelId: data.funnelId,
                  model: name,
                  path: csvPath.path,
                  disabled
                });
              })
            );
            // update juste l'etat "disabled" si necessaire pour les autres CSVconfig
            if (present.disabled !== disabled) {
              // Les csv qui ont été ajouté sont uploader
              const toUpdateStatus = config.filter(el => el._id);
              await Promise.all(
                toUpdateStatus.map(async ({ _id }) => {
                  await CSVCONFIG.update(_id, {
                    disabled
                  });
                })
              );
            }
          } else {
            // Ajout des nouveaux
            await Promise.all(
              config.map(async el => {
                let csvPath = {};
                const formDataCsv = new FormData();
                formDataCsv.append('file', el);
                formDataCsv.append('funnelId', data.funnelId);
                formDataCsv.append('model', name);
                if (name === 'custom') {
                  const fileName = `${mediaName}_custom`;
                  formDataCsv.append('fileName', fileName);
                }
                csvPath = await FILEUPLOAD.upload(formDataCsv);
                await CSVCONFIG.create({
                  funnelId: data.funnelId,
                  model: name,
                  path: csvPath.path,
                  disabled
                });
              })
            );
          }
        })
      );

      if (selectedFunnel.aggregationConfig.length) {
        FUNNEL.deleteAggregationConfig(selectedFunnel.aggregationConfig[0].id);
      }
    }

    if (aggregations) {
      // create
      if (
        (!selectedFunnel.aggregationConfig ||
          selectedFunnel.aggregationConfig.length === 0) &&
        aggregations.length
      ) {
        FUNNEL.createAggregationConfig({
          funnelId: selectedFunnel._id,
          funnels: aggregations
        });
        // delete
      } else if (
        selectedFunnel.aggregationConfig.length &&
        aggregations.length === 0
      ) {
        FUNNEL.deleteAggregationConfig(selectedFunnel.aggregationConfig[0].id);
      } else {
        FUNNEL.updateAggregationConfig(selectedFunnel.aggregationConfig[0].id, {
          funnelId: selectedFunnel._id,
          funnels: aggregations
        });
      }

      // delet configs (req, csv)
      const requestConfigJson = selectedFunnel.requestConfig.filter(
        el => el.type === 'JSON'
      );
      const requestConfigCsv = selectedFunnel.requestConfig.filter(
        el => el.type === 'CSV'
      );

      await Promise.all(
        requestConfigCsv.map(async ({ config }) => {
          await Promise.all(
            config.map(async file => {
              await CSVCONFIG.deleteConfig(file._id);
              await FILEUPLOAD.deleteUpload({ path: file.path });
            })
          );
        })
      );

      await Promise.all(
        requestConfigJson.map(async el => {
          await CONFIGCONNECTOR.deleteConfigConnector(el.id);
        })
      );
    }

    // Partie Duplicates
    const oldDataSendDuplicates = selectedFunnel.duplicatesConfig;

    if (!oldDataSendDuplicates.length && duplicates.length) {
      DUPLICATES.addOne({
        funnelId: selectedFunnel._id,
        kpiToReplace: duplicates.map(e => {
          return { target: e.target, src: e.src };
        })
      });
    } else if (oldDataSendDuplicates.length && !duplicates.length) {
      DUPLICATES.deleteOne(oldDataSendDuplicates[0].objectId);
    } else if (duplicates.length) {
      DUPLICATES.updateOne(duplicates[0].objectId, {
        funnelId: selectedFunnel._id,
        kpiToReplace: duplicates.map(e => {
          return { target: e.target, src: e.src };
        })
      });
    }

    if (emailAddress && !selectedFunnel.emailAddress) {
      await FUNNEL.createEmailAddress({
        funnelId: data.funnelId
      });
    }

    if (!emailAddress && selectedFunnel.emailAddress) {
      await FUNNEL.deleteEmailAddress(selectedFunnel.emailAddress._id);
    }

    await FUNNEL.update(data.funnelId, { dailyCompute });

    setLoaded(false);
    closeModal();
  };

  const getStatus = (statusUpdateList, computinList = 0) => {
    const lastCompute = computinList[computinList.length - 1] || {};
    const lastUpdate = statusUpdateList[0] || {};

    const {
      status: computeStatus,
      tracebackMsg,
      createdAt: dateCompute
    } = lastCompute;
    const { status: updatedStatus, createdAt: dateUpdate } = lastUpdate;

    if (computeStatus === 'error') {
      return { ...lastCompute, type: 'Contribution' };
    }
    if (updatedStatus === 'error') {
      return { ...lastUpdate, type: 'Connecteur' };
    }

    if (
      computeStatus === 'partial' ||
      (computeStatus === 'success' && tracebackMsg !== '')
    ) {
      return { ...lastCompute, type: 'Contribution' };
    }

    if (updatedStatus === 'partial') {
      return { ...lastUpdate, type: 'Connecteur' };
    }

    if (computinList === 'In progress') {
      return { ...lastCompute, type: 'Contribution' };
    }

    if (updatedStatus === 'inprogress' || updatedStatus === 'queued') {
      return { ...lastUpdate, type: 'Connecteur' };
    }

    if (dateCompute && moment(dateCompute).isAfter(moment(dateUpdate))) {
      return { ...lastCompute, type: 'Contribution' };
    }

    return { ...lastUpdate, type: 'Connecteur' };
  };

  const getComputings = async () => {
    const resp = await FUNNEL.getComputings();
    if (resp) {
      setComputings(resp);
    }
  };

  const activeRefresh = async id => {
    if (adminRights?.funnel?.connectors?.refresh) {
      await FUNNEL_UPDATE_STATUS.getFunnelUpdateJob({
        funnelId: id,
        status: 'queued',
        source: 'admin'
      });
      setRefreshStatus(true);
    } else {
      setOpenModal('unauthorized');
    }
  };

  const compute = async id => {
    if (adminRights?.funnel?.data?.compute) {
      await FUNNEL.setFunnelComputing(id, false);
      getComputings();
    } else {
      setOpenModal('unauthorized');
    }
  };

  const computeFromBegin = async id => {
    if (adminRights?.funnel?.data?.compute) {
      await FUNNEL.setFunnelComputing(id, true);
      getComputings();
    } else {
      setOpenModal('unauthorized');
    }
  };

  const getDatas = async () => {
    const [
      funnels,
      configConnector,
      configConnection,
      properties,
      configConnectorCSV,
      importModel,
      duplicates,
      aggregationConfig,
      configGeneratorsList,
      computingsList,
      emailAddress
    ] = await Promise.all([
      FUNNEL.getFunnels(['name', 'dailyCompute']),
      CONFIGCONNECTOR.getConfigConnector(),
      CONNEXION.getConnexions(),
      PROPERTIES.get(),
      CSVCONFIG.get(),
      IMPORTMODEL.get(),
      DUPLICATES.getAll(),
      FUNNEL.getAggregationConfig(),
      CONFIGCONNECTOR.getConfigGenerators(),
      FUNNEL.getComputings(),
      FUNNEL.getEmailAddress()
    ]);

    const funnelsMapped = funnels.funnels.map(item => {
      const newItem = item;
      newItem._id = item._id;
      newItem.id = item._id;
      newItem.name = item.name;
      newItem.dailyCompute = item.dailyCompute;
      newItem.propertie = findPropertie(item._id, properties);
      return newItem;
    });

    const richFunnels = funnelsMapped.map(item => {
      // Partie JSON
      const jsonConfig = configConnector
        .filter(el => el.funnelId === item._id)
        .map(el => {
          return {
            id: el._id,
            name: el.name,
            type: 'JSON',
            connexionId: el.connexionId,
            disabled: el.disabled,
            config: el.requestConfig,
            open: false
          };
        });
      // Partie CSV
      let csvConfig = configConnectorCSV.filter(el => el.funnelId === item._id);
      let modelConfigCsv = csvConfig.map(el => {
        let mediaName;
        if (el.model === 'custom') {
          const elemTab = el.path.split('/');
          const tabNameFile = elemTab[elemTab.length - 1].split('_')[0];
          mediaName = tabNameFile;
        }
        return `${el.model}_${mediaName}`;
      });
      modelConfigCsv = [...new Set(modelConfigCsv)];
      csvConfig = modelConfigCsv.map(element => {
        const tab = element.split('_');
        const model = tab[0];
        const mediaName = model === 'custom' ? tab[1] : undefined;
        const tabModel = csvConfig.filter(el => {
          let media;
          if (el.model === 'custom') {
            const elemTab = el.path.split('/');
            const tabNameFile = elemTab[elemTab.length - 1].split('_')[0];
            media = tabNameFile;
          }

          return el.model === model && media === mediaName;
        });

        return {
          name: model,
          type: 'CSV',
          mediaName,
          disabled: tabModel[0]?.disabled,
          config: tabModel.map(el => {
            const tabPath = el.path.split('/');
            return {
              _id: el._id,
              name: tabPath[tabPath.length - 1],
              path: el.path
            };
          }),
          open: false
        };
      });

      // Partie Duplicates
      let duplicatesConfig = [];
      const duplicateObj = duplicates.data.find(el => el.funnelId === item._id);

      if (duplicateObj) {
        duplicatesConfig = duplicateObj.kpiToReplace.map(e => {
          return {
            src: e.src,
            target: e.target,
            objectId: duplicateObj._id
          };
        });
      }

      const newItem = { ...item };
      newItem.duplicatesConfig = duplicatesConfig || [];
      newItem.requestConfig = [...jsonConfig, ...csvConfig];

      const agg = aggregationConfig.find(el => el.funnelId === item._id) || [];
      newItem.aggregationConfig =
        agg?.funnels?.map(funnelId => {
          const funnel = funnelsMapped.find(el => el._id === funnelId);
          return {
            id: agg._id,
            _id: funnelId,
            name: `${funnel?.propertie} - ${funnel?.name}`
          };
        }) || [];
      newItem.isConso = newItem.aggregationConfig.length !== 0;

      if (newItem.isConso) {
        newItem.type = 'Consolidé';
      } else {
        newItem.type = 'Connecteurs & CSV';
      }

      newItem.emailAddress = emailAddress.find(el => el.funnelId === item._id);

      return newItem;
    });
    const funnelConfig = richFunnels.filter(
      el => el.requestConfig.length || el.aggregationConfig.length
    );

    const updateStatusList = await FUNNEL_UPDATE_STATUS.getLastUpdateStatus(
      funnelConfig.map(el => el.id)
    );

    const mappedFunnels = funnelConfig.map(el => {
      const item = { ...el };
      const funnelUpdateStatus = updateStatusList.filter(
        statusList => statusList.funnelId === item._id
      );
      const lastUpdates = funnelUpdateStatus.find(
        funnelUpdate =>
          funnelUpdate.status === 'done' || funnelUpdate.status === 'partial'
      );
      // item.updateStatus = funnelUpdateStatus;
      // todo migrate computing like updateStatus

      item.onDelete = openDeleteModal;

      if (!el.isConso) {
        item.computings = computingsList.filter(
          computingList => computingList.funnelId === item._id
        );
        item.status = getStatus(funnelUpdateStatus, item.computings);
        item.lastUpdate = lastUpdates
          ? moment(lastUpdates.updatedAt).format('DD/MM/YYYY')
          : moment(el?.lastUpdate).format('DD/MM/YYYY') || '';
        item.onListLogs = id => openLogsModal(id);
        item.onRefresh = [
          { label: 'Mettre à jour les données', onClick: activeRefresh },
          {
            label: 'Mettre à jour la contribution',
            onClick: compute
          },
          {
            label: 'Mettre à jour la contribution depuis le début',
            onClick: computeFromBegin
          }
        ];
        item.onDelete = [
          { label: 'Supprimer le connecteur', onClick: openDeleteModal },
          {
            label: 'Supprimer les données du funnel',
            onClick: openDeleteDataModal
          }
        ];
      }

      return item;
    });

    const funnelAvailable = richFunnels
      .filter(richFunnel => !richFunnel.requestConfig.length)
      .map(richFunnel => ({
        label: `${richFunnel.propertie} - ${richFunnel.name}`,
        value: richFunnel.id
      }));

    const modelImport = importModel
      .map(el => {
        return {
          label: `${el.label} (${el.model})`,
          value: el.model,
          connectorName: el.connectorName,
          json: !!el.connectorName,
          csv: true
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    let formatConnexion = configConnection.map(el => ({
      label: el.name,
      model: el.model,
      value: el._id
    }));
    formatConnexion = formatConnection(formatConnexion);

    setConfigImportModel(modelImport);
    setConfigGenerators(configGeneratorsList);
    setConfigConnexion(formatConnexion);
    setConfigFunnelDispo(funnelAvailable);

    setAllRows(mappedFunnels);
    setRows(mappedFunnels);

    setRows(
      !searchFieldValue
        ? mappedFunnels
        : mappedFunnels.filter(item => {
            return seachKey(item, searchFieldValue);
          })
    );
    setAllFunnels(
      mappedFunnels.map(el => ({
        name: `${el.propertie} - ${el.name}`,
        _id: el.id
      }))
    );
    setLoaded(true);
  };

  const getUpdateStatus = async () => {
    const updateStatusList = await FUNNEL_UPDATE_STATUS.getLastUpdateStatus(
      allRows.map(el => el.id)
    );
    if (updateStatusList) {
      setUpdateStatus(updateStatusList);
    }
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }

    // eslint-disable-next-line
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      const mapped = allRows.map(item => {
        const newItem = item;
        const tmpUpdateStatus = updateStatus.filter(
          el => el.funnelId === newItem._id
        );
        newItem.computings = computings.filter(
          el => el.funnelId === newItem._id
        );
        if (!item.isConso) {
          newItem.status = getStatus(tmpUpdateStatus, newItem.computings);
        }

        return newItem;
      });
      setRows(
        mapped.filter(el => {
          const findRow = rows.find(row => row._id === el._id);
          return findRow && seachKey(el);
        })
      );
      setAllRows(mapped);
    }
    // eslint-disable-next-line
  }, [updateStatus, computings]);

  useEffect(() => {
    setSearchFieldValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshStatus) {
      getUpdateStatus();
      setRefreshStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshStatus]);

  return (
    <Container>
      <IntervalTimer
        timeout={20000}
        callback={getComputings}
        enabled={!openModal}
        repeat
      />
      <IntervalTimer
        timeout={20000}
        callback={getUpdateStatus}
        enabled={!openModal}
        repeat
      />
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={newRows => setRows(newRows)}
            datas={allRows}
            titleHead="Recherche un funnel"
            placeholder="funnel id, propriété, nom, ..."
            setSearchFieldValue={setSearchFieldValue}
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Ajouter une configuration"
          />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={() => {
                setSelectedFunnel({});
                setOpenModal('');
              }}
              config={{
                allFunnels,
                funnels: configFunnelDispo,
                importModel: configImportModel,
                configConnexion,
                configGenerators
              }}
              onValidate={onCreate}
              actionName="Sauvegarder"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Id Funnel', id: 'id', sortKey: 'id' },
                { label: 'Nom', id: 'name', sortKey: 'name' },
                { label: 'Propriété', id: 'propertie', sortKey: 'propertie' },
                { label: 'Type', id: 'type', sortKey: 'type' },
                {
                  label: 'Dernière MAJ',
                  id: 'lastUpdate',
                  sortKey: 'lastUpdate'
                }
              ]}
              onUpdate={openUpdateModal}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              funnel={selectedFunnel}
              open={openModal === 'modalUpdate'}
              onClose={() => {
                setSelectedFunnel({});
                setOpenModal('');
              }}
              onValidate={onUpdate}
              actionName="Modifier le funnel"
              config={{
                allFunnels,
                funnels: [
                  {
                    label: `${selectedFunnel.propertie} - ${selectedFunnel.name}`,
                    value: selectedFunnel.id
                  }
                ],
                importModel: configImportModel,
                configConnexion,
                configGenerators
              }}
            />
          )}
          {openModal === 'modalLogs' && (
            <ModalLogs
              name={selectedFunnel.name}
              datas={[
                {
                  label: 'Connecteurs',
                  values: selectedUpdateStatus
                },
                {
                  label: 'Contribution',
                  values: [...selectedFunnel.computings]
                }
              ]}
              onClose={() => {
                setSelectedFunnel({});
                setSelectedUpdateStatus([]);
                setOpenModal('');
              }}
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedFunnel.name}
              type="les configurations du funnel"
              open={openModal === 'modalDelete'}
              onClose={() => {
                setSelectedFunnel({});
                setOpenModal('');
              }}
              onDelete={onDelete}
            />
          )}
          {openModal === 'modalDeleteData' && (
            <ModalDeleteData
              funnel={selectedFunnel}
              open={openModal === 'modalDeleteData'}
              onClose={closeModal}
              onValidate={deleteFunnelData}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConnectorConfig;
