import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'utils/moment';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

import SingleBaseDatePicker, { SingleBaseDateWrapper } from './style';

import 'react-dates/initialize';

export const DatePicker = props => {
  const {
    show,
    autoFocus,
    error,
    initialDate,
    isOutSideRange,
    onClose,
    startDate,
    endDate,
    title,
    required,
    tooltip
  } = props;
  const [focused, setFocused] = useState(autoFocus);
  const [date, setDate] = useState(initialDate);

  const defaultIsOutsideRange = dateMoment => {
    if (!startDate || !endDate) {
      return moment(dateMoment).isAfter();
    }
    return !moment(dateMoment)
      .utc()
      .isBetween(startDate, endDate, 'day', '[]');
  };

  const onFocusChange = ({ focused: focus }) => {
    setFocused(focus);
  };

  return (
    <Fade show={show}>
      {title && (
        <Row spacing={2} justify="flex-start" alignItems="center">
          <Item
            justify="flex-start"
            flex
            style={{
              maxWidth: tooltip ? 'calc(100% - 24px)' : null
            }}
          >
            <Text noWrap color={error ? 'error' : null} fontSize="14px">
              {title === '&nbsp;' ? <>&nbsp;</> : title}
              {required && '*'}
            </Text>
          </Item>
          {tooltip && (
            <Item flex style={{ alignItem: 'center' }}>
              <HelpTooltip title={tooltip} />
            </Item>
          )}
        </Row>
      )}
      <SingleBaseDateWrapper {...props}>
        <SingleBaseDatePicker
          id="date_input"
          date={date}
          focused={focused}
          onDateChange={setDate}
          onFocusChange={onFocusChange}
          isOutsideRange={isOutSideRange || defaultIsOutsideRange}
          onClose={onClose}
          initialVisibleMonth={date ? () => date : null}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
        />
      </SingleBaseDateWrapper>
    </Fade>
  );
};

DatePicker.defaultProps = {
  show: null,
  autoFocus: false,
  initialDate: null,
  onClose: () => {},
  startDate: null,
  endDate: null,
  title: null,
  tootltip: null,
  required: false
};

DatePicker.propTypes = {
  show: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  initialDate: PropTypes.instanceOf(moment),
  onClose: PropTypes.func,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  title: PropTypes.string,
  tootltip: PropTypes.string,
  required: PropTypes.bool
};

export default DatePicker;
