import React, { useState } from 'react';
import {Popover} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import { ChromePicker } from 'react-color';

import { useTheme } from 'contexts/ThemeContext';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Container, Item, Row } from 'components/Layout';

export const ColorPicker = props => {
  const {
    error,
    onChange,
    show,
    value,
    title,
    required,
    tooltip,
    iconView,
    disabled
  } = props;

  const { themeColors } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = event => {
    if (anchorEl) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover-color-picker' : undefined;

  const handleChange = color => {
    onChange(color.hex);
  };

  return (
    <Fade show={show}>
      {title && (
        <Row spacing={2} justify="flex-start" alignItems="center">
          <Item
            justify="flex-start"
            flex
            style={{
              flex: 0,
              maxWidth: tooltip ? 'calc(100% - 24px)' : null
            }}
          >
            <Text noWrap color={error ? 'error' : null} fontSize="14px">
              {title}
              {required && '*'}
            </Text>
          </Item>
          {tooltip && (
            <Item flex style={{ flex: 0 }}>
              <HelpTooltip title={tooltip} />
            </Item>
          )}
        </Row>
      )}
      <Item>
        <Row spacing={0} onClick={handleOpen}>
          <Item justify="center">
            {iconView ? (
              <CircleIcon
                style={{
                  color: value,
                  border: `0px`,
                  borderRadius: '15px',
                  backgroundColor: themeColors.themeV2.detail
                }}
              />
            ) : (
              <Row spacing={0}>
                <Item
                  flex
                  style={{
                    margin: '4px',
                    width: '16px',
                    height: '16px',
                    backgroundColor: value,
                    border: `1px solid ${themeColors.themeV2.black}`,
                    borderRadius: '2px'
                  }}
                />
                <Item justify="flex-start" xs>
                  <Text fontWeight={400} fontSize="13px">
                    {value}
                  </Text>
                </Item>
                <Item flex>
                  <ExpandMoreIcon />
                </Item>
              </Row>
            )}
          </Item>
        </Row>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Container>
            <Row alignItems="flex-start" spacing={0}>
              <Item justify="flex-start" alignItems="flex-start">
                <ChromePicker
                  onChange={handleChange}
                  color={value}
                  disableAlpha
                  disabled={disabled}
                />
              </Item>
            </Row>
          </Container>
        </Popover>
      </Item>
    </Fade>
  );
};

export default ColorPicker;
