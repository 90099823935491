import React from 'react';
import PropTypes from 'prop-types';

import { panels } from 'utils/constants';

import BaseSidebarFunnel from './style';

/**
 * SidebarFunnel
 * @component
 *
 */
function SidebarFunnel(props) {
  const { children } = props;
  return (
    <BaseSidebarFunnel panel={panels.backoffice}>{children}</BaseSidebarFunnel>
  );
}
SidebarFunnel.propTypes = {
  children: PropTypes.node.isRequired
};

export { SideItemFunnel } from './style';
export default SidebarFunnel;
