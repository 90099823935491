import React from 'react';
import MUIAlert from '@mui/material/Alert';

export const Alert = props => {
  const { text } = props;
  return (
    <MUIAlert sx={{ mb: 2 }} {...props}>
      {text}
    </MUIAlert>
  );
};

export default Alert;
