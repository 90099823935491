import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

import 'cleave.js/dist/addons/cleave-phone.fr';

export const FormatInput = props => {
  const { inputRef, format, ...rest } = props;
  let option;
  switch (format) {
    case 'numberInt':
      option = {
        numeral: true,
        numeralDecimalMark: '.',
        delimiter: ' ',
        numeralPositiveOnly: true,
        numeralDecimalScale: 0
      };
      break;
    case 'numberDec':
      option = {
        numeral: true,
        numeralDecimalMark: '.',
        delimiter: ' ',
        numeralPositiveOnly: true,
        numeralDecimalScale: 3
      };
      break;
    case 'phone':
      option = {
        phone: true,
        phoneRegionCode: 'FR'
      };
      break;
    case 'date':
      option = {
        date: true,
        delimiter: '/',
        datePattern: ['d', 'm', 'Y']
      };
      break;
    case 'hour':
      option = {
        time: true,
        timePattern: ['h', 'm', 's']
      };
      break;
    default:
      option = {};
  }
  return format && <Cleave ref={inputRef} options={option} {...rest} />;
};

FormatInput.defaultProps = {
  format: null
};

FormatInput.propTypes = {
  format: PropTypes.string,
  inputRef: PropTypes.func.isRequired
};

export default FormatInput;
