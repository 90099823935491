import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Autorenew';
import OAuth2Login from 'react-simple-oauth2-login';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { CONNEXION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { IconButton } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDelete } from '../../../components/ModalDelete';

import { ModalForm } from './components/ModalForm';

const ConnexionContainer = () => {
  const [connexionList, setConnexion] = useState([]);
  const { adminRights } = useUser();
  const [connexionsGenerator, setConnexionsGenerator] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedConnexion, setSelectedConnexion] = useState({});
  const { themeColors } = useTheme();

  const domain = new URL(window.location.href);
  const closeModal = () => {
    setModal(null);
    setSelectedConnexion({});
  };
  const onDelete = () => {
    CONNEXION.deleteConnexion(selectedConnexion._id).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  const selectConnexion = id => {
    const connexion = connexionList.find(el => el._id === id);
    setSelectedConnexion(connexion);
  };

  const getConnexions = async () => {
    try {
      const result = await CONNEXION.getConnexions();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getConnexionsGenerator = async () => {
    try {
      const result = await CONNEXION.getConnexionsGenerator();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getAccessToken = async (code, id, clientId, clientSecret, uri) => {
    try {
      const data = await CONNEXION.generateTokens({
        code,
        clientId,
        clientSecret,
        uri,
        redirectUri: `${domain.origin}/`
      });

      const { access_token: accessToken, refresh_token: refreshToken } = data;
      await CONNEXION.updateConnexion({ accessToken, refreshToken }, id);
    } catch (e) {
      console.error('failed', e);
    }
  };

  const onSuccess = (id, clientId, clientSecret, uri) => response => {
    const { code } = response;
    getAccessToken(code, id, clientId, clientSecret, uri);
  };

  const onFailure = response => console.error(response);

  useEffect(() => {
    if (!loaded) {
      getConnexions().then(async resp => {
        const generatorList = await getConnexionsGenerator();
        setLoaded(true);
        const mapped = resp.map(item => {
          const model = generatorList.find(el => el.name === item.model);
          const newItem = {};
          newItem._id = item._id;
          newItem.name = item.name;
          newItem.model = item.model;
          newItem.customAction =
            model && model.type === 'oauth2' ? (
              <OAuth2Login
                authorizationUrl={model.config.authorizationUrl}
                responseType="code"
                clientId={item.clientId}
                redirectUri={`${domain.origin}/`}
                scope={model.config.scope}
                onSuccess={onSuccess(
                  item._id,
                  item.clientId,
                  item.clientSecret,
                  model.config.tokenUrl
                )}
                onFailure={onFailure}
                extraParams={{
                  // eslint-disable-next-line camelcase
                  approval_prompt: 'force',
                  // eslint-disable-next-line camelcase
                  access_type: 'offline'
                }}
                render={props => (
                  <IconButton {...props}>
                    <RefreshIcon style={{ color: themeColors.light }} />
                  </IconButton>
                )}
              />
            ) : null;
          return newItem;
        });
        setConnexionsGenerator(generatorList);
        setConnexion(resp);
        setRows(mapped);
        setAllRows(mapped);
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  const handleCreateConnexion = data => {
    CONNEXION.createConnexion(data).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const handleUpdateConnexion = data => {
    CONNEXION.updateConnexion(data, selectedConnexion._id).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const addConnexion = () => {
    if (adminRights?.general?.connection?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const updateFunnel = id => {
    if (adminRights?.general?.connection?.update) {
      selectConnexion(id);
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const deleteConnexion = id => {
    if (adminRights?.general?.connection?.delete) {
      selectConnexion(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche une connexion"
            placeholder="Nom, Model..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addConnexion} text="Ajouter une connexion" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={handleCreateConnexion}
              connexionsGenerator={connexionsGenerator}
              actionName="Créer"
            />
          )}
        </Item>
      </Row>
      <Row>
        <Item>
          <TableData
            rows={rows}
            headers={[
              { label: 'Id', id: '_id', sortKey: '_id' },
              { label: 'Nom', id: 'name', sortKey: 'name' },
              { label: 'Model', id: 'model' }
            ]}
            onUpdate={updateFunnel}
            onDelete={deleteConnexion}
          />
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onValidate={handleUpdateConnexion}
              config={selectedConnexion}
              connexionsGenerator={connexionsGenerator}
              actionName="Modifier"
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              name={selectedConnexion.name}
              type="cette connexion"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConnexionContainer;
