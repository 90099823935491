import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  AutocompleteTextField,
  Button,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  funnelIdError: false,
  sheetUrlError: false
};

export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onValidate, onClose } = props;
  const { funnels = [], selected, allUsers = [] } = config;

  const initialState = {
    funnelId: selected?.funnelId,
    toleranceLimit: selected?.toleranceLimit || 10,
    alerting: selected?.alerting || false,
    emails: selected?.emails || [],
    sheetUrl: selected?.sheetUrl || ''
  };

  const [
    { funnelId, toleranceLimit, alerting, emails, sheetUrl },
    setState
  ] = React.useState(initialState);
  const [{ funnelIdError, sheetUrlError }, setError] = React.useState(
    ErrorState
  );

  const resetStates = () => {
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const changeAlerting = () => {
    setState(prevState => ({
      ...prevState,
      alerting: !alerting
    }));
  };

  const onAutoCompliteChange = (data, reason) => {
    if (reason === 'select-option' || reason === 'selectOption') {
      handleChange('funnelId')(data?.id);
    }

    if (reason === 'clear') {
      handleChange('funnelId')('');
    }
  };
  const onNext = () => {
    let error = false;

    if (!funnelId) {
      toggleError('funnelId', true);
      error = true;
    }

    if (!sheetUrl) {
      toggleError('sheetUrl', true);
      error = true;
    }

    if (!error) {
      const datas = {
        funnelId,
        emails,
        sheetUrl,
        toleranceLimit,
        alerting
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onNext}
            >
              {selected ? 'Modifier' : 'Ajouter'} la configuration
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const searchOptionsKeys = ['name', 'id'];

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnel">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <AutocompleteTextField
                      title="Funnel"
                      required
                      options={funnels}
                      formatLabel={option => {
                        let str = option[searchOptionsKeys[0]];
                        if (searchOptionsKeys[1]) {
                          str += ` (${option[searchOptionsKeys[1]]})`;
                        }
                        return str;
                      }}
                      small
                      value={funnels.find(el => el.id === funnelId)}
                      onChange={onAutoCompliteChange}
                      placeholder="Choisissez un funnel"
                      disabled={selected?.funnelId}
                      error={funnelIdError}
                      bgColor={themeColors.primary}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Budgets hebdomadaires">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <TextField
                      title="URL Google Sheet"
                      required
                      small
                      value={sheetUrl}
                      onChange={handleChange('sheetUrl')}
                      placeholder="Saisissez un URL"
                      error={sheetUrlError}
                      bgColor={themeColors.primary}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Suivi budgétaire">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Text>Seuil de tolérance</Text>
                    <TextField
                      small
                      value={toleranceLimit}
                      onChange={handleChange('toleranceLimit')}
                      bgColor={themeColors.primary}
                      format="numberDec"
                      unit="%"
                    />
                  </Item>
                </Row>
                <Row spacing={1} justify="flex-start">
                  <Item xs={3} justify="flex-start">
                    <Text>Activer les alertes mail</Text>
                    <Switch checked={alerting} onChange={changeAlerting} />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Addresses d'alerting">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={12} justify="flex-start">
                    <ListField
                      options={allUsers.map(e => ({
                        name: e
                      }))}
                      searchOptionsKeys={['name']}
                      values={emails.map(e => ({ name: e }))}
                      small
                      value={emails.map(e => ({ name: e }))}
                      actionTxt="Ajouter un mail"
                      deleteAction
                      onChange={value => {
                        const newEmails = value.map(el => el.name);
                        handleChange('emails')(newEmails);
                      }}
                      placeholder="Choisissez un mail"
                      bgColor={themeColors.primary}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
