import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

export const ModalForm = props => {
  const { onValidate, config, actionBeta, channels } = props;
  const initialState = {
    channel: config.channel || null,
    media: config.media || null,
    low: config.low || 0,
    main: config.main || 0,
    high: config.high || 0,
    cpm: config.cpm || 0,
    label: config.label || null
  };
  const [
    { channel, media, low, main, high, cpm, label },
    setState
  ] = React.useState(initialState);

  const handleChange = key => value => {
    const text = value.trim();
    setState(prevState => {
      const states = { ...prevState };
      states[key] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    const datas = {
      channel,
      media,
      low,
      main,
      high,
      cpm,
      label
    };
    onValidate(datas);
    resetStates();
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionBeta || 'Modifier le média'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Channel</Text>
                          <Select
                            onChange={handleChange('channel')}
                            value={channel}
                            small
                            disabled={config._id}
                            options={channels}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Media</Text>
                          <TextField
                            small
                            value={media}
                            onChange={handleChange('media')}
                            disabled={config._id}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Label</Text>
                          <TextField
                            onChange={handleChange('label')}
                            value={label}
                            small
                            disabled={config._id}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de visite" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de visite minimal *</Text>
                          <TextField
                            small
                            value={low}
                            onChange={handleChange('low')}
                            format="numberDec"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de visite moyen *</Text>
                          <TextField
                            small
                            value={main}
                            onChange={handleChange('main')}
                            format="numberDec"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Taux de visite maximal *</Text>
                          <TextField
                            small
                            value={high}
                            onChange={handleChange('high')}
                            format="numberFloat"
                            unit="%"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="CPM" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>CPM *</Text>
                          <TextField
                            small
                            value={cpm}
                            onChange={handleChange('cpm')}
                            format="numberDec"
                            unit="€"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null,
  channels: []
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  channels: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
