import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  nameError: false,
  usersError: false
};

export const ModalForm = props => {
  const { onValidate, config } = props;
  const { selectedTagFunnel = {}, usersList = [] } = config;
  const initialState = {
    name: selectedTagFunnel.name,
    users:
      selectedTagFunnel.users
        ?.map(el => {
          return usersList.find(item => item.email === el);
        })
        .filter(el => el) || []
  };
  const [{ name, users }, setState] = React.useState(initialState);
  const [{ nameError }, setError] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!name) {
      toggleError('name', true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        users: users.map(el => el.email)
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              Valider
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Nom</Text>
                          <TextField
                            small
                            value={name}
                            onChange={handleChange('name')}
                            error={nameError}
                            disabled={selectedTagFunnel._id}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Utilisateur" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={usersList.map(e => ({
                        email: e.email,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['email']}
                      values={users.map(e => ({
                        email: e.email,
                        _id: e._id
                      }))}
                      onChange={handleChange('users')}
                      actionTxt="Ajouter un utilisateur"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null,
  update: false
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool
};

export default ModalForm;
