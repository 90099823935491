import React, { useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { CATEGORY, CRITERIA } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

function CriteriaContainer() {
  const [criterias, setCriterias] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedCriteria, setSelectedCriteria] = useState({});
  const [config, setConfig] = useState([]);

  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  const selectCriteria = id => {
    const criteria = criterias.find(el => el._id === id);
    setSelectedCriteria(criteria);
    return criteria;
  };

  const closeModal = () => {
    setModal(null);
    setSelectedCriteria({});
  };

  const onCreate = datas => {
    const params = datas;
    params.source = 'BackOffice';
    params.userCustomCriterias = null;
    CRITERIA.create(datas)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const onUpdate = datas => {
    CRITERIA.update(selectedCriteria._id, datas)
      .catch()
      .then(() => {
        setLoaded(false);
        closeModal();
      });
  };

  const formatSrc = (cat, sub) => {
    return (
      <Container>
        <Row spacing={0}>
          <Item flex>
            <Text color="insight">{cat}</Text>
          </Item>
          <Item flex>
            <Text>&nbsp;{'>'}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text color="insight">{sub}</Text>
          </Item>
        </Row>
      </Container>
    );
  };

  if (!loaded) {
    CRITERIA.getCriterias().then(resp => {
      setLoaded(true);
      setCriterias(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.key = item.key;
        newItem.name = item.name;
        // added only for searhfield
        newItem.catName = item.parentCategory && item.parentCategory.name;
        newItem.subcatName = item.subCategory && item.subCategory.name;
        newItem.src =
          item.subCategory && item.parentCategory
            ? formatSrc(item.parentCategory.name, item.subCategory.name)
            : 'aucune';
        newItem.description = item.description;
        newItem.active = item.active ? 'actif' : 'inactif';
        // newItem.CPM = 0; // <= on vera plus tard quand ce sera clair dans le tete de charles et william
        return newItem;
      });
      setRows(mapped);
      setAllRows(mapped);
    });
  }

  const addCriteria = async () => {
    if (adminRights?.audiences?.criteria?.create) {
      const categoryList = await CATEGORY.getFullCategoryList();
      setConfig({ categoryList, criterias });
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const updateCriteria = async id => {
    if (adminRights?.audiences?.criteria?.update) {
      const criteria = selectCriteria(id);
      const categoryList = await CATEGORY.getFullCategoryList();
      setConfig({ categoryList, criteria, criterias });
      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher un critère"
            placeholder="18-35, Age, C_XXX"
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={addCriteria} text="Ajouter un critère" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              config={config}
              onValidate={onCreate}
              actionName="Créer le critère"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} src="/assets/img/loader_insight.svg" />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Key', id: 'key', sortKey: 'key' },
                {
                  label: 'Nom',
                  id: 'name',
                  style: { bold: true },
                  sortKey: 'name'
                },
                {
                  label: 'Emplacement dans la taxonomie',
                  id: 'src',
                  sortKey: 'catName'
                },
                { label: 'Description', id: 'description' }
              ]}
              onUpdate={updateCriteria}
              rowCustomStyle={item => {
                return {
                  backgroundColor:
                    item.active === 'actif'
                      ? 'transparent'
                      : themeColors.greyLight
                };
              }}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              config={config}
              onValidate={onUpdate}
              actionName="Modifier le critère"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
}

export default CriteriaContainer;
