export const defaultModule  = {
  crm: { state: true, colorKey: 'data' },
  alice: { state: true, colorKey: 'content' },
  audiences: { state: true, colorKey: 'insight' },
  activation: { state: true, colorKey: 'activation' },
  funnel: { state: true, colorKey: 'funnel' },
  ermesLive: { state: true, colorKey: 'live' }
};

export default defaultModule;
