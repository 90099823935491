import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, Switch, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false,
  errorKey: false,
  errorModel: false
};

export const ModalForm = props => {
  const {
    config,
    onValidate,
    onClose,
    actionName,
    connexionsGenerator,
    channels,
    langues,
    parentList,
    childrenList
  } = props;
  const { themeColors } = useTheme();
  const generateDocumentation = documentation => {
    return Object.entries(documentation)
      .map(([key, value]) => {
        if (value) {
          return {
            langue: langues.find(el => el.key === key)?.label,
            url: value
          };
        }
        return null;
      })
      .filter(el => el !== null);
  };

  const initialState = {
    name: config?.name || '',
    key: config?.key || '',
    model: config?.model || '',
    types: config?.channels || [],
    minimumBudget: config?.minimumBudget || '',
    dailyMinimumBudget: config?.dailyMinimumBudget || '',
    minimumAudience: config?.minimumAudience || '',
    documentation:
      config?.documentation && langues?.length
        ? generateDocumentation(config?.documentation)
        : [],
    page: config?.page || false,
    parentId: config?.parentId || null,
    children:
      (config?.id && childrenList.filter(el => el.parentId === config.id)) ||
      [],
    creativeTypes: config?.creativeTypes || []
  };

  const creativeTypesOptions = [
    { label: 'Audio', value: 'audio' },
    { label: 'Image', value: 'image' },
    { label: 'Search', value: 'search' },
    { label: 'Video', value: 'video' },
    { label: 'SMS', value: 'sms' },
    { label: 'Email', value: 'email' }
  ];

  const [
    {
      name,
      model,
      key,
      types,
      minimumBudget,
      minimumAudience,
      dailyMinimumBudget,
      documentation,
      page,
      parentId,
      children,
      creativeTypes
    },
    setState
  ] = useState(initialState);
  const [{ errorKey, errorName, errorType }, setError] = useState(ErrorState);

  const toggleErrorKey = state => {
    setError(prevState => ({ ...prevState, errorKey: state }));
  };

  const toggleErrorName = state => {
    setError(prevState => ({ ...prevState, errorName: state }));
  };

  const toggleErrorTypes = state => {
    setError(prevState => ({ ...prevState, errorType: state }));
  };

  const toggleErrorCreativeTypes = state => {
    setError(prevState => ({ ...prevState, errorCreativeTypes: state }));
  };

  const handleChange = field => value => {
    let newValue = typeof value === 'string' ? value.trim() : value;
    if (field === 'name') {
      toggleErrorName(!newValue);
    }

    if (field === 'types') {
      newValue = value.map(el => {
        if (typeof el === 'string') {
          return el;
        }
        return el.value;
      });
    }
    if (field === 'creativeTypes') {
      newValue = value.map(el => {
        if (typeof el === 'string') {
          return el;
        }
        return el.value;
      });
    }
    setState(prevState => ({
      ...prevState,
      [field]: newValue
    }));
  };

  const handleChangeParent = value => {
    setState(prevState => ({
      ...prevState,
      parentId: parentList.find(f => f.key === value[0]?.key)?.id || '',
      children: []
    }));
  };

  const handleChangeChildren = value => {
    setState(prevState => ({
      ...prevState,
      parent: [],
      children: value,
      selectedCriterias: []
    }));
  };

  const resetStates = () => {
    setState(initialState);
    setError(ErrorState);
  };

  const doAction = () => {
    let error = false;

    if (!name) {
      toggleErrorName(true);
      error = true;
    }
    if (!key) {
      toggleErrorKey(true);
      error = true;
    }
    if (types.length === 0) {
      toggleErrorTypes(true);
      error = true;
    }
    if (creativeTypes.length === 0) {
      toggleErrorCreativeTypes(true);
      error = true;
    }
    if (!error) {
      const datas = {
        name,
        model,
        key,
        types,
        channels: types,
        creativeTypes,
        minimumBudget,
        minimumAudience,
        dailyMinimumBudget,
        documentation: documentation.reduce((acc, doc) => {
          acc[langues.find(langue => langue.label === doc.langue).key] =
            doc.url;
          return acc;
        }, {}),
        parentId,
        children: children.map(child => child.id),
        page
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le média activable
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const getClearIcon = () => {
    return <ClearIcon style={{ color: themeColors.light }} />;
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Média" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Nom du média"
                          required
                          placeholder=""
                          error={errorName}
                          onChange={handleChange('name')}
                          value={name}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="key"
                          required
                          placeholder=""
                          error={errorKey}
                          onChange={handleChange('key')}
                          value={key}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Select
                          title="Connecteur"
                          options={connexionsGenerator.map(el => el.name)}
                          onChange={handleChange('model')}
                          value={model}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Budget minimum par jour"
                          placeholder=""
                          onChange={handleChange('dailyMinimumBudget')}
                          value={dailyMinimumBudget}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Budget minimum"
                          onChange={handleChange('minimumBudget')}
                          value={minimumBudget}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Audience minimum"
                          placeholder=""
                          onChange={handleChange('minimumAudience')}
                          value={minimumAudience}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Switch
                          title="Page"
                          onChange={handleChange('page')}
                          checked={page}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Canaux*" xs={3} error={errorType}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item>
                    <ListField
                      options={channels.map(el => {
                        return {
                          label: el?.label,
                          value: el?.key
                        };
                      })}
                      searchOptionsKeys={['label']}
                      values={types.map(el => {
                        const value = channels.find(
                          channel => channel.key === el
                        );
                        return { label: value?.label, value: value?.key };
                      })}
                      onChange={handleChange('types')}
                      actionTxt="Ajouter"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Type de créatives*" xs={3} error={errorType}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item>
                    <ListField
                      options={creativeTypesOptions}
                      searchOptionsKeys={['label']}
                      values={creativeTypes.map(type =>
                        creativeTypesOptions.find(el => el.value === type)
                      )}
                      onChange={handleChange('creativeTypes')}
                      actionTxt="Ajouter"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Documentation" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item>
                    <ListField
                      labels={['Langue', 'Url']}
                      options={['langue', 'url']}
                      inputs={['Select', 'TextField']}
                      isRequireds={[false, false]}
                      values={documentation}
                      formatedValues={[langues]}
                      fieldsFormat={[langues]}
                      selectNbMax={2}
                      valueUnique
                      variant="customFields"
                      onChange={handleChange('documentation')}
                      actionTxt="Ajouter"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={0}>
          <Item justify="flex-start">
            <FormSection title="Média parent">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {children.length !== 0 ? (
                      <Text>
                        Vous ne pouvez pas sélectionner de media parent
                        lorsqu&lsquo;un média enfant est défini.
                      </Text>
                    ) : (
                      <ListField
                        options={parentList}
                        values={parentList.filter(el => el.id === parentId)}
                        searchOptionsKeys={['name', 'key']}
                        hideKeys={['id']}
                        onChange={handleChangeParent}
                        actionTxt="Ajouter un parent"
                        placeholder=""
                        deleteAction
                        textFieldOnTop
                        selectNbMax={1}
                        deleteIcon={getClearIcon()}
                      />
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={0}>
          <Item justify="flex-start">
            <FormSection title="Médias enfants">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {parentId ? (
                      <Text>
                        Vous ne pouvez pas sélectionner de medias enfants
                        lorsqu&lsquo;un média parent est défini.
                      </Text>
                    ) : (
                      <ListField
                        options={childrenList}
                        values={children}
                        searchOptionsKeys={['name', 'key']}
                        hideKeys={['id', 'parentId']}
                        onChange={handleChangeChildren}
                        actionTxt="Ajouter un enfant"
                        placeholder=""
                        deleteAction
                        useWarning
                        warningColor="global"
                        textFieldOnTop
                        deleteIcon={getClearIcon()}
                      />
                    )}
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalForm;
