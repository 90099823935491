import React from 'react';
import PropTypes from 'prop-types';

import numeral from 'utils/numeral';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  keyError: false,
  labelError: false,
  nbEmailsError: false,
  nbEmailsWithCookieError: false,
  proxyKeyError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionName, update, proxyKeys, mediaAll } = props;
  const initialState = {
    key: config.key || null,
    label: config.label || null,
    nbEmails: config.nbEmails || 0,
    nbEmailsWithCookie: config.nbEmailsWithCookie || 0,
    proxyKey: config.proxyKey || null,
    percentEmails: config?.percentEmails || 0,
    percentEmailsWithCookie: config?.percentEmailsWithCookie || 0
  };
  const [
    {
      key,
      label,
      nbEmails,
      nbEmailsWithCookie,
      proxyKey,
      percentEmails,
      percentEmailsWithCookie
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      keyError,
      labelError,
      nbEmailsError,
      nbEmailsWithCookieError,
      proxyKeyError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = itemKey => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      if (itemKey === 'nbEmails') {
        states.percentEmails =
          key !== mediaAll.key
            ? (parseFloat(text) / mediaAll.nbEmails) * 100
            : 100;
      }
      if (itemKey === 'nbEmailsWithCookie') {
        states.percentEmailsWithCookie =
          key !== mediaAll.key
            ? (parseFloat(text) / mediaAll.nbEmailsWithCookie) * 100
            : 100;
      }
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (typeof label === 'undefined') {
      toggleError('label', true);
      error = true;
    }
    if (typeof nbEmails === 'undefined') {
      toggleError('nbEmails', true);
      error = true;
    }
    if (typeof nbEmailsWithCookie === 'undefined') {
      toggleError('nbEmailsWithCookie', true);
      error = true;
    }
    if (proxyKey === null) {
      toggleError('proxyKey', true);
      error = true;
    }

    if (!error) {
      const datas = {
        key,
        label,
        nbEmails,
        nbEmailsWithCookie,
        percentEmails,
        percentEmailsWithCookie,
        proxyKey
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Key</Text>
                          <TextField
                            small
                            value={key}
                            disabled={update}
                            onChange={handleChange('key')}
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Canal</Text>
                          <TextField
                            small
                            value={label}
                            disabled={update}
                            onChange={handleChange('label')}
                            error={labelError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Choix du media proxy *</Text>
                          <Select
                            small
                            value={proxyKey}
                            onChange={handleChange('proxyKey')}
                            error={proxyKeyError}
                            options={proxyKeys}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Nombre d'emails" xs={2}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>% total pour la cible</Text>
                          <TextField
                            small
                            value={numeral(percentEmails).format('0,0[.]00')}
                            error={nbEmailsError}
                            format="numberDec"
                            unit="%"
                            disabled
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Nombre de emails hashés *</Text>
                          <TextField
                            small
                            value={nbEmails}
                            onChange={handleChange('nbEmails')}
                            error={nbEmailsError}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>% total pour la cible</Text>
                          <TextField
                            small
                            value={numeral(percentEmailsWithCookie).format(
                              '0,0[.]00'
                            )}
                            error={nbEmailsWithCookieError}
                            format="numberDec"
                            unit="%"
                            disabled
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text>Nombre de emails hashés avec cookies *</Text>
                          <TextField
                            small
                            value={nbEmailsWithCookie}
                            onChange={handleChange('nbEmailsWithCookie')}
                            error={nbEmailsWithCookieError}
                            format="numberDec"
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionName: null,
  update: false,
  proxyKeys: []
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool,
  proxyKeys: PropTypes.arrayOf(
    PropTypes.objectOf({ value: PropTypes.string, label: PropTypes.string })
  )
};

export default ModalForm;
