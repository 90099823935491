import React from 'react';
import PropTypes from 'prop-types';
// import IconActivation from 'Activation/components/IconActivation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { Text } from 'components/DataDisplay';

import BaseDropdown, { ArrowDown } from './style';

/**
 * IconArrow
 * @component
 *
 */
const IconArrow = p => {
  return (
    <ArrowDown
      {...p}
      className={`custom_select_arrow ${p.className}`}
      src="/assets/img/picto_arrow_down.svg"
      alt="fleche"
    />
  );
};

/**
 * BasicDropdown
 * @component
 *
 */
const BasicDropdown = props => {
  const { handleChange, defaultDisplay, options } = props;
  const onClick = (e, opt) => {
    e.preventDefault();
    handleChange(opt);
  };
  return (
    <>
      <button className="dropbtn" type="button">
        {defaultDisplay}
        <ExpandMoreIcon style={{ fill: 'white' }} />
      </button>
      <div className="dropdown-content">
        {options.map((option, index) => (
          <Text
            // eslint-disable-next-line react/no-array-index-key
            key={`dropdown_${index}`}
            onClick={e => onClick(e, option)}
            link
          >
            {option}
          </Text>
        ))}
      </div>
    </>
  );
};
BasicDropdown.propTypes = {
  defaultDisplay: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

/**
 * MUIDropDown
 * @component
 *
 */
const MUIDropDown = props => {
  const { selected, handleChange, IconCustom, defaultValue, options } = props;
  const { noIcon } = props;
  const onChange = e => {
    handleChange(e.target.value);
  };
  const IconComponent = p =>
    IconCustom ? <IconCustom {...p} /> : <IconArrow {...p} />;
  const iconProp = noIcon
    ? {}
    : {
        IconComponent: p => <IconComponent {...p} className={p.className} />
      };
  return (
    <FormControl variant="filled" className="form-control">
      <Select
        native
        value={selected || defaultValue}
        autoWidth
        onChange={onChange}
        {...iconProp}
        className="dropSelect"
        input={<FilledInput name="age" id="filled-age-native-simple" />}
      >
        {defaultValue && (
          <option value={defaultValue} disabled>
            {defaultValue}
          </option>
        )}
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
MUIDropDown.defaultProps = {
  IconCustom: undefined,
  defaultValue: '',
  noIcon: false
};
MUIDropDown.propTypes = {
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string.isRequired,
  IconCustom: PropTypes.node,
  noIcon: PropTypes.bool
};

/**
 *
 * Dropdown
 * @component
 *
 *
 */
const Dropdown = props => {
  const {
    options,
    onSelectChange,
    defaultValue,
    selected: defaultSelected,
    label,
    fix,
    isMui,
    Icon,
    noIcon,
    ...rest
  } = props;
  const [selected, setSelected] = React.useState(defaultSelected);
  const handleChange = value => {
    setSelected(value);
    onSelectChange(value);
  };
  const defaultDisplay = fix ? defaultValue : selected || defaultValue;
  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);
  return (
    <BaseDropdown {...rest}>
      <span className="label">{label}</span>
      {isMui ? (
        <MUIDropDown
          selected={selected}
          handleChange={handleChange}
          defaultValue={defaultValue}
          options={options}
          IconCustom={Icon}
          noIcon={noIcon}
          {...rest}
        />
      ) : (
        <BasicDropdown
          handleChange={handleChange}
          defaultDisplay={defaultDisplay}
          options={options}
        />
      )}
    </BaseDropdown>
  );
};
Dropdown.defaultProps = {
  Icon: undefined,
  defaultValue: 'Sélectionner une option',
  fix: false,
  isMui: false,
  label: '',
  onSelectChange: () => null,
  options: [],
  noIcon: false,
  selected: ''
};
Dropdown.propTypes = {
  Icon: PropTypes.node,
  defaultValue: PropTypes.string,
  fix: PropTypes.bool,
  isMui: PropTypes.bool,
  label: PropTypes.string,
  onSelectChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  noIcon: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Dropdown;
