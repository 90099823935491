import { api } from './api';

const getActivatedAudiences = async () => {
  try {
    const result = await api.get(`/insightActivatedAudiences`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateActivatedAudiences = async (id, datas) => {
  try {
    const result = await api.put(`/insightActivatedAudiences/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createActivatedAudiences = async datas => {
  try {
    const result = await api.post(`/insightActivatedAudiences`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const deleteActivatedAudiences = async id => {
  try {
    const result = await api.delete(`/insightActivatedAudiences/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getActivatedAudiences,
  updateActivatedAudiences,
  createActivatedAudiences,
  deleteActivatedAudiences
};
