import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATEDAUDIENCES, EXTRACTAUDIENCES, GROUP } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const ActivatedAudiencesContainer = () => {
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [activatedAudiencesList, setActivatedAudiencesList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedActivatedAudience, setSelectedActivatedAudience] = useState(
    {}
  );
  const [selectedExtractAudiences, setSelectedExtractAudiences] = useState({});
  const [allGroups, setAllGroups] = useState({});

  const selectActivatedAudiences = id => {
    const result = activatedAudiencesList.find(el => el._id === id);
    setSelectedActivatedAudience(result);
  };
  const closeModal = () => {
    setModal(null);
    selectActivatedAudiences({});
  };
  const fetchDatas = async () => {
    try {
      const groups = await GROUP.getGroups();
      const activatedAudiences = await ACTIVATEDAUDIENCES.getActivatedAudiences();
      const extract = await EXTRACTAUDIENCES.getExtractAudiences();
      const extractAudiences = extract.map(el => {
        return { name: el.name, _id: el._id };
      });
      return { activatedAudiences, extractAudiences, groups };
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.audiences?.activatedAudience?.update) {
      selectActivatedAudiences(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onAdd = async () => {
    if (adminRights?.audiences?.activatedAudience?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async datas => {
    for await (const el of datas.selectedGroup) {
      const newActivatedAudience = allGroups.find(e => e._id === el._id)
        .activatedAudiences;
      if (!newActivatedAudience.includes(selectedActivatedAudience._id)) {
        newActivatedAudience.push(selectedActivatedAudience._id);
        GROUP.updateV2(el._id, {
          activatedAudiences: newActivatedAudience
        });
      }
    }
    for await (const el of datas.removedGroup) {
      const oldActivatedAudience = allGroups.find(e => e._id === el._id)
        .activatedAudiences;
      if (oldActivatedAudience.includes(selectedActivatedAudience._id)) {
        const newActivatedAudience = oldActivatedAudience.filter(
          e => e !== selectedActivatedAudience._id
        );
        GROUP.updateV2(el._id, {
          activatedAudiences: newActivatedAudience
        });
      }
    }
    await ACTIVATEDAUDIENCES.updateActivatedAudiences(
      selectedActivatedAudience._id,
      datas
    );
    setLoaded(false);
    closeModal();
  };

  const onCreate = async datas => {
    const rslt = await ACTIVATEDAUDIENCES.createActivatedAudiences({
      name: datas.name,
      extractId: datas.extractId
    });

    for await (const el of datas.selectedGroup) {
      const newActivatedAudience = allGroups.find(e => e._id === el._id)
        .activatedAudiences;
      if (!newActivatedAudience.includes(rslt._id)) {
        newActivatedAudience.push(rslt._id);
        GROUP.updateV2(el._id, {
          activatedAudiences: newActivatedAudience
        });
      }
    }
    setLoaded(false);
    closeModal();
  };

  const openDelete = id => {
    if (adminRights?.audiences?.activatedAudience?.delete) {
      setSelectedActivatedAudience(id);
      setModal('modalDelete');
    } else {
      setModal('unauthorized');
    }
  };
  const onDelete = async () => {
    const toDelete = allGroups.filter(el =>
      el.activatedAudiences.find(e => e === selectedActivatedAudience)
    );
    for await (const el of toDelete) {
      GROUP.updateV2(el._id, {
        activatedAudiences: el.activatedAudiences.filter(
          e => e !== selectedActivatedAudience
        )
      });
    }
    await ACTIVATEDAUDIENCES.deleteActivatedAudiences(
      selectedActivatedAudience
    );
    setLoaded(false);
    closeModal();
  };

  useEffect(() => {
    if (!loaded) {
      fetchDatas().then(({ activatedAudiences, extractAudiences, groups }) => {
        setLoaded(true);
        setSelectedExtractAudiences(extractAudiences);
        setAllGroups(groups);
        setActivatedAudiencesList(activatedAudiences);
        const mapped = activatedAudiences.map(item => {
          const newItem = {};
          newItem._id = item._id;
          newItem.name = item.name;
          newItem.extractId = item.extractId;
          return newItem;
        });
        setRows(mapped);
        setAllRows(mapped);
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher une audience"
            placeholder="ID, Nom, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={onAdd} text="Ajouter une audience" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              config={{ selectedExtractAudiences, allGroups }}
              onValidate={onCreate}
              actionName="Créer une audience"
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'ID',
                  id: '_id',
                  sortKey: '_id'
                },
                {
                  label: 'Nom',
                  id: 'name',
                  sortKey: 'name'
                },
                {
                  label: 'extractId',
                  id: 'extractId'
                }
              ]}
              onUpdate={openUpdateModal}
              onDelete={openDelete}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                selectedActivatedAudience,
                selectedExtractAudiences,
                allGroups
              }}
              actionName="Modifier le média"
              update
            />
          )}
          {openModal === 'modalDelete' && (
            <ModalDelete
              type="cette audience"
              open={openModal === 'modalDelete'}
              onClose={closeModal}
              onDelete={onDelete}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ActivatedAudiencesContainer;
