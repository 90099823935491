import React from 'react';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import {
  Button,
  Checkbox,
  IconButton,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { usePlatformConfig } from 'contexts/PlatformConfigContext';

export const ModalForm = props => {
  const { themeColors } = useTheme();

  const { onValidate, config } = props;
  const { selectInsight, formats, mergeFormats, setMergeFormats } = config;
  const profilia = ['email', 'sms', 'mob'];
  const [parts, setParts] = React.useState([
    'PRO',
    'EMB',
    'CAR',
    'CARDATA',
    'WEL'
  ]);
  const initialState = {
    checkedValue: formats.reduce((acc, el) => {
      acc[el] = false;
      return acc;
    }, {})
  };
  const [{ checkedValue }, setState] = React.useState(initialState);
  const [isProfilia, setProfilia] = React.useState(false);
  const [maxLinePerFile, setMaxLinePerFile] = React.useState(0);
  const [professionnalData, setProData] = React.useState(false);
  const [uncrypted, setUncrypted] = React.useState(false);
  const { canExportUncrypted } = usePlatformConfig();

  const handleChangeFormat = (itemKey, reset) => value => {
    setState(prevState => {
      const states = { ...prevState };
      if (reset) {
        states.checkedValue = {
          [itemKey]: value.target.checked
        };
        setProfilia(true);
      } else {
        profilia.forEach(el => {
          states.checkedValue[el] = false;
        });
        setProfilia(false);
        states.checkedValue[itemKey] = value.target.checked;
      }
      return states;
    });
  };

  const selectAll = () => value => {
    setState(prevState => {
      const states = { ...prevState };
      profilia.forEach(el => {
        states.checkedValue[el] = false;
      });
      setProfilia(false);
      formats.forEach(el => {
        states.checkedValue[el] = value.target.checked;
      });
      return states;
    });
  };

  const generateCheckBox = (values, reset = false) => {
    return values.map(el => (
      <Item xs={3}>
        <Text>{el}</Text>
        <Checkbox
          onChange={handleChangeFormat(el, reset)}
          checked={!!checkedValue[el]}
        />
      </Item>
    ));
  };

  const selectMaxLine = value => {
    setMaxLinePerFile(value);
  };

  const selectProData = () => {
    setProData(!professionnalData);
  };

  const switchUncrypted = () => {
    setUncrypted(!uncrypted);
  };

  const sortParts = (index, up) => () => {
    const newParts = [...parts];
    newParts.splice(up ? index : index, 1);
    newParts.splice(up ? index - 1 : index + 1, 0, parts[index]);
    setParts(newParts);
  };

  const generateParts = values => {
    return values.map((el, index) => (
      <Row spacing={2}>
        <Item
          xs={4}
          style={{ borderBottom: `1px solid ${themeColors.greyLight}` }}
        >
          <Item xs={4}>
            <Text bold>{el}</Text>
          </Item>
          <Item xs={4}>
            {index === 0 ? null : (
              <IconButton
                style={{ color: themeColors.light }}
                onClick={sortParts(index, true)}
              >
                <ArrowUpwardIcon />
              </IconButton>
            )}
            {index === parts.length - 1 ? null : (
              <IconButton
                style={{ color: themeColors.light }}
                onClick={sortParts(index, false)}
              >
                <ArrowDownwardIcon />
              </IconButton>
            )}
          </Item>
        </Item>
      </Row>
    ));
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    if (Object.values(checkedValue).filter(el => el).length === 0) {
      return;
    }
    const maxLine = parseInt(maxLinePerFile, 10) || 0;
    onValidate({
      formats: Object.keys(checkedValue).reduce((acc, el) => {
        if (checkedValue[el] === true) {
          return [...acc, el];
        }
        return acc;
      }, []),
      profilia: isProfilia,
      parts,
      maxLinePerFile: maxLine < 10000 ? 0 : maxLine,
      professionnalData,
      uncrypted
    });
    resetStates();
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs>
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              Valider
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item>
            <Text>Segment sélectionné</Text>
          </Item>
        </Row>
        <Row>
          <Item justify="flex-start">
            <Text>Segment:&nbsp;</Text>
            <Text bold color={themeColors.audience}>
              {selectInsight?.name}
            </Text>
          </Item>
        </Row>
        <Row>
          <Item justify="flex-start">
            <Text>Nombre de profils:&nbsp;</Text>
            <Text bold color={themeColors.audience}>
              {selectInsight?.result?.nbProfiles}
            </Text>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Choix du format" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Item xs={12}>
                      <Text>Tout sélectionner</Text>
                      <Checkbox
                        onChange={selectAll()}
                        checked={
                          Object.values(checkedValue).filter(el => el)
                            .length === formats.length
                        }
                      />
                    </Item>
                    {generateCheckBox(formats, false)}
                    <Switch title='fusionner les formats' checked={mergeFormats} color={themeColors.audience} onChange={e => setMergeFormats(e)}/>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Découpage du fichier" xs={3}>
              <Container>
                {isProfilia ? (
                  <Text>Disponible uniquement pour le choix avec format</Text>
                ) : (
                  <Row spacing={0} justify="flex-start">
                    <Item xs={4}>
                      <TextField
                        title="Nombre de lignes maximum par fichier"
                        small
                        value={maxLinePerFile}
                        onChange={selectMaxLine}
                        format="numberDec"
                        unit={
                          maxLinePerFile >= 10000
                            ? 'lignes max'
                            : '(< 10.000 = pas de limite)'
                        }
                      />
                    </Item>
                  </Row>
                )}
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Données professionnelles" xs={3}>
              <Container>
                {isProfilia ? (
                  <Text>Disponible uniquement pour le choix avec format</Text>
                ) : (
                  <Row spacing={0} justify="flex-start">
                    <Switch
                      checked={professionnalData}
                      onChange={selectProData}
                    />
                  </Row>
                )}
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Ou choix pour contact" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    {generateCheckBox(profilia, true)}
                  </Item>
                  <Item justify="flex-start">{generateParts(parts)}</Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {canExportUncrypted && <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Données en clair" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <Switch
                      checked={uncrypted}
                      onChange={switchUncrypted}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>}
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null,
  update: false
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool
};

export default ModalForm;
