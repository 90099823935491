import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { IconButton, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const TagFunnel = ({ options, onChange, error, value }) => {
  const [tag, setTag] = React.useState(value.tag || null);
  const [corresp, setCorresp] = React.useState(value.correspondences || []);
  const funnelLevels = [
    { label: 'Etape 1 du funnel (impression)', value: 'kpi1' },
    { label: 'Etape 2 du funnel (visite)', value: 'kpi2' },
    { label: 'Etape 3 du funnel (engagement)', value: 'kpi3' },
    { label: 'Etape 4 du funnel (acquisition)', value: 'kpi4' },
    { label: 'Nectar', value: 'nectar' }
  ];

  React.useEffect(() => {
    onChange({
      tag,
      correspondences: corresp
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, corresp]);

  const handleTagChange = change => {
    setTag(options.find(f => `${f.name} (${f._id})` === change));
    setCorresp([]);
  };

  const handleCorrespChange = (data, index, field) => {
    setCorresp(
      corresp.map((e, mapIndex) => {
        if (mapIndex === index)
          return {
            ...e,
            [field]: data
          };
        return e;
      })
    );
  };

  const addCorresp = () =>
    setCorresp(corresp.concat({ funnelLevel: null, tagLevel: null }));

  const removeCorresp = index => {
    setCorresp(corresp.filter((_, fIndex) => fIndex !== index));
  };

  const getFunnelLevel = funnelStepValue => {
    const selected = funnelLevels.find(el => el.value === funnelStepValue);
    return selected && selected.label;
  };

  return (
    <Container>
      <Row spacing={0}>
        <Item xs={6} justify="flex-start">
          <Container>
            <Row spacing={0}>
              <Item xs={11} justify="flex-start">
                <Text>Tag</Text>
                <Select
                  onChange={handleTagChange}
                  options={options.map(e => `${e.name} (${e._id})`)}
                  defaultValue="aucun"
                  value={tag ? `${tag.name} (${tag._id})` : null}
                  error={error && tag === null}
                  small
                />
              </Item>
              <Item xs />
            </Row>
          </Container>
        </Item>
        <Item xs />
      </Row>
      {tag && corresp.length === 0 && (
        <Row spacing={2} justify="flex-start">
          <Item xs={3} justify="flex-start">
            <Text>Aucun niveau de funnel définit.</Text>
          </Item>
        </Row>
      )}
      {corresp.map((e, index) => (
        <Row spacing={0} justify="space-between">
          <Item xs={2} justify="flex-start">
            <Text>Correspondance {index + 1}:</Text>
          </Item>
          <Item xs={4} justify="flex-start">
            <Text>Niveau du funnel *</Text>
            <Select
              onChange={data =>
                handleCorrespChange(
                  funnelLevels.find(f => f.label === data).value,
                  index,
                  'funnelLevel'
                )
              }
              options={funnelLevels.map(el => el.label)}
              defaultValue="aucun"
              value={getFunnelLevel(corresp[index].funnelLevel)}
              error={error && corresp[index].funnelLevel === null}
              small
            />
          </Item>
          <Item xs />
          <Item xs={4} justify="flex-start">
            <Text>Niveau du tag *</Text>
            <Select
              onChange={data =>
                handleCorrespChange(
                  tag.funnelLevels.find(f => f.label === data),
                  index,
                  'tagLevel'
                )
              }
              options={tag.funnelLevels.map(funnelLvl => funnelLvl.label)}
              defaultValue="aucun"
              value={
                corresp[index].tagLevel ? corresp[index].tagLevel.label : null
              }
              error={error && corresp[index].tagLevel === null}
              small
            />
          </Item>
          <Item xs justify="center">
            <IconButton
              onClick={() => removeCorresp(index)}
              aria-label={`remove correspondence n°${index + 1}`}
            >
              <DeleteIcon />
            </IconButton>
          </Item>
        </Row>
      ))}
      {tag && corresp.length < 5 && (
        <Row spacing={0} justify="flex-start">
          <Item xs={3} justify="flex-start">
            <ButtonCreate onClick={addCorresp} text="Ajouter un niveau" />
          </Item>
        </Row>
      )}
    </Container>
  );
};

TagFunnel.defaultProps = {
  error: false,
  value: {}
};
TagFunnel.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
  ]).isRequired,
  error: PropTypes.bool,
  value: PropTypes.objectOf(PropTypes.any)
};

export default TagFunnel;
