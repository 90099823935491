import React, { useEffect, useState } from 'react';

import { exportXlsxFile } from 'utils/functions';
import moment from 'utils/moment';
import { FUNNEL, PROPERTIES } from 'utils/rest';

import { FormSection } from 'components/FormSection';
import { AutocompleteTextField, Button, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';

import PeriodFunnel from '../../../components/funnel/PeriodFunnel';

const ErrorState = {
  nameError: false,
  startError: false,
  endError: false,
  endDataError: false,
  startDataError: false
};

const ExportContainer = () => {
  const [granularity, setGranularity] = useState('media');
  const [options, setOptions] = useState([]);
  const [selectedFunnels, setSelectedFunnels] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [{ start, end, startData, endData }, setState] = useState({
    start: null,
    end: null,
    startData: null,
    endData: null
  });
  const [
    { startError, endError, endDataError, startDataError },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [`${key}Error`]: state }));
  };

  const onAutoCompliteChange = value => {
    toggleError('name', false);
    setSelectedFunnels([value]);
  };

  const getDateFromData = data => {
    if (!data) return data;
    if (typeof data === 'string') return new Date(data);
    if (data.toDate) return data.toDate();
    return data;
  };

  const setPeriod = async ({ startDate, endDate }) => {
    toggleError('start', !startDate);
    toggleError('end', !endDate);
    setState(prevState => ({ ...prevState, start: startDate, end: endDate }));
  };

  const setPeriodData = async ({ startDate, endDate }) => {
    toggleError('startData', !startDate);
    toggleError('endData', !endDate);
    setState(prevState => ({
      ...prevState,
      startData: startDate,
      endData: endDate
    }));
  };

  const findPropertie = (id, properties) => {
    for (const propertie of properties) {
      for (const funnelId of propertie.funnelIds) {
        if (funnelId === id) {
          return propertie.name;
        }
      }
    }
    return 'Autre';
  };

  useEffect(() => {
    const fetchData = async () => {
      const [{ funnels }, properties] = await Promise.all([
        FUNNEL.getFunnels(['name', 'email']),
        PROPERTIES.get()
      ]);

      setOptions(
        funnels.map(e => {
          const propertie = findPropertie(e._id, properties);
          return {
            name: e.name,
            email: e.email,
            id: e._id,
            propertie
          };
        })
      );
      setLoaded(true);
    };
    fetchData();
  }, []);

  const searchOptionsKeys = ['name', 'id', 'propertie'];

  const onclick = async () => {
    let error = false;
    const isMedia = granularity === 'media';
    if (!start) {
      error = true;
      toggleError('start', true);
    }
    if (!end) {
      error = true;
      toggleError('end', true);
    }
    if (selectedFunnels.length === 0) {
      error = true;
      toggleError('name', true);
    }

    if (error) {
      return;
    }
    setIsLoading(true);

    const methode =
      granularity === 'media'
        ? FUNNEL.getCampaignDetailsTsMedia
        : FUNNEL.getCampaignDetailsTs;

    const campaigns = await methode({
      funnelId: selectedFunnels[0].id,
      start: start.utc().startOf('day'),
      end: end.utc().endOf('day')
    });

    const columns = [
      { label: 'Date', value: 'date', format: 'dd/mm/yyyy' },
      { label: 'Propriété', value: 'property' },
      { label: 'Id du funnel', value: 'funnelId' },
      { label: 'Nom du funnel', value: 'funnelName' },
      { label: 'Média', value: 'media' },
      { label: 'Campagne', value: 'ioName' },
      { label: 'Ensemble de publicité', value: 'lineItemName' },
      { label: 'Publicité', value: 'adName' },
      { label: 'Budget dépensé', value: 'budget' },
      { label: 'Budget data', value: 'dataBudget' },
      { label: 'Budget Média', value: 'mediaBudget' },
      { label: 'Impressions', value: 'volume1' },
      { label: 'Clics', value: 'volumeClics' },
      { label: 'Conversions', value: 'volumeConversions' },
      { label: 'MediaCost', value: 'mediaCost' },
      { label: 'Marge', value: 'marge' },
      { label: 'mandat', value: 'payingAgent' }
    ];

    const content = [];
    const ref = {};

    campaigns.map(({ timeseries, ...campaign }) => {
      return timeseries.map(timeserie => {
        const { date } = timeserie;
        const { funnelId } = campaign;
        if (funnelId && !ref[funnelId]) {
          ref[funnelId] = options?.find(el => el.id === funnelId)?.propertie;
        }
        return content.push({
          ...campaign,
          ...timeserie,
          date: moment(date, 'DD/MM/YYYY')
            .startOf('day')
            .toDate(),
          marge: timeserie.mediaCost / timeserie.budget || 0,
          property: ref[funnelId]
        });
      });
    });

    const filteredColumns = isMedia
      ? columns.filter(
          column => !['ioName', 'lineItemName', 'adName'].includes(column.value)
        )
      : columns.filter(
          column =>
            !['property', 'funnelId', 'funnelName'].includes(column.value)
        );

    const data = [
      {
        sheet: 'Campagnes',
        columns: filteredColumns,
        content
      }
    ];

    exportXlsxFile(
      data,
      `${selectedFunnels[0].name}_${start.format('DDMMYYYY')}-${end.format(
        'DDMMYYYY'
      )}`
    );

    setIsLoading(false);
  };

  const onclickData = async () => {
    let error = false;
    if (!startData) {
      error = true;
      toggleError('startData', true);
    }
    if (!endData) {
      error = true;
      toggleError('endData', true);
    }

    if (error) {
      return;
    }

    setIsLoading(true);

    const dataInvoicings = await FUNNEL.getDataInvoicing({
      start: startData
        .utc()
        .startOf('day')
        .toISOString(),
      end: endData
        .utc()
        .endOf('day')
        .toISOString()
    });

    const columns = [
      { label: 'Organisation', value: 'organisation' },
      { label: 'Propriété', value: 'property' },
      { label: 'Id du funnel', value: 'funnelId' },
      { label: 'Nom du funnel', value: 'funnelName' },
      { label: "Id de l'Audience", value: 'segmentId' },
      { label: "Nom de l'Audience", value: 'segmentName' },
      { label: "Description l'Audience", value: 'segmentDescription' },
      { label: 'metadataId', value: 'metadataId' },
      { label: 'Nom du partenaire', value: 'partnerName' },
      { label: 'Revenu partenaire', value: 'amount' },
      { label: 'Revenu Total', value: 'partnerTotalAmount' },
      { label: 'Impressions', value: 'impressions' }
    ];

    const content = [];

    dataInvoicings.forEach(
      ({
        organisation,
        property,
        funnelId,
        funnelName,
        segmentId,
        segmentName,
        segmentDescription,
        partnerTotalAmount,
        metadataId,
        sourceRevenues,
        impressions
      }) => {
        sourceRevenues.forEach(({ name: partnerName, amount }) => {
          content.push({
            organisation,
            property,
            funnelId,
            funnelName,
            segmentId,
            segmentName,
            segmentDescription,
            metadataId,
            partnerName,
            amount,
            partnerTotalAmount,
            impressions
          });
        });
      }
    );

    const data = [
      {
        sheet: 'Revenue Data',
        columns,
        content
      }
    ];

    exportXlsxFile(
      data,
      `dataInvoicing_${startData.format('DDMMYYYY')}-${endData.format(
        'DDMMYYYY'
      )}`
    );

    setIsLoading(false);
  };

  return (
    <Container>
      <Loading loading={!loaded}>
        <Row spacing={4} justify="center">
          <Item xs={10}>
            <FormSection title="Funnel">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={8} justify="flex-start">
                    <AutocompleteTextField
                      title="Rechercher un funnel"
                      required
                      options={options}
                      formatLabel={option => {
                        let str = option[searchOptionsKeys[0]];
                        if (searchOptionsKeys[1]) {
                          str += ` (${option[searchOptionsKeys[1]]})`;
                        }
                        if (searchOptionsKeys[2]) {
                          str = `${option[searchOptionsKeys[2]]} - ${str}`;
                        }
                        return str;
                      }}
                      searchOptionsKeys={searchOptionsKeys}
                      small
                      onChange={onAutoCompliteChange}
                      placeholder="Funnel leads"
                    />
                  </Item>
                  <Item xs={4}>
                    <Select
                      title="Granularité"
                      options={[
                        { value: 'media', label: 'Média' },
                        { value: 'ad', label: 'Ad' }
                      ]}
                      value={granularity}
                      onChange={setGranularity}
                    />
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <PeriodFunnel
                      label="Période à exporter"
                      error={startError || endError}
                      start={getDateFromData(start)}
                      end={getDateFromData(end)}
                      setPeriod={setPeriod}
                    />
                  </Item>
                </Row>
                <Row spacing={4} justify="flex-start">
                  <Item justify="flex-end">
                    <Button
                      onClick={onclick}
                      variant="contained"
                      loading={isLoading}
                    >
                      Exporter les données du funnel
                    </Button>
                  </Item>
                </Row>
              </Container>
            </FormSection>
            <FormSection title="Revenue Data">
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <PeriodFunnel
                      label="Période à exporter"
                      error={startDataError || endDataError}
                      start={getDateFromData(startData)}
                      end={getDateFromData(endData)}
                      setPeriod={setPeriodData}
                    />
                  </Item>
                </Row>
                <Row spacing={4} justify="flex-start">
                  <Item justify="flex-end">
                    <Button
                      onClick={onclickData}
                      variant="contained"
                      loading={isLoading}
                    >
                      Télécharger le rapport
                    </Button>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Loading>
    </Container>
  );
};

export default ExportContainer;
