// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Paper as PaperMui } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

export const Paper = props => {
  const { themeColors } = useTheme();
  const { children, elevation, variant, bgColor, style } = props;
  return (
    <PaperMui
      elevation={elevation}
      variant={variant}
      style={{
        flex: 'auto',
        backgroundColor: themeColors[bgColor] || 'transparent',
        ...style
      }}
      {...props}
    >
      {children}
    </PaperMui>
  );
};

Paper.defaultProps = {
  bgColor: null,
  children: null,
  elevation: 3,
  variant: 'elevation',
  style: {}
};
Paper.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node,
  elevation: PropTypes.number,
  style: PropTypes.shape(),
  variant: PropTypes.oneOf(['elevation', 'outlined'])
};

export default Paper;
