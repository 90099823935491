import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { useNavigation } from '../../contexts/NavigationContext';

import SidebarActivation, {
  SideItemActivation
} from './components/SidebarActivation';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */

const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { menu, setMenu } = useNavigation();
  const { adminRights } = useUser();
  const linkInfo = [
    {
      name: 'campaign',
      svg: 'setting',
      subtitle: 'Gérer les campagnes créées dans la plateforme.',
      surname: 'Campagnes',
      enabled: adminRights?.campaign?.campaign?.read || false
    },
    {
      name: 'campaignOptions',
      svg: 'setting',
      subtitle: "Gestion des options proposées à l'utilisateur.",
      surname: 'Options',
      enabled: adminRights?.campaign?.campaign?.read || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarActivation>
      {linkInfo.map(link => {
        return (
          <SideItemActivation
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarActivation>
  );
};

export default SidebarContainer;
