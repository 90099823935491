import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import pluralize from 'utils/pluralize';
import { ACTIVATION, GROUP, MEDIA, PML, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm } from './components/ModalForm';

const PropertiesContainer = () => {
  const [users, setUsers] = useState([]);
  const [medias, setMedias] = useState([]);
  const [insightShops, setInsightShop] = useState([]);
  const [shops, setShops] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selected, setSelected] = useState({});
  const [shopGroups, setShopGroups] = useState([]);
  const { themeColors } = useTheme();
  const { adminRights, user } = useUser();

  const closeModal = () => {
    setModal(null);
    setSelected({});
  };

  const selectShop = id => {
    const shop = shops.find(el => el._id === id);
    setSelected(shop);

    return shop;
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatUsers = usersList => {
    const nbUsers = usersList.length;
    if (nbUsers === 0) {
      return <Text>Aucun</Text>;
    }
    if (nbUsers === 1)
      return <Text color="pml"> {usersList[0] && usersList[0].email} </Text>;

    const others = nbUsers - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'utilisateur',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>{usersList.map(usr => getToolTipText(usr.email))}</Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="pml"> {usersList[0].email}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="pml">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatMedias = mediasReceived => {
    const count = mediasReceived.length;
    if (count === 0) {
      return <Text>Aucun</Text>;
    }
    if (count === 1) return <Text color="pml"> {mediasReceived[0]} </Text>;

    const others = count - 1;
    const str = ` ${others} ${pluralize('autre', others)} ${pluralize(
      'média',
      others
    )}`;

    const tooltipContent = () => {
      return (
        <Container>
          {mediasReceived.map(media => getToolTipText(media))}
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Text color="pml"> {mediasReceived[0]}&nbsp;</Text>
          </Item>
          <Item flex>
            <Text> et&nbsp;</Text>
          </Item>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="pml">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const getDatas = async () => {
    const shopsList = await ACTIVATION.getActivationShops();
    const shopGroupsList = await ACTIVATION.getActivationShopGoups();
    const userslist = await USERS.getAll();
    const mediasList = await MEDIA.getMedias();
    const insightShopsList = await PML.getInsightShops();
    const mapped = shopsList.map(item => {
      const newItem = { ...item };
      const usersReduce = item.users.reduce((acc, currentUser) => {
        const exist = userslist.find(el => el._id === currentUser);
        if (exist) {
          acc.push(exist);
        }
        return acc;
      }, []);

      const mediaReduce = item.acceptedMedias.reduce((acc, currentMedia) => {
        const exist = mediasList.find(el => el.ermesMediaKey === currentMedia);
        if (exist) {
          acc.push(exist.name);
        }
        return acc;
      }, []);
      newItem.users = formatUsers(usersReduce);
      newItem.medias = formatMedias(mediaReduce);
      return newItem;
    });

    setShopGroups(shopGroupsList);
    setUsers(userslist);
    setMedias(mediasList);
    setInsightShop(insightShopsList);
    setShops(shopsList);
    setRows(mapped);
    setAllRows(mapped);

    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  const updateGroup = async (data, activationShopId) => {
    const group = await GROUP.getGroupByShop({
      domain: user.domain,
      name: data.name,
      activationShopId
    });

    await GROUP.updateV2(group._id, {
      userIds: data.users,
      activationShopId
    });
  };

  const createGroup = async (data, activationShopId) => {
    const body = {
      domain: user.domain,
      name: data.name,
      userIds: data.users,
      activationShopId
    };
    await GROUP.create(body);
  };

  const updateShopGroup = async (shopId, shopGroup) => {
    const oldGroup = shopGroups.find(el => el.shops.find(e => e === shopId));
    if (oldGroup) {
      await ACTIVATION.updateActivationShopGoup(oldGroup._id, {
        shops: oldGroup.shops.filter(el => el !== shopId)
      });
    }
    if (shopGroup) {
      const newShopGroup = shopGroups.find(el => el._id === shopGroup.value);
      await ACTIVATION.updateActivationShopGoup(newShopGroup._id, {
        shops: [...newShopGroup.shops, shopId]
      });
    }
  };

  const openCreate = async () => {
    if (adminRights?.pml?.shop?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async (data, shopGroup) => {
    const rslt = await ACTIVATION.createActivationShop({
      ...data,
      domain: user.domain,
      owner: user.id
    });
    if (rslt._id) {
      await updateShopGroup(rslt._id, shopGroup);
      await createGroup(data, rslt._id);
    }
    closeModal();
    setLoaded(false);
  };

  const openUpdate = id => {
    if (adminRights?.pml?.shop?.update) {
      selectShop(id);

      setModal('modalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = async (data, shopGroup) => {
    const rslt = await ACTIVATION.updateActivationShop(selected._id, {
      ...selected,
      ...data
    });
    if (rslt._id) {
      await updateShopGroup(rslt._id, shopGroup);
      await updateGroup(data, selected._id);
    }
    closeModal();
    setLoaded(false);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={setRows}
            datas={allRows}
            titleHead="Recherche un point de vente"
            placeholder="Key, ..."
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openCreate} text="Ajouter un point de vente" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onCreate={onCreate}
              config={{ medias, insightShops, users, shopGroups }}
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                { label: 'Key', id: 'key', sortKey: 'key' },
                { label: 'Nom', id: 'name', sortKey: 'name' },
                {
                  label: 'Budget',
                  id: 'monthlyBudget',
                  sortKey: 'monthlyBudget'
                },
                { label: 'Utilisateurs', id: 'users' },
                { label: 'Zone de chalandise', id: 'insightShop' },
                {
                  label: 'Subvention',
                  id: 'subvention',
                  format: val => numeral(val).format('0%')
                },
                { label: 'Médias activables', id: 'medias' }
              ]}
              hidenFields={['_id']}
              onUpdate={openUpdate}
            />
          )}
          {openModal === 'modalUpdate' && (
            <ModalForm
              open={openModal === 'modalUpdate'}
              onClose={closeModal}
              onCreate={onUpdate}
              config={{ medias, insightShops, users, selected, shopGroups }}
            />
          )}

          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default PropertiesContainer;
