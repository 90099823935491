import { api } from './api';

const getCriterias = async () => {
  try {
    const result = await api.get(`/backoffice-insight/criteriaList`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const update = async (id, datas) => {
  try {
    const result = await api.put(`/backoffice-insight/criteria/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const create = async datas => {
  try {
    const result = await api.post(`/backoffice-insight/criteria`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { getCriterias, create, update };
