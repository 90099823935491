import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { FUNNEL } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { AutocompleteTextField, Button, Switch } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import { InspectCampaigns } from '../InspectCampaigns';
import { StepsConfig } from '../StepsConfig';

const ErrorState = {
  funnelIdError: false
};

export const ModalForm = props => {
  const { themeColors } = useTheme();
  const { config, onValidate, onClose } = props;
  const {
    funnels = [],
    selected,
    allActivatedAudiences = [],
    customInsights = []
  } = config;

  const lineItemsCopy = { ...selected?.lineItems };
  const activatedAudiences = Object.keys(lineItemsCopy);
  for (const audiences of activatedAudiences) {
    const mappedLineItems = lineItemsCopy[audiences].filter(
      el => el.withData === true
    );
    lineItemsCopy[audiences] = mappedLineItems;
  }

  const initialState = {
    funnelId: selected?.funnelId,
    lineItems: lineItemsCopy || [],
    selectedActivatedAudiences: selected?.selectedActivatedAudiences || [],
    audiencesMappings: selected?.audiencesMappings,
    steps: selected?.steps,
    noInvoice: selected?.noInvoice || false
  };
  const [campaigns, setCampaigns] = React.useState([]);
  const [hasTag, setHasTag] = React.useState(false);
  const [
    {
      funnelId,
      lineItems,
      selectedActivatedAudiences,
      audiencesMappings,
      steps,
      noInvoice
    },
    setState
  ] = React.useState(initialState);
  const [{ funnelIdError }, setError] = React.useState(ErrorState);

  useEffect(() => {
    const fetch = async () => {
      const funnelTagMapping = await FUNNEL.getTagMapping(funnelId);
      setHasTag(!!funnelTagMapping?.length);

      const campaignList = await FUNNEL.getCampaigns(funnelId);
      setCampaigns(
        campaignList.map(({ media, campaigns: ios }) => {
          return {
            media,
            key: media,
            label: media,
            itemsType: 'campagnes',
            items: ios.map(({ ioId, ioName, lineItems: lis }) => {
              return {
                media,
                key: ioId,
                label: ioName,
                itemsType: 'line items',
                items: lis.map(({ lineItemId, lineItemName }) => {
                  return {
                    media,
                    key: lineItemId || ioId,
                    label: lineItemName
                  };
                })
              };
            })
          };
        })
      );
    };
    setCampaigns([]);
    fetch(funnelId);
  }, [funnelId]);

  const resetStates = () => {
    setError(ErrorState);
  };

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = (itemKey, audienceId) => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    if (itemKey === 'lineItems') {
      setState(prevState => {
        const states = { ...prevState };
        states[itemKey][audienceId] = text;
        return states;
      });
    } else {
      setState(prevState => {
        const states = { ...prevState };
        states[itemKey] = text;
        return states;
      });
    }
  };

  const onAutoCompliteChange = (data, reason) => {
    if (reason === 'select-option' || reason === 'selectOption') {
      handleChange('funnelId')(data?.id);
    }

    if (reason === 'clear') {
      handleChange('funnelId')('');
    }
  };
  const onNext = () => {
    let error = false;

    if (!funnelId) {
      toggleError('funnelId', true);
      error = true;
    }

    if (!error) {
      const datas = {
        funnelId,
        audiencesMappings,
        steps,
        lineItems,
        noInvoice
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={onNext}
            >
              {selected ? 'Modifier' : 'Ajouter'} la configuration
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const searchOptionsKeys = ['name', 'id'];

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Funnel global">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Text>Funnel *</Text>
                    <AutocompleteTextField
                      options={funnels}
                      formatLabel={option => {
                        let str = option[searchOptionsKeys[0]];
                        if (searchOptionsKeys[1]) {
                          str += ` (${option[searchOptionsKeys[1]]})`;
                        }
                        return str;
                      }}
                      small
                      value={funnels.find(el => el.id === funnelId)}
                      onChange={onAutoCompliteChange}
                      placeholder="Funnel leads"
                      disabled={selected?.funnelId}
                      error={funnelIdError}
                      bgColor={themeColors.primary}
                    />
                  </Item>
                  <Item justify="center" xs={6}>
                    <Row spacing={2} alignItems="center">
                      <Item flex>
                        <Text fontSize="14px">Masquer dans funnel</Text>
                      </Item>
                    </Row>
                    <Switch
                      small
                      options={['Afficher', 'Masquer']}
                      onChange={bool => handleChange('noInvoice')(bool)}
                      checked={noInvoice}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Audiences activées">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      options={allActivatedAudiences.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      searchOptionsKeys={['name', '_id']}
                      values={selectedActivatedAudiences.map(e => ({
                        name: e.name,
                        _id: e._id
                      }))}
                      onChange={value => {
                        const newActivateAudience = value.map(el => el._id);
                        const newAudiencesMappings = {
                          ...audiencesMappings,
                          activatedAudiences: newActivateAudience
                        };
                        handleChange('audiencesMappings')(newAudiencesMappings);
                      }}
                      actionTxt="Ajouter une audience"
                      deleteAction
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {campaigns && (
          <Row spacing={1}>
            <Item justify="flex-start">
              <FormSection title="LineItems utilisant la data Ermes">
                <Container>
                  <Row spacing={1} justify="flex-start">
                    <Item justify="flex-start">
                      {campaigns.length > 0 &&
                        audiencesMappings?.activatedAudiences?.map(
                          audienceId => {
                            const audience = allActivatedAudiences.find(
                              e => e._id === audienceId
                            );
                            if (audience) {
                              return (
                                <InspectCampaigns
                                  name={audience.name}
                                  options={campaigns.map(campaign => ({
                                    ...campaign,
                                    items: campaign.items.filter(
                                      item => item.label !== ''
                                    )
                                  }))}
                                  selected={lineItems[audienceId]?.reduce(
                                    (acc, curr) => {
                                      if (
                                        curr.insightActivatedAudienceId ===
                                        audienceId
                                      ) {
                                        const {
                                          externalId,
                                          friendlyName,
                                          media,
                                          insightActivatedAudienceId
                                        } = curr;
                                        acc.push({
                                          label: friendlyName,
                                          key: externalId,
                                          media,
                                          insightActivatedAudienceId
                                        });
                                      }
                                      return acc;
                                    },
                                    []
                                  )}
                                  onChange={value => {
                                    handleChange(
                                      'lineItems',
                                      audienceId
                                    )(
                                      value.map(({ key, label, media }) => ({
                                        externalId: key,
                                        friendlyName: label,
                                        media,
                                        insightActivatedAudienceId: audienceId
                                      }))
                                    );
                                  }}
                                />
                              );
                            }
                            return <></>;
                          }
                        )}
                    </Item>
                  </Row>
                </Container>
              </FormSection>
            </Item>
          </Row>
        )}
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Configuration des niveaux">
              <Container>
                <Row spacing={1} justify="flex-start">
                  <Item justify="flex-start">
                    <StepsConfig
                      values={steps}
                      onChange={handleChange('steps')}
                      config={{ customInsights, hasTag }}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ModalForm;
