import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { ACTIVATION } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { AutocompleteTextField, Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import Loading from '../../../../components/Loading';
import { useNavigation } from '../../contexts/NavigationContext';

import CreateNewPresetModal from './components/CreateNewPresetModal';
import SidebarActivation, {
  SideItemActivation
} from './components/SidebarActivation';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */
const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const {
    menu,
    setMenu,
    setCurrentPresetId,
    presets,
    setPresets
  } = useNavigation();
  const { adminRights } = useUser();
  const [refresh, setRefresh] = useState(true);
  const [value, setValue] = useState(undefined);
  const [presetModal, setPresetModal] = useState(null);

  const linkInfo = [
    {
      name: 'constraint',
      svg: 'setting',
      subtitle: 'Beta, contact / boide aux lettres,....',
      surname: 'Paramètres de l’algorithme',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'medias',
      svg: 'media',
      subtitle: 'Paramètrages des médias',
      surname: 'Médias',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'constraintChannel',
      svg: 'canal',
      subtitle: 'Contraintes par canal, groupe tarifaire, ...',
      surname: 'Canaux',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'conversionRatesMedias',
      svg: 'txCanal',
      subtitle: 'web et direct en magasin',
      surname: 'Taux de visite par canal',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'measures',
      svg: 'funnel',
      subtitle: 'Paramétrage des étapes des différents funnels',
      surname: 'Type de funnels',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'conversionRates',
      svg: 'txFunnel',
      subtitle: 'min, main et max par taux de conversion',
      surname: 'Taux de conversions des funnels',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'offlineCPM',
      svg: 'offlineCPM',
      subtitle: 'CPM par cible offline et par canal',
      surname: 'CPM médias offline',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'fees',
      svg: 'euro',
      subtitle: 'Paramétrage des groupes tarifaires',
      surname: 'Honoraires et frais techniques',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'forcedChannel',
      svg: 'canal',
      subtitle: "Forcer l'intégration d'un canal dans les plans",
      surname: 'Canaux obligatoires',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'seasonality',
      svg: 'sun',
      subtitle: 'Paramétrer la saisonnalité pour le preset',
      surname: 'Saisonalité',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'defaultBudget',
      svg: 'euro',
      subtitle: 'Paramétrer le budget apr défaut d’un canal',
      surname: 'Budgets par défaut',
      enabled: adminRights?.activation?.read
    },
    {
      name: 'emailsCount',
      svg: 'euro',
      subtitle:
        "Configurez le nombre d'emails hachés et de cookies dans la base Ermes",
      surname: 'Comptage emails et cookies',
      enabled: adminRights?.activation?.read
    }
  ];

  useEffect(() => {
    if (refresh) {
      ACTIVATION.getActivationPresets().then(data => {
        setPresets(data);
        const defaultPreset = data.find(el => el.isDefault);
        setValue(defaultPreset);
        setCurrentPresetId(defaultPreset.id);
      });

      setRefresh(false);
    }
    // eslint-disable-next-line
  }, [setPresets, refresh, setCurrentPresetId]);

  const onNewPreset = presetObj => {
    setPresets([...presets, presetObj]);
  };

  const onChange = e => {
    if (e) {
      setValue(e);
      setCurrentPresetId(e._id);
    }
  };

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  if (!presets || !value) {
    return (
      <Loading loading={!presets} src="/assets/img/loader_activation.svg" />
    );
  }

  const closeModal = () => {
    setPresetModal(null);
  };

  const onCreateModal = () => {
    if (adminRights?.activation?.create) {
      setPresetModal('create');
    } else {
      setPresetModal('unauthorized');
    }
  };

  return (
    <Container>
      <Container style={{ margin: '17px' }}>
        <Row row spacing={0} justify="space-between">
          <Item xs={10}>
            <AutocompleteTextField
              autoSelect
              autoComplete
              defaultValue={value}
              value={value}
              onChange={onChange}
              disableClearable
              small
              options={presets.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })}
              formatLabel={p => {
                return `${p.name} - ${p.id}`;
              }}
              renderOption={(props, option, e) => {
                if (e.inputValue) {
                  const { inputValue } = e;
                  const text = `${option.name} - ${option.id}`;

                  const regex = new RegExp(inputValue, 'ig');

                  const textFormatted = text.match(regex);
                  const textTest = text.split(regex);
                  return (
                    <li {...props}>
                      <Text>
                        {textTest[0]}
                        <strong>{textFormatted}</strong>
                        {textTest[1]}
                      </Text>
                    </li>
                  );
                }
                return (
                  <li {...props}>
                    <Text>
                      {option.name} - {option.id}
                    </Text>
                  </li>
                );
              }}
            />
          </Item>
          <Item xs={1}>
            <Button
              size="small"
              color="activation"
              rounded
              variant="contained"
              onClick={onCreateModal}
            >
              <Text variant="h6" bold color="inherit">
                +
              </Text>
            </Button>
          </Item>
        </Row>
      </Container>
      <SidebarActivation>
        {linkInfo.map(link => {
          return (
            <SideItemActivation
              key={link.name}
              active={link.name === menu.name}
              svg={link.svg}
              svgAttrs={{
                fill: themeColors.icon,
                dataStroke: themeColors.icon,
                stroke: themeColors.icon
              }}
              subtitle={link.subtitle}
              title={link.surname.toUpperCase()}
              onClick={() => updateMenu(link)}
              hasActiveClass={link.name === menu.name}
            />
          );
        })}
      </SidebarActivation>

      {presetModal === 'create' && (
        <CreateNewPresetModal
          onClose={closeModal}
          onCreate={onNewPreset}
          open={presetModal === 'create'}
          presets={presets}
        />
      )}
      {presetModal === 'unauthorized' && (
        <UnauthorizedModal
          open={presetModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
    </Container>
  );
};

export default SidebarContainer;
