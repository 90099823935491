import { api } from './api';

const getAll = async () => {
  try {
    const { data } = await api.get('/v2/activation/partners');
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const create = async datas => {
  try {
    const { data } = await api.post('/v2/activation/partners', datas);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const updatePartner = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/activation/partners/${id}`, datas);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const deletePartner = async id => {
  try {
    const { data } = await api.delete(`/v2/activation/partners/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default {
  create,
  deletePartner,
  updatePartner,
  getAll
};
