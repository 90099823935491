/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Download from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import moment from 'utils/moment';
import numeral from 'utils/numeral';
import pluralize from 'utils/pluralize';
import {
  EXPORTABLEFORMATS,
  INSIGHT,
  UPLOADSEGMENT,
  UPLOADTASK,
  USERS
} from 'utils/rest';

import { Text, Tooltip } from 'components/DataDisplay';
import { IconButton, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalDownload } from './components/ModalDownload';
import { ModalForm } from './components/ModalForm';

const ExportSegmentsContainer = () => {
  const { themeColors } = useTheme();

  const { adminRights, user } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [listEmailsloaded, setListEmailsLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(user.email);
  const [listEmails, setListEmails] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [insightUserTarget, setInsightUserTarget] = useState([]);
  const [segmentUploadTasks, setSegmentUploadTasks] = useState([]);
  const [selectInsight, setSelectedInsight] = useState({});
  const [selectUploadTask, setSelectedUploadTask] = useState({});
  const [formats, setFormats] = useState([]);
  const [mergeFormats, setMergeFormats] = React.useState(false);

  const closeModal = () => {
    setModal(null);
    setSelectedInsight({});
    setSelectedUploadTask({});
  };

  const openUpdateModal = id => {
    if (adminRights?.audiences?.exportSegment?.create) {
      setSelectedInsight(insightUserTarget.find(el => el._id === id));
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const openDownloadModal = (id, insights, uploadTask) => () => {
    if (adminRights?.audiences?.exportSegment?.read) {
      setSelectedInsight(insights.find(el => el._id === id));
      setSelectedUploadTask(uploadTask);
      setModal('ModalDownload');
    } else {
      setModal('unauthorized');
    }
  };

  const state = {
    file_creation_done: () => 'Terminé',
    done: () => 'Terminé',
    error: () => 'Erreur',
    to_do: () => 'En cours',
    file_creation_in_progress: percentage =>
      `En cours ${numeral(percentage).format('0.0')}%`,
    deleted: () => 'Supprimé'
  };

  const icons = {
    file_creation_done: (
      <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
    ),
    done: <CheckCircleOutlineIcon style={{ color: themeColors.success }} />,
    error: <ErrorOutlineIcon style={{ color: themeColors.error }} />,
    deleted: <ErrorOutlineIcon style={{ color: themeColors.error }} />,
    to_do: <QueryBuilderIcon style={{ color: themeColors.funnel }} />,
    file_creation_in_progress: (
      <QueryBuilderIcon style={{ color: themeColors.funnel }} />
    )
  };

  const setArrayWithEmail = (email, insights, segmentUploadTask) => {
    const mapped = insights.reduce((acc, el) => {
      if (el.email !== email) {
        return acc;
      }
      const uploadTask = segmentUploadTask
        .filter(e => e.insightUserTargetId === el._id && e?.formats?.length > 0)
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })[0];
      acc.push({
        _id: el._id,
        name: el.name,
        nbProfils: el?.result?.nbProfiles,
        dates: {
          saveDate: el.updatedAt,
          exportDate: uploadTask?.createdAt
        },
        formats: uploadTask?.formats,
        state: {
          state: uploadTask?.status
            ? state[uploadTask?.status](uploadTask?.percentage)
            : null,
          message: uploadTask?.message,
          icon: icons[uploadTask?.status] || ''
        },
        customAction:
          (uploadTask && uploadTask?.status === 'file_creation_done') ||
          uploadTask?.status === 'done' ? (
            <IconButton
              onClick={openDownloadModal(el._id, insights, uploadTask)}
            >
              <Download style={{ color: themeColors.light }} />
            </IconButton>
          ) : null
      });
      return acc;
    }, []);
    setRows(mapped);
    setAllRows(mapped);
  };

  const fetchUploadTask = async () => {
    const selectUser = listUsers.find(el => el.email === selectedEmail) || user;
    const segmentUploadTask = await UPLOADTASK.getUploadTaskUser(
      selectUser._id
    );
    setSegmentUploadTasks(segmentUploadTask);
    return segmentUploadTask;
  };

  const fetchDatasUser = async () => {
    setLoaded(false);
    const insights = await INSIGHT.getInsightByUser(selectedEmail);
    const segmentUploadTask = await fetchUploadTask();

    setArrayWithEmail(selectedEmail, insights, segmentUploadTask);
    setInsightUserTarget(insights);
    setLoaded(true);
  };

  const fetchData = async () => {
    const users = await USERS.getAll();
    const formatList = await EXPORTABLEFORMATS.getAllExportableFormats();
    setFormats(formatList.map(item => item.format));
    setListUsers(users);
    setListEmails(users.map(el => el.email));
    setListEmailsLoaded(true);
  };

  useEffect(() => {
    if (!listEmailsloaded) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [listEmailsloaded]);

  useEffect(() => {
    fetchDatasUser();
    // eslint-disable-next-line
  }, [selectedEmail]);

  const lightReload = async () => {
    setLoaded(false);
    const segmentUploadTask = await fetchUploadTask();
    setArrayWithEmail(selectedEmail, insightUserTarget, segmentUploadTask);
    setLoaded(true);
  };

  const onUpdate = async datas => {
    await UPLOADSEGMENT.UploadSegment({
      insightUserTargetIds: [selectInsight._id],
      platforms: [],
      push: false,
      userId: (listUsers.find(el => el.email === selectedEmail) || user)?._id,
      formats: datas.formats,
      status: 'to_do',
      message: '',
      profilia: datas.profilia,
      parts: datas.parts,
      maxLinePerFile: datas.maxLinePerFile,
      professionnalData: datas.professionnalData,
      uncrypted: datas.uncrypted,
      exportableMediasConfig: [
        {
          formats: datas.formats,
          mergeFiles: mergeFormats,
          mediaKey: '',
          uncrypted: datas.uncrypted,
          url: '',
          requestParams: []
        }
      ]
    });
    closeModal();
    lightReload();
  };

  const onChangeEmail = selected => {
    setSelectedEmail(selected);
    setArrayWithEmail(selected, insightUserTarget, segmentUploadTasks);
  };

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row>
        <Item xs={3}>
          <Loading loading={!listEmailsloaded} />
          {listEmailsloaded && (
            <Select
              onChange={onChangeEmail}
              value={selectedEmail}
              options={listEmails}
              titleHead="Utilisateurs"
            />
          )}
        </Item>
        <Item xs={1}>
          <IconButton onClick={lightReload}>
            <RefreshIcon style={{ color: themeColors.light }} />
          </IconButton>
        </Item>
      </Row>
      <Row spacing={4}>
        <Item justify="flex-start" xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Rechercher une audience"
            placeholder="Nom de la sauvegarde, ..."
          />
        </Item>
        <Item justify="flex-end" xs />
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Nom de la sauvegarde',
                  id: 'name',
                  sortKey: 'name'
                },
                {
                  label: 'Nombre de profils',
                  id: 'nbProfils',
                  format: item => {
                    return <Text bold>{numeral(item).format('0,0')}</Text>;
                  }
                },
                {
                  label: 'Date de la sauvegarde',
                  id: 'dates',
                  format: item => {
                    return <Text>{moment(item.saveDate).format('L')}</Text>;
                  }
                },
                {
                  label: "Dernière date d'export",
                  id: 'dates',
                  format: item => {
                    if (!item.exportDate) {
                      return null;
                    }
                    if (moment(item.saveDate).isAfter(item.exportDate)) {
                      return (
                        <Tooltip title="Date de la dernière sauvegarde supérieur à la demande d'export.">
                          <Text color={themeColors.red}>
                            {moment(item.exportDate).format('L')}
                          </Text>
                        </Tooltip>
                      );
                    }
                    return <Text>{moment(item.exportDate).format('L')}</Text>;
                  }
                },
                {
                  label: 'Format',
                  id: 'formats',
                  format: item => {
                    if (!item) {
                      return null;
                    }
                    const nbItem = item.length;
                    if (nbItem === 1) {
                      return (
                        <Text color={themeColors.audience}>{item[0]}</Text>
                      );
                    }
                    return (
                      <Tooltip title={item.slice(1).join(' ')}>
                        <Text color={themeColors.audience}>
                          {`${item[0]} et ${nbItem - 1} ${pluralize(
                            'autre',
                            nbItem - 1
                          )}`}
                        </Text>
                      </Tooltip>
                    );
                  }
                },
                {
                  label: 'État',
                  id: 'state',
                  format: item => {
                    if (item.icon) {
                      return (
                        <Item>
                          <Text style={{ paddingRight: '10px' }}>
                            {item.state}
                          </Text>

                          {item.message?.length > 0 ? (
                            <Tooltip title={item.message}>{item.icon}</Tooltip>
                          ) : (
                            item.icon
                          )}
                        </Item>
                      );
                    }
                    return null;
                  }
                }
              ]}
              onNoteAdd={openUpdateModal}
            />
          )}
          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{
                selectInsight,
                formats,
                mergeFormats,
                setMergeFormats
              }}
              actionName="Modifier le média"
              update
            />
          )}
          {openModal === 'ModalDownload' && (
            <ModalDownload
              open={openModal === 'ModalDownload'}
              onClose={closeModal}
              config={{
                selectInsight,
                selectUploadTask,
                formats
              }}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ExportSegmentsContainer;
