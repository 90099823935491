// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Slider as SliderComp } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import Fade from 'components/Fade';

export const Slider = props => {
  const { color, disabled, onChange, size, show, value, ...rest } = props;

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    root: {
      color: colorRules
    }
  });
  const classes = useStyles();

  return (
    <Fade show={show}>
      <SliderComp
        className={classes.root}
        disabled={disabled}
        onChange={onChange}
        size={size}
        value={value}
        {...rest}
      />
    </Fade>
  );
};

Slider.defaultProps = {
  color: null,
  disabled: false,
  size: 'medium',
  show: null,
  value: null
};

Slider.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Slider;
