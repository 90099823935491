import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  errorName: false,
  errorEmail: false
};

export const ModalForm = props => {
  const { onValidate, onClose, actionName, config } = props;

  const { partner = {}, activableMedias } = config;
  const { themeColors } = useTheme();

  const initialState = {
    name: partner.name || '',
    email: partner.email || '',
    cpms: partner.cpms || [],
    priorities: partner.priorities || []
  };

  const [{ name, email, cpms, priorities }, setState] = useState(initialState);
  const [{ errorName, errorEmail }, setError] = useState(ErrorState);

  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [key]: state }));
  };

  const handleChange = field => input => {
    const isString = typeof input === 'string';
    const newValue = isString ? input.trim() : input;

    toggleError(field, !newValue);

    setState(prevState => ({ ...prevState, [field]: newValue }));
  };

  const resetStates = () => {
    setState(initialState);
  };

  const create = () => {
    let error = false;

    if (!name) {
      toggleError('name', true);
      error = true;
    }
    if (!email) {
      toggleError('email', true);
      error = true;
    }
    if (!cpms.length) {
      error = true;
    }
    if (!priorities.length) {
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        email,
        cpms,
        priorities
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.insight}
              size="medium"
              widthSize="medium"
              onClick={create}
            >
              {actionName}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorName ? 'error' : 'inherit'}>
                            Nom du partenaire *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('name')}
                            value={name}
                            error={errorName}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Text color={errorEmail ? 'error' : 'inherit'}>
                            Email du partenaire *
                          </Text>
                          <TextField
                            small
                            onChange={handleChange('email')}
                            value={email}
                            error={errorEmail}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
          <Item justify="flex-start">
            <FormSection title="CPM *" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={cpms}
                      onChange={handleChange('cpms')}
                      actionTxt="Ajouter un CPM"
                      deleteAction
                      variant="customFields"
                      inputs={['Select', 'TextField']}
                      isRequireds={[true, true]}
                      labels={['Média', 'CPM']}
                      options={['media', 'cpm']}
                      formatedValues={[
                        activableMedias.map(activableMedia => ({
                          value: activableMedia.key,
                          label: activableMedia.name
                        }))
                      ]}
                      fieldsFormat={[
                        activableMedias.map(activableMedia => ({
                          value: activableMedia.key,
                          label: activableMedia.name
                        }))
                      ]}
                      valueUnique
                      hideKeys={['id', '_id']}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
          <Item justify="flex-start">
            <FormSection title="Priorités *" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={priorities}
                      onChange={handleChange('priorities')}
                      actionTxt="Ajouter une priorité"
                      deleteAction
                      variant="customFields"
                      inputs={['Select', 'TextField']}
                      isRequireds={[true, true]}
                      labels={['Média', 'Priorité']}
                      options={['media', 'priority']}
                      valueUnique
                      formatedValues={[
                        activableMedias.map(activableMedia => ({
                          value: activableMedia.key,
                          label: activableMedia.name
                        }))
                      ]}
                      fieldsFormat={[
                        activableMedias.map(activableMedia => ({
                          value: activableMedia.key,
                          label: activableMedia.name
                        }))
                      ]}
                      hideKeys={['id', '_id']}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  actionName: null,
  config: PropTypes.objectOf({
    partner: {},
    activableMedias: []
  })
};

ModalForm.propTypes = {
  config: PropTypes.objectOf({
    partner: PropTypes.shape({}),
    activableMedias: PropTypes.arrayOf()
  }),
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  actionName: PropTypes.string
};

export default ModalForm;
