export default [
  {
    key: 'AF',
    label: 'Afghanistan (AF)'
  },
  {
    key: 'ZA',
    label: 'Afrique du Sud (ZA)'
  },
  {
    key: 'AX',
    label: 'Ahvenanmaa (AX)'
  },
  {
    key: 'AL',
    label: 'Albanie (AL)'
  },
  {
    key: 'DZ',
    label: 'Algérie (DZ)'
  },
  {
    key: 'DE',
    label: 'Allemagne (DE)'
  },
  {
    key: 'AD',
    label: 'Andorre (AD)'
  },
  {
    key: 'AO',
    label: 'Angola (AO)'
  },
  {
    key: 'AI',
    label: 'Anguilla (AI)'
  },
  {
    key: 'AQ',
    label: 'Antarctique (AQ)'
  },
  {
    key: 'AG',
    label: 'Antigua-et-Barbuda (AG)'
  },
  {
    key: 'SA',
    label: 'Arabie Saoudite (SA)'
  },
  {
    key: 'AR',
    label: 'Argentine (AR)'
  },
  {
    key: 'AM',
    label: 'Arménie (AM)'
  },
  {
    key: 'AW',
    label: 'Aruba (AW)'
  },
  {
    key: 'AU',
    label: 'Australie (AU)'
  },
  {
    key: 'AT',
    label: 'Autriche (AT)'
  },
  {
    key: 'AZ',
    label: 'Azerbaïdjan (AZ)'
  },
  {
    key: 'BS',
    label: 'Bahamas (BS)'
  },
  {
    key: 'BH',
    label: 'Bahreïn (BH)'
  },
  {
    key: 'BD',
    label: 'Bangladesh (BD)'
  },
  {
    key: 'BB',
    label: 'Barbade (BB)'
  },
  {
    key: 'BE',
    label: 'Belgique (BE)'
  },
  {
    key: 'BZ',
    label: 'Belize (BZ)'
  },
  {
    key: 'BJ',
    label: 'Bénin (BJ)'
  },
  {
    key: 'BM',
    label: 'Bermudes (BM)'
  },
  {
    key: 'BT',
    label: 'Bhoutan (BT)'
  },
  {
    key: 'BY',
    label: 'Biélorussie (BY)'
  },
  {
    key: 'MM',
    label: 'Birmanie (MM)'
  },
  {
    key: 'BO',
    label: 'Bolivie (BO)'
  },
  {
    key: 'BA',
    label: 'Bosnie-Herzégovine (BA)'
  },
  {
    key: 'BW',
    label: 'Botswana (BW)'
  },
  {
    key: 'BR',
    label: 'Brésil (BR)'
  },
  {
    key: 'BN',
    label: 'Brunei (BN)'
  },
  {
    key: 'BG',
    label: 'Bulgarie (BG)'
  },
  {
    key: 'BF',
    label: 'Burkina Faso (BF)'
  },
  {
    key: 'BI',
    label: 'Burundi (BI)'
  },
  {
    key: 'KH',
    label: 'Cambodge (KH)'
  },
  {
    key: 'CM',
    label: 'Cameroun (CM)'
  },
  {
    key: 'CA',
    label: 'Canada (CA)'
  },
  {
    key: 'CL',
    label: 'Chili (CL)'
  },
  {
    key: 'CN',
    label: 'Chine (CN)'
  },
  {
    key: 'CY',
    label: 'Chypre (CY)'
  },
  {
    key: 'VA',
    label: 'Cité du Vatican (VA)'
  },
  {
    key: 'CO',
    label: 'Colombie (CO)'
  },
  {
    key: 'KM',
    label: 'Comores (KM)'
  },
  {
    key: 'CG',
    label: 'Congo (CG)'
  },
  {
    key: 'CD',
    label: 'Congo (Rép. dém.) (CD)'
  },
  {
    key: 'KP',
    label: 'Corée du Nord (KP)'
  },
  {
    key: 'KR',
    label: 'Corée du Sud (KR)'
  },
  {
    key: 'CR',
    label: 'Costa Rica (CR)'
  },
  {
    key: 'CI',
    label: "Côte d'Ivoire (CI)"
  },
  {
    key: 'HR',
    label: 'Croatie (HR)'
  },
  {
    key: 'CU',
    label: 'Cuba (CU)'
  },
  {
    key: 'CW',
    label: 'Curaçao (CW)'
  },
  {
    key: 'DK',
    label: 'Danemark (DK)'
  },
  {
    key: 'DJ',
    label: 'Djibouti (DJ)'
  },
  {
    key: 'DM',
    label: 'Dominique (DM)'
  },
  {
    key: 'EG',
    label: 'Égypte (EG)'
  },
  {
    key: 'AE',
    label: 'Émirats arabes unis (AE)'
  },
  {
    key: 'EC',
    label: 'Équateur (EC)'
  },
  {
    key: 'ER',
    label: 'Érythrée (ER)'
  },
  {
    key: 'ES',
    label: 'Espagne (ES)'
  },
  {
    key: 'EE',
    label: 'Estonie (EE)'
  },
  {
    key: 'US',
    label: 'États-Unis (US)'
  },
  {
    key: 'ET',
    label: 'Éthiopie (ET)'
  },
  {
    key: 'FJ',
    label: 'Fidji (FJ)'
  },
  {
    key: 'FI',
    label: 'Finlande (FI)'
  },
  {
    key: 'FR',
    label: 'France (FR)'
  },
  {
    key: 'GA',
    label: 'Gabon (GA)'
  },
  {
    key: 'GM',
    label: 'Gambie (GM)'
  },
  {
    key: 'GE',
    label: 'Géorgie (GE)'
  },
  {
    key: 'GS',
    label: 'Géorgie du Sud-et-les Îles Sandwich du Sud (GS)'
  },
  {
    key: 'GH',
    label: 'Ghana (GH)'
  },
  {
    key: 'GI',
    label: 'Gibraltar (GI)'
  },
  {
    key: 'GR',
    label: 'Grèce (GR)'
  },
  {
    key: 'GD',
    label: 'Grenade (GD)'
  },
  {
    key: 'GL',
    label: 'Groenland (GL)'
  },
  {
    key: 'GP',
    label: 'Guadeloupe (GP)'
  },
  {
    key: 'GU',
    label: 'Guam (GU)'
  },
  {
    key: 'GT',
    label: 'Guatemala (GT)'
  },
  {
    key: 'GG',
    label: 'Guernesey (GG)'
  },
  {
    key: 'GN',
    label: 'Guinée (GN)'
  },
  {
    key: 'GQ',
    label: 'Guinée équatoriale (GQ)'
  },
  {
    key: 'GW',
    label: 'Guinée-Bissau (GW)'
  },
  {
    key: 'GY',
    label: 'Guyana (GY)'
  },
  {
    key: 'GF',
    label: 'Guyane (GF)'
  },
  {
    key: 'HT',
    label: 'Haïti (HT)'
  },
  {
    key: 'HN',
    label: 'Honduras (HN)'
  },
  {
    key: 'HK',
    label: 'Hong Kong (HK)'
  },
  {
    key: 'HU',
    label: 'Hongrie (HU)'
  },
  {
    key: 'BV',
    label: 'Île Bouvet (BV)'
  },
  {
    key: 'CX',
    label: 'Île Christmas (CX)'
  },
  {
    key: 'IM',
    label: 'Île de Man (IM)'
  },
  {
    key: 'MU',
    label: 'Île Maurice (MU)'
  },
  {
    key: 'NF',
    label: 'Île Norfolk (NF)'
  },
  {
    key: 'KY',
    label: 'Îles Caïmans (KY)'
  },
  {
    key: 'CC',
    label: 'Îles Cocos (CC)'
  },
  {
    key: 'CK',
    label: 'Îles Cook (CK)'
  },
  {
    key: 'CV',
    label: 'Îles du Cap-Vert (CV)'
  },
  {
    key: 'FO',
    label: 'Îles Féroé (FO)'
  },
  {
    key: 'HM',
    label: 'Îles Heard-et-MacDonald (HM)'
  },
  {
    key: 'FK',
    label: 'Îles Malouines (FK)'
  },
  {
    key: 'MP',
    label: 'Îles Mariannes du Nord (MP)'
  },
  {
    key: 'MH',
    label: 'Îles Marshall (MH)'
  },
  {
    key: 'UM',
    label: 'Îles mineures éloignées des États-Unis (UM)'
  },
  {
    key: 'PN',
    label: 'Îles Pitcairn (PN)'
  },
  {
    key: 'SB',
    label: 'Îles Salomon (SB)'
  },
  {
    key: 'TC',
    label: 'Îles Turques-et-Caïques (TC)'
  },
  {
    key: 'VG',
    label: 'Îles Vierges britanniques (VG)'
  },
  {
    key: 'VI',
    label: 'Îles Vierges des États-Unis (VI)'
  },
  {
    key: 'IN',
    label: 'Inde (IN)'
  },
  {
    key: 'ID',
    label: 'Indonésie (ID)'
  },
  {
    key: 'IQ',
    label: 'Irak (IQ)'
  },
  {
    key: 'IR',
    label: 'Iran (IR)'
  },
  {
    key: 'IE',
    label: 'Irlande (IE)'
  },
  {
    key: 'IS',
    label: 'Islande (IS)'
  },
  {
    key: 'IL',
    label: 'Israël (IL)'
  },
  {
    key: 'IT',
    label: 'Italie (IT)'
  },
  {
    key: 'JM',
    label: 'Jamaïque (JM)'
  },
  {
    key: 'JP',
    label: 'Japon (JP)'
  },
  {
    key: 'JE',
    label: 'Jersey (JE)'
  },
  {
    key: 'JO',
    label: 'Jordanie (JO)'
  },
  {
    key: 'KZ',
    label: 'Kazakhstan (KZ)'
  },
  {
    key: 'KE',
    label: 'Kenya (KE)'
  },
  {
    key: 'KG',
    label: 'Kirghizistan (KG)'
  },
  {
    key: 'KI',
    label: 'Kiribati (KI)'
  },
  {
    key: 'XK',
    label: 'Kosovo (XK)'
  },
  {
    key: 'KW',
    label: 'Koweït (KW)'
  },
  {
    key: 'LA',
    label: 'Laos (LA)'
  },
  {
    key: 'LS',
    label: 'Lesotho (LS)'
  },
  {
    key: 'LV',
    label: 'Lettonie (LV)'
  },
  {
    key: 'LB',
    label: 'Liban (LB)'
  },
  {
    key: 'LR',
    label: 'Liberia (LR)'
  },
  {
    key: 'LY',
    label: 'Libye (LY)'
  },
  {
    key: 'LI',
    label: 'Liechtenstein (LI)'
  },
  {
    key: 'LT',
    label: 'Lituanie (LT)'
  },
  {
    key: 'LU',
    label: 'Luxembourg (LU)'
  },
  {
    key: 'MO',
    label: 'Macao (MO)'
  },
  {
    key: 'MK',
    label: 'Macédoine du Nord (MK)'
  },
  {
    key: 'MG',
    label: 'Madagascar (MG)'
  },
  {
    key: 'MY',
    label: 'Malaisie (MY)'
  },
  {
    key: 'MW',
    label: 'Malawi (MW)'
  },
  {
    key: 'MV',
    label: 'Maldives (MV)'
  },
  {
    key: 'ML',
    label: 'Mali (ML)'
  },
  {
    key: 'MT',
    label: 'Malte (MT)'
  },
  {
    key: 'MA',
    label: 'Maroc (MA)'
  },
  {
    key: 'MQ',
    label: 'Martinique (MQ)'
  },
  {
    key: 'MR',
    label: 'Mauritanie (MR)'
  },
  {
    key: 'YT',
    label: 'Mayotte (YT)'
  },
  {
    key: 'MX',
    label: 'Mexique (MX)'
  },
  {
    key: 'FM',
    label: 'Micronésie (FM)'
  },
  {
    key: 'MD',
    label: 'Moldavie (MD)'
  },
  {
    key: 'MC',
    label: 'Monaco (MC)'
  },
  {
    key: 'MN',
    label: 'Mongolie (MN)'
  },
  {
    key: 'ME',
    label: 'Monténégro (ME)'
  },
  {
    key: 'MS',
    label: 'Montserrat (MS)'
  },
  {
    key: 'MZ',
    label: 'Mozambique (MZ)'
  },
  {
    key: 'NA',
    label: 'Namibie (NA)'
  },
  {
    key: 'NR',
    label: 'Nauru (NR)'
  },
  {
    key: 'NP',
    label: 'Népal (NP)'
  },
  {
    key: 'NI',
    label: 'Nicaragua (NI)'
  },
  {
    key: 'NE',
    label: 'Niger (NE)'
  },
  {
    key: 'NG',
    label: 'Nigéria (NG)'
  },
  {
    key: 'NU',
    label: 'Niue (NU)'
  },
  {
    key: 'NO',
    label: 'Norvège (NO)'
  },
  {
    key: 'NC',
    label: 'Nouvelle-Calédonie (NC)'
  },
  {
    key: 'NZ',
    label: 'Nouvelle-Zélande (NZ)'
  },
  {
    key: 'OM',
    label: 'Oman (OM)'
  },
  {
    key: 'UG',
    label: 'Ouganda (UG)'
  },
  {
    key: 'UZ',
    label: 'Ouzbékistan (UZ)'
  },
  {
    key: 'PK',
    label: 'Pakistan (PK)'
  },
  {
    key: 'PW',
    label: 'Palaos (Palau) (PW)'
  },
  {
    key: 'PS',
    label: 'Palestine (PS)'
  },
  {
    key: 'PA',
    label: 'Panama (PA)'
  },
  {
    key: 'PG',
    label: 'Papouasie-Nouvelle-Guinée (PG)'
  },
  {
    key: 'PY',
    label: 'Paraguay (PY)'
  },
  {
    key: 'NL',
    label: 'Pays-Bas (NL)'
  },
  {
    key: 'BQ',
    label: 'Pays-Bas caribéens (BQ)'
  },
  {
    key: 'PE',
    label: 'Pérou (PE)'
  },
  {
    key: 'PH',
    label: 'Philippines (PH)'
  },
  {
    key: 'PL',
    label: 'Pologne (PL)'
  },
  {
    key: 'PF',
    label: 'Polynésie française (PF)'
  },
  {
    key: 'PR',
    label: 'Porto Rico (PR)'
  },
  {
    key: 'PT',
    label: 'Portugal (PT)'
  },
  {
    key: 'QA',
    label: 'Qatar (QA)'
  },
  {
    key: 'CF',
    label: 'République centrafricaine (CF)'
  },
  {
    key: 'DO',
    label: 'République dominicaine (DO)'
  },
  {
    key: 'RE',
    label: 'Réunion (RE)'
  },
  {
    key: 'RO',
    label: 'Roumanie (RO)'
  },
  {
    key: 'GB',
    label: 'Royaume-Uni (GB)'
  },
  {
    key: 'RU',
    label: 'Russie (RU)'
  },
  {
    key: 'RW',
    label: 'Rwanda (RW)'
  },
  {
    key: 'EH',
    label: 'Sahara Occidental (EH)'
  },
  {
    key: 'BL',
    label: 'Saint-Barthélemy (BL)'
  },
  {
    key: 'KN',
    label: 'Saint-Christophe-et-Niévès (KN)'
  },
  {
    key: 'SM',
    label: 'Saint-Marin (SM)'
  },
  {
    key: 'SX',
    label: 'Saint-Martin (SX)'
  },
  {
    key: 'MF',
    label: 'Saint-Martin (Partie Française) (MF)'
  },
  {
    key: 'PM',
    label: 'Saint-Pierre-et-Miquelon (PM)'
  },
  {
    key: 'VC',
    label: 'Saint-Vincent-et-les-Grenadines (VC)'
  },
  {
    key: 'SH',
    label: 'Sainte-Hélène, Ascension et Tristan da Cunha (SH)'
  },
  {
    key: 'LC',
    label: 'Sainte-Lucie (LC)'
  },
  {
    key: 'SV',
    label: 'Salvador (SV)'
  },
  {
    key: 'WS',
    label: 'Samoa (WS)'
  },
  {
    key: 'AS',
    label: 'Samoa américaines (AS)'
  },
  {
    key: 'ST',
    label: 'São Tomé et Príncipe (ST)'
  },
  {
    key: 'SN',
    label: 'Sénégal (SN)'
  },
  {
    key: 'RS',
    label: 'Serbie (RS)'
  },
  {
    key: 'SC',
    label: 'Seychelles (SC)'
  },
  {
    key: 'SL',
    label: 'Sierra Leone (SL)'
  },
  {
    key: 'SG',
    label: 'Singapour (SG)'
  },
  {
    key: 'SK',
    label: 'Slovaquie (SK)'
  },
  {
    key: 'SI',
    label: 'Slovénie (SI)'
  },
  {
    key: 'SO',
    label: 'Somalie (SO)'
  },
  {
    key: 'SD',
    label: 'Soudan (SD)'
  },
  {
    key: 'SS',
    label: 'Soudan du Sud (SS)'
  },
  {
    key: 'LK',
    label: 'Sri Lanka (LK)'
  },
  {
    key: 'SE',
    label: 'Suède (SE)'
  },
  {
    key: 'CH',
    label: 'Suisse (CH)'
  },
  {
    key: 'SR',
    label: 'Surinam (SR)'
  },
  {
    key: 'SJ',
    label: 'Svalbard et Jan Mayen (SJ)'
  },
  {
    key: 'SZ',
    label: 'Swaziland (SZ)'
  },
  {
    key: 'SY',
    label: 'Syrie (SY)'
  },
  {
    key: 'TJ',
    label: 'Tadjikistan (TJ)'
  },
  {
    key: 'TW',
    label: 'Taïwan (TW)'
  },
  {
    key: 'TZ',
    label: 'Tanzanie (TZ)'
  },
  {
    key: 'TD',
    label: 'Tchad (TD)'
  },
  {
    key: 'CZ',
    label: 'Tchéquie (CZ)'
  },
  {
    key: 'TF',
    label: 'Terres australes et antarctiques françaises (TF)'
  },
  {
    key: 'IO',
    label: "Territoire britannique de l'océan Indien (IO)"
  },
  {
    key: 'TH',
    label: 'Thaïlande (TH)'
  },
  {
    key: 'TL',
    label: 'Timor oriental (TL)'
  },
  {
    key: 'TG',
    label: 'Togo (TG)'
  },
  {
    key: 'TK',
    label: 'Tokelau (TK)'
  },
  {
    key: 'TO',
    label: 'Tonga (TO)'
  },
  {
    key: 'TT',
    label: 'Trinité-et-Tobago (TT)'
  },
  {
    key: 'TN',
    label: 'Tunisie (TN)'
  },
  {
    key: 'TM',
    label: 'Turkménistan (TM)'
  },
  {
    key: 'TR',
    label: 'Turquie (TR)'
  },
  {
    key: 'TV',
    label: 'Tuvalu (TV)'
  },
  {
    key: 'UA',
    label: 'Ukraine (UA)'
  },
  {
    key: 'UY',
    label: 'Uruguay (UY)'
  },
  {
    key: 'VU',
    label: 'Vanuatu (VU)'
  },
  {
    key: 'VE',
    label: 'Venezuela (VE)'
  },
  {
    key: 'VN',
    label: 'Viêt Nam (VN)'
  },
  {
    key: 'WF',
    label: 'Wallis-et-Futuna (WF)'
  },
  {
    key: 'YE',
    label: 'Yémen (YE)'
  },
  {
    key: 'ZM',
    label: 'Zambie (ZM)'
  },
  {
    key: 'ZW',
    label: 'Zimbabwe (ZW)'
  }
];
