export const spotHitErrors = [
  {
    errorCode: 1,
    description: 'Type de message non spécifié ou incorrect (paramètre "type")'
  },
  {
    errorCode: 2,
    description: 'Le message est vide'
  },
  {
    errorCode: 3,
    description: 'Le message contient plus de 160 caractères (70 en unicode)'
  },
  {
    errorCode: 4,
    description: "Aucun destinataire valide n'est renseigné"
  },
  {
    errorCode: 5,
    description: 'Numéro interdit'
  },
  {
    errorCode: 6,
    description: 'Numéro de destinataire invalide'
  },
  {
    errorCode: 7,
    description: "Votre compte n'a pas de formule définie"
  },
  {
    errorCode: 8,
    description:
      "SMS - L'expéditeur est invalide, VOCAL - L'expéditeur est invalide, EMAIL - L'e-mail d'expédition est invalide, MMS - Le sujet contient plus de 16 caractères."
  },
  {
    errorCode: 9,
    description: 'Le système a rencontré une erreur, merci de nous contacter'
  },
  {
    errorCode: 10,
    description:
      "Vous ne disposez pas d'assez de crédits pour effectuer cet envoi"
  },
  {
    errorCode: 11,
    description: "L'envoi des message est désactivé pour la démonstration"
  },
  {
    errorCode: 12,
    description:
      "Votre compte a été suspendu. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 13,
    description:
      "Votre limite d'envoi paramétrée est atteinte. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 14,
    description:
      "Votre limite d'envoi paramétrée est atteinte. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 15,
    description:
      "Votre limite d'envoi paramétrée est atteinte. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 16,
    description:
      'Le paramètre "smslongnbr" n\'est pas cohérent avec la taille du message envoyé'
  },
  {
    errorCode: 17,
    description: "L'expéditeur n'est pas autorisé"
  },
  {
    errorCode: 18,
    description: 'EMAIL - Le sujet est trop court'
  },
  {
    errorCode: 19,
    description: "EMAIL - L'email de réponse est invalide"
  },
  {
    errorCode: 20,
    description: "EMAIL - Le nom d'expéditeur est trop court"
  },
  {
    errorCode: 21,
    description: "Token invalide. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 22,
    description:
      "Durée du message non autorisée. Contactez-nous pour plus d'informations"
  },
  {
    errorCode: 23,
    description:
      "Aucune date variable valide n'a été trouvée dans votre liste de destinataires"
  },
  {
    errorCode: 24,
    description:
      "Votre campagne n'a pas été validée car il manque la mention « STOP au 36200 » dans votre message. Pour rappel, afin de respecter les obligations légales de la CNIL, il est impératif d'inclure une mention de désinscription"
  },
  {
    errorCode: 25,
    description: 'Echelonnage : date de début vide'
  },
  {
    errorCode: 26,
    description: 'Echelonnage : date de fin vide'
  },
  {
    errorCode: 27,
    description: 'Echelonnage : date de début plus tard que date de fin'
  },
  {
    errorCode: 28,
    description: 'Echelonnage : aucun créneau disponible'
  },
  {
    errorCode: 29,
    description:
      'MMS : Le mot "virtual" peut générer des anomalies dans le routage de vos messages\nNous vous invitons à utiliser un synonyme ou une autre écriture (Virtuel par exemple)\nNous sommes en train de corriger cette anomalie, veuillez-nous excuser pour la gêne occasionnée'
  },
  {
    errorCode: 30,
    description: 'Clé API non reconnue'
  },
  {
    errorCode: 36,
    description: "Vous ne pouvez pas avoir d'emojis dans votre message"
  },
  {
    errorCode: 38,
    description: 'Vous devez ajouter une mention "Stop" à votre SMS'
  },
  {
    errorCode: 40,
    description: 'Une pièce jointe ne vous appartient pas'
  },
  {
    errorCode: 41,
    description: 'Une pièce jointe est invalide'
  },
  {
    errorCode: 45,
    description: "Ce produit n'est pas activé"
  },
  {
    errorCode: 50,
    description: "Le fuseau horaire spécifié n'est pas valide"
  },
  {
    errorCode: 51,
    description: 'La date est déjà passée après calcule du fuseau horaire'
  },
  {
    errorCode: 52,
    description:
      'Vous avez atteint la limite maximale de 50 campagnes en brouillons. Si vous souhaitez en ajouter plus, merci de nous contacter'
  },
  {
    errorCode: 53,
    description: 'Nous limitons à 5 pièces jointes par campagne email'
  },
  {
    errorCode: 61,
    description:
      'Nous avons détecté un lien dans le contenu de votre message, merci de vous rapprocher de notre service client pour valider cet envoi'
  },
  {
    errorCode: 62,
    description: "Votre limite d'envoi est atteinte"
  },
  {
    errorCode: 63,
    description: 'Vous avez dépassé votre limite de requêtes api'
  },
  {
    errorCode: 65,
    description: 'Une maintenance est prévu sur ce créneaux horaire'
  },
  {
    errorCode: 66,
    description:
      "Nous avons bloqué préventivement cette campagne car elle présente des caractéristiques similaires à une campagne déjà envoyée (contenu, destinataires...). Merci de nous contacter pour plus d'informations"
  },
  {
    errorCode: 67,
    description: "Le nom d'expéditeur ne peut contenir une adresse email"
  },
  {
    errorCode: 68,
    description:
      'Nous avons détecté un numéro de téléphone dans le contenu de votre message, merci de vous rapprocher de notre service client pour valider cet envoi'
  },
  {
    errorCode: 69,
    description:
      'Nous avons détecté une erreur dans le lien utilisé dans le message'
  },
  {
    errorCode: 99,
    description: 'Une maintenance est prévu sur ce créneaux horaire'
  },
  {
    errorCode: 100,
    description: 'Ip non autorisée'
  }
];
export default spotHitErrors;
