import React from 'react';

import { colors, themes } from 'utils/constants';

const { DARK, LIGHT, cookieName } = themes;

const ThemeContext = React.createContext();

/**
 * ThemeProvider
 * @component
 * @context
 *
 */
function ThemeProvider(props) {
  const [theme, setTheme] = React.useState(
    localStorage.getItem(cookieName) || LIGHT
  );
  const [panelColor, setPanelColor] = React.useState();
  const value = {
    isThemeLight: theme !== DARK,
    isThemeDark: theme === DARK,
    setPanelColor: panel => setPanelColor(panel),
    panelColor,
    themeColors: colors[theme] || colors[LIGHT],
    toggleTheme: () => {
      const nextTheme = theme === DARK ? LIGHT : DARK;
      setTheme(nextTheme);
      localStorage.setItem(cookieName, nextTheme);
    }
  };
  return <ThemeContext.Provider {...props} value={value} />;
}

/**
 * useTheme
 * @hook
 * @return context
 *
 */
export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (!context) throw new Error('ThemeContext must be called in ThemeProvider');
  return context;
};

/**
 * withTheme
 * @HOC
 * Hight order component
 *
 */
export const withTheme = Component => props => {
  const { themeColors, isThemeLight } = useTheme();
  return (
    <Component
      themeColors={themeColors}
      isThemeLight={isThemeLight}
      {...props}
    />
  );
};
export default ThemeProvider;
