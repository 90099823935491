import { api } from './api';

const create = async data => {
  try {
    const result = await api.post(`/v2/funnel/csvconfig`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/funnel/csvconfig/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const get = async () => {
  try {
    const result = await api.get(`/v2/funnel/csvconfig`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteConfig = async id => {
  try {
    const result = await api.delete(`/v2/funnel/csvconfig/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  create,
  update,
  get,
  deleteConfig
};
