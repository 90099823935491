import { api } from './api';

const getFullCategoryList = async () => {
  try {
    const result = await api.get(`/backoffice-insight/categorylist`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const create = async datas => {
  try {
    const result = await api.post(`/backoffice-insight/category`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const update = async (id, datas) => {
  try {
    const result = await api.put(`/backoffice-insight/category/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { getFullCategoryList, create, update };
