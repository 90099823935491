import { api } from './api';

const UploadSegment = async data => {
  try {
    const result = await api.post(`/v2/activation/uploadSegment/`, data);
    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  UploadSegment
};
