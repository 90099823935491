// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { AppBar as AppBarMui, useScrollTrigger } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

const ElevationScroll = props => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

export const AppBar = props => {
  const { children } = props;
  const { themeColors } = useTheme();

  return (
    <ElevationScroll>
      <AppBarMui style={{ backgroundColor: themeColors.primary_header }}>
        {children}
      </AppBarMui>
    </ElevationScroll>
  );
};

AppBar.defaultProps = {
  children: null
};
AppBar.propTypes = {
  children: PropTypes.node
};

export default AppBar;
