import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import pluralize from 'utils/pluralize';
import { ACTIVATION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const DefaultBudgetContainer = () => {
  const { currentPresetId } = useNavigation();
  const { themeColors } = useTheme();
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [config, setConfig] = useState({});
  const [defaultBudgetList, setDefaultBudgetList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedDefaultBudget, setSelectedDefaultBudget] = useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectDefaultBudget = id => {
    const selected = defaultBudgetList.find(el => el._id === id);
    setSelectedDefaultBudget(selected);
  };
  const closeModal = () => {
    setModal(null);
    setSelectedDefaultBudget({});
  };

  const fetchDatas = async () => {
    try {
      const defaultBudgets = await ACTIVATION.getDefaultChannelSpendings(
        currentPresetId
      );
      const measures = await ACTIVATION.getMeasures(currentPresetId);
      const channels = await ACTIVATION.getChannels(currentPresetId);
      return { defaultBudgets, measures, channels };
    } catch (err) {
      return { defaultBudgets: [], measures: [], channels: [] };
    }
  };

  const openAddModal = () => {
    if (adminRights?.activation?.create) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };
  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectDefaultBudget(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async datas => {
    await ACTIVATION.createDefaultChannelSpendings({
      ...datas,
      preset: currentPresetId
    });
    setLoaded(false);
    closeModal();
  };

  const onUpdate = async datas => {
    await ACTIVATION.updateDefaultChannelSpendings(selectedDefaultBudget._id, {
      ...datas,
      preset: currentPresetId
    });
    setLoaded(false);
    closeModal();
  };

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const formatBudgets = (budgets, channels) => {
    const nbBudgets = budgets.length;
    if (nbBudgets === 0) {
      return <Text>Aucun</Text>;
    }

    const str = `${nbBudgets} ${pluralize('cannal', nbBudgets)}`;

    const tooltipContent = () => {
      return (
        <Container>
          {budgets.map(budget => {
            const channel =
              channels.find(el => el._id === budget.channel)?.label || '';
            return getToolTipText(
              `${channel} - ${numeral(budget.value).format('0[.]00%')}`
            );
          })}
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={2}>
          <Item flex>
            <Tooltip title={tooltipContent()}>
              <Text color="activation">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  useEffect(() => {
    if (!loaded) {
      fetchDatas().then(({ defaultBudgets, measures, channels }) => {
        setLoaded(true);
        const mapped = defaultBudgets.map(item => {
          const newItem = {};
          newItem._id = item._id;
          newItem.measure =
            measures.find(el => el._id === item.measure)?.target || '';
          newItem.budgets = formatBudgets(item.budgets, channels);
          return newItem;
        });
        setDefaultBudgetList(defaultBudgets);
        setRows(mapped);
        setAllRows(mapped);
        setConfig({ measures, channels });
      });
    }
    // eslint-disable-next-line
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={allRows}
            titleHead="Recherche un budget"
            placeholder="Leads, Display"
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate onClick={openAddModal} text="Ajouter un budget" />
          {openModal === 'modalCreate' && (
            <ModalForm
              open={openModal === 'modalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              config={{
                ...config,
                measures: config.measures.filter(
                  el => !allRows.some(item => item.measure === el._id)
                )
              }}
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Type de funnel',
                  id: 'measure'
                },
                {
                  label: 'Cannaux',
                  id: 'budgets'
                }
              ]}
              hidenFields={['","id']}
              onUpdate={openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...config, selectedDefaultBudget }}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default DefaultBudgetContainer;
