import React from 'react';
import PropTypes from 'prop-types';

import { panels } from 'utils/constants';

import BaseSidebarAudience from './style';

/**
 * SidebarAudience
 * @component
 *
 */
function SidebarAudience(props) {
  const { children } = props;
  return (
    <BaseSidebarAudience panel={panels.backoffice}>
      {children}
    </BaseSidebarAudience>
  );
}
SidebarAudience.propTypes = {
  children: PropTypes.node.isRequired
};

export { SideItemAudience } from './style';
export default SidebarAudience;
