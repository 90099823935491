import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/lab/Skeleton';

import { downloadCreative } from 'utils/functions';

import { Item } from 'components/Layout';

export const AssetItem = props => {
  const { selected, asset, onClick = () => {} } = props;
  const [loaded, setLoaded] = useState(!!asset.url);

  const loadImg = async () => {
    const creative = await downloadCreative(asset.uploadedAssetId);
    if (creative) {
      asset.url = creative.result;
    }
    setLoaded(true);
  };

  useEffect(() => {
    if (!asset.url) {
      loadImg();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Item
      xs
      onClick={() => {
        onClick(asset.id);
      }}
      alignItems="center"
      style={{
        filter: selected ? 'contrast (200%)' : 'contrast (100%)',
        height: '300px',
        width: '300px',
        maxHeight: '300px',
        maxWidth: '300px',
        backgroundImage: `url(${asset.url})`,
        backgroundColor: `white`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      {loaded ? ' ' : <Skeleton variant="rect" width="300px" height="300px" />}
    </Item>
  );
};

export default AssetItem;
