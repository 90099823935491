// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Fade from 'components/Fade';

export const Container = props => {
  const { children, flex, show, style, justify } = props;
  return (
    <Fade show={show}>
      {flex ? (
        <div
          style={{
            display: 'flex',
            ...style
          }}
        >
          {children}
        </div>
      ) : (
        <Grid
          container
          justifyContent={justify}
          alignItems="center"
          {...props}
        />
      )}
    </Fade>
  );
};

Container.defaultProps = {
  flex: false,
  show: null,
  style: null,
  justify: 'center'
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
  justify: PropTypes.string,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  style: PropTypes.objectOf(PropTypes.any)
};

export default Container;
