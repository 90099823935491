import { api } from './api';

const getGroups = async (object) => {
  const queryStr = object
    ? `?${Object.keys(object)
        .map(key => `${key}=${object[key]}`)
        .join('&')}`
    : '';
  try {
    const result = await api.get(`/group/${queryStr}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getGroup = async id => {
  try {
    const result = await api.get(`/group/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getGroupByShop = async ({ domain, name, activationShopId }) => {
  try {
    const result = await api.get(`/group/shop/`, {
      params: {
        name,
        domain,
        activationShopId
      }
    });
    return result.data;
  } catch (err) {
    console.error(err);
    return { funnels: [] };
  }
};

const addFunnel = async datas => {
  try {
    const result = await api.put(`/group/addfunnel`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const addUser = async datas => {
  try {
    const result = await api.put(`/group/adduser`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteUser = async datas => {
  try {
    const result = await api.put(`/group/deleteuser`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const create = async data => {
  try {
    const result = await api.post(`/group`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteGroup = async id => {
  try {
    const result = await api.delete(`/group/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteFunnel = async datas => {
  try {
    const result = await api.put(`/group/deletefunnel`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteActivationPreset = async datas => {
  try {
    const result = await api.put(`/group/delete-activation-preset`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateName = async (id, datas) => {
  try {
    const result = await api.put(`/group/${id}`, datas);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (
  groupId,
  {
    name,
    userToAdd = [],
    userToDelete = [],
    funnelToAdd = [],
    funnelToDelete = []
  }
) => {
  const result = {
    name: null,
    usersDelete: null,
    usersAdd: null,
    funnelDelete: null,
    funnelAdd: null
  };
  if (name) result.name = await updateName(groupId, { name });
  if (userToAdd.length !== 0) {
    const addUserPromises = userToAdd.map(e =>
      addUser({
        groupId,
        userId: e._id
      })
    );
    result.usersAdd = await Promise.all(addUserPromises);
  }
  if (userToDelete.length !== 0) {
    const deleteUserPromises = userToDelete.map(e =>
      deleteUser({
        groupId,
        userId: e._id
      })
    );
    result.usersDelete = await Promise.all(deleteUserPromises);
  }
  if (funnelToAdd.length !== 0) {
    const addFunnelPromises = funnelToAdd.map(e =>
      addFunnel({
        groupId,
        funnelId: e._id
      })
    );
    result.funnelAdd = await Promise.all(addFunnelPromises);
  }
  if (funnelToDelete.length !== 0) {
    const deleteFunnelPromises = funnelToDelete.map(e =>
      deleteFunnel({
        groupId,
        funnelId: e._id
      })
    );
    result.funnelDelete = await Promise.all(deleteFunnelPromises);
  }
  return result;
};

const updateV2 = async (groupId, data) => {
  try {
    const result = await api.put(`/group/${groupId}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};



export default {
  getGroups,
  getGroup,
  create,
  update,
  updateV2,
  deleteGroup,
  addUser,
  deleteUser,
  addFunnel,
  deleteFunnel,
  deleteActivationPreset,
  getGroupByShop
};
