import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

import { fontSizes } from 'utils/constants';

/**
 * BaseSideItem
 * @component
 *
 */
export const BaseSideItem = styled.li`
  background-color: ${({ selected, themeColors }) =>
    selected ? themeColors.tertiary : 'transparent'};
  display: grid;
  grid-template-columns: 56px 1fr 4px;
  height: 50px;
  list-style: none;
  position: relative;
  transition: background-color 0.2s ease-out;
  cursor: pointer;
  .subtitle {
    color: ${({ themeColors }) => themeColors.light};
    opacity: 0.6;
    font-size: ${fontSizes.small};
  }
  a,
  svg {
    align-self: center;
  }
  a {
    text-decoration: none;
    color: ${({ themeColors }) => themeColors.light};
    font-size: 22px;
    display: inline-block;
  }
  p {
    margin: auto 0px;
    cursor: pointer;
  }
  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    background-color: ${({ themeColors }) => themeColors.tertiary};
  }
  .badge {
    opacity: ${({ selected }) => (selected ? '1' : '0')};
    transition: all 0.3s ease-out;
  }
`;
/**
 * BaseSideBar
 * @component
 *
 */
const BaseSideBar = styled.div`
  width: ${({ width }) => width || 'auto'};
  background-color: ${({ themeColors }) => themeColors.secondary};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : '622px')};
  color: ${({ themeColors }) => themeColors.light};
  h1 {
    border-bottom: 1px solid ${({ themeColors }) => themeColors.light};
    color: ${({ themeColors }) => themeColors.light};
    font-size: 40px;
    font-weight: bold;
    margin: 25px 16px 0px 16px;
    padding: 0 16px 0 0;
    text-align: center;
    text-align: left;
  }
  ul {
    padding: 0;
    margin-top: 0;
  }
  ${BaseSideItem} {
    .badge {
      background-color: ${({ panel, themeColors }) => themeColors[panel]};
    }
  }
`;

export default withTheme(BaseSideBar);
