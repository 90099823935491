import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { useNavigation } from '../../contexts/NavigationContext';

import SidebarActivation, {
  SideItemActivation
} from './components/SidebarActivation';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */

const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { menu, setMenu } = useNavigation();
  const { adminRights } = useUser();
  const linkInfo = [
    {
      name: 'config',
      svg: 'setting',
      subtitle: 'Gestion de la configuration PML du domain',
      surname: 'Configuration',
      enabled: adminRights?.pml?.config?.read || false
    },
    {
      name: 'shop',
      svg: 'activationShop',
      subtitle: 'Administration des points de ventes',
      surname: 'Points de ventes',
      enabled: adminRights?.pml?.shop?.read || false
    },
    {
      name: 'shopGroup',
      svg: 'menuInfo',
      subtitle: 'Administration des groupes de magasins',
      surname: 'Groupes de magasins',
      enabled: adminRights?.pml?.shopGroup?.read || false
    },
    {
      name: 'newsletter',
      svg: 'menuInfo',
      subtitle: 'Envoyer un email aux utilisateurs',
      surname: 'Newsletter',
      enabled: adminRights?.pml?.newsletter?.send || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarActivation>
      {linkInfo.map(link => {
        return (
          <SideItemActivation
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarActivation>
  );
};

export default SidebarContainer;
