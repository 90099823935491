import { api } from './api';

const getExtractAudiences = async () => {
  try {
    const result = await api.get(`/audiences/extract/`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getExtractAudiences
};
