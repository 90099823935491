import React from 'react';
import PropTypes from 'prop-types';
import { RotatingLines } from 'react-loader-spinner';

import { useTheme } from 'contexts/ThemeContext';

import { isEmptyObject } from 'utils/functions';

import { Item } from 'components/Layout';

import { BaseLoadingError } from './style';

/**
 * Loading
 * @component
 *
 */
const Loading = props => {
  const {
    error,
    loading,
    refetch,
    children,
    data,
    onLoadingSuccess,
    skip,
    color,
    width,
    flex
  } = props;
  const { panelColor, themeColors } = useTheme();
  const colorRules =
    themeColors[color] ||
    color ||
    themeColors[panelColor] ||
    themeColors.funnel;

  let canDislay = !error && !loading && !isEmptyObject(data);
  canDislay = canDislay || skip;
  const firstData = data && Object.values(data)[0];
  React.useEffect(() => {
    if (canDislay) {
      onLoadingSuccess(data, refetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading, firstData, canDislay, data]);

  return (
    <React.Fragment>
      {error && <BaseLoadingError>{error.message}</BaseLoadingError>}
      {loading && (
        <Item flex={flex}>
          <RotatingLines
            strokeColor={colorRules}
            strokeWidth="5"
            animationDuration="1"
            width={width}
            visible
          />
        </Item>
      )}
      {canDislay && children}
    </React.Fragment>
  );
};

Loading.defaultProps = {
  children: undefined,
  data: null,
  error: null,
  loading: false,
  onLoadingSuccess: () => null,
  refetch: () => {},
  skip: false,
  flex: null,
  color: null,
  width: '40'
};

Loading.propTypes = {
  children: PropTypes.node,
  data: PropTypes.objectOf(PropTypes.any),
  error: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  onLoadingSuccess: PropTypes.func,
  refetch: PropTypes.func,
  skip: PropTypes.bool,
  flex: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.string
};

export default Loading;
