import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import { useTheme } from 'contexts/ThemeContext';

import { LLM } from 'utils/rest';

import { Text } from 'components/DataDisplay';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';

export const Graph3D = () => {
  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(true);

  const { themeColors } = useTheme();

  const fetchDatas = async () => {
    const result = await LLM.getGraphics();
    setPoints(
      result.map(el => {
        return {
          text: el.config.label,
          x: [el.config.coordonee_3D.x],
          y: [el.config.coordonee_3D.y],
          z: [el.config.coordonee_3D.z],
          mode: 'markers',
          marker: {
            color: themeColors.llm,
            line: {
              visible: 'hidden',
              width: 0.5
            },
            showscale: false,
            opacity: 0.8
          },
          hoverlabel: { bgcolor: 'white', font: { family: 'Poppins' } },
          type: 'scatter3d'
        };
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      fetchDatas();
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Container>
      <Row>
        <Item>
          <Text fontWeight={600}>Visualisation de la bdd enrichie</Text>
        </Item>
        <Item style={{ height: '460px' }}>
          <Loading loading={loading}>
            <Plot
              data={points}
              showlegend={false}
              layout={{
                showlegend: false,
                scene: {
                  xaxis: {
                    showticklabels: false,
                    showgrid: true,
                    showline: false,
                    title: { text: '' }
                  },
                  yaxis: {
                    showticklabels: false,
                    showgrid: true,
                    showline: false,
                    title: { text: '' }
                  },
                  zaxis: {
                    showticklabels: false,
                    showgrid: true,
                    showline: false,
                    title: { text: '' }
                  }
                },
                font: { family: 'Poppins' },
                width: '100%',
                margin: {
                  l: 0,
                  r: 0,
                  b: 0,
                  t: 0
                }
              }}
            />
          </Loading>
        </Item>
        <Item>
          <Button variant="contained" onClick={() => setLoading(true)}>
            Rafraichir
          </Button>
        </Item>
      </Row>
    </Container>
  );
};

export default Graph3D;
