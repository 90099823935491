import { api } from './api';

const get = async () => {
  try {
    const result = await api.get(`/v2/funnel/lineitems`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async data => {
  try {
    const result = await api.post(`/v2/funnel/lineitems`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, data) => {
  try {
    const result = await api.put(`/v2/funnel/lineitems/${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteOne = async id => {
  try {
    const result = await api.delete(`/v2/funnel/lineitems/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteByFunnelId = async id => {
  try {
    const result = await api.delete(`/v2/funnel/lineitems/byFunnelId/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteByIds = async ids => {
  try {
    const result = await api.delete(`/v2/funnel/lineitems/byIds`, {
      data: { ids }
    });
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createMany = async body => {
  try {
    const result = await api.post(`/v2/funnel/lineitems/createMany`, body);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default {
  get,
  create,
  update,
  deleteOne,
  deleteByFunnelId,
  deleteByIds,
  createMany
};
