import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from 'contexts/ThemeContext';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { AutocompleteTextField, IconButton, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const levelFunnel = {
  2: 'Visites',
  3: 'Engagements',
  4: 'Acquisitions'
};

const TypeSourceData = {
  funnelTag: 'tagErmes',
  crm: `audience issue d'un import`,
  media: `media`
};

export const StepsConfig = props => {
  const { onChange, values, config } = props;
  const { customInsights, hasTag } = config;
  const initialState = {
    steps: values
  };
  const [{ steps }, setState] = React.useState(initialState);
  const { themeColors } = useTheme();

  const handlechange = (index, type, obj) => value => {
    setState(prevState => {
      const states = { ...prevState };
      states.steps[index][type] = Object.keys(obj).find(
        key => obj[key] === value
      );
      states.steps[index].data =
        states.steps[index][type] === 'funnelTag'
          ? null
          : states.steps[index].data;
      return states;
    });
    onChange(steps);
  };

  const handlechangeAudience = index => value => {
    setState(prevState => {
      const states = { ...prevState };
      states.steps[index].data = value?.importKey;
      return states;
    });
    onChange(steps);
  };

  const onAddFields = () => {
    const newSteps = [...steps];
    newSteps.push({
      level: '',
      data: null,
      type: ''
    });
    setState(prevState => {
      const states = { ...prevState };
      states.steps = newSteps;
      return states;
    });
  };

  const onDelete = index => () => {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    setState(prevState => {
      const states = { ...prevState };
      states.steps = newSteps;
      onChange(states.steps);
      return states;
    });
  };

  let listLevel = ['Visites', 'Engagements', 'Acquisitions'];
  steps.forEach(el => {
    listLevel = listLevel.filter(e => e !== levelFunnel[el.level]);
  });

  return (
    <Container spacing={0}>
      {steps.map((el, index) => {
        return (
          <Row spacing={1} justify="flex-start">
            <Item xs={3} justify="flex-start">
              <Text>Niveau de funnel *</Text>
              <Select
                options={
                  el.level === ''
                    ? listLevel
                    : [...listLevel, levelFunnel[el.level]]
                }
                small
                value={levelFunnel[el.level]}
                onChange={handlechange(index, 'level', levelFunnel)}
                placeholder="Funnel leads"
              />
            </Item>
            <Item xs={4} justify="flex-start">
              <Text>Type source data</Text>
              <Select
                options={
                  hasTag
                    ? [
                        TypeSourceData.funnelTag,
                        TypeSourceData.crm,
                        TypeSourceData.media
                      ]
                    : [TypeSourceData.crm, TypeSourceData.media]
                }
                small
                value={TypeSourceData[el.type]}
                onChange={handlechange(index, 'type', TypeSourceData)}
                placeholder=""
              />
            </Item>
            {el.type === 'crm' && (
              <Item xs={4} justify="flex-start">
                <Text>Audience</Text>
                <AutocompleteTextField
                  options={customInsights?.map(e => ({
                    name: e.name,
                    importKey: e.importKey
                  }))}
                  formatLabel={p => p.name}
                  small
                  value={customInsights.find(e => e.importKey === el.data)}
                  onChange={handlechangeAudience(index)}
                  placeholder="customInsight"
                />
              </Item>
            )}
            <Item xs={1} justify="flex-start" alignItems="flex-end">
              <IconButton onClick={onDelete(index)}>
                <DeleteIcon style={{ color: themeColors.light }} />
              </IconButton>
            </Item>
          </Row>
        );
      })}
      {steps.length < 3 && (
        <Row spacing={1} justify="flex-start">
          <Item xs={3}>
            <ButtonCreate
              justify="flex-start"
              onClick={onAddFields}
              text="Ajouter un niveau"
            />
          </Item>
        </Row>
      )}
    </Container>
  );
};

StepsConfig.defaultProps = {
  onChange: () => null,
  values: [],
  config: []
};
StepsConfig.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.objectOf(PropTypes.any),
  config: PropTypes.arrayOf(PropTypes.any)
};

export default StepsConfig;
