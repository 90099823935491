// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip as TooltipMui } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { Container, Item, Row } from 'components/Layout';

import { Text } from './Text';

export const Tooltip = props => {
  const { children, title, isIcon, disabled, ...rest } = props;
  const { themeColors } = useTheme();
  const useStylesBootstrap = makeStyles(() => ({
    arrow: {
      color: themeColors.light
    },
    tooltip: {
      color: themeColors.dark,
      backgroundColor: themeColors.light,
      fontSize: 12,
      maxWidth: '500px'
    }
  }));
  const classes = useStylesBootstrap();
  const Content = () => (
    <Container spacing={1}>
      <Row spacing={1} alignItems="center">
        <Item flex>
          {typeof title === 'string' ? (
            <Text font="secondary" variant="inherit" color={themeColors.dark}>
              {title}
            </Text>
          ) : (
            title
          )}
        </Item>
      </Row>
    </Container>
  );
  return (
    <TooltipMui
      classes={classes}
      title={<Content />}
      arrow
      disableHoverListener={disabled}
      {...rest}
    >
      {isIcon ? children : <div>{children}</div>}
    </TooltipMui>
  );
};

Tooltip.defaultProps = {
  disabled: false,
  children: null,
  title: ''
};
Tooltip.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default Tooltip;

export const HelpTooltip = ({ width = '20px', ...rest }) => {
  const { themeColors } = useTheme();
  const style = {
    color: themeColors.light,
    width,
    ...rest.style
  };
  return (
    <Tooltip isIcon {...rest}>
      <HelpIcon style={style} />
    </Tooltip>
  );
};

HelpTooltip.defaultProps = {};
HelpTooltip.propTypes = {};
