import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { checkRefreshToken, cleanLocalStorage } from './index';

const AuthTokenContainer = ({ msInterval, redirectOnError, publicRoutes }) => {
  const [watcherInterval, setWatcherInterval] = React.useState(0);
  const history = useHistory();
  const location = useLocation();

  window.addEventListener('onError401', () => {
    cleanLocalStorage();
    history.push(redirectOnError);
  });

  const watcher = useCallback(() => {
    if (publicRoutes.includes(location.pathname)) return;
    checkRefreshToken().then(success => {
      if (!success) {
        cleanLocalStorage();
        history.push(redirectOnError);
      }
    });
  }, [history, location.pathname, publicRoutes, redirectOnError]);

  useEffect(() => {
    watcher();
    clearInterval(watcherInterval);
    setWatcherInterval(setInterval(watcher, msInterval));
    // eslint-disable-next-line
  }, [msInterval, watcher]);

  return <></>;
};

export default AuthTokenContainer;
AuthTokenContainer.defaultProps = {
  msInterval: 60000,
  redirectOnError: '/',
  publicRoutes: []
};
AuthTokenContainer.propTypes = {
  msInterval: PropTypes.number,
  redirectOnError: PropTypes.string,
  publicRoutes: PropTypes.arrayOf(PropTypes.string)
};
