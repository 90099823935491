import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useTheme } from 'contexts/ThemeContext';

import moment from 'utils/moment';
import { referencial } from 'utils/ref';
import { FILEUPLOAD } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { DatePicker } from 'components/DatePicker';
import { FormSection } from 'components/FormSection';
import {
  Button,
  Checkbox,
  IconButton,
  Select,
  Switch,
  TextAera,
  TextField as CustomTextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';
import { Paper } from 'components/Surfaces';

const findType = (name, importModel) => {
  const modelSelect = importModel.find(el => el.value === name);
  const options = [];
  if (modelSelect?.json) {
    options.push('JSON');
  }
  if (modelSelect?.csv) {
    options.push('CSV');
  }
  return options;
};

const JsonSection = ({
  value: valueJsonSection = '',
  onChange,
  error,
  fieldGenerators,
  menuIndex,
  isCampaignManagement
}) => {
  const convertStrToJson = str => {
    let json = {};
    try {
      json = JSON.parse(str);
    } catch {
      console.error('not a validate json');
    }
    return json;
  };

  const { themeColors } = useTheme();
  const [tab, setTab] = useState(menuIndex || (fieldGenerators ? 0 : 1));
  const [state, setState] = useState(convertStrToJson(valueJsonSection));
  const [jsonState, setJsonState] = useState(valueJsonSection);
  const [refresh, setRefresh] = useState(false);
  const [loadedSections, setAllSections] = useState();

  const fetchData = async fieldsToLoad => {
    const newSections = await Promise.all(
      fieldsToLoad.sections.map(async section => {
        const newfields = await Promise.all(
          section.fields.map(async field => {
            if (field.source) {
              const sourceRslt = await referencial[field.source]();
              return { ...field, selectOptions: sourceRslt };
            }
            return { ...field };
          })
        );
        return {
          ...section,
          fields: newfields
        };
      })
    );

    setAllSections(newSections);
  };

  useEffect(() => {
    if (refresh) {
      const json = convertStrToJson(jsonState);
      setState(json);
      onChange(tab, jsonState);
      setRefresh(false);
    }
    if (fieldGenerators) {
      fetchData(fieldGenerators);
    }
    // eslint-disable-next-line
  }, [jsonState, fieldGenerators]);

  const handleChange = (type, key, format) => data => {
    const newStates = { ...state };
    switch (type) {
      case 'TextField':
        newStates[key] = typeof data === 'object' ? data : data.trim();
        break;
      case 'Select':
        newStates[key] = typeof data === 'object' ? data : data.trim();
        break;
      case 'Switch':
        newStates[key] = data;
        break;
      case 'Checkbox':
        newStates[key] = data.target.checked;
        break;
      case 'ListField':
        newStates[key] = format ? data.map(el => el[key]) : data;
        break;
      case 'DatePicker':
        newStates[key] = data.date?.format(format) || '';
        break;
      default:
        break;
    }

    setState(newStates);
    const txt = JSON.stringify(newStates, null, 4);
    setJsonState(txt);
    onChange(tab, txt);
  };

  const getField = ({
    type,
    key,
    label,
    labels,
    required,
    format,
    inputs = [],
    description,
    tooltips = [],
    options = [],
    selectOptions = [],
    fieldsFormat = [],
    autoFilled
    // eslint-disable-next-line consistent-return
  }) => {
    if (isCampaignManagement && autoFilled) {
      return null;
    }

    switch (type) {
      case 'TextField':
        return (
          <CustomTextField
            small
            title={label || key}
            required={required}
            tooltip={description}
            bgColor={themeColors.primary}
            value={state[key]}
            onChange={handleChange('TextField', key)}
            onBlur={handleChange('TextField', key)}
          />
        );
      case 'Checkbox':
        return (
          <Checkbox
            title={label || key}
            required={required}
            tooltip={description}
            onChange={handleChange('Checkbox', key)}
            checked={!!state[key]}
          />
        );
      case 'Switch':
        return (
          <Switch
            title={label || key}
            required={required}
            tooltip={description}
            onChange={handleChange('Switch', key)}
            checked={!!state[key]}
          />
        );
      case 'Select':
        return (
          <Select
            title={label || key}
            required={required}
            tooltip={description}
            onChange={handleChange('Select', key)}
            small
            options={selectOptions}
            value={state[key]}
            bgColor={themeColors.primary}
          />
        );
      case 'ListField':
        return (
          <ListField
            labels={labels || [label || key]}
            values={
              state[key] && state[key].length
                ? state[key]?.map(el => {
                    if (inputs?.length) {
                      return el;
                    }
                    return { [key]: el };
                  })
                : []
            }
            tooltips={tooltips}
            onChange={handleChange('ListField', key, inputs?.length <= 1)}
            actionTxt="Ajouter"
            deleteAction
            variant="customFields"
            inputs={inputs && inputs.length ? inputs : ['TextField']}
            options={options && options.length ? options : [key]}
            fieldsFormat={fieldsFormat}
            bgColor={themeColors.primary}
          />
        );
      case 'DatePicker':
        return (
          <DatePicker
            title={label || key}
            required={required}
            tooltip={description}
            onClose={handleChange('DatePicker', key, format || 'DD/MM/YYYY')}
            initialDate={
              moment(state[key], format || 'DD/MM/YYYY').isValid()
                ? moment(state[key], format || 'DD/MM/YYYY')
                : undefined
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <Container>
      <Row justify="center">
        <Item xs={5} alignItems="flex-start">
          <Row spacing={2} justify="center">
            {fieldGenerators && (
              <Item
                xs={6}
                alignItems="flex-start"
                onClick={() => setTab(0)}
                justify="flex-end"
              >
                <Text
                  fontWeight={tab === 0 ? 700 : 400}
                  style={{
                    fontSize: '14px',
                    borderBottom:
                      tab === 0 ? `3px solid ${themeColors.funnel}` : ''
                  }}
                >
                  Formulaire
                </Text>
              </Item>
            )}
            <Item
              xs={6}
              alignItems="flex-start"
              onClick={() => setTab(1)}
              justify="flex-start"
            >
              <Text
                fontWeight={tab === 1 ? 700 : 400}
                style={{
                  fontSize: '14px',
                  borderBottom:
                    tab === 1 ? `3px solid ${themeColors.funnel}` : ''
                }}
              >
                Éditeur JSON
              </Text>
            </Item>
          </Row>
        </Item>
      </Row>
      {tab === 0 && (
        <Row spacing={1} justify="flex-start">
          <Item justify="flex-start">
            {loadedSections?.map(section => (
              <Row spacing={1}>
                <Item justify="flex-start">
                  <FormSection title={section.name}>
                    <Container>
                      {section.fields.map(field => (
                        <Row spacing={0}>
                          <Item
                            xs={field.type !== 'ListField' ? 6 : 12}
                            justify="flex-start"
                          >
                            <Container>
                              <Row spacing={1} justify="flex-start">
                                <Item xs={11} justify="flex-start">
                                  {getField(field)}
                                </Item>
                              </Row>
                            </Container>
                          </Item>
                          <Item xs={6} />
                        </Row>
                      ))}
                    </Container>
                  </FormSection>
                </Item>
              </Row>
            ))}
          </Item>
        </Row>
      )}
      {tab === 1 && (
        <Row spacing={1} justify="flex-start">
          <Item justify="flex-start">
            <TextAera
              color={themeColors.funnel}
              onChange={txt => {
                setRefresh(true);
                setJsonState(txt);
              }}
              error={error}
              value={jsonState}
              bgColor={themeColors.primary}
            />
          </Item>
        </Row>
      )}
    </Container>
  );
};

const ConnectorSection = ({
  value,
  onChange,
  onDelete,
  config,
  index,
  AnalyticsOnly
}) => {
  const { themeColors } = useTheme();
  const inputFile = useRef(null);
  const { importModel, configGenerators, configConnexion } = config;

  const [connector, setConnector] = useState(value);
  const [open, setOpen] = useState(value.open);
  const [selectOptions, setSelectOptions] = useState(
    value.name ? findType(value.name, importModel) : ['JSON', 'CSV']
  );
  const [connectorCustom, setConnectorCustom] = useState(value?.mediaName);
  const [errorTextAera, setErrorTextAera] = useState(false);

  useEffect(() => {
    setConnector(value);
    if (value.name) {
      setSelectOptions(findType(value.name, importModel));
    }
  }, [value, importModel]);

  const handleCheckJson = txt => {
    try {
      JSON.parse(txt);
      return true;
    } catch (error) {
      return false;
    }
  };

  const onJsonSectionChange = (menuIndex, data) => {
    setErrorTextAera(!handleCheckJson(data) && data.length);
    setConnector({ ...connector, config: data });
    onChange(index, { ...connector, config: data, menuIndex });
  };

  const onSwitchStateChange = () => {
    setConnector({
      ...connector,
      disabled: !connector.disabled
    });
    onChange(index, { ...connector, disabled: !connector.disabled });
  };

  const handleChangeTextCustom = mediaName => {
    setConnectorCustom(mediaName);
    setConnector({ ...connector, type: 'CSV', mediaName });
    onChange(index, { ...connector, type: 'CSV', mediaName });
  };

  const handleOpen = isOpen => {
    setOpen(isOpen);
    setConnector({ ...connector, open: isOpen });
    onChange(index, { ...connector, open: isOpen });
  };

  const handleSelectOptions = option => {
    setSelectOptions(findType(option, importModel));
  };

  const deleteUploadFile = name => {
    let newFile = connector.config.filter(el => el.name !== name);
    if (!newFile.length) {
      newFile = null;
    }
    setConnector({ ...connector, config: newFile });
    onChange(index, {
      ...connector,
      config: newFile
    });
  };

  const handleUploadFile = event => {
    let fileReceived = [];
    Object.entries(event.target.files).forEach(([_key, val]) => {
      fileReceived.push(val);
    });
    if (connector.config && connector.config.length) {
      const oldNameTab = connector.config.map(el => el.name);
      fileReceived = fileReceived.filter(
        el => oldNameTab.indexOf(el.name) === -1
      );
      fileReceived = [...fileReceived, ...connector.config];
    }

    setConnector({ ...connector, config: fileReceived });
    onChange(index, {
      ...connector,
      config: fileReceived
    });
  };

  const configGenerator = configGenerators.find(
    el =>
      el.connectorName ===
      importModel.find(model => model.value === connector.name)?.connectorName
  );

  const download = async path => {
    const datas = await FILEUPLOAD.download(path);
    const fileName = path.split('/').pop();
    const contentType = datas.headers['content-type'];
    const blob =
      datas.data instanceof Blob
        ? datas.data
        : new Blob([datas.data], {
            type: contentType
          });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const model = importModel.find(el => el.value === connector.name);
  const isCampaignManagement = model?.campaignManagement && AnalyticsOnly;

  return (
    <Container>
      <Row spacing={2}>
        <Item>
          <Paper
            style={{
              backgroundColor: themeColors.dark,
              borderRadius: '3px',
              border: '0px',
              flex: 1
            }}
            elevation={0}
          >
            <Collapse in={open} collapsedSize="48px" style={{ flex: 1 }}>
              <Container>
                <Row alignItems="center" spacing={0}>
                  <Item flex>
                    <IconButton
                      onClick={() => {
                        handleOpen(!open);
                      }}
                    >
                      {!open && (
                        <ChevronRightIcon
                          style={{ color: themeColors.light }}
                        />
                      )}
                      {open && (
                        <ExpandMoreIcon style={{ color: themeColors.light }} />
                      )}
                    </IconButton>
                  </Item>
                  <Item xs justify="flex-start">
                    {connector.name ? (
                      <Text bold>
                        {model?.label}
                        {connector.mediaName && ` - ${connector.mediaName}`}
                      </Text>
                    ) : (
                      <>
                        <Text>Connecteurs</Text>
                        <Autocomplete
                          options={importModel}
                          getOptionLabel={option => option.label}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Connecteur"
                              fullWidth
                            />
                          )}
                          onChange={(_, value) => {
                            const name = value?.value || '';
                            setConnector({ ...connector, name });
                            handleSelectOptions(name);
                          }}
                          style={{ width: '100%' }}
                        />
                      </>
                    )}
                  </Item>
                  <Item flex>
                    <Switch
                      checked={!connector.disabled}
                      onChange={onSwitchStateChange}
                    />
                  </Item>

                  <Item flex>
                    <IconButton
                      onClick={() => {
                        onDelete(index);
                      }}
                    >
                      <DeleteIcon style={{ color: themeColors.light }} />
                    </IconButton>
                  </Item>
                </Row>
                {connector.name && connector.name !== 'custom' && (
                  <Row>
                    <Item xs justify="flex-start">
                      <Text>Type</Text>
                      <Select
                        bgColor={themeColors.primary}
                        onChange={type => {
                          setConnector({
                            ...connector,
                            type,
                            config: null,
                            connexionId: null
                          });
                        }}
                        options={selectOptions}
                        value={connector.type}
                        small
                        disabled={isCampaignManagement}
                      />
                    </Item>
                    {connector.type === 'JSON' && (
                      <Item xs justify="flex-start">
                        <Text>Connexion</Text>
                        <Select
                          bgColor={themeColors.primary}
                          onChange={connexion => {
                            setConnector({
                              ...connector,
                              connexionId: connexion
                            });
                            onChange(index, {
                              ...connector,
                              connexionId: connexion
                            });
                          }}
                          options={configConnexion.filter(
                            el => model?.connectorName?.indexOf(el.model) !== -1
                          )}
                          value={connector.connexionId}
                          small
                        />
                      </Item>
                    )}
                  </Row>
                )}
                {connector.type === 'JSON' && (
                  <JsonSection
                    onChange={onJsonSectionChange}
                    error={errorTextAera}
                    connectorName={connector.name}
                    value={connector.config ? connector.config : ''}
                    fieldGenerators={configGenerator}
                    menuIndex={configGenerator ? connector.menuIndex : 1}
                    isCampaignManagement={isCampaignManagement}
                  />
                )}

                {connector.name === 'custom' && (
                  <Row spacing={1} justify="flex-start">
                    <Item xs={6} justify="flex-start">
                      <Select
                        value={connectorCustom}
                        options="media"
                        onChange={handleChangeTextCustom}
                        defaultValue="Custom name"
                        disabled={connector.config && connector.config.length}
                        small
                      />
                    </Item>
                  </Row>
                )}
                {connector.type === 'CSV' && (
                  <>
                    {connector.config &&
                      connector.config?.map(el => {
                        return (
                          <Row spacing={2} justify="flex-start">
                            <Item justify="flex-start">
                              <Paper style={{ flex: 1 }}>
                                <Container>
                                  <Item xs={10} justify="flex-start">
                                    <Text
                                      style={{
                                        padding: '0px 15px',
                                        cursor: 'pointer'
                                      }}
                                      bold
                                      color="funnel"
                                      onClick={() => download(el.path)}
                                    >
                                      {el.name}
                                    </Text>
                                  </Item>
                                  <Item xs={2}>
                                    <IconButton
                                      onClick={() => deleteUploadFile(el.name)}
                                    >
                                      <DeleteIcon
                                        style={{ color: themeColors.light }}
                                      />
                                    </IconButton>
                                  </Item>
                                </Container>
                              </Paper>
                            </Item>
                          </Row>
                        );
                      })}
                    <Row spacing={2} justify="flex-start">
                      <Item justify="flex-start">
                        <input
                          type="file"
                          ref={inputFile}
                          onChange={handleUploadFile}
                          style={{ display: 'none' }}
                          accept=".csv"
                          multiple
                        />
                        <Button
                          onClick={() => inputFile?.current.click()}
                          variant="contained"
                          color={themeColors.background}
                          textColor={themeColors.dark}
                        >
                          <>
                            <AddIcon style={{ fill: themeColors.dark }} />
                            Ajouter un fichier CSV
                          </>
                        </Button>
                      </Item>
                    </Row>
                  </>
                )}
              </Container>
            </Collapse>
          </Paper>
        </Item>
      </Row>
    </Container>
  );
};

const ConnectorsListField = ({
  onChange,
  value = [],
  AnalyticsOnly = false,
  importModel,
  configGenerators,
  configConnexion
}) => {
  const add = () => {
    onChange([
      ...value,
      { name: null, type: null, config: null, open: true, disabled: false }
    ]);
  };

  const handleDelete = index => {
    const copyConnectors = [...value];
    copyConnectors.splice(index, 1);
    onChange(copyConnectors);
  };

  const handleChange = (index, connector) => {
    const copyConnectors = [...value];
    copyConnectors[index] = { ...connector };
    onChange(copyConnectors);
  };

  const filteredImportModel = AnalyticsOnly
    ? importModel.filter(model => model.campaignManagement)
    : importModel;

  return (
    <Container key={`ConnectorSection_${value.length}`}>
      {value.map((el, index) => {
        return (
          <ConnectorSection
            config={{
              importModel: filteredImportModel,
              connectors: value,
              configGenerators,
              configConnexion
            }}
            value={el}
            onChange={handleChange}
            onDelete={handleDelete}
            index={index}
            AnalyticsOnly={AnalyticsOnly}
          />
        );
      })}
      <Row spacing={1} justify="flex-start">
        <Item flex style={{ padding: '10px' }}>
          <ButtonCreate onClick={add} text="Ajouter un connecteur" />
        </Item>
      </Row>
    </Container>
  );
};

export default ConnectorsListField;
