import { api } from './api';

export const getAll = async () => {
  try {
    const result = await api.get(`/v2/funnel/yougovSettings/segments`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const create = async datas => {
  try {
    const result = await api.post(`/v2/funnel/yougovSettings/segments`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const update = async (id, datas) => {
  try {
    const result = await api.put(
      `/v2/funnel/yougovSettings/segments/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const deleteSegments = async id => {
  try {
    const result = await api.delete(`/v2/funnel/yougovSettings/segments/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export default { getAll, create, update, deleteSegments };
