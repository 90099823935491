import React, { useContext, useReducer } from 'react';


/**
 *
 *
 We want the context to be undefined in case of errors
*/
const CustomerStateContext = React.createContext(undefined);
const CustomerDispatchContext = React.createContext(undefined);

export const CustomerActionsTypes = {
  UPDATE_CUSTOMER: 'update_customer'
};

const defaultState = {
  customers: [],
  selectedCustomer: {},
  presets: [],
  groups: [],
  countries: [],
  activableMedias: [],
};

const CustomerReducer = (state, action) => {
  switch (action.type) {
    case CustomerActionsTypes.UPDATE_CUSTOMER: {
      return {
        ...state,
        ...action.props
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const CustomerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CustomerReducer, defaultState);

  return (
    <CustomerStateContext.Provider value={state}>
      <CustomerDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerStateContext.Provider>
  );
};
export const useCustomerState = () => {
  const context = useContext(CustomerStateContext);
  if (context === undefined) {
    throw new Error('useCustomerState must be used within a CustomerProvider');
  }
  return context;
};

export const useCustomerDispatch = () => {
  const context = useContext(CustomerDispatchContext);
  if (context === undefined) {
    throw new Error('useCustomerDispatch must be used within a CustomerProvider');
  }
  return context;
};

export default CustomerProvider;

export const useCustomer = () => [useCustomerState(), useCustomerDispatch()];

