import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { HelpTooltip, Text } from 'components/DataDisplay';
import Fade from 'components/Fade';
import { Item, Row } from 'components/Layout';

import { FormatInput } from './format';

export const TextField = props => {
  const {
    color,
    defaultValue,
    disabled,
    error,
    errorText,
    format,
    helperText,
    onBlur,
    onChange,
    onEnter,
    small,
    show,
    label,
    placeholder,
    type,
    unit,
    value,
    multiline,
    rows,
    bgColor,
    fontSize,
    title,
    required,
    tooltip,
    cleanOnEnter
  } = props;
  const [valInput, setValInput] = React.useState(value);

  useEffect(() => {
    if (valInput !== value) {
      setValInput(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[color] || color || themeColors[panelColor];

  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },
      '& .MuiInputBase-root': {
        color: themeColors.light,
        backgroundColor: bgColor || 'transparent',
        fontSize
      },
      '& .Mui-disabled': {
        backgroundColor: themeColors.tertiary
      },
      '& .MuiInputAdornment-root p': {
        color: themeColors.light,
        fontSize
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });
  const classes = useStyles();
  const handleChange = e => {
    if (valInput !== e.target.value) {
      onChange(format ? e.target.rawValue : e.target.value);
      setValInput(e.target.value);
    }
  };
  const handleBlur = e => {
    onBlur(format ? e.target.rawValue : e.target.value);
    setValInput(e.target.value);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onEnter(format ? e.target.rawValue : e.target.value);
      if (cleanOnEnter) {
        setValInput('');
      }
    }
  };

  return (
    <Fade show={show}>
      <FormControl
        className={classes.root}
        variant="outlined"
        margin={small ? 'dense' : 'normal'}
        size={small ? 'small' : 'medium'}
        error={error}
        style={{
          marginTop: small && '6px',
          width: '100%'
        }}
      >
        {title && (
          <Row spacing={2} justify="flex-start" alignItems="center">
            <Item
              justify="flex-start"
              flex
              style={{
                maxWidth: tooltip ? 'calc(100% - 24px)' : null
              }}
            >
              <Text noWrap color={error ? 'error' : null} fontSize="14px">
                {title === '&nbsp;' ? <>&nbsp;</> : title}
                {required && '*'}
              </Text>
            </Item>
            {tooltip && (
              <Item flex style={{ alignItem: 'center' }}>
                <HelpTooltip title={tooltip} />
              </Item>
            )}
          </Row>
        )}
        {label && (
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        )}
        <OutlinedInput
          disabled={disabled}
          type={type}
          multiline={multiline}
          rows={rows}
          fullWidth
          label={label}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={disabled ? value : valInput}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          endAdornment={
            unit && <InputAdornment position="end">{unit}</InputAdornment>
          }
          inputProps={{
            format: `${format}`
          }}
          inputComponent={format ? FormatInput : 'input'}
          labelWidth={label && label.length * 8}
        />
        {((error && errorText) || helperText) && (
          <FormHelperText id="helper-text">
            {errorText || helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Fade>
  );
};

export default TextField;

TextField.defaultProps = {
  color: null,
  defaultValue: null,
  disabled: false,
  error: false,
  errorText: null,
  format: null,
  helperText: null,
  label: null,
  placeholder: null,
  onBlur: () => null,
  onChange: () => null,
  onEnter: () => null,
  show: null,
  small: true,
  type: 'text',
  unit: null,
  value: '',
  multiline: false,
  rows: 1,
  title: null,
  required: false,
  cleanOnEnter: false
};
TextField.propTypes = {
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  format: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])]),
  small: PropTypes.bool,
  type: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  title: PropTypes.string,
  required: PropTypes.bool,
  cleanOnEnter: PropTypes.bool
};
