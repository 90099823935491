import { api } from './api';

const get = async () => {
  try {
    const result = await api.get(`/v2/ramses/report`);
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async data => {
  try {
    const result = await api.post(`/v2/ramses/report`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const update = async (id, data) => {
  try {
    const result = await api.put(`/v2/ramses/report/${id}`, data);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deleteProperties = async id => {
  try {
    const result = await api.delete(`/v2/ramses/report/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const compute = async id => {
  try {
    const result = await api.post(`/v2/ramses/updateReport/${id}`);
    return result;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getLastReportsUpdateStatus = async reportIds => {
  try {
    const { data } = await api.post(`/v2/ramses/updateLogs/lastStatus`, {
      reportIds
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getReportUpdateStatus = async id => {
  try {
    const { data } = await api.get(`/v2/ramses/updateLogs/report/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  get,
  create,
  update,
  deleteProperties,
  compute,
  getLastReportsUpdateStatus,
  getReportUpdateStatus
};
