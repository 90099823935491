import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackward from '@mui/icons-material/ArrowBackIos';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Button } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const DeleteCustomerModal = ({ open, onClose, onConfirm, title, description }) => {
  const { themeColors } = useTheme();

  return (
    <Modal maxWidth="sm" open={open} onClose={onClose}>
      <Container>
        <Row spacing={2}>
          <Item>
            <Text fontWeight={700} fontSize="20px" align="center">
              {title}
            </Text>
          </Item>
          <Item>
            <Text fontWeight={400} fontSize="14px" align="center">
              {description}
            </Text>
          </Item>
          <Item justify="flex-start">
            <Row
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                style={{
                  borderRadius: '8px'
                }}
                color={themeColors.themeV2.detail}
                variant="contained"
                startIcon={
                  <ArrowBackward sx={{ color: themeColors.themeV2.black }} />
                }
                onClick={onClose}
              >
                <Text
                  color={themeColors.themeV2.black}
                  fontWeight={600}
                  fontSize="14px"
                >
                  Annuler
                </Text>
              </Button>
              <Button
                style={{ borderRadius: '8px' }}
                variant="contained"
                color="error"
                endIcon={<ArrowForward />}
                onClick={onConfirm}
              >
                <Text color="inherit" fontWeight={600} fontSize="14px">
                  Confirmer
                </Text>
              </Button>
            </Row>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

DeleteCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default DeleteCustomerModal;
