import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from 'contexts/ThemeContext';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import ListField from 'components/ListField';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  errorName: false,
  errorKey: false
};
export const ModalForm = props => {
  const { onValidate, config, actionName } = props;
  const { themeColors } = useTheme();
  const initialState = {
    name: config?.name,
    filters: config?.filters || []
  };
  const [{ name, filters }, setState] = React.useState(initialState);
  const [{ nameError }, setError] = React.useState(ErrorState);
  const toggleError = (key, state) => {
    setError(prevState => ({ ...prevState, [`${key}Error`]: state }));
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = field => value => {
    const newValue = typeof value === 'string' ? value.trim() : value;
    toggleError(field, !newValue);

    setState(prevState => ({
      ...prevState,
      [field]: newValue
    }));
  };

  const doAction = () => {
    let error = false;
    resetErrors();

    if (!name) {
      toggleError('name', true);
      error = true;
    }

    if (!error) {
      const datas = {
        name,
        filters: filters.map(filter => ({
          ...filter,
          values: filter.values.map(value => parseInt(value, 10))
        }))
      };
      onValidate(datas);
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.funnel}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName || 'Valider'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Segment">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Label"
                            small
                            value={name}
                            onChange={handleChange('name')}
                            error={nameError}
                            required
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Filtrage">
              <Container>
                <Row spacing={0}>
                  <Item justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <ListField
                            labels={['Id du filtre', 'Operation', 'Valeurs']}
                            values={filters.map(({ name, op, values }) => ({
                              name,
                              op,
                              values
                            }))}
                            onChange={handleChange('filters')}
                            actionTxt="Ajouter"
                            deleteAction
                            variant="customFields"
                            inputs={['TextField', 'Select', 'TextFieldArray']}
                            options={['name', 'op', 'values']}
                            isRequireds={[true, true, true]}
                            fieldsFormat={['', ['in'], '']}
                            bgColor={themeColors.primary}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionName: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;
