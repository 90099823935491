import React from 'react';

import { useTheme } from 'contexts/ThemeContext';

import { Text } from 'components/DataDisplay';
import { LinearProgress, Modal } from 'components/Feedback';
import { Container, Item, Row } from 'components/Layout';

export const ModalLoading = props => {
  const { themeColors } = useTheme();

  return (
    <Modal maxWidth="xs" {...props}>
      <Container>
        <Row>
          <Item>
            <Text
              color={themeColors.activation}
              fontSize="20px"
              variant="body1"
              fontWeight={600}
            >
              Veuillez patienter
            </Text>
          </Item>
        </Row>
        <Row style={{ paddingLeft: '0px', paddingTop: '24px' }}>
          <Item>
            <LinearProgress />
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalLoading.defaultProps = {};
ModalLoading.propTypes = {};

export default ModalLoading;
