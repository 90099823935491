import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { DOMAINCONFIG } from 'utils/rest';
import { updateDomain } from 'utils/rest/api';

const AppContext = React.createContext();

/**
 * AppProvider
 * @component
 */
export const AppProvider = props => {
  const { user } = useUser();
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [domainConfig, setDomainConfig] = useState(null);
  const [domainConfigs, setDomainConfigs] = useState([]);

  const resfreshDomainConfig = async () => {
    const result = await DOMAINCONFIG.get();
    setDomainConfig(result);
  };

  const resfreshDomainConfigs = async () => {
    const result = await DOMAINCONFIG.getAll();
    setDomainConfigs(result);
  };

  const loadApp = async () => {
    await Promise.all([resfreshDomainConfig(), resfreshDomainConfigs()]);
  };

  const setDomain = newDomain => {
    const exist = domainConfigs.find(el => el.domain === newDomain);
    if (exist) {
      setDomainConfig(exist);
      updateDomain(newDomain);
    }
  };

  useEffect(() => {
    if (user.id) {
      loadApp();
      updateDomain(user.domains?.[0]);
    }
    // eslint-disable-next-line
  }, [user]);

  const value = {
    domainConfig,
    setDomain,
    domainConfigs,
    searchFieldValue,
    setSearchFieldValue,
    resfreshDomainConfig
  };
  return <AppContext.Provider {...props} value={value} />;
};

export const useApp = () => {
  const context = React.useContext(AppContext);
  if (!context) throw new Error('AppContext must be in AppProvider');
  return context;
};

export default AppProvider;
