// READY TO STORYBOOK
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import Fade from 'components/Fade';

export const Column = props => {
  const { show } = props;
  return (
    <Fade show={show}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        {...props}
      />
    </Fade>
  );
};

Column.defaultProps = {
  show: null
};
Column.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['none'])])
};

export default Column;
